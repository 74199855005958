import useFetchCountries from "hooks/useFetchCountries";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchImages } from "services/auth";
import { fetchUserProfile } from "services/profile";
import Locale from "translations";
import imgIcon from "../../assets/images/customRegister/image-regular.svg";

import buildingIcon from "../../assets/images/customCompanyProfile/building.svg";
import materialIcon from "../../assets/images/customCompanyProfile/material-email.svg";
import metroIcon from "../../assets/images/customCompanyProfile/metro-earth.svg";
import phoneIcon from "../../assets/images/customCompanyProfile/phone.svg";

export default function ViewProfile() {
	const { userDetails, companyDetails, productsBuilder, commons } = Locale;
	const [countries] = useFetchCountries();

	const [userProfileState, setUserProfileState] = useState({
		name: "",
		email: "",
		country: 0,
		countryPhoneCode: null,
		phone: "",
		password: "",
		passwordConfirmation: "",
		companyLogo: null,
		filePreview: null,
		description: "",
		position: "",
		avatar: "",
		countryCode: null,
		spin: false,
		avatarID: null,
	});

	useEffect(() => {
		async function fetchData() {
			if (countries.length !== 0) {
				const user = await fetchUserProfile();
				const country = countries.find(
					(country) => country.nationality_id === user.nationality
				);
				setUserProfileState({
					...userProfileState,
					name: user.full_name,
					email: user.user_id,
					phone: user.phone.phone,
					position: user.position,
					avatar: user.avatar,
					description: user.extra_data?.description,
					country: { value: country.id, label: country.name },
					countryPhoneCode: country.phone_code,
					countryCode: country.code,
				});
			}
		}
		fetchData();
	}, [countries]);

	useEffect(() => {
		async function fetchData() {
			if (userProfileState.avatar) {
				const fetchImage = await fetchImages(userProfileState.avatar);
				if (fetchImage) {
					updateLogo(fetchImage);
					setUserProfileState({
						...userProfileState,
						filePreview: fetchImage,
					});
				}
			}
		}
		fetchData();
	}, [userProfileState.avatar]);

	const updateLogo = (file) => {
		setUserProfileState({ ...userProfileState, companyLogo: file });
	};

	return (
		<>
			<div className=" container bg-white  border rounded p-0">
				<div className="d-flex justify-content-between p-4 border-bottom">
					<h6 className="font-weight-bold sans-font">
						{userDetails.userProfile}
					</h6>
					<Link className="btn bg-nxt w-25" to={"/edit-user-profile"}>
						{userDetails.editProfileUser}
					</Link>
				</div>
				<div className="row m-0 p-5 align-items-center">
					<div className="col-3 px-4">
						<div className="image-uploader__outline position-relative bg-white img-center">
							<div className="img-holder ">
								<img
									src={
										userProfileState.filePreview
											? userProfileState.filePreview
											: imgIcon
									}
									className="img-fluid"
									alt="logo placeholder"
									accept="image/*"
								/>
							</div>
						</div>
					</div>
					<div className="col-8">
						<div className="d-flex">
							<img src={buildingIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font">{userDetails.fullName}</p>
								<p className="m-0 font-weight-bold font-s20">
									{userProfileState.name}
								</p>
							</div>
						</div>

						<div className="d-flex mt-3 ">
							<img src={materialIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font">{companyDetails.businessEmail}</p>
								<p className="m-0 font-weight-bold font-s20">
									{userProfileState.email}
								</p>
							</div>
						</div>

						<div className="d-flex mt-3">
							<img src={metroIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font">{companyDetails.country}</p>
								<p className="m-0 font-weight-bold font-s20">
									{userProfileState.country?.label}
								</p>
							</div>
						</div>

						<div className="d-flex mt-3">
							<img src={phoneIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 dsnd-font">{userDetails.phoneNumber}</p>
								{userProfileState.phone ? (
									<>
										<span className="m-0 font-weight-bold font-s20">
											<span className={Locale._language === "ar" && "code-num"}>
												{userProfileState.countryPhoneCode}
											</span>
											<span>{userProfileState.phone} </span>
										</span>
									</>
								) : (
									productsBuilder.noResult
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
