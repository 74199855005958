import arrowRight from 'assets/images/icons/arrow-right-icons.svg';
import visaToken from "assets/images/printvisa/visa.png";
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import NumberField from 'components/Form/NumberField/NumberField';
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState
} from "context/marketplace";
import moment from 'moment';
import { useEffect, useState } from "react";
import { store } from 'react-notifications-component';
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	editRequestPassengers,
	sendRequestPassengers,
	splitRequestPassengers,
	viewVisaOnline
} from "services/VisaOnline";
import Locale from "translations";
import AddPassengers from "./components/AddPassengers";
import ListHeader from "./components/ListHeader";
import CustomModal from "./components/Modal";
import MRZ from "./components/MRZ";

export default function ListPassengers() {
	const { onlineVisaSearch, onlineVisaPassenger } = useMarketplaceState();
	let history = useHistory();
	const dispatch = useMarketplaceDispatch();
	const { onlineVisa, productsBuilder, success, visa } = Locale;
	const [ModalAddGroupMembers, setModalAddGroupMembers] = useState(false);
	const tokenSafaVisa = localStorage.getItem("tokenSafaVisa");
	const loginData = JSON.parse(localStorage.getItem("loginData"));
	const { status, id } = useParams();
	const location = useLocation();
	const [ModalNewMember, setModalNewMember] = useState(false);
	const [searchVal, setSearchVal] = useState(null);
	const [searching, setSearching] = useState(false);
	const { locale } = useSBSState();
	const [checkedIds, setCheckedIds] = useState(
		onlineVisaPassenger.map((item) =>
			item.id ? item.id : item.uniqueIdFrontend
		)
	);
	const [sendTravelersModal, setSendTravelersModal] = useState(false);
	const [passengersList, setPassengersList] = useState([]);
	const [serverPassengersList, setServerPassengersList] = useState([])
	const [visaDates, setVisaDates] = useState({ departure_date: null, return_date: null, })
	const [splitCount, setSplitCount] = useState(0);
	const [modalSplit, setModalSplit] = useState(false);
	const [downloadList, setDownloadList] = useState([]);
	const search = (value) => {
		setSearching(true);
		if (onlineVisaPassenger.length > 0 && value) {
			let filteredData = [];
			for (let index = 0; index < onlineVisaPassenger.length; index++) {
				for (const key in onlineVisaPassenger[index]) {
					if (
						onlineVisaPassenger[index][key]
							?.toString()
							.includes(value?.toLowerCase()) ||
						onlineVisaPassenger[index][key]
							?.toString()
							.includes(value?.toUpperCase())
					) {
						filteredData.push(onlineVisaPassenger[index]);
						break;
					}
				}
			}
			setPassengersList(filteredData);
			setSearching(false);
		} else {
			setPassengersList(onlineVisaPassenger);
			setSearching(false);
		}
	};


	const Clearsearch = () => {
		setPassengersList(onlineVisaPassenger);
		setSearchVal("");
	};

	const toggleModelAddGroupMembers = () => {
		setModalAddGroupMembers(!ModalAddGroupMembers);
	};

	const toggleModelSendTravelers = () => {
		setSendTravelersModal(!sendTravelersModal);
	}

	const toggleModelSplit = () => {
		setModalSplit(!modalSplit);
	}

	const toggleModelNewMember = () => {
		setModalNewMember(!ModalNewMember);
	};

	const addSelectedPassangers = (data) => {
		setPassengersList([...passengersList, ...data]);
		dispatch({
			type: "onlineVisaPassenger",
			payload: data.map(passenger => {
				return {
					...passenger,
					national_id: passenger?.nationality_id?.toString(),
					issue_country: passenger?.national_id?.toString() || passenger?.nationality_id?.toString(),
					first_name_ar: passenger?.full_name_ar?.split(" ")[0],
					father_name_ar: passenger?.full_name_ar?.split(" ")[1],
					grandfather_name_ar: passenger?.full_name_ar?.split(" ")[2],
					last_name_ar: passenger?.full_name_ar?.split(" ")[3],
					first_name_en: passenger?.full_name_en?.split(" ")[0],
					father_name_en: passenger?.full_name_en?.split(" ")[1],
					grandfather_name_en: passenger?.full_name_ar?.split(" ")[2],
					last_name_en: passenger?.full_name_en?.split(" ")[3],
				}
			}),
		});
		toggleModelAddGroupMembers();
	};

	const removePassanger = (id) => {
		let newFIlteredPassanger = onlineVisaPassenger.filter(
			(item) => id !== item.id && id !== item.uniqueIdFrontend
		);
		setPassengersList(newFIlteredPassanger);
		dispatch({
			type: "onlineVisaPassengerRemove",
			payload: newFIlteredPassanger,
		});
		let newCheckedIds = checkedIds.filter((item) => item != id);
		setCheckedIds(newCheckedIds);
		setSearchVal("");
	};

	function handelCheck(passenger, checked) {
		if (checked) {
			setDownloadList([...downloadList, passenger.id])
		} else {
			setDownloadList(downloadList.filter((item) => item != passenger.id));
		}
	}
	function handelCheckAll(checked) {
		if (checked) {
			setDownloadList(serverPassengersList.map(passenger => passenger.id));
		} else {
			setDownloadList([]);
		}
	}


	const allPassengers =
		passengersList?.length > 0 && status != "edit" && status != "view"
			? passengersList?.map((item) => {
				return (
					<tr>
						<td className="d-flex align-items-center">
							<input
								type="checkbox"
								checked={downloadList.includes(item.id)}
								className="mx-1 insert-group-checkbox "
								onChange={(e) => {
									handelCheck(item, e.target.checked);
								}}
							/>
							{item.passport_number}
						</td>
						<td>
							{locale === "en"
								?
								item.name_en ? item.name_en : item.full_name_en
								:
								item.name_ar ? item.name_ar : item.full_name_ar
							}
						</td>
						<td>{item.gender}</td>
						<td>{item.birth_date}</td>
						<td>{item.age}</td>
						<td>{item?.relationship[locale] ? item?.relationship[locale] : item?.relationship ? item?.relationship : ""}</td>
						<td>{item.national_id}</td>
						<td>{item.nationality}</td>
						<td>{item?.visa ? item?.visa : "-"}</td>
						{status != "edit" && status != "view" ? (
							<td>
								<i class="fas fa-trash-alt text-danger pointer"
									onClick={() => {
										removePassanger(item.id ? item.id : item.uniqueIdFrontend);
									}}
								></i>
							</td>
						) : null}
						<td>
							<span className='pointer'>
								{status === "edit" &&
									<img src={arrowRight} alt="" onClick={() => {
										dispatch({ type: "onlineVisaEditPassenger", payload: item })
										history.push('/visa-requests/Edit/Passenger')
									}} />
								}
							</span>
						</td>
					</tr>
				);
			})
			: [];

	const allPassengersEdit =
		(passengersList?.length > 0 && status === "edit") || status === "view"
			? passengersList?.map((item) => {
				return (
					<tr>
						<td className="d-flex align-items-center">
							<input
								type="checkbox"
								checked={downloadList.includes(item.id)}
								className="mx-1 insert-group-checkbox "
								onChange={(e) => {
									handelCheck(item, e.target.checked);
								}}
							/>
							{item.passport_number}
						</td>
						<td>{locale === "ar" ? item.full_name_ar : item.full_name_en}</td>
						<td>{item.gender}</td>
						<td>{item.date_of_birth}</td>
						<td>{item.age}</td>
						<td>{item.relationship ? item.relationship[locale] : ""}</td>
						<td>{item.national_id}</td>
						<td>{item.nationality}</td>
						<td>{item?.visa ? item?.visa : "-"}</td>
						{status == "edit" ?
							<td>
								<i class="fas fa-trash-alt text-danger pointer" onClick={() => removePassanger(item.id ? item.id : item.uniqueIdFrontend)}></i>
							</td>
							:
							<td></td>
						}
						<td>
							<span className='pointer'>
								{status === "edit" &&
									<img src={arrowRight} alt="" onClick={() => {
										dispatch({ type: "onlineVisaEditPassenger", payload: item })
										history.push('/visa-requests/Edit/Passenger')
									}} />
								}
							</span>
						</td>
					</tr >
				);
			})
			: [];

	const fetchVisaOnline = async (saveNewTravelers) => {
		if (location.state !== 'editPassenger' || saveNewTravelers) {
			const response = await viewVisaOnline(id);
			if (response.status === 200 || response.status === 201) {
				let searchKeys = response.data.data;
				dispatch({
					type: "onlineVisaSearch",
					payload: {
						destination: searchKeys?.destination_name,
						destination_id: searchKeys?.destination_id,
						pax: searchKeys?.pax,
						visaType: {
							id: searchKeys?.visa_type_id,
							name: searchKeys?.visa_type_name,
							currency_name: searchKeys?.currency,
							price: searchKeys.price_per_one,
						},
						residence: {
							value: searchKeys.residence_id,
							label: searchKeys.residence_name,
						},
						embassy: {
							value: searchKeys.embassy_id,
							label: searchKeys.embassy_name,
						},
						transportation_type: {
							value: searchKeys.transportation_type,
							label: searchKeys.transportation_type,

							currency: searchKeys?.currency,
							price: searchKeys.price_per_one,
						},
						departure_date: searchKeys?.departure_date,
						return_date: searchKeys?.return_date,
						requestDate: searchKeys?.request_date,
						expirytDate: searchKeys?.expiration_date,
						status: searchKeys?.status,
					},
				});
				dispatch({
					type: "onlineVisaPassengerRemove",
					payload: searchKeys.passengers,
				});
				setPassengersList(searchKeys.passengers);
				setServerPassengersList(searchKeys.passengers);
			}
		} else {
			setPassengersList(onlineVisaPassenger)
		}
	};
	// start edit
	useEffect(() => {
		if (status === "edit" || status === "view") {
			fetchVisaOnline(false);
		}
	}, [status]);

	function successfulRequest(response, redirect) {
		store.addNotification({
			title: "Success!",
			message: `${response.data.message}`,
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 5000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
		if (redirect) {
			history.push("/inventory/visa-requests");
			dispatch({
				type: "onlineVisaPassengerRemove",
				payload: [],
			});
		}
	}

	async function splitTravelers() {
		const response = await splitRequestPassengers(id);
		if (response.status === 200) {
			successfulRequest(response, true);
		}
	}

	async function saveTravalers() {
		const data = {
			request_id: id,
			passengers: passengersList.map(passenger => {
				return {
					...passenger,
					national_id: passenger?.nationality_id?.toString(),
					issue_country: passenger?.national_id?.toString() || passenger?.nationality_id?.toString(),
					first_name_ar: passenger?.full_name_ar?.split(" ")[0],
					father_name_ar: passenger?.full_name_ar?.split(" ")[1],
					grandfather_name_ar: passenger?.full_name_ar?.split(" ")[2],
					last_name_ar: passenger?.full_name_ar?.split(" ")[3],

					first_name_en: passenger?.full_name_en?.split(" ")[0],
					father_name_en: passenger?.full_name_en?.split(" ")[1],
					grandfather_name_en: passenger?.full_name_ar?.split(" ")[2],
					last_name_en: passenger?.full_name_en?.split(" ")[3],
				}
			})
		}
		console.log(data);
		const response = await editRequestPassengers(data)
		if (response.status === 200) {
			successfulRequest(response, false);
			fetchVisaOnline(true);
		}
	}

	async function sendTravelers() {
		const data = {
			request_id: id,
			departure_date: moment(visaDates?.departure_date).format("YYYY-MM-DD"),
			return_date: moment(visaDates?.return_date).format("YYYY-MM-DD"),
		}
		const response = await sendRequestPassengers(data)
		if (response.status === 200) {
			successfulRequest(response, true);
			setSendTravelersModal(false);
		}
	}
	// redirect if the user changed the url
	useEffect(() => {
		if (status) {
			dispatch({ type: "redirectURL", payload: location.pathname })
		}
		if (onlineVisaSearch.status === "waiting") {
			history.push(`/inventory/visa-requests/view/${id}`);
		}
		return () => dispatch({ type: "redirectURL", payload: location.pathname })
	}, [])

	return (
		<>
			<div className="list-container">
				<div>
					<ListHeader />
				</div>

				<div className="mt-3 bg-white border rounded ">
					<header className=" p-3 border-bottom d-flex justify-content-between align-items-center">
						<p className="m-0 font-weight-bold">
							{onlineVisa.Travelers}
							({onlineVisaPassenger?.length}/{" "}{onlineVisaSearch?.pax})
						</p>
						{status != "view" ?
							<button type='button' className="btn bg-nxt m-1"
								disabled={serverPassengersList.length === 0 || serverPassengersList.length === +onlineVisaSearch?.pax}
								onClick={() => {
									setModalSplit(true);
									setSplitCount(+onlineVisaSearch?.pax - serverPassengersList.length);
								}}
							>
								{onlineVisa.split}
							</button>
							:
							null
						}
					</header>

					<div className="d-flex border-top px-3 align-items-center justify-content-between List-passengers-header">
						{status == "edit" ?
							<div className="col-4 p-0">
								<button className="btn bg-nxt m-1"
									disabled={passengersList.length === Number(onlineVisaSearch.pax)}
									onClick={toggleModelAddGroupMembers}
								>
									{onlineVisa.InsertFromGroups}
								</button>
								<button className="btn bg-nxt m-1"
									disabled={passengersList.length === Number(onlineVisaSearch.pax)}
									onClick={toggleModelNewMember}
								>
									{onlineVisa.AddNew}
								</button>
							</div>
							:
							status === "view" && onlineVisaSearch.status !== 'issued' ? <div className="col-4 p-0"></div> : null
						}
						{onlineVisaSearch.status == 'issued' && status != "edit" ?
							<div className="col-4">
								{/* <button className="btn bg-nxt m-1"
									disabled={downloadList.length === 0}
								// onClick={toggleModelNewMember}
								>
									{inventory.messages.downloadSelectedVisas}
								</button> */}
							</div>
							:
							null
						}

						{/* search in Travelers  */}
						<div className="col-6 m-0  justify-content-end d-flex align-items-end py-2 ">
							<div className=" mx-2 w-50">
								<TextField
									label={onlineVisa.search}
									placeholder={onlineVisa.search}
									value={searchVal}
									onChange={(e) => {
										search(e.target.value);
										setSearchVal(e.target.value);
									}}
								/>
							</div>

							<div className="clear text-secondary pointer my-2">
								<button className="btn bg-white px-4  onlineVisa-clear-bottom text-gray" onClick={() => Clearsearch()}>
									<i class="fas fa-undo mx-1"></i>
									{onlineVisa.clear}
								</button>
							</div>
						</div>

					</div>
				</div>
				{/* passengers list */}
				<div className="border">
					<table class="table-update p-2  m-0 bg-white  table table-striped">
						<thead>
							<tr>
								<th>
									<input
										type="checkbox"
										checked={serverPassengersList.length > 0 && serverPassengersList.length === downloadList.length}
										onChange={(e) => {
											handelCheckAll(e.target.checked);
										}}
										className="mx-1 insert-group-checkbox "
									/>
									{onlineVisa.PassportNumber}
								</th>
								<th>{onlineVisa.Name}</th>
								<th>{onlineVisa.Gender}</th>
								<th>
									<i class="fas fa-sort"></i>
									{onlineVisa.Birthday}
								</th>
								<th>{onlineVisa.Age}</th>
								<th>{onlineVisa.Relationship}</th>
								<th>{onlineVisa.NationalId}</th>
								<th>{onlineVisa.Nationality}</th>
								<th>{visa.Visa}</th>
								{status != "view" ? <th>{onlineVisa.actions}</th> : null}
							</tr>
						</thead>
						<tbody>
							{passengersList.length > 0 && !searching ? (
								status === "edit" || status === "view" ? (
									allPassengersEdit
								) : (
									allPassengers
								)
							) : (
								<tr className="">
									<td
										colSpan={9}
										className="text-center online-visa-noResult  "
									>
										<p className="online-visa-noResult-tr">
											{searching ? "" : "No Travelers Added"}
											{searching && (
												<span
													className="spinner-border spinner-border-sm mx-2"
													role="status"
												/>
											)}
										</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				{/* footer */}
				{status != "view" ? (
					<footer className=" d-flex  justify-content-between  align-items-center py-4">
						<button type="button" className="btn btn-apply mt-2 px-3 py-2"
							onClick={() => history.push(`/inventory/visa-requests/`)}
						>
							{onlineVisa.backTobooking}
						</button>
						{/* save current passengers */}
						<div className="d-flex align-items-center">
							{status != "view" ? (
								<>
									<button type="button" class="btn  btn-green-300 mt-2 px-3 py-2"
										disabled={serverPassengersList.length !== +onlineVisaSearch?.pax}
										onClick={() => {
											setSendTravelersModal(true);
										}}
									>
										{onlineVisa.sendTravelers}
									</button>
									<button type="button" class="btn bg-nxt mt-2 px-3 py-2 mx-2"
										disabled={serverPassengersList.length === passengersList.length || passengersList.length === 0}
										onClick={saveTravalers}
									>
										{productsBuilder.save}
									</button>
								</>
							) : null}
						</div>
					</footer>
				) : null}
			</div>
			{/* InsertFromGroups */}
			<CustomModal
				modalIsOpen={ModalAddGroupMembers}
				header={onlineVisa.InsertFromGroups}
				toggle={toggleModelAddGroupMembers}
				size={tokenSafaVisa ? "xl" : "md"}
				centered={false}
			>
				{tokenSafaVisa && loginData?.safa_key ? (
					<AddPassengers
						checkedIds={checkedIds}
						setCheckedIds={setCheckedIds}
						addSelectedPassangers={addSelectedPassangers}
					/>
				) : (
					<>
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div>
								<img src={visaToken} alt="" />
							</div>
							<p className="m-0 my-3">You must login with safa visa account</p>
							<div className="text-center w-100">
								<button
									className="btn bg-nxt w-75"
									onClick={() => {
										history.push("/visa/login");
									}}
								>
									LOGIN
								</button>
							</div>
						</div>
					</>
				)}
			</CustomModal>

			<CustomModal
				modalIsOpen={modalSplit}
				header={onlineVisa.split}
				toggle={toggleModelSplit}
				size={"md"}
				centered={true}
			>
				<div className='d-flex flex-column justify-content-center align-items-center w-75 m-auto py-5'>
					<NumberField
						type={"number"}
						hasLabel={true}
						readOnly={true}
						disabled={true}
						removeArrow={true}
						label={onlineVisa.count}
						placeholder={onlineVisa.enterCount}
						value={splitCount}
						name="split_count"
					/>
					<button type='button' className="btn bg-nxt m-1 w-100" onClick={splitTravelers}
					>
						{success.confirm}
					</button>
				</div>
			</CustomModal>
			{/* send travelers modal */}
			<CustomModal
				modalIsOpen={sendTravelersModal}
				header={onlineVisa.sendTravelers}
				toggle={toggleModelSendTravelers}
				size={"md"}
				centered={true}
			>
				<div className='d-flex flex-column justify-content-center align-items-center w-75 m-auto py-3'>
					<div className="col-12">
						<DatePickerField
							label={onlineVisa.Departure}
							date={visaDates.departure_date}
							onChangeDate={(departureDate) => {
								setVisaDates({
									...visaDates,
									departure_date: departureDate,
									return_date: moment(departureDate) >= moment(visaDates?.return_date) ? null : visaDates?.return_date,
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(0, "d"), "day")
							}
						/>
					</div>
					<div className="col-12">
						<DatePickerField
							label={onlineVisa.Return}
							date={visaDates.return_date}
							onChangeDate={(returnDate) => {
								setVisaDates({
									...visaDates,
									return_date: returnDate,
									departure_date: moment(returnDate) <= moment(visaDates?.departure_date) ? null : visaDates?.departure_date,
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(1, "d"), "day")
							}
						/>
					</div>
					<button type='button' className="btn bg-nxt m-1 w-100" onClick={sendTravelers}>
						{success.confirm}
					</button>
				</div>
			</CustomModal>

			<CustomModal
				modalIsOpen={ModalNewMember}
				header={onlineVisa.ScanFromMRZ}
				toggle={toggleModelNewMember}
				size={"md"}
				centered={false}
			>
				<MRZ checkedIds={checkedIds} />
			</CustomModal>
		</>
	);
}
