import { useSBSState } from "context/global";
import Locale from "translations";

export const GroupCard = ({ groupInfo }) => {
	const { visa } = Locale;
	const { locale } = useSBSState();
	const status = [
		{ id: 0, name: "status 1" },
		{ id: 1, name: "status 2" },
		{ id: 2, name: "status 3" },
	];
	return (
		<div className="group-card agents">
			<div className="agent-item hayya pt-2">
				<div className="agent-container   ">
					<div className="agent-name ">
						<h3 className="dark-blue-color">{visa.GroupCard}</h3>
					</div>

					<div className="agent-content bg-white d-flex justify-content-between">
						<div className="row align-items-center w-40 no-gutter m-0  p-3">
							<div className="d-flex justify-content-between  w-100">
								<h4>TAG</h4>
								<p>{groupInfo?.tag}</p>
							</div>
							<div className="d-flex justify-content-between  w-100">
								<h4>{visa.CONTRACT}</h4>
								<p>{groupInfo?.contract ? groupInfo?.contract : "-"}</p>
							</div>
							<div className="d-flex justify-content-between  w-100">
								<h4>{visa.GroupStatus}</h4>
								<p>
									{locale == "en"
										? groupInfo?.status.status_name_la
										: groupInfo?.status.status_name_ar}
								</p>
							</div>
							<div className="d-flex justify-content-between  w-100">
								<h4>{visa.departureDate}</h4>
								<p>{groupInfo?.travel_date}</p>
							</div>
						</div>

						<div className="row align-items-center w-40 no-gutter m-0 p-3">
							<div className="d-flex justify-content-between  w-100">
								<h4>{visa.LevelType}</h4>
								<p>{groupInfo?.level}</p>
							</div>

							<div className="d-flex justify-content-between  w-100">
								<h4>{visa.COEMBASSY}</h4>
								<p>
									{locale == "en"
										? groupInfo?.embassy.EMB_NAME_EN
										: groupInfo?.embassy.EMB_NAME_AR}
								</p>
							</div>

							<div className="d-flex justify-content-between  w-100">
								<h4>{visa.totalNights}</h4>
								<p>{groupInfo?.nights}</p>
							</div>

							<div className="d-flex justify-content-between  w-100">
								<h4>{visa.arrivalDate}</h4>
								<p>{groupInfo?.return_date}</p>
							</div>
						</div>
						<span className="edit-group">
							<i class="far fa-edit mx-1"></i>
							{visa.edit}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
