import transactionGray from "assets/images/wallet/transactionGray.svg";
import Pagination from "components/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import { getWalletTransactions } from "services/wallet";
import Locale from "translations";
import FilterTransaction from "./FilterTransaction";

export default function Transaction({ id, currency }) {
	const { payment,inventory } = Locale;
	// let { id } = useParams();
	let history = useHistory();

	const [transactionList, setTransactionList] = useState([]);
	const [meta, setMeta] = useState();

	const [filter, setFilter] = useState({
		transaction_number: "",
		transaction_type: "",
		created_at: undefined,
		page: 1,
	});
	const [searchStatus, setSearchStatus] = useState("");
	useEffect(() => {
		async function fetchTransaction() {
			const res = await getWalletTransactions(id, {
				...filter,
				transaction_type: filter?.transaction_type?.title,
				created_at: filter.created_at
					? moment(filter.created_at).format("YYYY-MM-DD")
					: "",
				//page: 1,
			});
			setTransactionList(res.data);
			setMeta(res?.data?.meta);
		}
		fetchTransaction();
	}, [searchStatus, filter.page]);

	const search = () => {
		setSearchStatus(!searchStatus);
	};
	const AllTransactions =
		transactionList?.data?.length > 0 ? (
			transactionList?.data.map((productItem, index) => {
				return (
					<tr key={productItem.id}>
						<td>{productItem.transaction_number}</td>
						<td>{productItem.created_at
						}</td>
						<td>
							{productItem.amount.toFixed(2)} {currency}
						</td>
						<td>
							{productItem?.transaction_type == "deduct"
								? payment.messages.deduct
								: payment.messages.add}
						</td>
						<td>
						{productItem.payment_method} 

						</td>
						<td>
						{productItem.action_by} 

						</td>
{/* 						<td className="actions ">
 */}							{/* <span className="text-yellow font-15">
								<i class="fas fa-cloud-download-alt  text-yellow"></i>{" "}
								{payment.messages.download}
							</span>
							<span className="mx-4 text-yellow font-15">
								<i class="fas fa-print text-yellow"></i>{" "}
								{payment.messages.print}
							</span> */}
							{/* <span onClick={() => showTransactions(productItem.id)}>
								<i onClick={toggle} className="far fa-eye text-primary"></i>
							</span>

							<i className="fas fa-box-open text-primary"></i>
							<i className="fas fa-print fa-fw text-primary"></i>

							<i
								className="fas fa-trash-alt text-danger"
								onClick={() => removeItem(index)}
							></i> */}
{/* 						</td>
 */}					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="9">
					<div className="product-build__product-no-data fullHeight">
						{/* <i className="icx icx-eye-slash" aria-hidden="true"></i> */}
						<img src={transactionGray} alt="" width={50} height={50} />
						<h4 className="no-data-color">{"No Transactions Found"}</h4>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};

	return (
		<div className="wallet-wrraper0">
			<div className="product-builder-list bg-transparent ">
				<h6 className="font-weight-bold dark-blue-color my-4 mx-2">
					{payment.messages.allTransctions} ({transactionList?.data?.length})
				</h6>
				<FilterTransaction
					filter={filter}
					setFilter={setFilter}
					search={search}
				/>
				<div className='table-container'>
					<Table striped className="table-update p-2 bg-white ">
						<thead>
							<tr>
								<th>{payment.messages.transactionId}</th>
								<th>
									<i class="fas fa-sort"></i>
									{payment.messages.date}
								</th>
								<th>
									<i class="fas fa-sort"></i>
									{payment.messages.amount}
								</th>
								<th>{payment.messages.paymentType}</th>
								<th>{inventory.messages.PaymentMethod}</th>
								<th>{inventory.messages.action_by}</th>
{/* 								<th width="30%">{payment.messages.actions}</th>
 */}							</tr>
						</thead>
						<tbody>{AllTransactions}</tbody>
					</Table>
				</div>
				{transactionList?.data?.length > 0 ? (
					<Pagination info={meta} goTo={goTo} />
				) : null}
			</div>

			<div></div>
		</div>
	);
}
