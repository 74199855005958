import TextField from "components/Form/TextField/TextField";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import Accounting from "./accounting";
import ApplicationOwner from "./applicationOwner";
import ProductHeader from "./ProductHeader";
import TableContent from "./tableContent";
export default function Edit(props) {
	const history = useHistory();

	//tabel Date
	const data = [
		{
			ref: "Taha0000",
			app_no: "12",
			app_owner: "taha",
			pax: "20",
			booking_date: "12/5/2010",
			source: "Source Name",
			price: "250 EGP",
		},
		{
			ref: "Taha",
			app_no: "12",
			app_owner: "taha",
			pax: "20",
			booking_date: "12/5/2010",
			source: "Source Name",
			price: "250 EGP",
		},
		{
			ref: "Taha",
			app_no: "12",
			app_owner: "taha",
			pax: "20",
			booking_date: "12/5/2010",
			source: "Source Name",
			price: "250 EGP",
		},
	];
	const editRaw =
		data?.length > 0 ? (
			data?.map((member, index) => (
				<TableContent member={member} index={index} />
			))
		) : (
			<tr>
				<td colSpan="9">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result"}</h4>
					</div>
				</td>
			</tr>
		);

	return (
		<>
			<ProductHeader />

			<ApplicationOwner />

			<div className="container-custom content">
				<h3 className="font-weight-bold mx-1 mt-3" style={{ color: "#324356" }}>
					Travelers
				</h3>
				<div className="table-header d-flex align-items-center justify-content-between ">
					<div className="col-md-2">
						<TextField
							type="text"
							label={"search"}
							placeholder={"search"}
							//	value={filters.search}
							// onChange={(e) =>
							// 	setFilters({ ...filters, search: e.target.value })
							// }
						/>
					</div>
					<Link
						to="/aplication/add-traveler"
						className="btn btn-success px-4 py-2"
					>
						Add New
					</Link>
				</div>
				<Table striped className="custom-table ">
					<thead>
						<tr>
							<th>{"Traveler Name"}</th>
							<th>{"Type"}</th>
							<th>{"Gender"}</th>
							<th>{"Nationalty"}</th>
							<th>{"Passport Number "}</th>
							<th>{"ID Number"}</th>
							<th>{"Price"}</th>
							<th>{"Extra Item"}</th>
							<th width="10%">{"Actions"}</th>
						</tr>
					</thead>

					<tbody>{editRaw}</tbody>
				</Table>
			</div>

			<Accounting />
			<div className="container-custom d-flex justify-content-end py-3">
				<button className="btn btn-secondary mx-2 ">Cancel</button>
				<button className="btn btn-primary px-4">Save Changes</button>
			</div>
		</>
	);
}
