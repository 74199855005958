import "@fortawesome/fontawesome-free/css/all.min.css";
import ErrorModal from "components/ErrorModal";
import TechnicalSupport from "components/TechnicalSupport";
import { useSBSState } from "context/global";
import moment from "moment";
import "moment/locale/ar";
import { Suspense, useEffect } from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import "scss/style.scss";
import Locale from "translations";
import ErrorBoundary from "./components/Errors/ErrorBoundary/ErrorBoundary";
import Loader from "./components/Loader";
import AxiosConfiguration from "./helpers/axiosConfiguration";
import Routes from "./routes";

export default function App(props) {
	const { locale, loading } = useSBSState();
	Locale.setLanguage(locale);
	document
		.getElementsByTagName("html")[0]
		.setAttribute("dir", locale === "ar" ? "rtl" : "ltr");
	moment.locale("en");

	// Axios Configurations
	useEffect(() => {
		// const res = fetchAreasa();
	}, []);
	AxiosConfiguration();
	return (
		<>
			<TechnicalSupport />
			{loading && <Loader />}
			<ErrorModal />
			<div
				style={{
					direction: locale === "ar" ? "rtl" : "ltr",
					textAlign: locale === "ar" ? "right" : "left",
					fontFamily: locale === "ar" ? "Cairo" : "Cairo",
				}}
			>
				<ReactNotification />
				<ErrorBoundary>
					<Router>
						<Suspense fallback={<Loader />}>{Routes}</Suspense>
					</Router>
				</ErrorBoundary>
			</div>
		</>
	);
}
