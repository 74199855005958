import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "reactstrap";
import Locale from "translations";

const InventoryDataRow = ({
	productItem,
	index,
	toggleModalEdit,
	details,
	setSelected,
	toggleModalTerms,
	toggleModalPassenger,
	setCancellationReason,
	toggleModal,
	voucherData,
	dawnloadVoucher,
}) => {
	const { teamManagement, inventory } = Locale;
	let history = useHistory();
	const [tooltip, setTooltip] = useState({
		edit: false,
		voucher: false,
		passenger: false,
		terms: false,
		cancel: false,
	});

	return (
		<>
			<tr>
				<td>#{index}</td>
				<td>{productItem.from_date}</td>
				<td>{productItem.to_date}</td>
				<td className="set-w-11">
					{productItem?.passengers?.length > 0 ? (
						<span>
							{productItem?.passengers[0]?.first_name}{" "}
							{productItem?.passengers[0]?.last_name}
						</span>
					) : (
						""
					)}
					{/* {productItem?.passengers[0].first_name}{" "}
					{productItem?.passengers[0].last_name} */}
				</td>
				<td>
					{/* {productItem.room_rates && productItem.room_rates.length > 0 ? (
						productItem.room_rates.filter(
							(res) => +res.mealOptionId == +productItem.mealOptionId
						)[0].mealTitle
					) : (
						<span className="text-gray">-</span>
					)} */}
					{productItem?.name}
				</td>

				<td>{productItem.adults}</td>
				<td>{productItem.children}</td>
				<td>
					{productItem.room_rates && productItem.room_rates.length > 0 ? (
						productItem.room_rates.filter(
							(res) => +res.mealOptionId == +productItem.mealOptionId
						)[0]?.mealTitle
					) : (
						<span className="text-gray">-</span>
					)}
				</td>
				{/* <td>{productItem.maxOccupancy}</td> */}
				<td>
					{productItem.price} {details.currency}
				</td>
				<td width="19%" className="tools">
					{details?.reservation_status == "canceled" ? (
						<span className="text-danger p-2 mx-2 font-weight-bold">
							Canceled
						</span>
					) : (
						<div className="row d-flex " style={{ gap: "2px" }}>
							{!productItem.is_cancelled &&
							details.gds != 5 &&
							details.gds != 6 &&details.gds != 3 ? (
								<>
									<a
										className="mx-1 px-1 bg-yellow d-flex align-items-center"
										href={() => false}
										onClick={() => {
											toggleModalEdit();
											setSelected({
												...productItem,
												room_price_currency:
													productItem.room_rates[0]?.room_price_currency,
											});
										}}
										id={`tooltip-${index}`}
									>
										<i class="far fa-edit custom-icon-size mx-1 custom-icon-color text-white"></i>
										{/* <span className="custom-icon-color">{teamManagement.edit}</span> */}
									</a>
									<Tooltip
										placement="top"
										isOpen={tooltip.edit}
										autohide={false}
										toggle={() =>
											setTooltip({ ...tooltip, edit: !tooltip.edit })
										}
										target={`tooltip-${index}`}
										// style={{ background: "#83878b", color: "white" }}
									>
										{teamManagement.edit}
									</Tooltip>
								</>
							) : null}

							{details.gds != 5 ? (
								<>
									<a
										href={() => false}
										onClick={() => {
											voucherData(productItem?.bookingCode);
											setTimeout(() => {
												//dawnloadVoucher(productItem?.room_key);
											}, 3500);
										}}
										className="d-flex mx-1 align-items-center"
										style={{ background: "#28A745", height: "26px" }}
										id={`tooltip-${index}-voucher`}
									>
										<i class="fas fa-cloud-download-alt custom-icon-size mx-1 custom-icon-color  text-white"></i>
									</a>
									<Tooltip
										placement="top"
										isOpen={tooltip.voucher}
										autohide={false}
										toggle={() =>
											setTooltip({ ...tooltip, voucher: !tooltip.voucher })
										}
										target={`tooltip-${index}-voucher`}
										// style={{ background: "#83878b", color: "white" }}
									>
										{inventory.messages.voucher}
									</Tooltip>
								</>
							) : (
								""
							)}
							<>
								<a
									className="px-1 mx-1 d-flex align-items-center"
									href={() => false}
									onClick={() => {
										toggleModalTerms();
										setSelected({
											...productItem,
											room_price_currency:
												productItem.room_rates[0].room_price_currency,
										});
									}}
									style={{ background: "#0C3B5C", height: "26px" }}
									id={`tooltip-${index}-terms`}
								>
									<i class="fas fa-file-alt custom-icon-size mx-1 custom-icon-color text-white"></i>
									{/* <img src={info} alt="" className="mx-1" />
								<span className=" terms-color">
									{inventory.messages.termsAndConditions}
								</span> */}
								</a>
								<Tooltip
									placement="top"
									isOpen={tooltip.terms}
									autohide={false}
									toggle={() =>
										setTooltip({ ...tooltip, terms: !tooltip.terms })
									}
									target={`tooltip-${index}-terms`}
								>
									{inventory.messages.termsAndConditions}
								</Tooltip>
							</>
							<>
								{/* <a
									className=" d-flex mx-1 align-items-center"
									href={() => false}
									style={{ background: "#0C3B5C", height: "26px" }}
									onClick={() => {
										toggleModalPassenger();
									}}
									id={`tooltip-${index}-passenger`}
								>
									<i class="fas fa-users custom-icon-size mx-1 custom-icon-color text-white"></i>
								</a> */}
								{/* <Tooltip
									placement="top"
									isOpen={tooltip.passenger}
									autohide={false}
									toggle={() =>
										setTooltip({ ...tooltip, passenger: !tooltip.passenger })
									}
									target={`tooltip-${index}-passenger`}
								>
									{inventory.messages.passengers}
								</Tooltip> */}
							</>

							{!productItem.is_cancelled ? (
								<>
									<a
										href={() => false}
										onClick={() => {
											toggleModal();
											setSelected({
												...productItem,
												room_price_currency:
													productItem?.room_rates[0]?.room_price_currency,
											});
											setCancellationReason("");
										}}
										className="mx-1 px-1 d-flex align-items-center"
										style={{ background: "#FF6868", height: "26px" }}
										id={`tooltip-${index}-cancel`}
									>
										<i class="fas fa-ban mx-1 custom-icon-size text-white"></i>
										{/* {teamManagement.cancel} */}
									</a>
									<Tooltip
										placement="top"
										isOpen={tooltip.cancel}
										autohide={false}
										toggle={() =>
											setTooltip({ ...tooltip, cancel: !tooltip.cancel })
										}
										target={`tooltip-${index}-cancel`}
									>
										{teamManagement.cancel}
									</Tooltip>
								</>
							) : (
								<span className="text-danger p-2 mx-2 font-weight-bold">
									Canceled
								</span>
							)}
						</div>
					)}

					<div className="row"></div>
				</td>
			</tr>
		</>
	);
};

export default InventoryDataRow;
