import HotelGray from "assets/images/inventory/HotelGray.svg";
import HotelColored from "assets/images/inventory/HotelsColored.svg";
import classnames from "classnames";
import { useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Locale from "translations";
import GroundServices from "./GroundServices";
import TransportationRequests from "./Transportation";
import CustomTableInventoryHotel from "./Umrah-holidays-hotel";
import VisaRequests from "./VisaRequests";

export default function InventoryList() {
	const { state } = useLocation();
	const history = useHistory();
	const { permissions, role } = useSBSState();
	const { inventory } = Locale;
	const transportation =
		permissions?.includes("manage-transportations") || role == "super-admin";
	const hotals =
		permissions?.includes("manage-hotels") || role == "super-admin";
	const visas =
		permissions?.includes("manage-visa-requests") || role == "super-admin";
	const groundWork =
		permissions?.includes("manage-ground-work") || role == "super-admin";

	const [activeTab, setActiveTab] = useState(0);
	const { tab } = useParams();

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	useEffect(() => {
		switch (tab) {
			case "hotels":
				if (hotals) setActiveTab("1");
				break;
			case "transportations":
				setActiveTab("2");
				break;
			case "visa-requests":
				setActiveTab("3");
				break;
			case "ground-services":
				setActiveTab("4");
				break;
			default:
				setActiveTab("1");
				history.push("/inventory/hotels");
				break;
		}
	}, [tab, activeTab]);

	useEffect(() => {
		if ((permissions && permissions.length > 0) || role) {
			setActiveTab(
				tab == "hotels" && hotals
					? "1"
					: transportation
					? "2"
					: visas
					? "3"
					: groundWork
					? "4"
					: null
			);
		}
	}, [permissions, role]);

	return (
		<>
			{/* <FilterInventory /> */}
			<div className="list-container  inventory">
				<div className="bg-white rounded main-nav-sbs">
					<div className="tab-icons">
						<Nav tabs>
							<ShowForPermission permission={["manage-hotels"]}>
								<NavItem className="">
									<NavLink
										className={`border-0  ${classnames({
											active: activeTab === "1",
										})}`}
										onClick={() => {
											toggle("1");
											history.push("/inventory/hotels");
										}}
									>
										<div className="product-build-list-item">
											<img
												src={activeTab === "1" ? HotelColored : HotelGray}
												alt=""
											/>
											<span
												className={`text  mx-1 ${
													activeTab === "1" ? "title-Filter" : "txt-dis-tabs"
												} `}
											>
												{inventory.messages.hotels}
											</span>
										</div>
									</NavLink>
								</NavItem>
							</ShowForPermission>
						 {/* <ShowForPermission permission={["manage-transportations"]}>
								<NavItem className="position-relative">
									<NavLink
										className={classnames({ active: activeTab === "2" })}
										onClick={() => {
											toggle("2");
											history.push("/inventory/transportations");
										}}
										
									>
										<span className="Soon-span">{inventory.messages.soon}</span> 
										<div className="product-build-list-item">
											<img
												src={activeTab === "2" ? carColored : carCray}
												alt=""
											/>
											<span
												className={`text  mx-1 ${
													activeTab === "2" ? "title-Filter" : "txt-dis-tabs"
												} `}
											>
												{inventory.messages.transportation}
											</span>
										</div>
									</NavLink>
								</NavItem>
							</ShowForPermission>
							<ShowForPermission permission={["manage-visa-requests"]}>
								<NavItem className="position-relative">
									<NavLink
										className={classnames({ active: activeTab === "3" })}
										onClick={() => {
											toggle("3");
											history.push("/inventory/visa-requests");
										}}
										
									>
										<div className="product-build-list-item">
											<img
												src={activeTab === "3" ? visaColored : visaCray}
												alt=""
												width={25}
												style={{ marginTop: "-2px" }}
											/>
											<span
												className={`text  mx-1 ${
													activeTab === "3" ? "title-Filter" : "txt-dis-tabs"
												} `}
											>
												{inventory.messages.visaRequests}
											</span>
										</div>
									</NavLink>
								</NavItem>
							</ShowForPermission>
							<ShowForPermission permission={["manage-ground-work"]}>
								<NavItem className="position-relative">
									<NavLink
										className={classnames({ active: activeTab === "4" })}
										onClick={() => {
											toggle("4");
											history.push("/inventory/ground-services");
										}}
										
									>

										<div className="product-build-list-item">
											<img
												src={
													activeTab === "4" ? groundServiceAcive : groundService
												}
												alt=""
												width={25}
												style={{ marginTop: "-2px" }}
											/>
											<span
												className={`text  mx-1 ${
													activeTab === "4" ? "title-Filter" : "txt-dis-tabs"
												} `}
											>
												{inventory.messages.GroundServices}
											</span>
										</div>
									</NavLink>
								</NavItem>
							</ShowForPermission> */}
						</Nav>
					</div>

					<TabContent activeTab={activeTab} style={{ minHeight: "100%" }}>
						<TabPane tabId="1">
							{activeTab === "1" ? <CustomTableInventoryHotel /> : null}
						</TabPane>

						{/* Hotel Tab */}
						<TabPane tabId="2">
							{activeTab === "2" ? <TransportationRequests /> : null}
						</TabPane>
						{/* Visa Requests Tab */}
						<TabPane tabId="3">
							{activeTab === "3" ? <VisaRequests /> : null}
						</TabPane>

						<TabPane tabId="4">
							{activeTab === "4" ? <GroundServices /> : null}
						</TabPane>
					</TabContent>
				</div>
			</div>
		</>
	);
}
