import backgroundGround from "assets/images/groundService-banner.png";
import groundServiceAcive from 'assets/images/groundServiceAcive.svg';
import classnames from "classnames";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import Locale from "translations";
import GroundSearch from './GroundSearch';

export default function GroundService() {
  const { marketPlace } = Locale;
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    // activeTab === "1"
    //   ? setHotels(hotelInitial)
    //   : setHotels(transportationInitial);
  };


  return (
    <>
      <div className="market-search  mb-4">
        <div className="main-tabs w-100 p-0">
          <Nav tabs className="align-items-center main-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                {/* <i className="fas fa-hotel fa-fw fa-lg mx-1"></i> */}
                <img className="px-1" src={groundServiceAcive} alt={marketPlace.messages.groundService} srcSet="" />
                {marketPlace.messages.groundService}
              </NavLink>
            </NavItem>
          </Nav>

          <div className="heading col-md-10 py-3 m-auto">
            <h5 className=" mt-3 search-title terms-color text-uppercase">
              {marketPlace.messages.groundServiceRequest}
            </h5>
            <h3 className="mb-2 ">
              {" "}
              {marketPlace.messages.compeleteGroundServiceForm}
            </h3>
          </div>

          {/*****************Start Container Tab********************** */}

          {/*****************End Container Tab********************** */}

          <TabContent activeTab="1">
            <GroundSearch />
          </TabContent>
        </div>
        <div
          className="search-banner"
          style={{
            backgroundImage: `url(${backgroundGround})`,
            // height: `${clientHeight}px`,
          }}
        ></div>
      </div>
    </>
  );
}
