import reservationError from "assets/images/inventory/error.png";
import HotelPlaceHolder from "assets/images/new-hotels.svg";

import { useSBSDispatch, useSBSState } from "context/global";
import { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";
import { fetchCitiesHotelsSearchData, fetchCities } from "services/marketplace";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import useFetchCountries from "hooks/useFetchCountries";
import SelectField from "components/Form/SelectField/SelectField";
export default function CityModal(props) {
	const { isOpen, setIsOpen, onSubmit } = props;
	const { locale } = useSBSState();
	const { marketPlace, inventory, productElements } = Locale;

	const [destination, setDestination] = useState({
		country: null,
		city: null,
		hotel: null,
	});

	// const dispatch = useSBSDispatch();

	const closeModal = () => {
		setIsOpen(false);
	};


	const [countries] = useFetchCountries();


	const [cityListAuto, setCityListAuto] = useState([]);
	const getCityListAuto = async (inputValue) => {
		const country_id = destination?.country?.id;
		console.log(country_id);

		if (inputValue.length > 2) {
			const cities = await fetchCities("is_vacation", {
				search: inputValue,
				country_id,
			});

			let result = cities.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name + " - " + item.country.name[locale],
					value: item.id,
					label: item.name + " - " + item.country.name[locale],
				};
			});

			setCityListAuto(result);
		}
	};

	const [hotelListAuto, setHotelListAuto] = useState([]);
	const getHotelListAuto = async (inputValue) => {
		// debugger;
		const country_id = destination?.country?.id;
		const city_id = destination?.city?.id;
		if (inputValue.length > 2) {
			const hotels = await fetchCitiesHotelsSearchData({
				is_vacation: 1,
				search: inputValue,
				country_id: country_id,
				city_id: city_id,
			});
			let result = hotels.data.data.map((item) => {
				return {
					...item,
					id: item.id,
					country: {
						flag: HotelPlaceHolder,
					},
					name: item.name + " - " + item.city?.name[locale],
					value: item.id,
					label: item.name + " - " + item.city?.name[locale],
				};
			});
			setHotelListAuto(result);
		}
	};

	const handleChange = (e) => {
		const name = e.name;
		setDestination({
			...destination,
			[name]: e["value"], // countries[value],
		});
	};

	let cityPrevValue = useRef(null);
	let hotelPrevValue = useRef(null);
	function clearSearchText({ e, name }) {
		name === "city"
			? (cityPrevValue.current = destination.city)
			: (hotelPrevValue.current = destination.hotel);
		const value = e.target.value;
		if (value.length > 0) {
			setDestination({ ...destination, [name]: null });
		}
	}

	const submit = () => {
		onSubmit(destination);
		closeModal();
	};

	return (
		<>
			<Modal size="md" className="mt-10	" isOpen={isOpen}>
				<div className="p-3 border-bottom d-flex justify-content-between align-items-center">
					{window.location.href.indexOf("/Hotels/inventory/") > -1 ? (
						<p className="error-model-title ">
							{inventory.messages.EditReservation}
						</p>
					) : (
						<p className="error-model-title "></p>
					)}
					<i class="fas fa-times pointer" onClick={closeModal}></i>
				</div>

				<ModalBody className="payModal">
					<div className="col-md-12" style={{ position: "relative" }}>
						<SelectField
							name="contract"
							label={productElements.country}
							placeholder={productElements.countryPlaceholder}
							options={countries}
							value={destination?.country?.label}
							onChange={(e) => {
								handleChange({ name: "country", value: e });
							}}
						/>
					</div>
					<div className="col-md-12" style={{ position: "relative" }}>
						<AutoCompleteField
							hasLabel={true}
							flag={destination.city?.country?.flag}
							disabled={!destination?.country}
							listAuto={cityListAuto}
							setListAuto={setCityListAuto}
							getListAuto={getCityListAuto}
							label={productElements.city}
							isSearchable={true}
							placeholder={productElements.cityPlaceholder}
							value={destination.city?.name || ""}
							onFocus={(e) => clearSearchText({ e, name: "city" })}
							onBlur={() =>
								setDestination({ ...destination, city: cityPrevValue.current })
							}
							onChange={(e) =>
								handleChange({ name: "city", value: { name: e } })
							}
							onSelectValue={(e) => {
								handleChange({ name: "city", value: e });
							}}
							// color={errors?.city?.required ? "danger" : ""}
							// errors={errors?.city}
						/>
					</div>
					<div className="col-md-12" style={{ position: "relative" }}>
						<AutoCompleteField
							hasLabel={true}
							flag={destination.hotel?.country?.flag}
							disabled={!destination?.city}
							listAuto={hotelListAuto}
							setListAuto={setHotelListAuto}
							getListAuto={getHotelListAuto}
							label={productElements.hotel}
							isSearchable={true}
							placeholder={productElements.hotel}
							value={destination.hotel?.name || ""}
							onFocus={(e) => clearSearchText({ e, name: "hotel" })}
							onBlur={() =>
								setDestination({
									...destination,
									hotel: hotelPrevValue.current,
								})
							}
							onChange={(e) =>
								handleChange({ name: "hotel", value: { name: e } })
							}
							onSelectValue={(e) => {
								handleChange({ name: "hotel", value: e });
							}}
						/>
					</div>

					<div className="col-md-12">
						<div className="full-input search-button">
							<div className="full-input search-button">
								<button
									onClick={submit}
									disabled={!destination.hotel}
									className="btn w-100 bg-nxt mb-3 mt-3"
									type="button"
								>
									{marketPlace.messages.search}
								</button>
								{/* <Link className="form-control btn-primary text-center" to="/market-view">{marketPlace.messages.search}</Link> */}
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
