export function SafaLogoFull() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.477 38.282">
      <g id="Group_898" data-name="Group 898" transform="translate(-40.219 -456.291)">
        <g id="Group_870" data-name="Group 870" transform="translate(40.219 460.343)">
          <path id="Path_2717" data-name="Path 2717" d="M21.236,29.466a6.276,6.276,0,0,0-2.15.348,5.267,5.267,0,0,0-1.654.943,4.121,4.121,0,0,0-1.075,1.423,4.306,4.306,0,0,0-.38,1.82,2.157,2.157,0,0,0,.711,1.754,5.381,5.381,0,0,0,2.067.96q1.686.464,3.258.877t2.53.679a11.561,11.561,0,0,1,2.051.794,6.407,6.407,0,0,1,1.769,1.307,6.292,6.292,0,0,1,1.257,1.969,7.077,7.077,0,0,1,.479,2.713,8.554,8.554,0,0,1-.6,3.293,7.38,7.38,0,0,1-1.587,2.432,6.745,6.745,0,0,1-2.266,1.506,7.055,7.055,0,0,1-2.663.513H13v-.728a4.752,4.752,0,0,1,1.158-.512,6.733,6.733,0,0,1,1.918-.216H21.1a7.027,7.027,0,0,0,2.447-.414,5.613,5.613,0,0,0,1.935-1.174,5.273,5.273,0,0,0,1.257-1.82,6,6,0,0,0,.446-2.349,3.807,3.807,0,0,0-.876-2.615,6.445,6.445,0,0,0-3.093-1.622q-.265-.1-1.141-.331t-1.835-.479q-.96-.249-1.8-.464T17.4,39.791a6.159,6.159,0,0,1-1.869-.86,6.237,6.237,0,0,1-1.389-1.307,5.171,5.171,0,0,1-.86-1.637A5.94,5.94,0,0,1,13,34.2a5.807,5.807,0,0,1,.678-2.746,6.06,6.06,0,0,1,1.654-1.919,7.091,7.091,0,0,1,2.315-1.142,9.613,9.613,0,0,1,2.7-.381h7.64v.728a4.713,4.713,0,0,1-1.158.513,6.735,6.735,0,0,1-1.918.215Z" transform="translate(-13 -28.01)" fill="#1a1818" />
          <path id="Path_2718" data-name="Path 2718" d="M71.623,51.63a19.742,19.742,0,0,0-3.241.066,9.119,9.119,0,0,0-2.745.695,4.619,4.619,0,0,0-1.9,1.506,4.3,4.3,0,0,0-.745,2.5,4.124,4.124,0,0,0,.579,2.432A3.984,3.984,0,0,0,65.108,60.2a6.381,6.381,0,0,0,1.951.612,12.821,12.821,0,0,0,1.819.148h2.745ZM66.6,44.284a18.823,18.823,0,0,1,2.382.182,8.821,8.821,0,0,1,2.646.777A5.464,5.464,0,0,1,73.724,47a5.027,5.027,0,0,1,.81,3.111V62.286H68.018q-.662,0-1.538-.066a11.145,11.145,0,0,1-1.769-.281,10.286,10.286,0,0,1-1.753-.6,4.777,4.777,0,0,1-2.547-2.614,5.373,5.373,0,0,1-.364-2.234,5.1,5.1,0,0,1,1.075-3.276A6.782,6.782,0,0,1,63.8,51.283a12.48,12.48,0,0,1,3.688-.86,27.232,27.232,0,0,1,4.134-.05v-.166a4.134,4.134,0,0,0-.578-2.432A3.994,3.994,0,0,0,69.507,46.4a6.387,6.387,0,0,0-1.952-.612,12.822,12.822,0,0,0-1.819-.149H63.52v-.827a4.925,4.925,0,0,1,1.157-.381,9.632,9.632,0,0,1,1.918-.149" transform="translate(-40.434 -37.5)" fill="#1a1818" />
          <path id="Path_2719" data-name="Path 2719" d="M110.212,29.546a10.316,10.316,0,0,0-1.554.116,2.789,2.789,0,0,0-2.3,1.9,6.441,6.441,0,0,0-.314,2.416v1.092h4.167V35.9a4.9,4.9,0,0,1-1.157.381,9.629,9.629,0,0,1-1.918.149l-1.091-.033V52.843H103.2V36.4h-.86V35.072h.86V33.881a5.366,5.366,0,0,1,.661-3.078,4.938,4.938,0,0,1,1.82-1.754,6.936,6.936,0,0,1,2.348-.778,16.108,16.108,0,0,1,2.281-.182h2.977v.96a4.461,4.461,0,0,1-1.157.364,10.837,10.837,0,0,1-1.919.132" transform="translate(-65.097 -28.057)" fill="#1a1818" />
          <path id="Path_2720" data-name="Path 2720" d="M139.54,51.63a19.742,19.742,0,0,0-3.241.066,9.119,9.119,0,0,0-2.745.695,4.622,4.622,0,0,0-1.9,1.506,4.3,4.3,0,0,0-.745,2.5,4.127,4.127,0,0,0,.579,2.432,3.985,3.985,0,0,0,1.538,1.373,6.381,6.381,0,0,0,1.951.612,12.821,12.821,0,0,0,1.819.148h2.745Zm-5.027-7.346a18.823,18.823,0,0,1,2.382.182,8.82,8.82,0,0,1,2.646.777,5.464,5.464,0,0,1,2.1,1.754,5.027,5.027,0,0,1,.81,3.111V62.286h-6.516q-.662,0-1.538-.066a11.138,11.138,0,0,1-1.769-.281,10.287,10.287,0,0,1-1.753-.6,4.777,4.777,0,0,1-2.547-2.614,5.373,5.373,0,0,1-.364-2.234,5.1,5.1,0,0,1,1.075-3.276,6.779,6.779,0,0,1,2.679-1.935,12.48,12.48,0,0,1,3.688-.86,27.234,27.234,0,0,1,4.134-.05v-.166a4.135,4.135,0,0,0-.578-2.432,3.994,3.994,0,0,0-1.538-1.373,6.387,6.387,0,0,0-1.952-.612,12.822,12.822,0,0,0-1.819-.149h-2.216v-.827a4.925,4.925,0,0,1,1.157-.381,9.629,9.629,0,0,1,1.918-.149" transform="translate(-80.038 -37.5)" fill="#1a1818" />
        </g>
        <g id="Group_871" data-name="Group 871" transform="translate(147.603 460.324)">
          <path id="Path_2721" data-name="Path 2721" d="M291.179,30.606a2.327,2.327,0,0,0-.91-1.628l-.5.5c-.055-.056-.1-.111-.16-.168l-1.666,1.666a1.7,1.7,0,0,1,.985,1.591c0,.151-.039.378-.076.681a7.388,7.388,0,0,0,.687-.558c-.01.075-.019.142-.03.229,1.1-.795,1.667-1.552,1.667-2.311m-2.947,8.351a4.381,4.381,0,0,1,1.607-.3,3.933,3.933,0,0,1,2.614,1.023,4.021,4.021,0,0,1,1.477,2.387,13.828,13.828,0,0,1,.152,2.614v5.454a2.916,2.916,0,0,1-.214,1.2,3.4,3.4,0,0,0-2.462-.924h-1.92c.05-.031.1-.066.142-.1a2.249,2.249,0,0,0,1.552-1.718,2.8,2.8,0,0,1-1.477.454,3.447,3.447,0,0,1-2.879-1.78,5.982,5.982,0,0,1-1.1-3.41,5.74,5.74,0,0,1,1.288-3.787,4.644,4.644,0,0,1,1.22-1.118m29.7,2.619a7.56,7.56,0,0,1,3.12-.718,9.05,9.05,0,0,1,6.4,2.8,8.2,8.2,0,0,1,2.8,5.909,3.691,3.691,0,0,1-.371,1.771,3.9,3.9,0,0,0-2.758-.925H309.51a52.088,52.088,0,0,1,5.378-6.554,14.068,14.068,0,0,1,3.041-2.287m14.762,8.082a10.5,10.5,0,0,0-3.371-7.575,10.4,10.4,0,0,0-7.5-3.371,8.4,8.4,0,0,0-3.971,1.122q-3.909,1.793-8.415,7.689V40.909l-.657.657v-.328L307,43.018v7.4h-9.682q-.8,0-.8-.682V37.8l-.657.657v-.329l-.823.824a6.141,6.141,0,0,0-4.731-2.4,5.718,5.718,0,0,0-2.971.782,6.142,6.142,0,0,0-2.307,1.743,7.528,7.528,0,0,0-1.7,4.886,7.875,7.875,0,0,0,1.136,4.128,5.764,5.764,0,0,0,2.413,2.324H273.036V27.965L270.6,30.073V52.525h19.126a5.629,5.629,0,0,1,2.083.265,2.4,2.4,0,0,1,1.4,1.439,4.842,4.842,0,0,0,1.02-.491,3.742,3.742,0,0,0,1.8-1.437.744.744,0,0,0,.516.224h29.2a7.364,7.364,0,0,1,1.931.189,2.8,2.8,0,0,1,1.4,1.213l.076.113.076.114a3.081,3.081,0,0,0,1.35-.486c1.4-.469,2.111-1.81,2.111-4.009" transform="translate(-270.599 -27.965)" fill="#1a1818" />
        </g>
        <g id="Group_872" data-name="Group 872" transform="translate(108.828 456.291)">
          <path id="Path_2722" data-name="Path 2722" d="M216.617,27.7l4.079-2.363,4.064-2.35L220.7,20.64l-4.079-2.349V27.7Z" transform="translate(-200.345 -18.291)" fill="#1a1818" />
          <path id="Path_2723" data-name="Path 2723" d="M236.152,29.56l4.064,2.35,4.079,2.363-4.079,2.35-4.064,2.349V29.56Z" transform="translate(-211.736 -24.862)" fill="#1a1818" />
          <path id="Path_2724" data-name="Path 2724" d="M236.152,63.14l4.064-2.349,4.079-2.349-4.079-2.365-4.064-2.35V63.14Z" transform="translate(-211.736 -38.955)" fill="#1a1818" />
          <path id="Path_2725" data-name="Path 2725" d="M224.761,63.14,220.7,60.791l-4.079-2.349,4.079-2.365,4.064-2.35V63.14Z" transform="translate(-200.345 -38.955)" fill="#1a1818" />
          <path id="Path_2726" data-name="Path 2726" d="M236.152,76.306l4.064,2.35,4.079,2.349-4.079,2.35L236.152,85.7v-9.4Z" transform="translate(-211.736 -52.122)" fill="#1a1818" />
          <path id="Path_2727" data-name="Path 2727" d="M185.71,29.56l-4.064,2.35-4.065,2.363,4.065,2.35,4.064,2.349V29.56Z" transform="translate(-177.581 -24.862)" fill="#5a5b5e" />
          <path id="Path_2728" data-name="Path 2728" d="M197.081,29.56l4.079,2.35,4.064,2.363-4.064,2.35-4.079,2.349V29.56Z" transform="translate(-188.953 -24.862)" fill="#5a5b5e" />
          <path id="Path_2729" data-name="Path 2729" d="M185.71,63.14l-4.064-2.349-4.065-2.349,4.065-2.365,4.064-2.35V63.14Z" transform="translate(-177.581 -38.955)" fill="#5a5b5e" />
          <path id="Path_2730" data-name="Path 2730" d="M185.71,76.306l-4.064,2.35-4.065,2.349,4.065,2.35L185.71,85.7v-9.4Z" transform="translate(-177.581 -52.122)" fill="#5a5b5e" />
          <path id="Path_2731" data-name="Path 2731" d="M197.081,76.306l4.079,2.35,4.064,2.349-4.064,2.35L197.081,85.7v-9.4Z" transform="translate(-188.953 -52.122)" fill="#5a5b5e" />
          <path id="Path_2732" data-name="Path 2732" d="M205.225,27.7l-4.064-2.363-4.079-2.35,4.079-2.349,4.064-2.349V27.7Z" transform="translate(-188.953 -18.291)" fill="#5a5b5e" />
          <path id="Path_2733" data-name="Path 2733" d="M244.3,40.867l-4.079,2.349-4.064,2.349,4.064,2.349,4.079,2.35v-9.4Z" transform="translate(-211.736 -31.456)" fill="#1a1818" />
          <path id="Path_2734" data-name="Path 2734" d="M244.3,74.434l-4.079-2.349-4.064-2.35,4.064-2.349,4.079-2.349v9.4Z" transform="translate(-211.736 -45.55)" fill="#1a1818" />
          <path id="Path_2735" data-name="Path 2735" d="M177.581,40.867l4.065,2.349,4.064,2.349-4.064,2.349-4.065,2.35v-9.4Z" transform="translate(-177.581 -31.456)" fill="#5a5b5e" />
          <path id="Path_2736" data-name="Path 2736" d="M177.581,74.434l4.065-2.349,4.064-2.35-4.064-2.349-4.065-2.349v9.4Z" transform="translate(-177.581 -45.55)" fill="#5a5b5e" />
          <path id="Path_2737" data-name="Path 2737" d="M224.761,29.56,220.7,31.91l-4.079,2.363,4.079,2.35,4.064,2.349V29.56Z" transform="translate(-200.345 -24.862)" fill="#1a1818" />
          <path id="Path_2738" data-name="Path 2738" d="M224.761,76.306l-4.064,2.35-4.079,2.349,4.079,2.35,4.064,2.349v-9.4Z" transform="translate(-200.345 -52.122)" fill="#cb9a51" />
          <path id="Path_2739" data-name="Path 2739" d="M197.081,63.14l4.079-2.349,4.064-2.349-4.064-2.365-4.079-2.35V63.14Z" transform="translate(-188.953 -38.955)" fill="#eaceaa" />
          <path id="Path_2740" data-name="Path 2740" d="M216.617,42.458l4.079,2.349,4.064,2.349-4.064,2.35-4.079,2.364V42.458Z" transform="translate(-200.345 -32.384)" fill="#cb9a51" />
          <path id="Path_2741" data-name="Path 2741" d="M205.225,42.458l-4.064,2.349-4.079,2.349,4.079,2.35,4.064,2.364V42.458Z" transform="translate(-188.953 -32.384)" fill="#eaceaa" />
          <path id="Path_2742" data-name="Path 2742" d="M216.617,65.036v9.4l4.079-2.35,4.064-2.349L220.7,67.386Z" transform="translate(-200.345 -45.55)" fill="#6f552c" />
          <path id="Path_2743" data-name="Path 2743" d="M205.225,65.036h0l-4.064,2.35-4.079,2.349,4.079,2.349,4.064,2.35v-9.4Z" transform="translate(-188.953 -45.55)" fill="#a39279" />
          <path id="Path_2744" data-name="Path 2744" d="M216.617,87.579l4.079,2.35,4.064,2.35L220.7,94.628l-4.079,2.349v-9.4Z" transform="translate(-200.345 -58.695)" fill="#cb9a51" />
          <path id="Path_2745" data-name="Path 2745" d="M205.225,87.579l-4.064,2.35-4.079,2.35,4.079,2.349,4.064,2.349v-9.4Z" transform="translate(-188.953 -58.695)" fill="#eaceaa" />
        </g>
      </g>
    </svg>

  )
}

export function SafaLogoMinimal() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.56 38.282">
      <g id="Group_898" data-name="Group 898" transform="translate(-108.828 -456.291)">
        <g id="Group_872" data-name="Group 872" transform="translate(108.828 456.291)">
          <path id="Path_2722" data-name="Path 2722" d="M216.617,27.7l4.079-2.363,4.064-2.35L220.7,20.64l-4.079-2.349V27.7Z" transform="translate(-200.345 -18.291)" fill="#1a1818" />
          <path id="Path_2723" data-name="Path 2723" d="M236.152,29.56l4.064,2.35,4.079,2.363-4.079,2.35-4.064,2.349V29.56Z" transform="translate(-211.736 -24.862)" fill="#1a1818" />
          <path id="Path_2724" data-name="Path 2724" d="M236.152,63.14l4.064-2.349,4.079-2.349-4.079-2.365-4.064-2.35V63.14Z" transform="translate(-211.736 -38.955)" fill="#1a1818" />
          <path id="Path_2725" data-name="Path 2725" d="M224.761,63.14,220.7,60.791l-4.079-2.349,4.079-2.365,4.064-2.35V63.14Z" transform="translate(-200.345 -38.955)" fill="#1a1818" />
          <path id="Path_2726" data-name="Path 2726" d="M236.152,76.306l4.064,2.35,4.079,2.349-4.079,2.35L236.152,85.7v-9.4Z" transform="translate(-211.736 -52.122)" fill="#1a1818" />
          <path id="Path_2727" data-name="Path 2727" d="M185.71,29.56l-4.064,2.35-4.065,2.363,4.065,2.35,4.064,2.349V29.56Z" transform="translate(-177.581 -24.862)" fill="#5a5b5e" />
          <path id="Path_2728" data-name="Path 2728" d="M197.081,29.56l4.079,2.35,4.064,2.363-4.064,2.35-4.079,2.349V29.56Z" transform="translate(-188.953 -24.862)" fill="#5a5b5e" />
          <path id="Path_2729" data-name="Path 2729" d="M185.71,63.14l-4.064-2.349-4.065-2.349,4.065-2.365,4.064-2.35V63.14Z" transform="translate(-177.581 -38.955)" fill="#5a5b5e" />
          <path id="Path_2730" data-name="Path 2730" d="M185.71,76.306l-4.064,2.35-4.065,2.349,4.065,2.35L185.71,85.7v-9.4Z" transform="translate(-177.581 -52.122)" fill="#5a5b5e" />
          <path id="Path_2731" data-name="Path 2731" d="M197.081,76.306l4.079,2.35,4.064,2.349-4.064,2.35L197.081,85.7v-9.4Z" transform="translate(-188.953 -52.122)" fill="#5a5b5e" />
          <path id="Path_2732" data-name="Path 2732" d="M205.225,27.7l-4.064-2.363-4.079-2.35,4.079-2.349,4.064-2.349V27.7Z" transform="translate(-188.953 -18.291)" fill="#5a5b5e" />
          <path id="Path_2733" data-name="Path 2733" d="M244.3,40.867l-4.079,2.349-4.064,2.349,4.064,2.349,4.079,2.35v-9.4Z" transform="translate(-211.736 -31.456)" fill="#1a1818" />
          <path id="Path_2734" data-name="Path 2734" d="M244.3,74.434l-4.079-2.349-4.064-2.35,4.064-2.349,4.079-2.349v9.4Z" transform="translate(-211.736 -45.55)" fill="#1a1818" />
          <path id="Path_2735" data-name="Path 2735" d="M177.581,40.867l4.065,2.349,4.064,2.349-4.064,2.349-4.065,2.35v-9.4Z" transform="translate(-177.581 -31.456)" fill="#5a5b5e" />
          <path id="Path_2736" data-name="Path 2736" d="M177.581,74.434l4.065-2.349,4.064-2.35-4.064-2.349-4.065-2.349v9.4Z" transform="translate(-177.581 -45.55)" fill="#5a5b5e" />
          <path id="Path_2737" data-name="Path 2737" d="M224.761,29.56,220.7,31.91l-4.079,2.363,4.079,2.35,4.064,2.349V29.56Z" transform="translate(-200.345 -24.862)" fill="#1a1818" />
          <path id="Path_2738" data-name="Path 2738" d="M224.761,76.306l-4.064,2.35-4.079,2.349,4.079,2.35,4.064,2.349v-9.4Z" transform="translate(-200.345 -52.122)" fill="#cb9a51" />
          <path id="Path_2739" data-name="Path 2739" d="M197.081,63.14l4.079-2.349,4.064-2.349-4.064-2.365-4.079-2.35V63.14Z" transform="translate(-188.953 -38.955)" fill="#eaceaa" />
          <path id="Path_2740" data-name="Path 2740" d="M216.617,42.458l4.079,2.349,4.064,2.349-4.064,2.35-4.079,2.364V42.458Z" transform="translate(-200.345 -32.384)" fill="#cb9a51" />
          <path id="Path_2741" data-name="Path 2741" d="M205.225,42.458l-4.064,2.349-4.079,2.349,4.079,2.35,4.064,2.364V42.458Z" transform="translate(-188.953 -32.384)" fill="#eaceaa" />
          <path id="Path_2742" data-name="Path 2742" d="M216.617,65.036v9.4l4.079-2.35,4.064-2.349L220.7,67.386Z" transform="translate(-200.345 -45.55)" fill="#6f552c" />
          <path id="Path_2743" data-name="Path 2743" d="M205.225,65.036h0l-4.064,2.35-4.079,2.349,4.079,2.349,4.064,2.35v-9.4Z" transform="translate(-188.953 -45.55)" fill="#a39279" />
          <path id="Path_2744" data-name="Path 2744" d="M216.617,87.579l4.079,2.35,4.064,2.35L220.7,94.628l-4.079,2.349v-9.4Z" transform="translate(-200.345 -58.695)" fill="#cb9a51" />
          <path id="Path_2745" data-name="Path 2745" d="M205.225,87.579l-4.064,2.35-4.079,2.35,4.079,2.349,4.064,2.349v-9.4Z" transform="translate(-188.953 -58.695)" fill="#eaceaa" />
        </g>
      </g>
    </svg>

  )
}

export function HotelFIightsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.825 20.586">
      <g id="Group_4073" data-name="Group 4073" transform="translate(-4060 -1073)">
        <g id="pw-icon-4" transform="translate(4060 1073)">
          <g id="Group_4070" data-name="Group 4070" transform="translate(5.938 1.239)">
            <path id="Path_3737" data-name="Path 3737" d="M33.461,36.288H31V34.569h2.461Zm3.506-9.269H34.506V25.3h2.461Zm3.506,3.741H38.011V29.041h2.461Zm0-3.741H38.011V25.3h2.461Z" transform="translate(-27.36 -21.221)" />
            <path id="Path_3738" data-name="Path 3738" d="M32.011,43.7H31v1.719h1.382c-.034-.1-.034-.169-.067-.27Z" transform="translate(-27.36 -33.419)" />
            <rect id="Rectangle_17853" data-name="Rectangle 17853" width="2.461" height="1.719" transform="translate(3.64 13.348)" />
            <path id="Path_3739" data-name="Path 3739" d="M31,26.683a1.852,1.852,0,0,1,.3.236h2.157V25.2H31.472L31,25.6Z" transform="translate(-27.36 -21.155)" />
            <rect id="Rectangle_17854" data-name="Rectangle 17854" width="2.461" height="1.719" transform="translate(7.146 4.079)" />
            <path id="Path_3740" data-name="Path 3740" d="M34.323,46.577H29.806V42.06H27.346v4.517H22.8V37.308c-.438-.236-.91-.472-1.348-.708V46.645H20.2V48.06H37.087V46.645H35.671S34.357,46.577,34.323,46.577Z" transform="translate(-20.2 -28.713)" />
            <path id="Path_3741" data-name="Path 3741" d="M39.391,14.245,37.2,16h8.191a.23.23,0,0,1,.236.236v7.281c-.024-.007,1.345-.01,1.355,0l-.007-7.281a1.582,1.582,0,0,0-1.584-1.584H43.638V13.2H40.031A1.894,1.894,0,0,1,39.391,14.245Z" transform="translate(-31.47 -13.2)" />
            <rect id="Rectangle_17855" data-name="Rectangle 17855" width="2.461" height="1.719" transform="translate(10.651 7.82)" />
            <rect id="Rectangle_17856" data-name="Rectangle 17856" width="2.461" height="1.719" transform="translate(10.651 4.079)" />
          </g>
          <g id="Group_4072" data-name="Group 4072" transform="translate(0 0)">
            <g id="Group_4071" data-name="Group 4071">
              <path id="Path_3744" data-name="Path 3744" d="M6.431,25.46a1.025,1.025,0,0,0,1.18-.742c.236-.977.472-1.955.674-2.932.27-1.18.573-2.393.843-3.573.034-.135.067-.3.1-.438.034-.1.067-.1.135-.067.876.472,1.753.944,2.663,1.416.472.27.977.506,1.449.775a.228.228,0,0,1,.135.2c.169.775.337,1.551.472,2.326.034.1.067.135.135.135.135.034.27.067.438.1.067.034.1,0,.135-.1.169-.742.337-1.449.506-2.157a.379.379,0,0,1,.169-.27c.573-.438,1.146-.91,1.753-1.382.067-.067.1-.1.034-.2-.067-.067-.1-.135-.169-.2-.169-.2-.169-.2-.4-.169-.708.169-1.449.337-2.157.539a.277.277,0,0,1-.236-.034c-1.213-.91-2.461-1.82-3.674-2.764-.135-.1-.135-.1,0-.2l5.46-4.348a1.057,1.057,0,0,0,.1-1.618.822.822,0,0,0-.775-.2,2.868,2.868,0,0,0-.573.2C12.263,11.1,9.97,12.382,7.442,13.7c-.1.067-.169.034-.27,0-.742-.4-3.775-2.6-4.483-1.382-.742,1.18,2.6,2.9,3.27,3.371a.2.2,0,0,1,.1.2c-.067,1.449-.135,2.528-.2,3.977-.067,1.483-.135,3-.169,4.483a1.1,1.1,0,0,0,.4.977A.515.515,0,0,0,6.431,25.46Z" transform="translate(-2.583 -9.525)" />
            </g>
          </g>
        </g>
        <path id="Path_3745" data-name="Path 3745" d="M80,69.8c0,.386.279.494.537.73V71.3a.431.431,0,0,0,.429.429H81.4a.431.431,0,0,0,.429-.429v-.429h3.435V71.3a.431.431,0,0,0,.429.429h.429a.431.431,0,0,0,.429-.429v-.773c.258-.236.537-.365.537-.73V65.7c0-1.5-1.653-1.7-3.542-1.7S80,64.195,80,65.7Zm1.61.322a.644.644,0,1,1,.644-.644A.632.632,0,0,1,81.61,70.118Zm3.864,0a.644.644,0,1,1,.644-.644A.632.632,0,0,1,85.474,70.118Zm.644-2.468H80.966V65.5h5.152Z" transform="translate(3995.741 1020.258)" />
      </g>
    </svg>
  )
}

export function MyBookingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.159 15.827">
      <g>
        <path id="Path_33" d="M18.493,20.827H2.666A1.666,1.666,0,0,1,1,19.161V6.666A1.666,1.666,0,0,1,2.666,5H18.493a1.666,1.666,0,0,1,1.666,1.666v12.5A1.666,1.666,0,0,1,18.493,20.827Zm.833-14.161a.833.833,0,0,0-.833-.833H2.666a.833.833,0,0,0-.833.833v2.5H19.326Zm0,3.332H1.833v9.163a.833.833,0,0,0,.833.833H18.493a.833.833,0,0,0,.833-.833ZM17.66,15.829h-.833a.417.417,0,0,1-.417-.417V14.58a.417.417,0,0,1,.417-.417h.833a.417.417,0,0,1,.417.417v.833A.417.417,0,0,1,17.66,15.829Zm0-2.916h-.833a.417.417,0,0,1-.417-.417v-.833a.417.417,0,0,1,.417-.417h.833a.417.417,0,0,1,.417.417V12.5A.417.417,0,0,1,17.66,12.914Zm-3.332,2.916H13.5a.417.417,0,0,1-.417-.417V14.58a.417.417,0,0,1,.417-.417h.833a.417.417,0,0,1,.417.417v.833A.417.417,0,0,1,14.328,15.829Zm0-2.916H13.5a.417.417,0,0,1-.417-.417v-.833a.417.417,0,0,1,.417-.417h.833a.417.417,0,0,1,.417.417V12.5A.417.417,0,0,1,14.328,12.914ZM11,15.829h-.833a.417.417,0,0,1-.417-.417V14.58a.417.417,0,0,1,.417-.417H11a.417.417,0,0,1,.417.417v.833A.417.417,0,0,1,11,15.829Zm0-2.916h-.833a.417.417,0,0,1-.417-.417v-.833a.417.417,0,0,1,.417-.417H11a.417.417,0,0,1,.417.417V12.5A.417.417,0,0,1,11,12.914ZM7.664,18.745H6.831a.416.416,0,0,1-.417-.416V17.5a.416.416,0,0,1,.417-.417h.833a.417.417,0,0,1,.417.417v.833A.416.416,0,0,1,7.664,18.745Zm0-2.916H6.831a.417.417,0,0,1-.417-.417V14.58a.417.417,0,0,1,.417-.417h.833a.417.417,0,0,1,.417.417v.833A.417.417,0,0,1,7.664,15.829Zm0-2.916H6.831a.417.417,0,0,1-.417-.417v-.833a.417.417,0,0,1,.417-.417h.833a.417.417,0,0,1,.417.417V12.5A.417.417,0,0,1,7.664,12.914ZM4.332,18.745H3.5a.416.416,0,0,1-.417-.416V17.5a.416.416,0,0,1,.417-.417h.833a.416.416,0,0,1,.417.417v.833A.416.416,0,0,1,4.332,18.745Zm0-2.916H3.5a.417.417,0,0,1-.417-.417V14.58a.417.417,0,0,1,.417-.417h.833a.417.417,0,0,1,.417.417v.833A.417.417,0,0,1,4.332,15.829Zm5.831,1.25H11a.417.417,0,0,1,.417.417v.833a.416.416,0,0,1-.417.416h-.833a.416.416,0,0,1-.417-.416V17.5A.417.417,0,0,1,10.163,17.079Z" transform="translate(-1 -5)" fill-rule="evenodd" />
      </g>
    </svg>
  )
}

export function MyTeamIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.898 19.932">
      <g id="Group_2" data-name="Group 2" transform="translate(-2.25 -3)">
        <path id="Path_14" data-name="Path 14" d="M18.2,14.948a.955.955,0,0,0,.4.344,3.65,3.65,0,0,1,2.072,3.293V20.83a.842.842,0,1,0,1.684,0V18.584a5.334,5.334,0,0,0-5.334-5.334.135.135,0,0,0-.085.237A6.771,6.771,0,0,1,18.2,14.948Z" transform="translate(1.799 1.26)" />
        <path id="Path_15" data-name="Path 15" d="M7.584,14.934a3.65,3.65,0,0,0-3.65,3.65V20.83a.842.842,0,1,1-1.684,0V18.584A5.334,5.334,0,0,1,7.584,13.25h6.738a5.334,5.334,0,0,1,5.334,5.334V20.83a.842.842,0,1,1-1.684,0V18.584a3.65,3.65,0,0,0-3.65-3.65Z" transform="translate(0 1.26)" fill-rule="evenodd" />
        <path id="Path_16" data-name="Path 16" d="M14.988,10.363a1.148,1.148,0,0,1,.445-.449,2.806,2.806,0,0,0,0-4.844,1.157,1.157,0,0,1-.445-.449A6.192,6.192,0,0,0,13.94,3.18.1.1,0,0,1,14.011,3a4.492,4.492,0,1,1,0,8.984.1.1,0,0,1-.071-.18A6.128,6.128,0,0,0,14.988,10.363Z" transform="translate(1.434)" />
        <path id="Path_17" data-name="Path 17" d="M10.492,10.3A2.807,2.807,0,1,0,7.684,7.492,2.807,2.807,0,0,0,10.492,10.3Zm0,1.684A4.492,4.492,0,1,0,6,7.492,4.492,4.492,0,0,0,10.492,11.984Z" transform="translate(0.461)" fill-rule="evenodd" />
      </g>
    </svg>
  )
}

export function TripsManagementIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.898 21.669">
      <g id="trip" transform="translate(0.001)">
        <path id="Path_212" data-name="Path 212" d="M65.333,64.663a8.7,8.7,0,0,0-.129-1.5l-.712.124a7.945,7.945,0,0,1-3.268,7.873l-.582-.95a1.077,1.077,0,0,1-.045-1.051l.164-.328a.359.359,0,0,1,.323-.2h.509a1.079,1.079,0,0,0,1.006-.681l.489-1.222a1.071,1.071,0,0,0,.078-.4v-.795a.718.718,0,0,0-.212-.511l-.433-.433a.722.722,0,0,0-.834-.135L60.8,64.9a.361.361,0,0,1-.323,0l-1.445-.722a.359.359,0,0,1-.2-.322v-1a.722.722,0,0,0-.722-.722.361.361,0,0,1-.361-.361v-.361a.362.362,0,0,1,.144-.289l1.156-.867-.433-.578-1.156.867a1.089,1.089,0,0,0-.433.867v.361a1.083,1.083,0,0,0,1.083,1.083v1a1.078,1.078,0,0,0,.6.969l1.445.722a1.088,1.088,0,0,0,.969,0l.889-.444.433.433v.795a.357.357,0,0,1-.026.134l-.489,1.222a.359.359,0,0,1-.335.227h-.509a1.077,1.077,0,0,0-.969.6l-.164.328a1.8,1.8,0,0,0,.075,1.751l.589.961a7.917,7.917,0,0,1-7.264.329l.443-.3a.363.363,0,0,1,.2-.061h.926a1.076,1.076,0,0,0,.766-.317l1.021-1.021a1.076,1.076,0,0,0,.317-.766V67.751a1.084,1.084,0,0,0-.153-.556l-.771-1.29a1.09,1.09,0,0,0-.926-.52H54.163a1.084,1.084,0,0,0-.9.482l-.4.6h-.89a.722.722,0,0,0-.722.722v.69a.361.361,0,0,1-.165.3l-1.127.727a7.9,7.9,0,0,1-1.236-4.248c0-.121,0-.241.009-.361h.628a.359.359,0,0,1,.323.2l.423.848a.718.718,0,0,0,.646.4h.381a.722.722,0,0,0,.7-.921l-.46-1.607h.713a1.082,1.082,0,0,0,.677-.238l1.179-.943a1.085,1.085,0,0,0,.319-1.273l-.618-1.445a.361.361,0,0,1,.146-.452l.909-.546a1.088,1.088,0,0,0,.526-.942l-.006-.528a7.929,7.929,0,0,1,3.509.132l.185-.7a8.668,8.668,0,1,0,6.428,8.375Z" transform="translate(-45.833 -53.467)" />
        <path id="Path_213" data-name="Path 213" d="M1.306,255.82l-.72.05c.017.244.042.492.076.735l.716-.1C1.346,256.28,1.322,256.047,1.306,255.82Zm0,0" transform="translate(-0.56 -244.273)" />
        <path id="Path_214" data-name="Path 214" d="M20.525,130.441l-.642-.332c-.113.217-.219.443-.317.669l.663.286C20.321,130.854,20.421,130.644,20.525,130.441Zm0,0" transform="translate(-18.684 -124.237)" />
        <path id="Path_215" data-name="Path 215" d="M5.777,286.074l-.707.148c.051.241.108.482.176.718l.695-.2C5.88,286.524,5.825,286.3,5.777,286.074Zm0,0" transform="translate(-4.842 -273.161)" />
        <path id="Path_216" data-name="Path 216" d="M2.967,191.91l-.708-.144c-.049.239-.09.485-.123.729l.716.1C2.883,192.363,2.921,192.133,2.967,191.91Zm0,0" transform="translate(-2.041 -183.11)" />
        <path id="Path_217" data-name="Path 217" d="M35.612,102.51l-.591-.416c-.141.2-.277.409-.4.619l.618.375C35.354,102.891,35.481,102.7,35.612,102.51Zm0,0" transform="translate(-33.055 -97.486)" />
        <path id="Path_218" data-name="Path 218" d="M9.59,160.443l-.681-.24c-.082.233-.156.47-.222.706l.7.193C9.444,160.882,9.514,160.66,9.59,160.443Zm0,0" transform="translate(-8.296 -152.973)" />
        <path id="Path_219" data-name="Path 219" d="M.744,224.25.022,224.2q-.022.354-.022.715v.023l.722-.023Q.722,224.582.744,224.25Zm0,0" transform="translate(-0.001 -214.083)" />
        <path id="Path_220" data-name="Path 220" d="M14.641,315.484l-.68.243c.083.232.174.463.272.686l.662-.289C14.8,315.916,14.718,315.7,14.641,315.484Zm0,0" transform="translate(-13.332 -301.244)" />
        <path id="Path_221" data-name="Path 221" d="M54.471,77.152l-.529-.491c-.167.181-.33.368-.485.558l.561.455C54.162,77.5,54.314,77.32,54.471,77.152Zm0,0" transform="translate(-51.045 -73.201)" />
        <path id="Path_222" data-name="Path 222" d="M191.255,3.063l-.1-.716c-.242.034-.487.077-.728.127l.148.707C190.8,3.134,191.028,3.094,191.255,3.063Zm0,0" transform="translate(-181.83 -2.243)" />
        <path id="Path_223" data-name="Path 223" d="M223.581.757l0-.722c-.247,0-.5.01-.739.027l.051.722Q223.233.757,223.581.757Zm0,0" transform="translate(-212.781 -0.035)" />
        <path id="Path_224" data-name="Path 224" d="M159.822,9.781l-.2-.7c-.235.066-.472.142-.7.226l.244.68C159.382,9.914,159.6,9.843,159.822,9.781Zm0,0" transform="translate(-151.749 -8.677)" />
        <path id="Path_225" data-name="Path 225" d="M76.767,54.813l-.457-.559c-.19.155-.377.319-.556.487l.494.527C76.415,55.111,76.59,54.958,76.767,54.813Zm0,0" transform="translate(-72.335 -51.806)" />
        <path id="Path_226" data-name="Path 226" d="M129.947,20.794l-.289-.662c-.223.1-.447.205-.666.32l.334.64C129.529,20.986,129.738,20.887,129.947,20.794Zm0,0" transform="translate(-123.17 -19.226)" />
        <path id="Path_227" data-name="Path 227" d="M102.064,35.9l-.378-.616c-.21.129-.417.266-.616.408l.419.589C101.675,36.15,101.869,36.021,102.064,35.9Zm0,0" transform="translate(-96.509 -33.694)" />
        <path id="Path_228" data-name="Path 228" d="M329.4,439.2l.289.662c.225-.1.449-.205.666-.318l-.332-.641C329.818,439.012,329.608,439.112,329.4,439.2Zm0,0" transform="translate(-314.529 -419.094)" />
        <path id="Path_229" data-name="Path 229" d="M403.742,381.883l.528.493c.168-.181.331-.367.486-.557l-.56-.456C404.051,381.541,403.9,381.716,403.742,381.883Zm0,0" transform="translate(-385.517 -364.149)" />
        <path id="Path_230" data-name="Path 230" d="M381.426,404.153l.456.56c.19-.155.377-.318.557-.486l-.493-.528Q381.693,403.935,381.426,404.153Zm0,0" transform="translate(-364.208 -385.476)" />
        <path id="Path_231" data-name="Path 231" d="M423.055,357l.59.418c.142-.2.278-.408.406-.618l-.617-.375C423.314,356.616,423.187,356.81,423.055,357Zm0,0" transform="translate(-403.958 -340.333)" />
        <path id="Path_232" data-name="Path 232" d="M356.488,423.391l.375.617c.209-.128.416-.264.617-.406l-.418-.589C356.877,423.144,356.683,423.272,356.488,423.391Zm0,0" transform="translate(-340.396 -403.917)" />
        <path id="Path_233" data-name="Path 233" d="M300.656,451.31l.2.7c.236-.066.473-.142.7-.224l-.243-.68C301.1,451.18,300.876,451.249,300.656,451.31Zm0,0" transform="translate(-287.085 -430.739)" />
        <path id="Path_234" data-name="Path 234" d="M463.488,240.725l.721.048c.017-.245.025-.493.025-.739h-.722Q463.511,240.383,463.488,240.725Zm0,0" transform="translate(-442.566 -229.201)" />
        <path id="Path_235" data-name="Path 235" d="M459.367,271.174l.708.144c.049-.239.091-.485.125-.729l-.716-.1C459.452,270.721,459.413,270.95,459.367,271.174Zm0,0" transform="translate(-438.631 -258.283)" />
        <path id="Path_236" data-name="Path 236" d="M451.152,301.1l.68.242c.083-.233.158-.469.224-.705l-.7-.194C451.3,300.665,451.229,300.886,451.152,301.1Zm0,0" transform="translate(-430.787 -286.884)" />
        <path id="Path_237" data-name="Path 237" d="M438.953,329.851l.641.333c.113-.217.22-.443.318-.668l-.662-.289C439.159,329.438,439.059,329.648,438.953,329.851Zm0,0" transform="translate(-419.138 -314.366)" />
        <path id="Path_238" data-name="Path 238" d="M27.695,343.438l-.64.334c.114.217.236.433.364.642l.618-.377C27.916,343.843,27.8,343.641,27.695,343.438Zm0,0" transform="translate(-25.834 -327.935)" />
        <path id="Path_239" data-name="Path 239" d="M88.8,414.565c.191.156.39.306.589.447l.415-.591q-.282-.2-.551-.417Zm0,0" transform="translate(-84.793 -395.316)" />
        <path id="Path_240" data-name="Path 240" d="M144.648,446.331c.224.1.456.189.689.271l.241-.681c-.217-.078-.433-.162-.643-.253Zm0,0" transform="translate(-138.12 -425.551)" />
        <path id="Path_241" data-name="Path 241" d="M115.543,432.2c.211.128.428.25.645.361l.332-.642c-.2-.1-.405-.218-.6-.338Zm0,0" transform="translate(-110.328 -412.097)" />
        <path id="Path_242" data-name="Path 242" d="M64.8,393.65c.169.181.345.356.523.522l.493-.529c-.167-.155-.332-.319-.489-.487Zm0,0" transform="translate(-61.876 -375.409)" />
        <path id="Path_243" data-name="Path 243" d="M44.71,369.5l-.589.417c.142.2.293.4.448.588l.558-.458C44.982,369.871,44.843,369.687,44.71,369.5Zm0,0" transform="translate(-42.13 -352.821)" />
        <path id="Path_244" data-name="Path 244" d="M270.742,459.465l.1.716c.242-.033.488-.075.729-.125l-.147-.707C271.2,459.394,270.968,459.434,270.742,459.465Zm0,0" transform="translate(-258.521 -438.613)" />
        <path id="Path_245" data-name="Path 245" d="M207.609,462.66c.242.033.489.057.736.072l.048-.722c-.231-.015-.462-.039-.686-.069Zm0,0" transform="translate(-198.238 -441.089)" />
        <path id="Path_246" data-name="Path 246" d="M240.238,463.494v.722c.247,0,.5-.009.739-.026l-.05-.722Q240.587,463.494,240.238,463.494Zm0,0" transform="translate(-229.394 -442.548)" />
        <path id="Path_247" data-name="Path 247" d="M175.543,456.563c.237.066.479.125.719.174l.144-.707c-.224-.046-.449-.1-.67-.162Zm0,0" transform="translate(-167.619 -435.289)" />
        <path id="Path_248" data-name="Path 248" d="M274.282,4.071,274.074,7.6a1.808,1.808,0,0,0,.526,1.383l.7.7,1.275-3.318,1.289,1.289V9.252a1.075,1.075,0,0,0,.317.764l.626.627.766-1.277.184.183a1.083,1.083,0,1,0,1.532-1.532l-.183-.184,1.277-.766-.626-.627a1.075,1.075,0,0,0-.766-.317H279.4L278.11,4.835l3.32-1.277-.7-.7a1.8,1.8,0,0,0-1.383-.526l-3.532.208-1.162-1.162a1.11,1.11,0,0,0-1.532,0,1.084,1.084,0,0,0,0,1.532Zm.746,4.311a1.087,1.087,0,0,1-.233-.737l.17-2.889,1.053,1.053Zm3.638,1.092a.365.365,0,0,1-.079-.226V8.378l.462.461Zm2.325-2.63a.364.364,0,0,1,.226.079l-.636.384-.461-.461Zm-1.6-3.792a1.083,1.083,0,0,1,.737.233l-2.575.99L276.5,3.223Zm-5.757-1.164a.361.361,0,0,1,.51,0l6.64,6.64a.361.361,0,0,1,0,.51.369.369,0,0,1-.511,0L273.629,2.4A.361.361,0,0,1,273.629,1.888Zm0,0" transform="translate(-260.487 -1.024)" />
      </g>
    </svg>

  )
}

export function Arrow({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 6.057 10.114">
      <path id="Path_5" data-name="Path 5" d="M15.785,11.5l-3.643,3.643L8.5,11.5" transform="translate(-10.086 17.199) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
    </svg>
  )
}

export function WalletIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.199" height="24.131" viewBox="0 0 19.199 24.131">
      <g id="Group_4074" data-name="Group 4074" transform="translate(-5 -0.139)">
        <circle id="Ellipse_283" data-name="Ellipse 283" cx="0.505" cy="0.505" r="0.505" transform="translate(18.642 18.207)" />
        <circle id="Ellipse_284" data-name="Ellipse 284" cx="1.01" cy="1.01" r="1.01" transform="translate(14.968 7.056) rotate(-71.652)" />
        <path id="Path_3746" data-name="Path 3746" d="M21.673,10.123H21.42l1.718-5.1a1.507,1.507,0,0,0-.96-1.92L13.539.22a1.523,1.523,0,0,0-1.869.808H6.516a1.376,1.376,0,0,0-1.061.455A1.376,1.376,0,0,0,5,2.544l.051,9.6v.051A1.4,1.4,0,0,0,5,12.649v9.094A2.5,2.5,0,0,0,7.526,24.27H21.673A2.5,2.5,0,0,0,24.2,21.744V12.649a2.5,2.5,0,0,0-2.526-2.526Zm1.516,10.1H19.1a1.517,1.517,0,0,1,0-3.031h4.092Zm-10-19.048,8.64,2.88a.517.517,0,0,1,.3.253.432.432,0,0,1,0,.354l-1.819,5.457H19.248l1.112-3.335a.4.4,0,0,0-.051-.4.517.517,0,0,0-.3-.253,1.046,1.046,0,0,1-.657-1.263.526.526,0,0,0-.3-.657l-3.84-1.263A.4.4,0,0,0,14.8,3a.517.517,0,0,0-.253.3,1.046,1.046,0,0,1-1.263.657.526.526,0,0,0-.657.3l-1.97,5.911H9.6l2.981-8.69a.453.453,0,0,1,.606-.3Zm-1.364,8.943L13.539,4.97a1.988,1.988,0,0,0,1.819-.909l2.981,1.01a2.042,2.042,0,0,0,.909,1.819l-1.061,3.234Zm-3.789,0v-4.6A2.137,2.137,0,0,0,9.5,4.06h1.162L8.638,10.123ZM6.162,2.191a.459.459,0,0,1,.354-.152h4.8l-.354,1.01H9.042a.459.459,0,0,0-.354.152.36.36,0,0,0-.152.3,1.013,1.013,0,0,1-1.01,1.01.477.477,0,0,0-.505.505v5.153a2.6,2.6,0,0,0-1.01.455V2.544A.459.459,0,0,1,6.162,2.191ZM23.189,21.744a1.489,1.489,0,0,1-1.516,1.516H7.526A1.489,1.489,0,0,1,6.01,21.744V12.649a1.489,1.489,0,0,1,1.516-1.516H21.673a1.489,1.489,0,0,1,1.516,1.516v3.537H19.1a2.527,2.527,0,0,0,0,5.052h4.092Z" />
      </g>
    </svg>

  )
}

export function SafaOfflineIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.102" height="23.635" viewBox="0 0 20.102 23.635">
      <g id="Group_3723" data-name="Group 3723" transform="translate(606.257 -617.344)">
        <g id="Group_872" data-name="Group 872" transform="translate(-606.257 617.344)">
          <path id="Path_2722" data-name="Path 2722" d="M216.617,24.1l2.518-1.459,2.509-1.451-2.509-1.45-2.518-1.45V24.1Z" transform="translate(-206.57 -18.291)" />
          <path id="Path_2723" data-name="Path 2723" d="M236.152,29.56l2.509,1.451,2.518,1.459-2.518,1.451-2.509,1.45V29.56Z" transform="translate(-221.078 -26.659)" />
          <path id="Path_2724" data-name="Path 2724" d="M236.152,59.539l2.509-1.45,2.518-1.451-2.518-1.46-2.509-1.451v5.811Z" transform="translate(-221.078 -44.607)" />
          <path id="Path_2725" data-name="Path 2725" d="M221.645,59.539l-2.509-1.45-2.518-1.451,2.518-1.46,2.509-1.451v5.811Z" transform="translate(-206.57 -44.607)" />
          <path id="Path_2726" data-name="Path 2726" d="M236.152,76.306l2.509,1.451,2.518,1.45-2.518,1.451-2.509,1.451v-5.8Z" transform="translate(-221.078 -61.375)" />
          <path id="Path_2727" data-name="Path 2727" d="M182.6,29.56l-2.509,1.451-2.51,1.459,2.51,1.451,2.509,1.45V29.56Z" transform="translate(-177.581 -26.659)" />
          <path id="Path_2728" data-name="Path 2728" d="M197.081,29.56l2.519,1.451,2.509,1.459L199.6,33.921l-2.519,1.45V29.56Z" transform="translate(-192.063 -26.659)" />
          <path id="Path_2729" data-name="Path 2729" d="M182.6,59.539l-2.509-1.45-2.51-1.451,2.51-1.46,2.509-1.451v5.811Z" transform="translate(-177.581 -44.607)" />
          <path id="Path_2730" data-name="Path 2730" d="M182.6,76.306l-2.509,1.451-2.51,1.45,2.51,1.451,2.509,1.451v-5.8Z" transform="translate(-177.581 -61.375)" />
          <path id="Path_2731" data-name="Path 2731" d="M197.081,76.306l2.519,1.451,2.509,1.45L199.6,80.658l-2.519,1.451v-5.8Z" transform="translate(-192.063 -61.375)" />
          <path id="Path_2732" data-name="Path 2732" d="M202.109,24.1,199.6,22.642l-2.519-1.451,2.519-1.45,2.509-1.45V24.1Z" transform="translate(-192.063 -18.291)" />
          <path id="Path_2733" data-name="Path 2733" d="M241.18,40.867l-2.518,1.45-2.509,1.45,2.509,1.45,2.518,1.451v-5.8Z" transform="translate(-221.078 -35.057)" />
          <path id="Path_2734" data-name="Path 2734" d="M241.18,70.838l-2.518-1.45-2.509-1.451,2.509-1.45,2.518-1.451v5.8Z" transform="translate(-221.078 -53.006)" />
          <path id="Path_2735" data-name="Path 2735" d="M177.581,40.867l2.51,1.45,2.509,1.45-2.509,1.45-2.51,1.451v-5.8Z" transform="translate(-177.581 -35.057)" />
          <path id="Path_2736" data-name="Path 2736" d="M177.581,70.838l2.51-1.45,2.509-1.451-2.509-1.45-2.51-1.451v5.8Z" transform="translate(-177.581 -53.006)" />
          <path id="Path_2737" data-name="Path 2737" d="M221.645,29.56l-2.509,1.451-2.518,1.459,2.518,1.451,2.509,1.45V29.56Z" transform="translate(-206.57 -26.659)" />
          <path id="Path_2738" data-name="Path 2738" d="M221.645,76.306l-2.509,1.451-2.518,1.45,2.518,1.451,2.509,1.451v-5.8Z" transform="translate(-206.57 -61.375)" />
          <path id="Path_2739" data-name="Path 2739" d="M197.081,59.539l2.519-1.45,2.509-1.451-2.509-1.46-2.519-1.451v5.811Z" transform="translate(-192.063 -44.607)" />
          <path id="Path_2740" data-name="Path 2740" d="M216.617,42.458l2.518,1.45,2.509,1.45-2.509,1.451-2.518,1.46V42.458Z" transform="translate(-206.57 -36.238)" />
          <path id="Path_2741" data-name="Path 2741" d="M202.109,42.458l-2.509,1.45-2.519,1.45,2.519,1.451,2.509,1.46V42.458Z" transform="translate(-192.063 -36.238)" />
          <path id="Path_2742" data-name="Path 2742" d="M216.617,65.036v5.8l2.518-1.451,2.509-1.45-2.509-1.45Z" transform="translate(-206.57 -53.005)" />
          <path id="Path_2743" data-name="Path 2743" d="M202.109,65.036h0L199.6,66.487l-2.519,1.45,2.519,1.45,2.509,1.451v-5.8Z" transform="translate(-192.063 -53.005)" />
          <path id="Path_2744" data-name="Path 2744" d="M216.617,87.579l2.518,1.451,2.509,1.451-2.509,1.45-2.518,1.45v-5.8Z" transform="translate(-206.57 -69.746)" />
          <path id="Path_2745" data-name="Path 2745" d="M202.109,87.579,199.6,89.03l-2.519,1.451,2.519,1.45,2.509,1.45v-5.8Z" transform="translate(-192.063 -69.746)" />
        </g>
      </g>
    </svg>
  )
}
export function ActivityLogIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 11.853">
      <path id="Path_3732" data-name="Path 3732" d="M2.006,4V5.695H3.7V4Zm3.386,0V5.7H20.627V4.005H5.392Zm14.389,2.91-3.216,3.86h2.368v5.079h1.693V10.775h2.38l-3.227-3.86ZM2.006,9.081v1.693H3.7V9.081Zm3.386,0v1.693H15.549V9.084ZM2.006,14.159v1.693H3.7V14.159Zm3.386,0v1.693h8.464V14.163Z" transform="translate(-2.006 -4.002)" />
    </svg>
  )
}

export function StatisticsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16.227" viewBox="0 0 21 16.227">
      <path d="M22.045,13.682H18.227v1.432a.477.477,0,0,1-.477.477H14.409V17.5a.477.477,0,0,1-.477.477H11.545v-.955h1.909V15.114a.477.477,0,0,1,.477-.477h3.341V13.2a.477.477,0,0,1,.477-.477h4.3ZM3.432,22.273H9.159v.955H3.432A1.432,1.432,0,0,1,2,21.8V8.432A1.432,1.432,0,0,1,3.432,7H20.614a1.432,1.432,0,0,1,1.432,1.432V11.3a.477.477,0,0,1-.138.339.5.5,0,0,1-.339.138H2.955V21.8A.477.477,0,0,0,3.432,22.273ZM8.682,9.864h1.432V8.909H8.682Zm-2.386,0H7.727V8.909H6.3Zm-2.386,0H5.341V8.909H3.909Zm10.023,9.068H12.023a.477.477,0,0,0-.477.477V21.8h2.864V19.409A.477.477,0,0,0,13.932,18.932Zm3.818-2.386H15.841a.477.477,0,0,0-.477.477V21.8h2.864V17.023A.477.477,0,0,0,17.75,16.545Zm1.909-1.909a.477.477,0,0,0-.477.477V21.8h2.864V15.114a.477.477,0,0,0-.477-.477Zm-9.068,7.636V13.682H9.636V22.75a.477.477,0,0,0,.477.477H23v-.955ZM8.682,13.2a.477.477,0,0,0-.477-.477H4.386a.477.477,0,0,0-.477.477v1.909a.477.477,0,0,0,.477.477H8.2a.477.477,0,0,0,.477-.477Zm0,3.341H3.909V17.5H8.682Zm0,1.909H3.909v.955H8.682ZM3.909,21.318H8.682v-.955H3.909Z" transform="translate(-2 -7)" />
    </svg>
  )
}