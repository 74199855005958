import { useSBSState } from "context/global";
import { useMarketplaceDispatch, useMarketplaceState } from "context/marketplace";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { fetchCategories, fetchRoutes, fetchVehicleTypes } from "services/marketplace";
import Locale from "translations";
import HotelSearch from "./HotelSearch";
function Vacations({ allCountries, allCurrenciesData, allCitiesVacation, allLookups, mealPlanLookups, roomTypesLookups, roomViewsLookups }) {
	//Locale
	const { marketPlace } = Locale;
	const { locale } = useSBSState();
	const divHigth = useRef({});
	let clientHeight = divHigth.current.clientHeight;

	const { countries } = useMarketplaceState();
	const dispatch = useMarketplaceDispatch();
	const hotelInitial = {
		checkIn: moment(new Date()).add(1, "d"),
		checkOut: moment(new Date()).add(2, "d"),
		goingTo: null,
		adult: 1,
		child: 0,

		roomType: null,
		rooms: [{ number_of_adult: null, BoxChild: false }],
	};

	const transportationInitial = {
		validTo: moment(new Date()).add(1, "d"),
		goingTo: null,
		country: null,
		busType: null,
		busClass: null,
		busCount: 1,
		peopleNumber: 1,
	};
	const [activeTab, setActiveTab] = useState("1");
	const [activeContainerTab, setActiveContainerTab] = useState("1");

	// const [transCountries, setTransCountries] = useState(null);
	const [transRoutes, setTransRoutes] = useState(null);
	const [state, setState] = useState(
		hotelInitial

		// 	{
		// 	validTo: moment(new Date()).add(1, "d")._d,
		// 	goingTo: null,
		// 	country: null,
		// 	busType: null,
		// 	busClass: null,
		// 	busCount: 1,
		// 	peopleNumber: 1,
		// 	checkIn: new Date(),
		// 	checkOut: null,
		// 	adult: 1,
		// 	child: 0,
		// 	roomType: 1,
		// }
	);
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
		activeTab === "1"
			? setState(hotelInitial)
			: setState(transportationInitial);
	};
	const toggleContainer = (tab) => {
		if (activeContainerTab !== tab) setActiveContainerTab(tab);
		// activeContainerTab === "1"
		// 	? setState(hotelInitial)
		// 	: setState(transportationInitial);
	};
	const hotelCountries = [
		{
			code: "SA1",
			name: marketPlace.messages.makkah,
		},
		{
			code: "SA4",
			name: marketPlace.messages.medina,
		},
	];

	const handleChange = (e, flag = false) => {
		const name = e.target?.name;
		const value = e.target?.value;
		if (flag) {
			setState({
				...state,
				country: e["value"], // countries[value],
				goingTo: e.value, //countries[value]?.code,
			});
		} else setState({ ...state, [name]: value });
	};

	const fetchLookups = async () => {
		// const countries = fetchCountries();
		// const categories = fetchCategories();
		// const vehicleTypes = fetchVehicleTypes();
		// const companies = fetchCompanies();
		const [routes, categories, vehicleTypes] = await Promise.all([
			fetchRoutes(locale),
			fetchCategories(locale),
			fetchVehicleTypes(locale),
			// fetchCompanies(locale),
		]);
		setTransRoutes(routes);
		dispatch({ type: "fetchCategories", payload: categories });
		dispatch({ type: "fetchVehicleTypes", payload: vehicleTypes });
		// dispatch({ type: "fetchCompanies", payload: companies });
	};

	useEffect(() => {
		async function fetchData() {
			// if (
			// 	[countries, categories, vehicleTypes, companies].some(
			// 		(item) => item === undefined || item.length === 0
			// 	)
			// )
			// fetchLookups();
		}
		fetchData();
	}, [locale]);

	useEffect(() => {
		activeTab === "1"
			? setState(hotelInitial)
			: setState(transportationInitial);
		dispatch({
			type: "fetchCountries",
			payload: activeTab === "1" ? hotelCountries : transRoutes,
		});
	}, [activeTab]);

	// const [filters, setFilters] = useState({});

	return (
		<>
			<div className="market-search mb-5">
				<div className="main-tabs w-100 p-0" ref={divHigth}>
					<HotelSearch
						hotel={state}
						setHotel={setState}
						handleChange={handleChange}
						divHigth={divHigth}
						allCurrenciesData={allCurrenciesData}
						allCountries={allCountries}
						allCitiesVacation={allCitiesVacation}
						allLookups={allLookups}
						mealPlanLookups={mealPlanLookups}
						roomTypesLookups={roomTypesLookups}
						roomViewsLookups={roomViewsLookups}
					/>
					{/* <Nav tabs className="align-items-center main-nav">
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggle("1");
								}}
							>
								<img className="px-1" src={HotelsSvg} alt="" srcset="" />
								{marketPlace.messages.hotels}
							</NavLink>
						</NavItem> */}
					{/* <NavItem>
							<NavLink
								className={classnames({ active: activeTab === "2" })}
								onClick={() => {
									toggle("2");
								}}
								disabled
							>
								<span className="Soon-span"> {marketPlace.Soon}!</span>
								<img
									className="px-1"
									src={TransportationSvg}
									alt=""
									srcset=""
								/>
								{marketPlace.messages.transportation}
							</NavLink>
						</NavItem> */}

					{/* <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                <img
                  className="px-1"
                  src={activeTab === "3" ? groundServiceAcive : groundService}
                  alt=""
                  srcset=""
                />

                {marketPlace.messages.groundService}
              </NavLink>
            </NavItem>
            */}
					{/* </Nav> */}

					{/* <div className="heading col-md-10 py-3 m-auto">
						<h5 className="mt-3 search-title terms-color ">
							{marketPlace.messages.search} & {marketPlace.messages.book}
						</h5>
						<h3 className="text-uppercase mb-2 ">
							{marketPlace.messages.NewDeals}
						</h3>
					</div> */}

					{/*****************Start Container Tab********************** */}

					{/*****************End Container Tab********************** */}

					{/* <TabContent activeTab={activeTab}>
						{activeTab == "1" ? (
							<HotelSearch
								hotel={state}
								setHotel={setState}
								handleChange={handleChange}
								divHigth={divHigth}
								allCurrenciesData={allCurrenciesData}
								allCountries={allCountries}
								allCitiesVacation={allCitiesVacation}
								allLookups={allLookups}
							/>
						) : activeTab == "2" ? (
							`<TransportationSearch
								transportation={state}
								setTransportation={setState}
								handleChange={handleChange}
							/>`
						) : activeTab == "3" ? (
							`<GroundSearch
                  transportation={state}
                  setTransportation={setState}
                  handleChange={handleChange}
                />`
						) : (
							""
						)}
					</TabContent> */}
				</div>

			</div>
		</>
	);
}

export default Vacations;
