import axios from "axios";
import { cleanEmpty } from "./general";

const onlineVisaURL = process.env.REACT_APP_API_URL + "/v1";

export const onlineVisaLookups = async (data) => {
	return await axios
		.get(`${onlineVisaURL}/online-visa/issue-request`, {
			params: cleanEmpty(data),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const SearchOnlineVisa = async (data) => {
	return await axios
		.post(`${onlineVisaURL}/online-visa/issue-request`, {
			params: cleanEmpty(data),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const OnlineVisaGroups = async (data) => {
	return await axios
		.post(`${onlineVisaURL}/online-visa/issue-request/groups`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const getGroupPassengers = async (data, id) => {
	return await axios
		.post(`${onlineVisaURL}/online-visa/issue-request/group/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const makeSearchRequest = async (data, id) => {
	return await axios
		.post(`${onlineVisaURL}/online-visa/issue-request/create`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const onlineVisaRequest = async (data) => {
	return await axios.get(
		`${onlineVisaURL}/online-visa/issue-request/list/all`,
		{
			params: cleanEmpty(data),
		}
	);
};

export const addPassengers = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/issue-request/add/passengers/from-group`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getPassengerMrz = async (data) => {
	return await axios
		.post(`${onlineVisaURL}/online-visa/issue-request/passport-data`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listMehrem = async () => {
	return await axios
		.get(`${onlineVisaURL}/online-visa/lookups/mehrem-relations`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createOnlineVisaRequest = async (data) => {
	return await axios.post(`${onlineVisaURL}/online-visa/issue-request/create-visa-request`, data)
		.then((res) => res)
		.catch((err) => err.response);
}

export const paymentOnlineVisa = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/issue-request/create-visa-request-with-passengers`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listResidence = async () => {
	return await axios
		.get(`${onlineVisaURL}/online-visa/issue-request/list-countries-embassies`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const uploadImageOnlineVisa = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/issue-request/passenger/update-photo`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

//online-visa/issue-request/dropdown/types
export const onlineVisaTypeLookups = async () => {
	return await axios.get(
		`${onlineVisaURL}/online-visa/issue-request/dropdown/types`
	);
};

export const viewVisaOnline = async (id) => {
	return await axios.get(
		`${onlineVisaURL}/online-visa/issue-request/view/request/${id}`
	);
};

export const editVisaOnline = async (data) => {
	return await axios
		.post(
			`${onlineVisaURL}/online-visa/issue-request/edit-request-passengers`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};


export const editRequestPassengers = async (data) => {
	return await axios.post(`${onlineVisaURL}/online-visa/issue-request/edit-request-passengers`, data)
		.then((res) => res)
		.catch((err) => err.response);
}
export const splitRequestPassengers = async (id) => {
	return await axios.post(`${onlineVisaURL}/online-visa/issue-request/split-request-passengers/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
}
export const sendRequestPassengers = async (data) => {
	return await axios.post(`${onlineVisaURL}/online-visa/issue-request/send-request-passengers`, data)
		.then((res) => res)
		.catch((err) => err.response);
}