import Bus from "assets/images/bus.jpg";
import Iconhotel from "assets/images/hotel-placeholder.png";
import Sedan from "assets/images/sedan.png";
import Suv from "assets/images/suv.jpg";
import Van from "assets/images/van.jpg";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import { useMarketplaceState } from "context/marketplace";
import { useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import {
	Button,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import { bookHotel } from "services/marketplace";
import Locale from "translations";
import Hotel from "./Hotel";
import PayModal from "./payModal";
function Summary() {
	const { marketPlace, payment, productsBuilder } = Locale;
	const history = useHistory();

	const {  selected } = useMarketplaceState();
	const { locale } = useSBSState();
	const HotelData = JSON.parse(localStorage.getItem("HotelData"));
	const [modalPay, setModalPay] = useState(false);
	const togglePayModal = () => setModalPay(!modalPay);
	const [isChecked, setIsChecked] = useState(false);
	const [modal, setModal] = useState(false);
	const [account, setAccount] = useState(null);
	const [otp, setOtp] = useState(null);
	const toggleModal = () => setModal(!modal);

	const payNow = async () => {
		if (+HotelData.gds == 3) {
			book();
		} else if (+HotelData.gds == 4) {
			togglePayModal();
		} else {
			toggleModal();
		}
	};

	const book = async () => {
		const roomData = {
			special_request: "",
			hotel_availability_cache_id: selected.checkId,
			room_type: +selected.type,
			provider_id: HotelData.gds,
			// dummy room_guests
			rooms_data: [
				{
					room_key: selected.roomKey,
					room_guests: [
						{
							type: "ADT",
							first_name: "Sarfaraz",
							middle_name: "",
							last_name: "Khan",
							gender: "M",
							birth_date: "1985-02-25T00:00:00",
							location: {
								name: "Jeddah Plaza",
								country_code: "SA",
								country: "Saudi Arabia",
								address: "Ibrahim Al Khalil Street",
								city: "Jeddah",
								state: "",
								zip_code: "41419",
							},
							contact_information: {
								phone_number: "789456120",
								phone_number_country_code: "966",
								home_phone_number: "4561230789",
								home_phone_number_country_code: "966",
								fax: "7778889900",
								email: "actlkalpesh@gmail.com",
							},
						},
					],
				},
			],

			payment_info: {
				account_number: account,
				otp: otp,
			},
			lang: locale,
		};


		let book = await bookHotel(roomData);
		if (book.status === 200) {
			if (HotelData.gds != 3) {
				toggleModal();
			}

			store.addNotification({
				title: "Info!",
				message: "Booked successfully!",
				type: "info",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push("/inventory");
				},
			});
		} else {
			store.addNotification({
				title: "Info!",
				message: "Booking failed!",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push({
						pathname: `/market-details/${selected.checkId}`,
						state: { code: selected.checkId },
					});
				},
			});
		}
	};


	//

	return (
		<>
			<div className="b2c-invoice-wrapper pt-2">
				<div className="container">
					<Hotel icon={Iconhotel} />
					<div className="airlines-checkbox mt-4">
						<FormGroup check>
							<Label check>
								<Input
									onChange={() => setIsChecked(!isChecked)}
									checked={isChecked}
									id="agree"
									name="agree"
									type="checkbox"
								/>
								{marketPlace.messages.agreeTandC}
							</Label>
						</FormGroup>
					</div>
					<button
						onClick={() => payNow()}
						type="button"
						data-toggle="modal"
						className="blue-bg-color details-button form-control text-black  font-14"
						disabled={!isChecked}
					>
						{payment.messages.payNow}
					</button>
				</div>

				<Modal size="lg" isOpen={modal} toggle={toggleModal}>
					<ModalHeader className="align-items-center" toggle={toggleModal}>
						{marketPlace.messages.accountData}
					</ModalHeader>
					<ModalBody className="mx-3">
						<div className="row">
							<div className="col-md-6">
								<TextField
									label={marketPlace.messages.accountNumber}
									placeholder={marketPlace.messages.enterAccountNumber}
									value={account}
									onChange={(e) => setAccount(e.target.value)}
								/>
							</div>

							<div className="col-md-6">
								<TextField
									label={marketPlace.messages.otp}
									placeholder={marketPlace.messages.enterOtp}
									value={otp}
									onChange={(e) => setOtp(e.target.value)}
								/>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							className="bg-gray-150 text-dark border-0 px-4"
							onClick={toggleModal}
						>
							{productsBuilder.cancel}
						</Button>
						<Button
							disabled={[account, otp].some((el) => el === null || el === "")}
							className="px-5 w-30"
							color="primary"
							onClick={() => book()}
						>
							{productsBuilder.save}
						</Button>{" "}
					</ModalFooter>
				</Modal>
			</div>
			<PayModal
				modalPay={modalPay}
				togglePayModal={togglePayModal}
				productItem={HotelData}
				account={account}
				otp={otp}
			/>
		</>
	);
}

export default Summary;
