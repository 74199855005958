import axios from "axios";
import { cleanEmpty } from "./general";
const productBuilderURL =
	process.env.REACT_APP_PRODUCT_BUILDER_BREAKDOWN + "/products";
const baseURL = process.env.REACT_APP_API_URL;

export const createProduct = async (data) => {
	return await axios
		.post(`${productBuilderURL}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const getAllProducts = async (data, page = 1) => {
	return await axios
		.post(
			`${process.env.REACT_APP_PRODUCTS_BUILDER}/products_list?page=${page}`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getSingleProduct = async (id) => {
	return await axios
		.get(`${productBuilderURL}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteProduct = async (id) => {
	return await axios
		.delete(`${productBuilderURL}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateProduct = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const addProductsPrice = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/price`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteItem = async (uuid, id) => {
	return await axios
		.delete(`${productBuilderURL}/${uuid}/items/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createHotel = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/hotels`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateHotel = async (id, hotel_id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/hotels/${hotel_id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const createExternal = async (id, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/external-transportations`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateExternal = async (id, compId, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/external-transportations/${compId}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createDomestic = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/domestics`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateDomestic = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/domestics/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const createInternal = async (id, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/internal-transportations`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateInternal = async (id, compId, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/internal-transportations/${compId}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createLandmark = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/sightseeings`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateLandmark = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/sightseeings/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const createVisa = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/visa`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateVisa = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/visa/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const createInsurance = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/insurance`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateInsurance = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/insurance/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const createOther = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/other-services`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateOther = async (id, compId, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/other-services/${compId}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getHotelMapToolHotels = async () => {
	return await axios
		.get(`${baseURL}/v1/lookups/hotel_map`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const getInventoryHotels = async (param) => {
	return await axios
		.get(`${baseURL}/trip-management/inventory_hotels`, {
			params: cleanEmpty(param),
		})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const paymentActionDone = async (param) => {
	return await axios
		.post(`${baseURL}/online-payment/webhook/hyperpay`, cleanEmpty(param))
		.then((res) => res.data)
		.catch((err) => err.response);
};
