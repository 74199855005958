import { useSBSState } from "context/global";
import DashboardLayout from "layouts/DashboardLayout";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { MarketPlaceProvider } from "../../context/marketplace";
// React Component
export default function MarketPlaceRoute({ component: Component, ...props }) {
	const { isAuth } = useSBSState();

	if (true) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<MarketPlaceProvider>
						<DashboardLayout>
							<Component {...matchProps} />
						</DashboardLayout>
					</MarketPlaceProvider>
				)}
			/>
		);
	} else {
		return <Redirect to="/" />;
	}
}
