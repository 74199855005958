import Pagination from "components/Pagination";
import { useSBSState } from "context/global";
import { useMarketplaceDispatch } from 'context/marketplace';
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import { onlineVisaRequest } from "services/VisaOnline";
import Locale from "translations";
import Filter from "./Filter";

export default function VisaRequests() {
	const { inventory, payment, onlineVisa, marketPlace } = Locale;
	let history = useHistory();
	const { locale } = useSBSState();
	const dispatch = useMarketplaceDispatch();

	const [visaList, setVisaList] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		search: "",
		destination_id: "",
		visa_type_id: "",
		request_date: "",
		status: "",
		page: 1,
	});
	const [searchStatus, setSearchStatus] = useState("");
	const [total, setTotal] = useState();

	useEffect(() => {
		async function fetchRequests() {
			const res = await onlineVisaRequest({
				...filter,
				destination_id: filter?.destination_id?.value,
				request_date: filter.request_date
					? moment(filter.request_date).format("YYYY-MM-DD")
					: "",
				visa_type_id: filter?.visa_type_id?.value,
				status: filter?.status?.title,
			});
			setVisaList(res.data?.data);
			setMeta(res?.data?.meta);
			setTotal(res?.data?.counter);
		}
		fetchRequests();
		// reset 
		dispatch({
			type: "onlineVisaPassengerRemove",
			payload: [],
		});
		dispatch({
			type: "onlineVisaSearch",
			payload: {},
		});
	}, [searchStatus, filter.page]);

	const search = () => {
		setSearchStatus(!searchStatus);
	};
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};

	//test

	return (
		<div className="visa-requests">
			<div className="d-flex justify-content-between p-3 header-visa inventory-header-totals">
				<h3>{inventory.messages.visaRequests}</h3>
				<div className=" d-flex justify-content-between totals_container">
					<span className="btn btn-yellow text-light mx-2">
						{inventory.messages.all} ({meta?.total})
					</span>
					<span className="btn btn-light border mx-2">
						{payment.messages.Pending}
						({total?.filter(approved => approved?.status == "pending")[0]?.total || 0})
					</span>
					<span className="btn btn-light border mx-2">
						{inventory.messages.waitingVisa}
						({total?.filter(approved => approved?.status == "waiting")[0]?.total || 0})
					</span>
					<span className="btn btn-light border mx-2">
						{inventory.messages.issuedVisas}
						({total?.filter(approved => approved?.status == "issued")[0]?.total || 0})
					</span>
					<span className="btn btn-light border mx-2">
						{payment.messages.Expired}
						({total?.filter(expired => expired?.status == "expired")[0]?.total || 0})
					</span>
				</div>
			</div>
			<Filter filter={filter} setFilter={setFilter} search={search} />
			<div className='table-container'>
			<Table striped className="table-update p-2 bg-white text-capitalize">
				<thead>
					<tr>
						<th>ID</th>
						<th>Main Request ID</th>
						<th>{inventory.messages.destination}</th>
						<th>{inventory.messages.visaType}</th>
						<th>{inventory.messages.transportationType}</th>
						<th>{onlineVisa.VisaCount}</th>
						<th>{inventory.messages.departure}</th>
						<th>{onlineVisa.Return}</th>
						<th>{inventory.messages.requestDate}</th>
						<th>{marketPlace.messages.requestExpireDate}</th>
						<th>{inventory.messages.status}</th>
						<th>{inventory.messages.actions}</th>
					</tr>
				</thead>
				<tbody>
					{visaList?.length > 0 ? (
						visaList.map((item, index) => {
							return (
								<tr>
									<td>{item?.id}</td>
									<td>{item?.parent_id || "-"}</td>
									<td>{item?.destination}</td>
									<td>{item?.visa_type}</td>
									<td>{item?.transportation_type}</td>
									<td>{item?.pax}</td>
									<td>{item?.departure_date ? moment(item?.departure_date).format("YYYY-MM-DD") : "-"}</td>
									<td>{item?.return_date ? moment(item?.return_date).format("YYYY-MM-DD") : "-"}</td>
									<td>{item?.request_date?.slice(0, 10)}</td>
									<td>{item?.expiration_date ? moment(item?.expiration_date).format("YYYY-MM-DD") : "-"}</td>


									<td>
										<span className={`font-weight-normal text-capitalize badge badge-${item?.status == "waiting" ? "waiting" :
											item?.status === "incomplete" || item?.status === "expired" ? "danger" :
												item?.status === 'pending' ? 'pending' : 'issued'
											}`}
										>
											{item?.status === 'waiting' ? inventory.messages.waitingVisa : item?.status}
										</span>
									</td>
									<td>
										<span className="text-yellow">
											{item?.status == "incomplete" || item?.status == "pending" ? (
												<span className="pointer"
													onClick={() => {
														history.push(`/inventory/visa-requests/edit/${item.id}`);
													}}
												>
													<i class="far fa-edit pointer"></i> {inventory.messages.edit}
												</span>
											) : (
												<span className="pointer"
													onClick={() => {
														history.push(`/inventory/visa-requests/view/${item.id}`);
													}}
												>
													<i class="fas fa-eye"></i> {inventory.messages.view}
												</span>
											)}
										</span>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan="7">
								<div className="product-build__product-no-data fullHeight">
									<i className="icx icx-eye-slash" aria-hidden="true"></i>
									<h4>{"No Result"}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			</div>
			{visaList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</div>
	);
}
