import ImagePlaceholder from "assets/images/image_placeholder.png";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import useFetchCountries from "hooks/useFetchCountries";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { listMehrem, uploadImageOnlineVisa } from "services/VisaOnline";
import Locale from "translations";

export default function EditPassangerProfile() {
	const { visa } = Locale;
	const dispatch = useMarketplaceDispatch();
	const { onlineVisaEditPassenger, onlineVisaPassenger, onlineVisaSearch } =
		useMarketplaceState();
	const [changingImage, setchangingImage] = useState(false);

	let history = useHistory();
	const [relationsLookups, setRelationsLookups] = useState([]);

	const { locale } = useSBSState();
	const [countries] = useFetchCountries();

	const genderLookups = [
		{
			id: 1,
			name: `${locale === "en" ? "Male" : "ذكر"}`,
		},
		{ id: 2, name: `${locale === "en" ? "Female" : "أنثي"}` },
	];

	useEffect(() => {
		const fetchMehremRelations = async () => {
			const respones = await listMehrem();
			if (respones.status === 200 || respones.status === 201) {
				setRelationsLookups(respones.data.data);
			}
		};
		fetchMehremRelations();
	}, []);

	useEffect(() => {
		if (countries) {
			let issue_country = countries.find(
				(country) => country.id === member?.issue_country?.value
			);
			let nationality = countries.find(
				(country) => country.id === member?.nationality?.value
			);
			setMember({
				...member,
				issue_country: { value: issue_country?.id, label: issue_country?.name },
				nationality: { value: nationality?.id, label: nationality?.name },
			});
		}
	}, [countries]);

	const makeLookups = (arr) => {
		return arr.map((item, index) => {
			return {
				id: item.id,
				name: locale === "ar" ? item.Name_Ar : item.Name_En,
			};
		});
	};
	const PassType = makeLookups([
		{
			id: 1,
			Name_En: "Normal",
			Name_Ar: "عادي",
		},
		{
			id: 2,
			Name_En: "Diplomatic",
			Name_Ar: "دبلوماسي",
		},
		{
			id: 3,
			Name_En: "Travel Document",
			Name_Ar: "وثيقة سفر",
		},
		{
			id: 4,
			Name_En: "UN Passport",
			Name_Ar: "جواز الأمم المتحدة",
		},
		{
			id: 99,
			Name_En: "Other",
			Name_Ar: "اخرى",
		},
	]);
	const RelationsList = makeLookups([
		{
			id: -1,
			Name_En: "No Mahram",
			Name_Ar: "بدون محرم",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 0,
			Name_En: "",
			Name_Ar: "",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 0,
		},
		{
			id: 1,
			Name_En: "Son",
			Name_Ar: "ابن",
			Gender_id: 1,
			RR_TYPE: 1,
			RR_Custom: 1,
		},
		{
			id: 2,
			Name_En: "Daughter",
			Name_Ar: "ابنه",
			Gender_id: 2,
			RR_TYPE: 1,
			RR_Custom: 0,
		},
		{
			id: 3,
			Name_En: "Father",
			Name_Ar: "اب",
			Gender_id: 1,
			RR_TYPE: 1,
			RR_Custom: 1,
		},
		{
			id: 4,
			Name_En: "Mother",
			Name_Ar: "ام",
			Gender_id: 2,
			RR_TYPE: 1,
			RR_Custom: 0,
		},
		{
			id: 5,
			Name_En: "Brother",
			Name_Ar: "اخ",
			Gender_id: 1,
			RR_TYPE: 1,
			RR_Custom: 1,
		},
		{
			id: 6,
			Name_En: "Sister",
			Name_Ar: "اخت",
			Gender_id: 2,
			RR_TYPE: 1,
			RR_Custom: 0,
		},
		{
			id: 7,
			Name_En: "Husband",
			Name_Ar: "زوج",
			Gender_id: 1,
			RR_TYPE: 1,
			RR_Custom: 1,
		},
		{
			id: 8,
			Name_En: "Wife",
			Name_Ar: "زوجة",
			Gender_id: 2,
			RR_TYPE: 1,
			RR_Custom: 0,
		},
		{
			id: 9,
			Name_En: "Grandfather",
			Name_Ar: "الجد",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 10,
			Name_En: "Grandmother",
			Name_Ar: "الجدة",
			Gender_id: 2,
			RR_TYPE: 0,
			RR_Custom: 0,
		},
		{
			id: 11,
			Name_En: "Nephew-Brother Son",
			Name_Ar: "ابن الاخ",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 12,
			Name_En: "Nephew-Sister Son",
			Name_Ar: "ابن الاخت",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 13,
			Name_En: "Wife`s Son-in-law",
			Name_Ar: "صهر الزوجة",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 0,
		},
		{
			id: 14,
			Name_En: "Husband`s Son-in-law",
			Name_Ar: "صهر الزوج",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 0,
		},
		{
			id: 15,
			Name_En: "Women Group",
			Name_Ar: "عصبة النساء",
			Gender_id: 1,
			RR_TYPE: 2,
			RR_Custom: 1,
		},
		{
			id: 16,
			Name_En: "Grandson",
			Name_Ar: "حفيد",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 17,
			Name_En: "Mother-in-law",
			Name_Ar: "الحماة",
			Gender_id: 2,
			RR_TYPE: 0,
			RR_Custom: 0,
		},
		{
			id: 18,
			Name_En: "Uncle[Mother brother]",
			Name_Ar: "الخال",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 19,
			Name_En: "Aunt[Mother sister]",
			Name_Ar: "الخالة",
			Gender_id: 2,
			RR_TYPE: 0,
			RR_Custom: 0,
		},
		{
			id: 20,
			Name_En: "Uncle[Father brother]",
			Name_Ar: "العم",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 21,
			Name_En: "Aunt[Father Sister]",
			Name_Ar: "العمة",
			Gender_id: 2,
			RR_TYPE: 0,
			RR_Custom: 0,
		},
		{
			id: 22,
			Name_En: "Daughter-in-law",
			Name_Ar: "زوجة الابن",
			Gender_id: 2,
			RR_TYPE: 0,
			RR_Custom: 0,
		},
		{
			id: 23,
			Name_En: "Son-in-law",
			Name_Ar: "زوج البنت",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 24,
			Name_En: "Step Mother",
			Name_Ar: "زوجة الاب",
			Gender_id: 2,
			RR_TYPE: 0,
			RR_Custom: 0,
		},
		{
			id: 25,
			Name_En: "Step Father",
			Name_Ar: "زوج الام",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 26,
			Name_En: "Granddaughter",
			Name_Ar: "حفيدة",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 27,
			Name_En: "Father-in-law",
			Name_Ar: "الحمو",
			Gender_id: 1,
			RR_TYPE: 0,
			RR_Custom: 1,
		},
		{
			id: 99,
			Name_En: "Others",
			Name_Ar: "اخرى",
			Gender_id: 1,
			RR_TYPE: 1,
			RR_Custom: 1,
		},
	]);
	const TitleList = makeLookups([
		{
			id: 1,
			Name_En: "Mr",
			Name_Ar: "السيد",
		},
		{
			id: 2,
			Name_En: "Miss",
			Name_Ar: "الأنسة",
		},
		{
			id: 3,
			Name_En: "Mrs",
			Name_Ar: "السيدة",
		},
		{
			id: 4,
			Name_En: "Dr",
			Name_Ar: "دكتور",
		},
		{
			id: 5,
			Name_En: "His Excellency",
			Name_Ar: "سعادة",
		},
		{
			id: 6,
			Name_En: "His Royal Highness",
			Name_Ar: "سمو",
		},
		{
			id: 99,
			Name_En: "Other",
			Name_Ar: "اخرى",
		},
	]);
	const MaritalStatus = makeLookups([
		{
			id: 1,
			Name_En: "Single",
			Name_Ar: "أعزب",
		},
		{
			id: 2,
			Name_En: "Married",
			Name_Ar: "متزوج",
		},
		{
			id: 3,
			Name_En: "Divorced",
			Name_Ar: "مطلق",
		},
		{
			id: 4,
			Name_En: "Widowed",
			Name_Ar: "أرمل",
		},
		{
			id: 99,
			Name_En: "Other",
			Name_Ar: "اخرى",
		},
	]);
	const EduLevel = makeLookups([
		{
			id: 1,
			Name_Ar: "بدون تعليم",
			Name_En: "No Education",
		},
		{
			id: 2,
			Name_Ar: "تعليم ابتدائي",
			Name_En: "Primary School",
		},
		{
			id: 3,
			Name_Ar: "تعليم ثانوي",
			Name_En: "High School",
		},
		{
			id: 4,
			Name_Ar: "تعليم جامعي",
			Name_En: "Higher Education",
		},
		{
			id: 99,
			Name_Ar: "اخرى",
			Name_En: "Other",
		},
	]);

	const [member, setMember] = useState({
		first_name: { ar: "", en: "" },
		father_name: { ar: "", en: "" },
		grandfather_name: { ar: "", en: "" },
		last_name: { ar: "", en: "" },
		national_id: "",
		gender: { value: null, label: "" },
		title: "",
		passport_type: { value: null, label: locale === "en" ? "normal" : "عادي" },
		passport_number: "",
		marital_status: { value: "99", label: locale === "en" ? "other" : "اخرى" },
		educational_level: {
			value: "99",
			label: locale === "en" ? "other" : "اخرى",
		},
		country: onlineVisaSearch?.residence,
		nationality: { value: null, label: "" },
		birth_country: { value: null, label: "" },
		birth_place: "",
		issue_country: { value: null, label: "" },
		issue_place: "",
		date_of_birth: "",
		issue_date: "",
		expire_date: "",
		job: "",
		address: "",
		photo: "",
		relationship: { value: null, label: "" },
		mehrem_name: { value: null, label: "" },
		mrz: "",
	});

	useEffect(() => {
		// this condition means that this passenger comes from group else this passenger comes from mrz
		if (onlineVisaEditPassenger && onlineVisaEditPassenger.id) {
			setMember({
				...onlineVisaEditPassenger,
				first_name: {
					ar: onlineVisaEditPassenger?.full_name_ar?.split(" ")[0],
					en: onlineVisaEditPassenger?.full_name_en?.split(" ")[0],
				},
				father_name: {
					ar: onlineVisaEditPassenger?.full_name_ar?.split(" ")[1],
					en: onlineVisaEditPassenger?.full_name_en?.split(" ")[1],
				},
				grandfather_name: {
					ar: onlineVisaEditPassenger?.full_name_ar?.split(" ")[2],
					en: onlineVisaEditPassenger?.full_name_en?.split(" ")[2],
				},
				last_name: {
					ar: onlineVisaEditPassenger?.full_name_ar?.split(" ")[3],
					en: onlineVisaEditPassenger?.full_name_en?.split(" ")[3],
				},
				date_of_birth: onlineVisaEditPassenger?.birth_date,
				expire_date: onlineVisaEditPassenger?.expire_date,
				issue_date: onlineVisaEditPassenger?.issue_date,
				issue_place: onlineVisaEditPassenger?.issue_place,
				passport_number: onlineVisaEditPassenger?.passport_number,
				photo: onlineVisaEditPassenger?.photo
					? onlineVisaEditPassenger?.photo
					: "",
				mrz: onlineVisaEditPassenger?.mrz,

				gender: {
					value:
						onlineVisaEditPassenger?.gender == "Male"
							? 1
							: onlineVisaEditPassenger?.gender === "Female"
							? 2
							: 0,
					label: onlineVisaEditPassenger?.gender,
				},

				title: {
					value:
						onlineVisaEditPassenger?.gender == "Male"
							? "1"
							: onlineVisaEditPassenger?.gender == "Female"
							? "2"
							: "",
					label:
						onlineVisaEditPassenger?.gender == "Male"
							? `${locale === "en" ? "Mr" : "سيد"}`
							: onlineVisaEditPassenger?.gender == "Female"
							? `${locale === "en" ? "Mrs" : "سيدة"}`
							: "",
				},

				issue_country: {
					value: onlineVisaEditPassenger?.issue_country,
					label: onlineVisaEditPassenger?.issue_country_label,
				},

				nationality: {
					value: onlineVisaEditPassenger.nationality_id,
					label: onlineVisaEditPassenger?.nationality,
				},

				relationship: {
					value: onlineVisaEditPassenger.mehrem_relation_code,
					label: onlineVisaEditPassenger.relationship[locale]
						? {
								ar: onlineVisaEditPassenger.relationship.ar,
								en: onlineVisaEditPassenger.relationship.en,
						  }
						: {
								ar: onlineVisaEditPassenger.relationship,
								en: onlineVisaEditPassenger.relationship,
						  },
				},
				mehrem_name: {
					value: onlineVisaEditPassenger.mehrem_passport_number,
					label: onlineVisaEditPassenger?.mehrem_name,
				},

				passport_type: {
					value: 0,
					label: onlineVisaEditPassenger?.passport_type
						? onlineVisaEditPassenger?.passport_type
						: member.passport_type.label,
				},

				marital_status: {
					value: 0,
					label: onlineVisaEditPassenger?.marital_status
						? onlineVisaEditPassenger?.marital_status
						: member.marital_status.label,
				},

				country: {
					value: 0,
					label: onlineVisaEditPassenger?.country
						? onlineVisaEditPassenger?.country
						: member.country.label,
				},

				educational_level: {
					value: 0,
					label: onlineVisaEditPassenger?.educational_level
						? onlineVisaEditPassenger?.educational_level
						: member.educational_level.label,
				},

				birth_country: {
					value: onlineVisaEditPassenger.nationality_id,
					label: onlineVisaEditPassenger?.nationality,
				},
			});
		} else {
			setMember({
				...onlineVisaEditPassenger,
				first_name: {
					ar: onlineVisaEditPassenger?.first_name_ar,
					en: onlineVisaEditPassenger?.first_name_en,
				},
				father_name: {
					ar: onlineVisaEditPassenger?.father_name_ar,
					en: onlineVisaEditPassenger?.father_name_en,
				},
				grandfather_name: {
					ar: onlineVisaEditPassenger?.grandfather_name_ar,
					en: onlineVisaEditPassenger?.grandfather_name_en,
				},
				last_name: {
					ar: onlineVisaEditPassenger?.last_name_ar,
					en: onlineVisaEditPassenger?.last_name_en,
				},
				date_of_birth: onlineVisaEditPassenger?.birth_date,
				expire_date: onlineVisaEditPassenger?.expire_date,
				issue_date: onlineVisaEditPassenger?.issue_date,
				issue_place: onlineVisaEditPassenger?.issue_place,
				passport_number: onlineVisaEditPassenger?.passport_number,
				photo: onlineVisaEditPassenger?.photo
					? onlineVisaEditPassenger?.photo
					: "",
				mrz: onlineVisaEditPassenger?.mrz,
				title: {
					value: onlineVisaEditPassenger?.title,
					label: onlineVisaEditPassenger?.title,
				},
				gender: {
					value:
						onlineVisaEditPassenger?.gender == "Male"
							? 1
							: onlineVisaEditPassenger?.gender === "Female"
							? 2
							: 0,
					label: onlineVisaEditPassenger?.gender,
				},

				issue_country: {
					value: onlineVisaEditPassenger?.issue_country,
					label: onlineVisaEditPassenger?.issue_country_label,
				},

				nationality: {
					value: onlineVisaEditPassenger.nationality_id,
					label: onlineVisaEditPassenger?.nationality,
				},

				relationship: {
					value: onlineVisaEditPassenger.mehrem_relation_code,
					label: onlineVisaEditPassenger.relationship[locale]
						? {
								ar: onlineVisaEditPassenger.relationship.ar,
								en: onlineVisaEditPassenger.relationship.en,
						  }
						: {
								ar: onlineVisaEditPassenger.relationship,
								en: onlineVisaEditPassenger.relationship,
						  },
				},
				mehrem_name: {
					value: onlineVisaEditPassenger.mehrem_passport_number,
					label: onlineVisaEditPassenger?.mehrem_name,
				},
				passport_type: {
					value: 0,
					label: onlineVisaEditPassenger?.passport_type,
				},

				marital_status: {
					value: 0,
					label: onlineVisaEditPassenger?.marital_status,
				},

				country: {
					value: 0,
					label: onlineVisaEditPassenger?.country,
				},

				educational_level: {
					value: 0,
					label: onlineVisaEditPassenger?.educational_level,
				},

				birth_country: {
					value: 0,
					label: onlineVisaEditPassenger?.birth_country,
				},
			});
		}
	}, [onlineVisaEditPassenger]);

	const mehermList = onlineVisaPassenger
		.filter(
			(passenger) =>
				passenger.id != onlineVisaEditPassenger.id ||
				passenger.uniqueIdFrontend != onlineVisaEditPassenger.uniqueIdFrontend
		)
		.map((item) => {
			return {
				id: item.passport_number,
				name: locale === "ar" ? item.name_ar : item.name_en,
			};
		});

	const savePassanger = () => {
		console.log(member);
		dispatch({
			type: "onlineVisaRepalceEditPassenger",
			payload: {
				id: member.id ?? "",
				uniqueIdFrontend: member.uniqueIdFrontend ?? "",
				full_name_ar: `${member?.first_name?.ar} ${member?.father_name?.ar} ${member?.grandfather_name?.ar} ${member?.last_name?.ar}`,
				name_ar: `${member?.first_name?.ar} ${member?.father_name?.ar} ${member?.grandfather_name?.ar} ${member?.last_name?.ar}`,
				full_name_en: `${member?.first_name?.en} ${member?.father_name?.en} ${member?.grandfather_name?.en} ${member?.last_name?.en}`,
				name_en: `${member?.first_name?.en} ${member?.father_name?.en} ${member?.grandfather_name?.en} ${member?.last_name?.en}`,
				first_name_ar: member?.first_name?.ar,
				first_name_en: member?.first_name?.en,
				father_name_ar: member?.father_name?.ar,
				father_name_en: member?.father_name?.en,
				grandfather_name_ar: member?.grandfather_name?.ar,
				grandfather_name_en: member?.grandfather_name?.en,
				last_name_ar: member?.last_name?.ar,
				last_name_en: member?.last_name?.en,
				birth_date: member.date_of_birth,
				national_id: member?.national_id,
				gender: member?.gender?.label,
				title: member?.title.label,
				passport_type: member?.passport_type?.label,
				passport_number: member?.passport_number,
				marital_status: member?.marital_status?.label,
				educational_level: member?.educational_level?.label,
				country: member?.country?.label,
				nationality: member?.nationality?.label,
				birth_country: member?.birth_country?.label,
				birth_place: member?.birth_place,
				issue_country_label: member?.issue_country?.label,
				issue_country: member?.issue_country?.value,
				issue_place: member?.issue_place,
				date_of_birth: member?.date_of_birth
					? moment(member?.date_of_birth).format("YYYY-MM-DD")
					: "",
				issue_date: member?.issue_date
					? moment(member?.issue_date).format("YYYY-MM-DD")
					: "",
				expire_date: member?.expire_date
					? moment(member?.expire_date).format("YYYY-MM-DD")
					: "",
				job: member?.job,
				address: member?.address,
				relationship: member?.relationship?.label[locale],
				mehrem_relation_code: member?.relationship?.value?.toString(),
				mehrem_passport_number: member?.mehrem_name?.value,
				mehrem_name: member?.mehrem_name?.label,
				photo: member?.photo,
				mrz: member?.mrz,
				nationality_id: member?.nationality?.value,
				offline_company_id: member.offline_company_id
					? member.offline_company_id
					: "",
			},
		});
		history.push("/Online-Visa/Search");
	};

	const editImage = async () => {
		let formData = new FormData();
		formData.append("photo", member.imgFile);
		formData.append(
			"nationality_id",
			member?.nationality?.value ?? member.issue_country
		);
		formData.append("passport_number", member.passport_number);
		formData.append(
			"offline_company_id",
			member.offline_company_id ? member.offline_company_id : ""
		);
		const res = await uploadImageOnlineVisa(formData);
		if (res.data.data.faceImageUrl) {
			setMember({
				...member,
				photo: res.data.data.faceImageUrl,
			});
			setchangingImage(false);
		}
	};

	return (
		<div className="list-container">
			<div className="row">
				<div className="member-photo m-0 col-2 online-vise-img mt-4">
					{/* <img
            className="img-fluid rounded w-75"
            src={member.newPhoto ? member.newPhoto + "?" + new Date().getTime() : ImagePlaceholder
            }
            alt=""
          /> */}
					<img
						className="img-fluid rounded w-75"
						src={
							member.imgPrev
								? member.imgPrev
								: member.photo
								? `${member.photo + "?" + new Date().getTime()}`
								: ImagePlaceholder
						}
						alt=""
					/>
					{changingImage ? (
						<>
							<button
								className="btn btn-link text-center w-75 file-btn mt-2"
								onClick={editImage}
							>
								save
							</button>
							<button className="btn btn-link text-center w-75 file-btn mt-2">
								<i class="fas fa-edit mx-2"></i>
								{visa.ChangePhoto}
								<input
									type="file"
									className="custom-file"
									accept="image/png, image/gif, image/jpeg"
									onChange={(e) => {
										setMember({
											...member,
											imgPrev: URL.createObjectURL(e.target.files[0]),
											imgFile: e.target.files[0],
										});
										setchangingImage(true);
									}}
								/>
							</button>
						</>
					) : (
						<button className="btn btn-link text-center w-75 file-btn mt-2">
							<i class="fas fa-edit mx-2"></i>
							{visa.ChangePhoto}
							<input
								type="file"
								className="custom-file"
								accept="image/png, image/gif, image/jpeg"
								onChange={(e) => {
									setMember({
										...member,
										imgPrev: URL.createObjectURL(e.target.files[0]),
										imgFile: e.target.files[0],
									});
									setchangingImage(true);
								}}
							/>
						</button>
					)}
				</div>

				<div className=" col-10 p-0 ">
					<div className=" d-flex align-items-start justify-content-between">
						<div className=" w-100">
							<div className="box1">
								<div className="row align-items-center w-100 no-gutter  m-0 ">
									<div className="col-md-3 ">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.NATIONALID}
											placeholder={visa.NATIONALIDPlaceholder}
											value={member.national_id}
											onChange={(e) => {
												setMember({
													...member,
													national_id: e.target.value,
												});
											}}
										/>
									</div>
									<div className="col-md-3 ">
										<SelectField
											name="contract"
											placeholder={"Select Gender"}
											disabled={true}
											options={genderLookups}
											label={visa.Gender}
											value={member.gender.label}
											onChange={(e) => {
												setMember({
													...member,
													gender: e,
												});
											}}
										/>
									</div>

									<div className="col-md-3 ">
										<SelectField
											name="group-status"
											placeholder={"Select Title"}
											label={visa.title}
											options={TitleList}
											value={member?.title?.label}
											onChange={(e) => {
												setMember({
													...member,
													title: e,
												});
											}}
										/>
									</div>
									<div className="col-md-3"></div>
								</div>
							</div>

							<div className="box2 bg-gray p-1 mx-1 px-2 rounded">
								<h3 className="p-1 font-dark-blue"> {visa.ArabicName}</h3>
								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3 ">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.firstNamePlaceholder}
											value={member.first_name.ar}
											onChange={(e) => {
												setMember({
													...member,
													first_name: {
														en: member.first_name.en,
														ar: e.target.value,
													},
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.fatherNamePlaceholder}
											value={member.father_name.ar}
											onChange={(e) => {
												setMember({
													...member,
													father_name: {
														en: member.father_name.en,
														ar: e.target.value,
													},
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.grandFatherNamePlaceholder}
											value={member.grandfather_name.ar}
											onChange={(e) => {
												setMember({
													...member,
													grandfather_name: {
														en: member.grandfather_name.en,
														ar: e.target.value,
													},
												});
											}}
										/>
									</div>

									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.lastNamePlaceholder}
											value={member.last_name.ar}
											onChange={(e) => {
												setMember({
													...member,
													last_name: {
														en: member.last_name.en,
														ar: e.target.value,
													},
												});
											}}
										/>
									</div>
									<div className="col-md-3"></div>
								</div>
								<h3 className="p-1 font-dark-blue"> English Name</h3>
								<div className="row align-items-center w-100 no-gutter m-0  ">
									<div className="col-md-3 ">
										<TextField
											hasLabel={false}
											type="text"
											placeholder={visa.firstNamePlaceholder}
											value={member.first_name.en}
											onChange={(e) => {
												setMember({
													...member,
													first_name: {
														en: e.target.value,
														ar: member.first_name.ar,
													},
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											//label={visa.fatherName}
											placeholder={visa.fatherNamePlaceholder}
											value={member.father_name.en}
											onChange={(e) => {
												setMember({
													...member,
													father_name: {
														en: e.target.value,
														ar: member.father_name.ar,
													},
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											//label={visa.grandFatherName}
											placeholder={visa.grandFatherNamePlaceholder}
											value={member.grandfather_name.en}
											onChange={(e) => {
												setMember({
													...member,
													grandfather_name: {
														en: e.target.value,
														ar: member.grandfather_name.ar,
													},
												});
											}}
										/>
									</div>

									<div className="col-md-3">
										<TextField
											hasLabel={false}
											type="text"
											//label={visa.lastName}

											placeholder={visa.lastNamePlaceholder}
											value={member.last_name.en}
											onChange={(e) => {
												setMember({
													...member,
													last_name: {
														en: e.target.value,
														ar: member.last_name.ar,
													},
												});
											}}
										/>
									</div>
									<div className="col-md-3"></div>
								</div>
							</div>

							<div className=" my-2  ">
								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.passportType}
											placeholder={visa.passportTypePlaceholder}
											options={PassType}
											value={member?.passport_type?.label}
											onChange={(e) => {
												setMember({
													...member,
													passport_type: e,
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.maritalStatus}
											placeholder={visa.maritalStatusPlaceholder}
											options={MaritalStatus}
											value={member?.marital_status?.label}
											onChange={(e) => {
												setMember({
													...member,
													marital_status: e,
												});
											}}
										/>
									</div>

									<div className="col-md-3">
										<SelectField
											name="group-status"
											label={visa.educationLevel}
											placeholder={visa.educationLevelPlaceholder}
											options={EduLevel}
											value={member.educational_level?.label}
											onChange={(e) => {
												setMember({
													...member,
													educational_level: e,
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.country}
											placeholder={visa.countryPlaceholder}
											options={countries}
											disabled={member.country}
											value={member?.country?.label}
											onChange={(e) => {
												setMember({
													...member,
													country: e,
												});
											}}
										/>
									</div>
								</div>
								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.nationality}
											disabled={true}
											placeholder={visa.nationalityPlaceholder}
											options={countries}
											value={member?.nationality?.label}
											onChange={(e) => {
												setMember({
													...member,
													nationality: e,
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<SelectField
											name="contract"
											label={visa.birthCountry}
											placeholder={visa.birthCountryPlaceholder}
											options={countries}
											value={member?.birth_country?.label}
											onChange={(e) => {
												setMember({
													...member,
													birth_country: e,
												});
											}}
										/>
									</div>

									<div className="col-md-3">
										<SelectField
											name="group-status"
											label={visa.issueCountry}
											placeholder={visa.issueCountryPlaceholder}
											options={countries}
											value={member.issue_country.label}
											onChange={(e) => {
												setMember({
													...member,
													issue_country: e,
												});
											}}
										/>
									</div>
									<div className="col-md-3">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.issuePlace}
											placeholder={visa.issuePlacePlaceholder}
											value={member.issue_place}
											onChange={(e) => {
												setMember({
													...member,
													issue_place: e.target.value,
												});
											}}
										/>
									</div>
								</div>

								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.birthPlace}
											placeholder={visa.birthPlacePLaceholder}
											value={member.birth_place}
											onChange={(e) => {
												setMember({
													...member,
													birth_place: e.target.value,
												});
											}}
										/>
									</div>
									<div className="col-md-3 ">
										<DatePickerField
											label={visa.Birthday}
											placeholder={"DD/MM/YYYY"}
											disabled={true}
											date={moment(member?.date_of_birth)}
											value={moment(member?.date_of_birth)}
											onChangeDate={(e) => {
												setMember({
													...member,
													date_of_birth: e,
												});
											}}
										/>
									</div>

									<div className="col-md-3 ">
										<DatePickerField
											label={visa.issueDate}
											placeholder={"DD/MM/YYYY"}
											isOutsideRange={() => false}
											date={
												member?.issue_date ? moment(member?.issue_date) : null
											}
											onChangeDate={(e) => {
												setMember({
													...member,
													issue_date: e,
												});
											}}
										/>
									</div>
									<div className="col-md-3 ">
										<DatePickerField
											label={visa.expiryDate}
											disabled={true}
											placeholder={"DD/MM/YYYY"}
											date={moment(member?.expire_date)}
											value={moment(member?.expire_date)}
											onChangeDate={(e) => {
												setMember({
													...member,
													expire_date: e,
												});
											}}
										/>
									</div>
								</div>

								<div className="row align-items-center w-100 no-gutter m-0 ">
									<div className="col-md-3">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.JOB}
											placeholder={visa.jobPlaceholdre}
											value={member?.job}
											onChange={(e) => {
												setMember({
													...member,
													job: e.target.value,
												});
											}}
										/>
									</div>
									<div className="col-3">
										<TextField
											hasLabel={true}
											type="text"
											disabled={true}
											label={"Passport No"}
											placeholder={"Passport No"}
											value={member.passport_number}
											onChange={(e) => {
												setMember({
													...member,
													passport_number: e.target.value,
												});
											}}
										/>
									</div>
								</div>

								<div className="row align-items-center w-100 no-gutter m-0 ">
									{/* <div className="col-md-6">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.ADDRESS}
											//6placeholder={visa.ADDRESSPlaceholder}
											value={member?.CAddaCity}
									
										
										/>
									</div> */}

									<div className="col-md-3">
										<SelectField
											name="group-status"
											label={visa.MAHRAM}
											placeholder={visa.MAHRAMPlaceholder}
											options={mehermList}
											value={member?.mehrem_name?.label}
											onChange={(e) => {
												setMember({
													...member,
													mehrem_name: e,
												});
											}}
										/>
									</div>

									<div className="col-md-3">
										<SelectField
											name="group-status"
											label={visa.RELATION}
											placeholder={visa.relationPlaceholder}
											options={relationsLookups}
											value={member?.relationship?.label[locale]}
											onChange={(e) => {
												setMember({
													...member,
													relationship: {
														value: e.value,
														label: { ar: e.label, en: e.label },
													},
												});
											}}
										/>
									</div>
								</div>

								<div className="row align-items-center w-100 no-gutter m-0  round mrz   px-2">
									<div className="mrz-label col-md-2 bg-grey py-2 text-light text-center rounded ">
										<p>MRZ</p>
									</div>
									<div className="col-md-10  text-16 bg-gray text-center">
										<p className="py-2">{member?.mrz}</p>
									</div>
								</div>

								<div className="d-flex justify-content-end my-4">
									<button
										className="btn bg-gray mx-2"
										onClick={() => {
											history.push("/Online-Visa/Search");
										}}
									>
										Cancel
									</button>
									<button className="btn bg-nxt" onClick={savePassanger}>
										save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
