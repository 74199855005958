import Locale from "translations";

const TermsDetails = ({ details }) => {
	const { inventory } = Locale;

	return (
		<div className="px-4 my-4 room-details">
			<div className="passenger ">
				<h3 className="header-hotel-info">
					{inventory.messages.TERMSwCONDITIONS}
				</h3>
				<div className="mt-3 invetory-table terms-details ">
					{details?.terms != null ? (
						<p>{details?.terms}</p>
					) : (
						<div className="product-build__product-no-data ">
							<i class="fas fa-exclamation"></i>
							<h4>{inventory.messages.NoTERMs}</h4>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TermsDetails;
