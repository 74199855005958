export default function sbsReducer(state, action) {
	switch (action.type) {
		case "setLocale":
			localStorage.setItem("currentLocale", action.payload);
			window.location.reload();
			return { ...state, locale: action.payload };

		case "setLoading":
			return { ...state, loading: action.payload };

		case "setError":
			return {
				...state,
				isError: action.payload,
				ErrorMassage: { ...action.message },
			};

		case "searchOffer":
			return {
				...state,
				token_data: action.payload,
			};

		case "searchOfferClear":
			return {
				...state,
				token_data: undefined,
			};

		case "login":
			localStorage.setItem("isAuth", true);
			localStorage.setItem("token", action.token);
			localStorage.setItem(
				"companyName",
				action.companyInfo.name && action.companyInfo.name.hasOwnProperty("en")
					? action.companyInfo?.name?.en
					: action.companyInfo?.name?.ar
			);
			localStorage.setItem("userInfo", JSON.stringify(action.userInfo));

			localStorage.setItem("permissions", JSON.stringify(action?.permissions));
			localStorage.setItem("role", action?.role);

			return {
				...state,
				isAuth: action.isAuth,
				token: action.token,
				userInfo: action.userInfo,
				companyInfo: action.companyInfo,
				companyName:
					action?.companyInfo?.name &&
					action?.companyInfo?.name.hasOwnProperty("en")
						? action.companyInfo?.name?.en
						: action.companyInfo?.name?.ar,
				permissions: action?.permissions,
				role: action?.role,
			};

		case "logout":
			localStorage.removeItem("isAuth");
			localStorage.removeItem("token");
			localStorage.removeItem("VisaToken");
			localStorage.removeItem("userInfo");

			return {
				...state,
				isAuth: false,
				token: null,
				userInfo: null,
				companyInfo: null,
			};

		case "getPermissions":
			localStorage.setItem("role", action?.payload?.role);
			localStorage.setItem(
				"permissions",
				JSON.stringify(action?.payload?.permissions)
			);
			return {
				...state,
				permissions: action?.payload?.permissions,
				role: action?.payload?.role,
			};

		case "getSafaBalance":
			return { ...state, safaBalance: action?.payload };
		case "getVoucher":
			return { ...state, voucher: action?.payload };
		case "getMyBalance":
			return { ...state, myBalance: action?.payload };
		case "Save_User_Logo":
			return {
				...state,
				userLogo: action.userLogo,
			};

		case "guide": {
			return { ...state, guide: action.payload }
		}

		case "removeFirstLogin" : {
			localStorage.setItem("userInfo", JSON.stringify(action.payload));
			return { ...state, userInfo: action.payload } 
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}
