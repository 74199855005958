import Activity from "../Components/Activity";
import BookingList from "../Components/BookingList";
import RevenueStatistics from "../Components/RevenueStatistics";

export default function index() {
	return (
		<div className="container statistics-container">
			<div className="row m-0 pt-5  statistics">
				<div className="col-md-9">
					<RevenueStatistics />
					<BookingList />
				</div>
				<div className="col-md-3">
					<div className="col-md-12 mb-3 bg-white shadow-sm rounded">
						<Activity />
					</div>
				</div>
			</div>
		</div>
	);
}
