import noHotel from "assets/images/placeHoleder.png";
import { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { store } from "react-notifications-component";
import { useHistory, useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { fetchImages } from "services/auth";
import { getInvoice, payFees, payGroupsFees } from "services/marketplace";
import Locale from "translations";
import SafaLogo from "../../../assets/images/MarketInvoice/SafaLogo.png";
import RequestStatus from "./Components/requestStatus";
import InventoryTab from "./InventoryTab";
import CustomModal from "./Modal";
import Payment from "./Payment";
import PaymentDetails from "./PaymentDetails";
import RoomDetails from "./RoomDetails";
import TermsDetails from "./TermsDetails";
export default function BookingDetails({
	details,
	setDetails,
	GroupInventory,
}) {
	const { marketPlace, inventory } = Locale;
	const translate = inventory.viewReservation;
	const [hotelInfo, setHotelInfo] = useState(details);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const scroll = queryParams.get("scroll");
	const state = { tab: "hotel" };
	const [Silders, setSilders] = useState([]);
	const [active, setActive] = useState(0);
	const [modalNote, setModalNote] = useState(false);
	const toggleModalNote = () => setModalNote(!modalNote);

	const [modalPay, setModalPay] = useState(false);
	const togglePayModal = () => setModalPay(!modalPay);
	const [modalPaymentDetails, setModalPaymentDetails] = useState(false);
	const togglePaymentDetailsModal = () =>
		setModalPaymentDetails(!modalPaymentDetails);

	const [modalPromocode, setModalPromocode] = useState({
		toggle: false,
		title: "promo Code",
	});
	const togglePromocodeModal = (titleModal = "Promo Code") =>
		setModalPromocode({
			toggle: !modalPromocode.toggle,
			title: titleModal,
		});
	let history = useHistory();

	useEffect(() => {
		let x =
			details?.hotel_data?.images && details?.hotel_data?.images?.length > 0
				? details.hotel_data.images.map((res, index) => {
						return {
							key: index + "uuid.v4(2)",
							content: (
								<img
									className="bg-white"
									src={res}
									alt={index + 1}
									onError={(e) => {
										e.target.onerror = null;
										e.target.src = noHotel;
									}}
								/>
							),
							onClick: () => setActive(index),
						};
				  })
				: null;
		setSilders(x);
	}, []);

	const [IdGroupPaymentRow, setIdGroupPaymentRow] = useState(null);
	const pay = async (type) => {
		if (!GroupInventory) {
			const res = await payFees(details.brn, { payment_method: type });
			if (res.status == 200 || res.status == 201) {
				store.addNotification({
					title: "info!",
					message: "res?.data?.message",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});

				togglePayModal(null);
				window.location.reload();
			}
		} else {
			const res = await payGroupsFees(IdGroupPaymentRow?.id, {
				payment_method: type,
			});
			if (res.status == 200 || res.status == 201) {
				store.addNotification({
					title: "info!",
					message: "Paid Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 2000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						togglePayModal(null);
						window.location.reload();
					},
				});
			}
		}
	};

	const [invoicesTrue, setinvoicesTrue] = useState({});
	const [avatar, setAvatar] = useState("print");

	useEffect(() => {
		const fetchCompanyImage = async () => {
			const res = await fetchImages(invoicesTrue?.company?.avatar);
			if (res) {
				setAvatar(res);
			} else {
				setAvatar("print");
			}
		};

		if (invoicesTrue?.company?.avatar) {
			fetchCompanyImage();
		}
	}, [invoicesTrue?.company?.avatar]);

	const daweloadInvoice = async (brn) => {
		//history.push(`/Print/invoice/${details.brn}`);

		const invoice = await getInvoice(brn);

		const allRooms =
			invoice?.rooms?.length > 0
				? invoice?.rooms.map((room, index) => {
						return `		<tr>
							<td
								class=${
									+index + 1 === +invoice.rooms.length
										? "last-td-border"
										: "remove-last-td-border"
								}
							>
								${+index === 0 ? invoice?.hotel_name : ""}
							</td>
							<td class="f-12">${room.name}</td>
							<td>
						
								${
									room?.passengers?.length > 0
										? room?.passengers.map((passenger) => {
												return `<p class="m-0 f-12">
												${passenger?.first_name} ${passenger?.last_name}
											</p>`;
										  })
										: "-"
								}
							</td>
							<td class="f-12">${invoice?.rooms?.length}</td>
							<td class="f-12">${room.adults}</td>
							<td class="f-12">${room.children}</td>
							<td class="f-12">${room.nights}</td>
							<td class="f-12">${room.from_date}</td>
							<td class="f-12">${room.to_date}</td>
							<td class="f-12">
								${room.price} ${invoice?.currency}
							</td>
						</tr>`;
				  })
				: [];
		if (invoice) {
			var mywindow = window.open("", "PRINT", "height=600,width=1000");

			mywindow.document.write("<html><head><title>" + "file name" + "</title>");
			mywindow.document.write(`
				<style>
				html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}
a {
	text-decoration: none !important;
	cursor: pointer;
}
a:hover {
	text-decoration: none !important;
}

fieldset,
img {
	border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
	font-style: normal;
	font-weight: normal;
}
ol,
ul {
	list-style: none;
}
caption,
th {
	text-align: var(--left);
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal;
}
q:before,
q:after {
	content: "";
}
abbr,
acronym {
	border: 0;
	font-variant: normal;
}
sup {
	vertical-align: text-top;
}
sub {
	vertical-align: text-bottom;
}
input,
textarea,
select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}
legend {
	color: #000;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}
			* {
    font-family: "Cairo", sans-serif;
		box-sizing: border-box;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}
 .container {
    max-width: 1160px;
}
 .py-4 {
    padding-bottom: 1.5rem !important;
 padding-top: 1.5rem !important;
	}
.mx-3 {
    margin-left: 1rem !important;
		   margin-right: 1rem !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.bg-gray {
    background: #e8e8e8 !important;
}
.p-4 {
    padding: 1.5rem !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-around {
    justify-content: space-around !important;
}
.font-weight-bold {
    font-weight: 700 !important;
}

.mb-1, .my-1 {
    margin-bottom: 0.25rem !important;
}
.overFlow-hide{
	
}

element.style {
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
}

element.style {
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
}
.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}
.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
}
.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
}
.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.w-50{
width : 50%
}

element.style {
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
}

.txt-blue {
    color: #0c3b5c;
    font-weight: 700;
}

.container-wrapper {
		border: 1px solid #0c3b5c;
	}
		.justify-content-between {
    justify-content: space-between !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.border-header {
	border-bottom: 1px solid #2d2d2d;
}
.border-section {
	border-bottom: 1px solid #0c3b5c;
}

.invoice-word {
	color: #0c3b5c;
	font-size: 40px;
	font-weight: 700;
}
.f-12{
font-size: 12px;
}

table {
		border-collapse: collapse;
	}
	td {
		border: 1px solid #0c3b5c;
		padding: 0.5rem;
	}
	th {
		border: 1px solid #0c3b5c;
		padding: 0.5rem;
	}
	thead th {
		border: 1px solid #0c3b5c;
		padding: 0.5rem;
	}
	tr:first-child td {
		border-top: 0;
	}
	tr td:first-child {
		border-left: 0;
	}

	tr td:last-child {
		border-right: 0;
	}
	tr th:first-child {
		border-left: 0;
	}
	tr th:last-child {
		border-right: 0;
	}
	.last-td-border {
		border: 0 !important;
	font-size: 12px;
		border-bottom: 1px solid #0c3b5c !important;
	}

.remove-last-td-border {
	border-bottom: 0 !important;
		border-top: 0 !important;
	font-size: 12px;
	}
.bordered-img-holder {
	display: flex;
	align-items: center;
	height: 20vh;
}
.width-10 {
	width: 11% !important;
}	
.invoice {
		height: 95vh !important;
		width : 100%;
		  padding-right: 5px;
    padding-left: 5px;

	}
.company-logo-font {
	font-size: 2rem !important;
}

	.container-wrapper {
		height: 95vh !important;
	}
 .p-5{
		  padding: 2rem;
 }
.d-flex {
    display: flex !important;
}
.justify-content-center {
    justify-content: center!important;
}
.flex-column{
	flex-direction: column;
}
 .border{
	border: 1px solid #dfdfdf;
 }
	.letter-logo {
	width: 40px;
	height: 40px;
	font-size: 16px;
	font-weight: 600;
	color: white;
	background-color: #cb9a51;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: capitalize;
}

			</style>
			</head>
			<body >`);
			mywindow.document.write(`
			<div class="invoice  py-4 bg-white  ">
			<div class="container-wrapper mt-3 ">
				<div class="bg-gray p-4 border-header">
					<div class="row justify-content-between align-items-center ">
						<div class="col-3">
							<img src=${SafaLogo} alt="hotal-logo" class="img-fluid " />
						</div>
						<div class="col-5">
							<h4 class="txt-blue">SAFA SOFT</h4>
							<h4 class="txt-gray">9 Othman Towers</h4>
							<h4 class="txt-gray">
								Kornish El-Nile, Maadi, Cairo - Egypt
							</h4>
							<h4 class="txt-gray">Email: info@safasoft.com</h4>
						</div>
					</div>
				</div>

				<div class="border-section">
					<div class="row justify-content-around align-items-center  p-4 ">
						<div class="col-3 p-2">
							<h2 class="invoice-word ">INVOICE</h2>
						</div>

						<div class="row col-8">
							<div class="col-1 "></div>
							<div class="col-4">
								<h4 class="txt-gray mb-1">Invoice No.</h4>
								<h4 class="txt-gray mb-1">Invoice Date</h4>
								<h4 class="txt-gray mb-1">Confirmation No.</h4>
							</div>

							<div class="col-6">
								<h4 class="font-weight-bold mb-1">
									${invoice?.invoiceNumber}
								</h4>
								<h4 class="font-weight-bold mb-1">
									${invoice?.invoiceDate}
								</h4>
								<h4 class="font-weight-bold mb-1">
									${invoice?.confirmationNumber}
								</h4>
							</div>
						</div>
					</div>
				</div>

				<div class="">
					<div class="row justify-content-around align-items-center  p-4 ">
						<div class="col-3 border  p-2 d-flex  justify-content-center ">
							${
								invoice?.companyImage
									? `<img src=${invoice?.companyImage} alt="hotal-logo" class="img-fluid w-50" />`
									: `<div class="company-logo-font letter-logo p-5 ">
									${invoice?.company?.name[0]}
								</div>`
							}
						</div>

						<div class="row col-8">
							<div class="col-1	"></div>

							<div class="col-4 px-2">
								<h4 class="txt-gray mb-1">Agency Name</h4>
								<h4 class="txt-gray mb-1">Address</h4>
								<h4 class="txt-gray mb-1">Phone</h4>
							</div>

							<div class="col-6">
								<h4 class="font-weight-bold mb-1">
									${invoice?.company?.name}
								</h4>

								<h4 class="font-weight-bold mb-1">
									${invoice?.company?.address ? invoice?.company?.address : "-"}
								</h4>

								<h4 class="font-weight-bold mb-1">
									${invoice?.company?.phone}
								</h4>
							</div>
						</div>
					</div>
				</div>

				<div class="">
					<table class=" marketInvoice-table">
						<thead>
							<tr>
								<th scope="col  " class="font-weight-bold width-10 f-12">
									Hotel Name
								</th>
								<th scope="col  " class="font-weight-bold width-10 f-12">
									Room Name
								</th>
								<th scope="col  " class="font-weight-bold width-10 f-12">
									Guest Name
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Rooms
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Adult
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Child
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Nights
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Check-in
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Check-out
								</th>
								<th scope="col  " class="font-weight-bold f-12">
									Price
								</th>
							</tr>
						</thead>
						<tbody>${allRooms}</tbody>
					</table>
				</div>

				
				${
					invoice?.remark
						? `	<div class="border-section">
						<div class="row justify-content-start align-items-center  p-4 ">
							<div class=" col-12 d-flex flex-column">
								<p class="font-weight-bold mb-1">Agent Remarks</p>
								<p class="m-0">${invoice?.remark}</p>
							</div>
						</div>
					</div>`
						: ""
				}

				<div class="">
					<div class="row justify-content-start align-items-center  p-4 ">
						<div class=" col-3 d-flex flex-column">
							<p class="mb-1">Billed by</p>
							<p class="mb-1">Issued By</p>
							<p class="mb-1">Invoice Status</p>
							<p class="mb-1">Amount</p>
						</div>

						<div class=" col-6 d-flex flex-column">
							<p class="font-weight-bold mb-1"> ${invoice?.company?.name}</p>
							<p class="font-weight-bold mb-1">Safa Soft</p>
							<p class="font-weight-bold mb-1">Confirmed</p>
							<p class="font-weight-bold mb-1">
								${invoice?.total_price} ${invoice?.currency}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	
	`);
			mywindow.document.write("</body></html>");

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/
			setTimeout(() => {
				mywindow.print();
			}, 500);

			return true;
		}
	};

	var sumPax = 0;
	const totalPax = () => {
		for (let i = 0; i < details.payload.rooms?.length; i++) {
			sumPax +=
				details.payload.rooms[i].adults + details.payload.rooms[i].children;
		}
		return sumPax;
	};

	useEffect(() => {
		if (scroll) {
			document.getElementById("scroll").scrollIntoView();
		}
	}, []);
	return (
		<>
			<section className="marketplace-details  bg-white py-3">
				<div className="d-flex justify-content-between hotel-name px-4">
					<div className="d-flex">
						<h6 className="font-weight-bold dark-blue-color bg-white pt-3 text-headline">
							{/* Fairmont Makkah Clock Tower */}
							{/* {state.tab === "hotel" ? state.hotel : state.transport}  */}
							{/* {inventory.messages.viewHotel} :  */}
							{details?.hotel_name}
						</h6>
						{!GroupInventory && details.hotel_data.rating > 0 ? (
							<div className="hotel-star-rate d-inline mt-3 px-2">
								{[...Array(details.hotel_data.rating).keys()].map((i) => (
									<i key={i} className="fa fa-star" aria-hidden="true"></i>
								))}
							</div>
						) : !GroupInventory ? (
							<div className="hotel-star-rate d-inline mt-3 px-2">
								{[...Array(5)].map((i) => (
									<i key={i} className="far fa-star" aria-hidden="true"></i>
								))}
							</div>
						) : null}
					</div>
					{!GroupInventory && (
						<button
							className="mx-1 btn btn-white-border py-1"
							onClick={() =>
								history.push({
									pathname: "/Hotels/view-booking",
									state: { detail: details },
								})
							}
						>
							<a href={() => false} className="text-decor">
								{inventory.messages.viewHotel}
							</a>
						</button>
					)}
				</div>
				<hr className="hr my-2" />
				{hotelInfo.gds == 3 ? (
					<div className="hotel-details">
						<RequestStatus
							modalPay={modalPay}
							setModalPay={setModalPay}
							togglePayModal={togglePayModal}
							togglePromocodeModal={togglePromocodeModal}
							modalPromocode={modalPromocode}
							hotelInfo={hotelInfo}
							title={inventory.messages.requestStatus}
							promoVoucher={details}
							status={[
								{
									status: `${
										details?.reservation_status == "pending" ||
										details?.reservation_status == "canceled" ||
										details?.reservation_status == "rejected" ||
										details?.reservation_status == "expired"
											? "inactive"
											: "active"
									}`,
									name: `${translate.ApprovedRequest}`,
								},
								{
									status: `${
										details?.payment_status == "pending" ||
										details?.reservation_status == "canceled"
											? "inactive"
											: "active"
									}`,
									name: `${
										details?.payment_type == "deposit" ||
										details?.payment_type == null
											? inventory.messages.PayFullAmount
											: inventory.messages.PayFullAmount
									}`,
								},

								{
									status: `${
										details?.payment_status == "confirmed" &&
										details?.reservation_status != "canceled" &&
										(details?.payment_type == "full payment" ||
											details?.payment_type == "deposit" ||
											details?.payment_type == null) &&
										details?.payment_status == "confirmed" &&
										details?.reservation_status == "confirmed"
											? "active"
											: details?.reservation_status == "paid" &&
											  details?.reservation_type == "auto"
											? "active"
											: "inactive"
									}`,
									name: `${
										details?.payment_type == "full payment"
											? inventory.messages.VoucherIssuance
											: inventory.messages.VoucherIssuance
									}`,
								},
							]}
						/>
					</div>
				) : null}

				<div className="px-4 my-4 res-remove-padding">
					<div className="hotel-info p-3">
						<h3 className="header-hotel-info">
							{inventory.messages.bookingDetails}
						</h3>
						<div className="btns-booking d-flex justify-content-end">
							{!GroupInventory && (
								<button
									className=" btn btn-link"
									onClick={() => {
										toggleModalNote();
									}}
								>
									<i class="fas fa-eye mx-1"></i>
									{inventory.messages.bookingRemarks}
								</button>
							)}

							{location.pathname.includes("/inventory-group") ? (
								""
							) : (
								<button
									className=" btn btn-yellow text-uppercase"
									onClick={() => daweloadInvoice(details.brn)}
								>
									<i class="fas fa-cloud-download-alt"></i>{" "}
									{inventory.messages.downloudInvoice}
								</button>
							)}
						</div>
						{!GroupInventory ? (
							<div className=" booking-data-container px-4 my-3 ">
								<div className="row ">
									<div className="col-md-6 col-12">
										<div className="booking-data my-2">
											{/* <div className="service-data px-4 py-2"> */}
											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.bookDate}</h5>
												</div>
												<div className="col-8">
													<p>{details.start_date}</p>
												</div>
											</div>
											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.bookingService}</h5>
												</div>
												<div className="col-8">
													<p>{details?.hotel_name.substring(0, 43)}</p>
												</div>
											</div>

											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.numberRoom}</h5>
												</div>
												<div className="col-8">
													<p>{details?.rooms_number}</p>
												</div>
											</div>

											{/* <div className="row">
											<div className="col-4">
												<h5>{inventory.messages.totalPax}</h5>
											</div>
											<div className="col-8">
												<p>
													{details.payload.rooms?.length > 0 &&
														details.payload.rooms?.map(
															(item, index) => item.adults + item.children
														)}
												</p>
											</div>
										</div> */}

											<div className="row">
												<div className="col-4">
													<h5 className="text-uppercase">
														{inventory.messages.status}
													</h5>
												</div>
												<div className="col-8">
													<p
														className={`${
															details?.reservation_status == "confirmed" ||
															details?.reservation_status == "approved" ||
															details?.reservation_status == "paid"
																? "text-success"
																: "text-danger"
														} font-weight-bold text-uppercase`}
													>
														{details?.reservation_status}
													</p>
												</div>
											</div>
											{/* </div> */}
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="booking-data my-2">
											{/* <div className="service-data px-4 py-2"> */}
											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.totalPax}</h5>
												</div>
												<div className="col-8">
													<p>
														{/* {details.payload.rooms?.length > 0 &&
														details.payload.rooms?.map(
															(item, index) => +item.adults + +item.children
														)} */}

														{!GroupInventory && totalPax()}
														{/* {details.availablity} */}
													</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.totalPrice}</h5>
												</div>
												<div className="col-8">
													<p>
														{details?.total_price} {details?.currency}
													</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.leadPassenger}</h5>
												</div>
												<div className="col-8">
													<p>
														{/* {details?.payload?.rooms[0]?.passengers?.length >
													0 ? (
														<span>
															{
																details?.payload?.rooms[0]?.passengers[0]
																	.first_name
															}{" "}
															{
																details?.payload?.rooms[0]?.passengers[0]
																	.last_name
															}
														</span>
													) : (
														<span className="text-gray">No Passengers</span>
													)} */}
														_
													</p>
												</div>
											</div>

											{/* <div className="row row-inner">
											<div className="col-4">
												<h5>{inventory.messages.rateNotes}</h5>
											</div>
											<div className="col-8">
												<p>_</p>
											</div>
										</div> */}

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.payment}</h5>
												</div>
												<div className="col-8">
													<p
														className={`${
															details?.payment_status == "paid" ||
															details?.payment_status == "confirmed"
																? "text-success"
																: details?.payment_status == "pending"
																? "text-warning"
																: "text-danger"
														} font-weight-bold text-uppercase`}
													>
														{details?.payment_status}
													</p>
												</div>
											</div>
											{/* </div> */}
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className=" booking-data-container px-4 my-3 ">
								<div className="row ">
									<div className="col-6">
										<div className="booking-data my-2">
											<div className="row row-inner ">
												<div className="col-4">
													<h5>{inventory.messages.Status}</h5>
												</div>
												<div
													className={`col-8  ${
														details?.status == "canceled"
															? "bg-light-danger"
															: details?.status == "definite"
															? "bg-light-success"
															: "bg-light-warning"
													}`}
												>
													<p
														className={`font-weight-bolder ${
															details?.status == "canceled"
																? "text-danger"
																: details?.status == "definite"
																? "text-success"
																: "text-light-warning"
														}`}
													>
														{details?.status}
													</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.reference}</h5>
												</div>
												<div className="col-8">
													<p>{details?.id}</p>
												</div>
											</div>
											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.destination}</h5>
												</div>
												<div className="col-8">
													<p>{details?.destination}</p>
												</div>
											</div>

											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.checkin}</h5>
												</div>
												<div className="col-8">
													<p>{details?.date_from}</p>
												</div>
											</div>

											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.nights}</h5>
												</div>
												<div className="col-8">
													<p>{details?.nights}</p>
												</div>
											</div>
											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.nationality}</h5>
												</div>
												<div className="col-8">
													<p>{details?.nationality}</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="booking-data my-2">
											<div className="row ">
												<div className="col-4 bg-white">
													<h5 className="text-white">0</h5>
												</div>
												<div className="col-8">
													<p className="text-white">0</p>
												</div>
											</div>
											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.requestDate}</h5>
												</div>
												<div className="col-8">
													<p>{details?.created_at}</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.hotelName}</h5>
												</div>
												<div className="col-8">
													<p>{details?.hotel_name}</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.checkOut}</h5>
												</div>
												<div className="col-8">
													<p>{details?.date_to}</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.residence}</h5>
												</div>
												<div className="col-8">
													<p>{details?.residence}</p>
												</div>
											</div>
											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.currency}</h5>
												</div>
												<div className="col-8">
													<p>{details?.currency}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				{!GroupInventory ? (
					<div className="px-4 my-4 res-remove-padding">
						<div className="passenger ">
							<h3 className="header-hotel-info">
								{inventory.messages.passengerDetails}
							</h3>
							<div className="mt-4 invetory-table">
								<InventoryTab details={details} setDetails={setDetails} />
							</div>
						</div>
					</div>
				) : null}
				{/*** Start Room Details ***/}
				{GroupInventory ? <RoomDetails details={details} /> : null}
				{/*** End Room Details ***/}
				{/*** Start TERMS & CONDITIONS ***/}
				{GroupInventory ? <TermsDetails details={details} /> : null}
				{/*** End TERMS & CONDITIONS ***/}
				{/*** Start Payment ***/}
				<div id="scroll">
					{GroupInventory ? (
						<PaymentDetails
							modalPaymentDetails={modalPaymentDetails}
							setModalPaymentDetails={setModalPaymentDetails}
							togglePaymentDetailsModal={togglePaymentDetailsModal}
							details={details}
							setIdGroupPaymentRow={setIdGroupPaymentRow}
						/>
					) : null}
				</div>
				{/*** End Payment ***/}

				{/* Passenger Modal */}
				<Modal
					size="md"
					className="mt-10	model-remark "
					isOpen={modalNote}
					toggle={toggleModalNote}
				>
					<ModalHeader
						className="align-items-center font-weight-bold hd-title-model"
						toggle={toggleModalNote}
					>
						{inventory.messages.bookingRemarks}
					</ModalHeader>
					<ModalBody className="payModal">
						<div className="container my-3 ">
							<p className="p-remark">{details?.notes}</p>
						</div>
					</ModalBody>
				</Modal>

				{/* <div className="d-flex px-4 border-bottom">
					<p className="hotel-title2">
						<span>
							{details.hotel_data.address !== ""
								? details.hotel_data.address + " |"
								: details.hotel_data.address}
						</span>

						<span className="mx-1 link">
							<a
								href={`https://maps.google.com/?q=${details.hotel_data.latitude},${details.hotel_data.longitude}`}
								target="_blank"
								className="text-decor"
							>
								{marketPlace.messages.showInMap}
							</a>
						</span>
					</p>
				</div> */}
				{/* <div className=""> */}
				{/* <div className="">
					{Silders && Silders.length > 0 ? (
						<div
							style={{
								height: "300px",
								width: "100%",
								overflow: "hidden",
							}}
						>
							<Carousel slides={Silders} offsetRadius={6} goToSlide={active} />
						</div>
					) : null}
				</div> */}

				{/* <!-- start transport slider --> */}
				{/* <div className="transport-slider-container px-0 bg-white"> */}
				{/* 					<div className="slider-headers mb-2">
							<h5 className="font-weight-bold text-headline">
								{state.tab === "hotel"
									? details.hotel_data.name
									: details.transporter_data.name}
							</h5>
							<div className="d-flex">
								<p>
									{state.tab === "hotel"
										? details.hotel_data.address
										: details.transporter_data.address}
								</p>
								<span className="px-2">|</span>
								<p>
									{state.tab === "hotel"
										? details.hotel_data.distance + " m from Haram"
										: null}
								</p>
							</div>

							<ul className="list-unstyled d-flex">
								{state.tab === "hotel"
									? [...Array(details.hotel_data.rating).keys()].map((i) => (
											<li>
												<i
													key={i}
													className="fa fa-star"
													aria-hidden="true"
												></i>
											</li>
									  ))
									: null}
							</ul>
						</div> */}

				{/* <div className="bg-details mt-0  p-2 rounded mb-3">
							<p className=" font-weight-bold m-0 px-1 h5 pt-3 text-uppercase  my-2">
								{inventory.messages.bookingDetails}
							</p>

							<div className="row m-0 p-0"> */}
				{/* <div className="col-2">
									<p className="txt-blue  text-headline">
										{state.tab === "hotel"
											? `${inventory.messages.hotelName}`
											: `${inventory.messages.transportName}`}
									</p>
								</div> */}

				{/* <div className="col-4">
									<p className="font-weight-bold text-primary text-headline">
										{state.tab === "hotel"
											? details.hotel_name
											: details.transporter_company_name}
									</p>
								</div> */}

				{/* {state.tab === "transport" ? (
									<div className="col-2">
										<p className="txt-blue  text-headline">
											{inventory.messages.transportType}
										</p>
									</div>
								) : null}

								{state.tab === "transport" ? (
									<div className="col-4">
										<p className="font-weight-bold text-headline">
											{details.trans_type}
										</p>
									</div>
								) : null} */}

				{/* 		{state.tab === "hotel" ? (
									<div className="col-2">
										<p className="txt-blue  text-headline">
											{inventory.messages.roomType}
										</p>
									</div>
								) : null} */}

				{/* 				{state.tab === "hotel" ? (
									<div className="col-4">
										<p className="font-weight-bold text-headline">
											{state.tab === "hotel"
												? details.room_type
												: details.trans_type}
										</p>
									</div>
								) : null} */}

				{/* <p className="txt-blue  col-2">
									{state.tab === "hotel"
										? inventory.messages.checkIn
										: inventory.messages.bookingDate}
								</p>

								<p className="font-weight-bold text-headline col-4">
									{state.tab === "hotel"
										? details.start_date
										: details.created_at}
								</p>

								<p className="txt-blue  text-headline col-2">
									{state.tab === "hotel"
										? inventory.messages.checkOut
										: inventory.messages.tripDate}
								</p>
								<p className="font-weight-bold text-headline col-4">
									{state.tab === "hotel"
										? details.end_date
										: details.flight_date}
								</p>

								<p className="txt-blue  text-headline col-2">
									{state.tab === "hotel"
										? inventory.messages.noOfNights
										: inventory.messages.busCount}
								</p>
								<p className="font-weight-bold text-headline col-4">
									{state.tab === "hotel"
										? details.nights_count
										: details.trans_count}
								</p>

								<p className="txt-blue  text-headline col-2">
									{inventory.messages.brn}
								</p>

								<p className="font-weight-bold text-headline col-4">
									{details.brn}
								</p>

								<p className="txt-blue  text-headline col-2">
									{inventory.messages.reservationStatus}
								</p>

								<p className="font-weight-bold text-headline col-4">
									{details.reservation_status}
								</p>
							</div>
						</div>
					</div> */}
				{/* <!-- end transport slider --> */}
				{/* <!-- Start OverView --> */}
				{/* {details?.hotel_data?.description ||
					details?.transporter_data?.description ? (
						<div className="rounded bg-overveiw p-2  mb-3">
							<h5 className=" font-weight-bold m-0 px-1 h5 pt-3 text-dark text-uppercase my-2">
								{marketPlace.messages.overview}
							</h5>
							<p className="mb-1 rounded bg-overveiw px-1 ">
								{state.tab === "hotel"
									? details.hotel_data.description
									: details.transporter_data.description}
							</p>
						</div>
					) : null} */}

				{/* <!-- end OverView --> */}

				{/* <!--Start hotel Facilities--> */}
				{/* {state.tab === "hotel" ? (
						<div className="rounded p-2 bg-overveiw mb-3 ">
							<h5 className=" font-weight-bold m-0 px-1 h5 pt-3 text-dark text-uppercase my-2">
								{marketPlace.messages.hotelFacility}
							</h5>

							<ul className="row m-0 p-0">
								{state.tab === "hotel"
									? details.hotel_data.facilities.map((facility, i) => {
											return (
												<li className="col-4" key={i}>
													<i className="fas fa-check px-1 text-gray"></i>
													{facility}
												</li>
											);
									  })
									: null}
							</ul>
						</div>
					) : null} */}

				{/* <!--End hotel Facilities--> */}

				{/* <!--Start Terms And Conditions--> */}
				{/* {state.tab === "hotel" && details.hotel_data.terms_conditions ? (
						<div className="bg-overveiw rounded p-2 ">
							<h5 className="font-weight-bold m-0 px-1 h5 pt-3 text-dark text-uppercase my-2">
								Terms And Conditions
							</h5>
							<p className="mb-0 px-1 ">
								{details.hotel_data.terms_conditions}
							</p>
						</div>
					) : null} */}

				{/* {state.tab === "transportation" &&
					details.transporter_data.terms_and_conditions ? (
						<div className="bg-overveiw rounded p-2 ">
							<h5 className="font-weight-bold m-0 px-1 h5 pt-3 text-dark text-uppercase my-2">
								Terms And Conditions
							</h5>
							<p className="mb-0 px-1">
								{details.transporter_data.terms_and_conditions}
							</p>
						</div>
					) : null} */}

				{/* <!--End Terms And Conditions--> */}

				{/* <!-- Start Payment policies --> */}

				{/* {state.tab === "hotel" && details.hotel_data.payment_policy ? (
						<div className="bg-overveiw rounded p-2 mb-3">
							<h5 className="font-weight-bold m-0 px-1 h5 pt-3 text-dark text-success text-uppercase my-2">
								{marketPlace.messages.paymentPolicy}
							</h5>

							<p className="m-0 px-1">{details.hotel_data.payment_policy}</p>
						</div>
					) : null} */}

				{/* {state.tab === "hotel" && details.hotel_data.cancelation_policy ? (
						<div className="bg-overveiw rounded p-2">
							<h5 className="font-weight-bold m-0 px-1 h5 pt-3 text-dark text-danger text-uppercase my-2">
								{marketPlace.messages.cancellationPolicy}
							</h5>

							<p className="m-0 px-1">
								{details.hotel_data.cancelation_policy !== ""}
							</p>
						</div>
					) : null} */}
				{/* </div> */}

				{/* <!-- End Payment policies --> */}
				{/* 	<Link to="/inventory" className="btn btn-primary px-5 my-2">
						{inventory.messages.back}
					</Link> */}
			</section>

			<CustomModal
				modalIsOpen={modalPay}
				header={"ChoosePayment"}
				toggle={togglePayModal}
				size={"lg"}
				centered={true}
			>
				<Payment
					currency_name={details?.currency}
					price={details?.total_price}
					payOnlineVisa={(e) => pay(e)}
				/>
			</CustomModal>
			{/******* payment Details*/}
			<CustomModal
				modalIsOpen={modalPaymentDetails}
				header={inventory.messages.ChoosePayment}
				toggle={togglePaymentDetailsModal}
				size={"md"}
				centered={true}
			>
				<Payment
					currency_name={
						details?.currency
							? details?.currency
							: details?.destination?.currency
					}
					price={IdGroupPaymentRow?.amount}
					payOnlineVisa={(e) => pay(e)}
				/>
			</CustomModal>
		</>
	);
}
