import ApplicationRoute from "components/Routes/ApplicationRouter";
import AuthRoute from "components/Routes/AuthRoute";
import PrivateRoute from "components/Routes/PrivateRoute";
import ProductsBuilderRoute from "components/Routes/ProductsBuliderRouter";
import ProgressCircular from "components/ProgressCircular/ProgressCircular";
import SuccessfullyPayment from "components/successfullyPayment";
// import Home from "modules/main/Home";
import TeamManagementRoute from "components/Routes/TeamManagementRoute";
import verificationGoogle from "components/verificationGoogle";
import VisaRoute from "components/Routes/VisaRouter";
import WalletRoute from "components/Routes/WalletRouter";
import DashboardLayout from "layouts/DashboardLayout";
import ActivityLogs from "modules/activityLogs";
import Application from "modules/application";
import Edit from "modules/application/edit";
import Travelers from "modules/application/edit/Travelers";
import Activation from "modules/auth/pages/Activation";
import PasswordChangeSucsses from "modules/auth/pages/PasswordChangeSucsses";
import ChangePassword from "modules/change-password/ChangePassword";
import CompanyAccountsEdit from "modules/company-profile/indexEdit";
import CompanyAccountsView from "modules/company-profile/indexView";
import InventoryList from "modules/Inventory";
import Voucher from "modules/Inventory/Umrah-holidays-hotel/Components/Voucher";
// import BookingDetails from "modules/Inventory/BookingDetails";
// import InventoryList from "modules/Inventory/UI/InventoryList";
import InventoryUmrahHolidays from "modules/Inventory/Umrah-holidays-hotel/Inventory-umrah-holidays";
import MarketInvoice from "modules/Inventory/Umrah-holidays-hotel/invoices";
import ViewBooking from "modules/Inventory/Umrah-holidays-hotel/viewBooking";
import LandPage from "modules/LandPage";
import Details from "modules/market-place/HotelResponse/Details";
import List from "modules/market-place/HotelResponse/HotelList";
import HotelListMap from "modules/market-place/HotelResponse/HotelList/HotelListMap";
// import Confirm from "modules/market-place/containers/MarketSearch/Confirm";
// import MarketAddHotel from "modules/market-place/HotelResponse/MarketAddHotel";
// import MarketItems from "modules/market-place/HotelResponse/MarketItems";
// import MarketList from "modules/market-place/HotelResponse/MarketList";
// import PaymentMode from "modules/market-place/HotelResponse/PaymentMode";
import Summary from "modules/market-place/HotelResponse/Summary";
import UmrahHolidaysHotel from "modules/market-place/HotelResponse/Summary/Umrah-holidays-hotel";
import SearchContainer from "modules/market-place/Search";
import GroundEditPassangerProfile from "modules/market-place/Search/GroundService/GroundEditPassangerProfile";
import GroundListPassengers from "modules/market-place/Search/GroundService/GroundListPassengers";
import GroundPassangerProfile from "modules/market-place/Search/GroundService/GroundPassangerProfile";
import EditPassangerProfile from "modules/market-place/Search/OnlineVisa/EditPassangerProfile";
import ListPassengers from "modules/market-place/Search/OnlineVisa/ListPassengers";
import PassangerProfile from "modules/market-place/Search/OnlineVisa/PassangerProfile";
import TransportationEditPassangerProfile from "modules/market-place/Search/Transportation/EditPassangerProfile";
import TransportationListPassengers from "modules/market-place/Search/Transportation/TransportationListPassengers";
import TransportationPassangerProfile from "modules/market-place/Search/Transportation/TransportationPassangerProfile";
import NewLanding from "modules/newLanding";
import Notification from "modules/Notification";
import PaymentAction from "modules/payment/paymentAction";
import PaymentHyperpay from "modules/payment/PaymentHyperpay";
import AddBankTransfer from "modules/payment/UI/PaymentAdmin/AdminBankTransfer/AddBankTransfer";
import AdminBankTransfer from "modules/payment/UI/PaymentAdmin/AdminBankTransfer/AdminBankTransfer";
import AddNewCurrency from "modules/payment/UI/PaymentAdmin/AdminWalletCurrencies/AddNewCurrency";
import PaymentAdmin from "modules/payment/UI/PaymentAdmin/AdminWalletCurrencies/PaymentAdmin";
import AddNewBank from "modules/payment/UI/PaymentAdmin/OnlineBanking/AddNewBank";
import OnlineBanking from "modules/payment/UI/PaymentAdmin/OnlineBanking/OnlineBanking";
import BankTransfer from "modules/payment/UI/RequestBank/BankTransfer";
import CreditCard from "modules/payment/UI/RequestBank/CreditCard";
import PaymentFailed from "modules/payment/UI/RequestBank/PaymentFailed";
import PaymentSuccessful from "modules/payment/UI/RequestBank/PaymentSuccessful";
// import Reservation from "modules/reservation/UI/Reservation";
import ReservationList from "modules/reservation/UI/ReservationList";
import ReservationTabs from "modules/reservation/UI/ReservationTabs";
import index from "modules/Statistics/Containers";
// import TeamManagementList from "modules/team-management/UI/TeamManagementList";
import UserProfile from "modules/user-profile/UserProfile";
import ViewProfile from "modules/user-profile/viewProfile";
import Visa from "modules/visa";
import LoginVisa from "modules/visa/Login";
import ManageContract from "modules/visa/manage-contract";
import ViewGroup from "modules/visa/view-group";
import PrintVisa from "modules/visa/view-group/print-visa";
import ViewMember from "modules/visa/view-member";
import NewWallet from "modules/wallet";
import Requests from "modules/wallet/lists/requests/requests";
import Transaction from "modules/wallet/lists/transaction/transaction";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import InterestsRoute from "./components/Routes/InterestsRoute";
import MarketPlaceRoute from "./components/Routes/MarketPlaceRoute";
import ReservationRoute from "./components/Routes/ReservationRoute";
import Reservation from "./modules/reservation/UI/reservation";

// Lazy Components
const Error404 = lazy(() => import("./components/Errors/Error404/Error404"));
const Login = lazy(() => import("./modules/auth/pages/Login"));
const ForgotPassword = lazy(() =>
	import("./modules/auth/pages/ForgotPassword")
);
const ForgotPasswordSubmitted = lazy(() =>
	import("./modules/auth/pages/ForgotPasswordSubmitted")
);
const ResetPassword = lazy(() => import("./modules/auth/pages/ResetPassword"));
const RegistrationSucceed = lazy(() =>
	import("modules/auth/pages/RegistrationSucceed")
);
const Register = lazy(() => import("modules/auth/pages/Register"));

// Products Builder
const Products = lazy(() => import("modules/products-builder/pages/Products"));
const CreateProduct = lazy(() =>
	import("modules/products-builder/pages/CreateProduct")
);

const TeamManagement = lazy(() =>
	import("modules/team-management/containers/TeamManagementList")
);

// const Interests = lazy(() => import("modules/Interests/Containers/Interests"));

//InterestModuleRefactor
const InterestModule = lazy(() =>
	import("modules/interest-module/Containers/InterestModule")
);

// const BuildProduct = lazy(() => import('modules/products-builder/UI/BuildProduct/BuildProduct'));

// Application Routes
const Routes = (
	<Switch>
		{/* ****************** Start Auth Route ******************** */}

		{/* Login Module */}
		<AuthRoute path="/auth/login" exact component={Login} />
		<AuthRoute path="/auth/register" exact component={Register} />
		<AuthRoute
			path="/auth/register/success"
			exact
			component={RegistrationSucceed}
		/>
		<AuthRoute path="/auth/forgot_password" exact component={ForgotPassword} />
		<AuthRoute
			path="/auth/forgot_password_submitted"
			exact
			component={ForgotPasswordSubmitted}
		/>
		<AuthRoute
			path="/auth/reset_password/:token/:email"
			exact
			component={ResetPassword}
		/>
		<AuthRoute path="/sbs/welcome" exact component={LandPage} />
		<AuthRoute path="/new-landing" exact component={NewLanding} />
		<AuthRoute
			path="/auth/passwordChanged/successfully"
			exact
			component={PasswordChangeSucsses}
		/>
		<Route path="/auth/activation/:token/:email" component={Activation} />
		<AuthRoute path="/auth/activation/resend" component={RegistrationSucceed} />
		<AuthRoute
			path="/auth/register/success"
			exact
			component={RegistrationSucceed}
		/>
		{/* ****************** End Auth Route ******************** */}


			{/* UI Static Routes */}

			{/* ****************** Start Reservation Route ******************** */}

			<ReservationRoute
				path="/reservation-traveler"
				component={ReservationTabs}
			/>
			<ReservationRoute path="/reservation" component={Reservation} />
			<ReservationRoute path="/reservation-list" component={ReservationList} />
			{/* ****************** End Reservation Route ******************** */}

			{/* Team Management Routes */}
			<TeamManagementRoute
				path="/team-managementlist"
				component={TeamManagement}
			/>

			{/* ****************** Start Market Place Route ******************** */}

			{/* Market Place Routes */}
			<MarketPlaceRoute path="/market-search" component={SearchContainer} />
			<MarketPlaceRoute path="/market-view" component={List} />
			<MarketPlaceRoute path="/market-view-map" component={HotelListMap} />
			<MarketPlaceRoute path="/market-details/:id" component={Details} />
			<MarketPlaceRoute
				path="/successfullyPayment"
				component={SuccessfullyPayment}
			/>
			<MarketPlaceRoute path="/:searchBy/confirm" component={Summary} />
			<MarketPlaceRoute
				path="/Umrah/confirm-hotel"
				component={UmrahHolidaysHotel}
			/>

			<MarketPlaceRoute
				path="/inventory/visa-requests/:status/:id"
				component={ListPassengers}
			/>
			<MarketPlaceRoute path="/Online-Visa/Search" component={ListPassengers} />
			<MarketPlaceRoute
				path="/ground-service/Search"
				component={GroundListPassengers}
			/>
			<MarketPlaceRoute
				path="/ground-service/Add/Passenger"
				component={GroundPassangerProfile}
			/>
			<MarketPlaceRoute
				path="/ground-services/Edit/Passenger"
				component={GroundEditPassangerProfile}
			/>
			<MarketPlaceRoute
				path="/inventory/ground-services/:status/:id"
				exact
				component={GroundListPassengers}
			/>

			<MarketPlaceRoute
				path="/transportation/Search"
				component={TransportationListPassengers}
			/>
			<MarketPlaceRoute
				path="/transportation/Add/Passenger"
				component={TransportationPassangerProfile}
			/>
			<MarketPlaceRoute
				path="/transportation/Edit/Passenger"
				component={TransportationEditPassangerProfile}
			/>

			<MarketPlaceRoute
				path="/Online-Visa/Add/Passenger"
				component={PassangerProfile}
			/>

			<MarketPlaceRoute
				path="/visa-requests/Edit/Passenger"
				component={EditPassangerProfile}
			/>

			<MarketPlaceRoute path="/Print/invoice/:brn" component={MarketInvoice} />

			<MarketPlaceRoute
				path="/inventory/:tab"
				exact
				component={InventoryList}
			/>
			<MarketPlaceRoute
				path="/inventory/transportations/:status/:id"
				exact
				component={TransportationListPassengers}
			/>

			{/* ****************** End Market Place Route ******************** */}

			{/* Payment Routes */}

			<Route
				path="/google1ddf08c5d66e9bbf.html"
				component={verificationGoogle}
			/>

			{/* ****************** Start Wallet Route ******************** */}

			{/* Payment Routes */}
			<WalletRoute path="/credit-card" component={CreditCard} />
			<WalletRoute path="/bank-transfer" component={BankTransfer} />
			<WalletRoute path="/payment-successful" component={PaymentSuccessful} />
			<WalletRoute path="/payment-failed" component={PaymentFailed} />
			<WalletRoute path="/payment-admin" component={PaymentAdmin} />
			<WalletRoute path="/add-currency" component={AddNewCurrency} />
			<WalletRoute path="/add-bank-transfer" component={AddBankTransfer} />
			<WalletRoute path="/admin-bank-transfer" component={AdminBankTransfer} />
			<WalletRoute path="/online-banking" component={OnlineBanking} />
			<WalletRoute path="/add-new-bank" component={AddNewBank} />
			{/* New Wallet Routes*/}
			<WalletRoute path="/wallet" exact component={NewWallet} />
			<WalletRoute path="/wallet/transaction/:id" component={Transaction} />
			<WalletRoute path="/wallet/requests/:id" component={Requests} />
			{/* /wallet/requests/ */}
			{/* ****************** End Wallet Route ******************** */}

			{/* ****************** Start Products Builder Route ******************** */}

			{/* Products Builder */}
			<ProductsBuilderRoute
				path="/products-builder/products"
				component={Products}
			/>
			<ProductsBuilderRoute path="/activity-logs" component={ActivityLogs} />
			{/*New Products Builder refactor*/}
			<ProductsBuilderRoute
				path="/products-builder/create"
				component={CreateProduct}
			/>
			{/* ****************** End Products Builder Route ******************** */}

			{/* ****************** Start Application Route ******************** */}
			{/* Application Module */}
			<ApplicationRoute path="/application" component={Application} />
			<ApplicationRoute path="/aplication/edit" component={Edit} />
			<ApplicationRoute path="/aplication/add-traveler" component={Travelers} />

			{/* ****************** End Application Route ******************** */}

			{/* ****************** Start Visa Route ******************** */}

			{/* Visa Module */}
			<VisaRoute exact path="/visa" component={Visa} />
			<VisaRoute path="/visa/login" component={LoginVisa} />
			<VisaRoute path="/visa/manage-contract" component={ManageContract} />
			<VisaRoute path="/visa/view-group/:id" component={ViewGroup} />
			<VisaRoute
				path="/visa/view-member/:onlineId/:groupId"
				component={ViewMember}
			/>
			<VisaRoute exact path="/visa/print-visa" component={PrintVisa} />
			{/* ****************** End Visa Route ******************** */}

			<InterestsRoute path="/interest-module" component={InterestModule} />

			{/* ****************** Start Private Route ******************** */}

			{/* User Profile Routes */}
			<PrivateRoute path="/edit-user-profile" component={UserProfile} />
			<PrivateRoute path="/user-profile" component={ViewProfile} />
			<PrivateRoute path="/change-password" component={ChangePassword} />
			{/* Company Profile Routes */}
			<PrivateRoute
				path="/edit-company-profile"
				component={CompanyAccountsEdit}
			/>
			<PrivateRoute path="/company-profile" component={CompanyAccountsView} />

			{/* Inventory Routes */}
			<PrivateRoute path="/inventory" exact component={InventoryList} />

			<PrivateRoute
				path="/Hotels/inventory/:brn"
				component={InventoryUmrahHolidays}
			/>
			<PrivateRoute
				path="/Hotels/inventory-group/:brn"
				component={InventoryUmrahHolidays}
			/>

			<PrivateRoute path="/Hotels/view-booking" component={ViewBooking} />

			<PrivateRoute path="/PayOnline" component={PaymentAction} />
			<PrivateRoute path="/hyperpay" component={PaymentHyperpay} />
			<PrivateRoute exact path="/inventory-voucher" component={Voucher} />
			{/* Dashboard Module */}
			<PrivateRoute exact path="/" component={index} />
			{/* BackOffice */}
			<PrivateRoute path="/progress-circular" component={ProgressCircular} />
			<PrivateRoute path="/statistics" component={index} />
			{/* General Routes */}
			<PrivateRoute path="/notification" component={Notification} />
			{/* ****************** End Private Route ******************** */}
		<Route path="*" component={Error404} />
	</Switch>
);

export default Routes;
