import noHotel from "assets/images/placeHoleder.png";
import { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useLocation } from "react-router-dom";
import Carousel from "react-spring-3d-carousel";

import Locale from "translations";
export default function ViewBooking() {
	const { marketPlace, inventory } = Locale;
	const state = { tab: "hotel" };
	const [Silders, setSilders] = useState([]);
	const [active, setActive] = useState(0);
	const location = useLocation();
	const details = location.state.detail;

	let history = useHistory();

	useEffect(() => {
		let x =
			details?.hotel_data?.images && details?.hotel_data?.images?.length > 0
				? details.hotel_data.images.map((res, index) => {
					return {
						key: index + "uuid.v4(2)",
						content: (
							<img
								className="bg-white"
								src={res}
								alt={index + 1}
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = noHotel;
								}}
							/>
						),
						onClick: () => setActive(index),
					};
				})
				: null;
		setSilders(x);
	}, []);

	return (
		<>
			<section className="marketplace-details  bg-white py-3 container">
				<div className="d-flex justify-content-between hotel-name px-4">
					<div>
						{/* <h6 className="font-weight-bold dark-blue-color bg-white pt-3 text-headline">
							{details?.hotel_name}
						</h6> */}

						<div className="slider-headers mb-2">
							<h5 className="font-weight-bold text-headline">
								{state.tab === "hotel"
									? details.hotel_data.name
									: details.transporter_data.name}
							</h5>
							<div className="d-flex">
								<p>
									{state.tab === "hotel"
										? details.hotel_data.address
										: details.transporter_data.address}
								</p>
								<span className="px-2">|</span>
								{/* <p>
									{state.tab === "hotel"
										? details.hotel_data.distance + " m from Haram"
										: null}
								</p> */}
								<a
									href={`https://maps.google.com/?q=${details.hotel_data.latitude},${details.hotel_data.longitude}`}
									target="_blank"
									className="text-decor-none text-yellow"
									rel="noreferrer"
								>
									{" "}
									Show Map{" "}
								</a>
							</div>

							<ul className="list-unstyled d-flex">
								{state.tab === "hotel"
									? [...Array(details.hotel_data.rating).keys()].map((i) => (
										<li>
											<i
												key={i}
												className="fa fa-star"
												aria-hidden="true"
											></i>
										</li>
									))
									: null}
							</ul>
						</div>
					</div>
					<div>
						<button
							className="mx-1 btn btn-white-border-gray "
							onClick={history.goBack}
						>
							{/* {marketPlace.messages.showInMap} */}
							<i class="fas fa-angle-left"></i> Back To Booking Details
						</button>
					</div>
				</div>
				<hr className="hr my-2" />

				{/* <div className=""> */}
				<div className="">
					{Silders && Silders.length > 0 ? (
						<div
							style={{
								height: "300px",
								width: "100%",
								overflow: "hidden",
							}}
						>
							<Carousel slides={Silders} offsetRadius={2}  goToSlide={active} />
						</div>
					) : null}
				</div>

				{/* <!-- start transport slider --> */}
				{/* <div className="transport-slider-container px-0 bg-white">
					<div className="bg-details mt-0  p-2 rounded mb-3">
						<p className=" font-weight-bold m-0 px-1 h5 pt-3 text-uppercase  my-2">
							{inventory.messages.bookingDetails}***
						</p>

						<div className="row m-0 p-0">
							<div className="col-2">
								<p className="txt-blue  text-headline">
									{state.tab === "hotel"
										? `${inventory.messages.hotelName}`
										: `${inventory.messages.transportName}`}
								</p>
							</div>

							<div className="col-4">
								<p className="font-weight-bold text-primary text-headline">
									{state.tab === "hotel"
										? details.hotel_name
										: details.transporter_company_name}
								</p>
							</div>

							{state.tab === "transport" ? (
								<div className="col-2">
									<p className="txt-blue  text-headline">
										{inventory.messages.transportType}
									</p>
								</div>
							) : null}

							{state.tab === "transport" ? (
								<div className="col-4">
									<p className="font-weight-bold text-headline">
										{details.trans_type}
									</p>
								</div>
							) : null}

							{state.tab === "hotel" ? (
								<div className="col-2">
									<p className="txt-blue  text-headline">
										{inventory.messages.roomType}
									</p>
								</div>
							) : null}

							{state.tab === "hotel" ? (
								<div className="col-4">
									<p className="font-weight-bold text-headline">
										{state.tab === "hotel"
											? details.room_type
											: details.trans_type}
									</p>
								</div>
							) : null}

							<p className="txt-blue  col-2">
								{state.tab === "hotel"
									? inventory.messages.checkIn
									: inventory.messages.bookingDate}
							</p>

							<p className="font-weight-bold text-headline col-4">
								{state.tab === "hotel"
									? details.start_date
									: details.created_at}
							</p>

							<p className="txt-blue  text-headline col-2">
								{state.tab === "hotel"
									? inventory.messages.checkOut
									: inventory.messages.tripDate}
							</p>
							<p className="font-weight-bold text-headline col-4">
								{state.tab === "hotel" ? details.end_date : details.flight_date}
							</p>

							<p className="txt-blue  text-headline col-2">
								{state.tab === "hotel"
									? inventory.messages.noOfNights
									: inventory.messages.busCount}
							</p>
							<p className="font-weight-bold text-headline col-4">
								{state.tab === "hotel"
									? details.nights_count
									: details.trans_count}
							</p>

							<p className="txt-blue  text-headline col-2">
								{inventory.messages.brn}
							</p>

							<p className="font-weight-bold text-headline col-4">
								{details.brn}
							</p>

							<p className="txt-blue  text-headline col-2">
								{inventory.messages.reservationStatus}
							</p>

							<p className="font-weight-bold text-headline col-4">
								{details.reservation_status}
							</p>
						</div>
					</div>
				</div> */}
				{/* <!-- end transport slider --> */}
				{/* <!-- Start OverView --> */}

				<div className="rounded bg-overveiw p-1  mb-3">
					<h5 className=" font-weight-bold m-0 px-1 h5 text-dark text-uppercase my-2">
						{marketPlace.messages.overview}
					</h5>
					{details?.hotel_data?.description ||
						details?.transporter_data?.description ? (
						<p className="mb-1 rounded bg-overveiw px-1 ">
							{state.tab === "hotel"
								? details.hotel_data.description
								: details.transporter_data.description}
						</p>
					) : (
						<div className="my-4">
							<p className="text-center text-gray">
								<i class="fas fa-exclamation"></i> No Overview
							</p>
						</div>
					)}
				</div>

				{/* <!-- end OverView --> */}

				{/* <!--Start hotel Facilities--> */}
				{state.tab === "hotel" ? (
					<div className="rounded p-1 bg-overveiw mb-3 ">
						<h5 className=" font-weight-bold m-0 px-1 h5  text-dark text-uppercase my-2">
							{marketPlace.messages.hotelFacility}
						</h5>
						{state.tab === "hotel" &&
							details.hotel_data.facilities.length > 0 ? (
							details.hotel_data.facilities.map((facility, i) => {
								return (
									<ul className="row m-0 p-0">
										<li className="col-4" key={i}>
											<i className="fas fa-check px-1 text-gray"></i>
											{facility}
										</li>
									</ul>
								);
							})
						) : (
							<div className="my-4">
								<p className="text-center text-gray">
									<i class="fas fa-exclamation"></i>{" "}
									{inventory.messages.noFacilities}
								</p>
							</div>
						)}
					</div>
				) : null}

				{/* <!--End hotel Facilities--> */}

				{/* <!--Start Terms And Conditions--> */}
				{/* {state.tab === "hotel" && details.hotel_data.terms_conditions ? (
					<div className="bg-overveiw rounded p-2 ">
						<h5 className="font-weight-bold m-0 px-1 h5 pt-3 text-dark text-uppercase my-2">
							Terms And Conditions
						</h5>
						<p className="mb-0 px-1 ">{details.hotel_data.terms_conditions}</p>
					</div>
				) : null} */}

				{/* {state.tab === "transportation" &&
				details.transporter_data.terms_and_conditions ? (
					<div className="bg-overveiw rounded p-2 ">
						<h5 className="font-weight-bold m-0 px-1 h5 pt-3 text-dark text-uppercase my-2">
							Terms And Conditions
						</h5>
						<p className="mb-0 px-1">
							{details.transporter_data.terms_and_conditions}
						</p>
					</div>
				) : null} */}

				{/* <!--End Terms And Conditions--> */}

				{/* <!-- Start Payment policies --> */}

				{/* {state.tab === "hotel" && details.hotel_data.payment_policy ? (
					<div className="bg-overveiw rounded p-2 mb-3">
						<h5 className="font-weight-bold m-0 px-1 h5 pt-3 text-dark text-success text-uppercase my-2">
							{marketPlace.messages.paymentPolicy}
						</h5>

						<p className="m-0 px-1">{details.hotel_data.payment_policy}</p>
					</div>
				) : null} */}

				{/* {state.tab === "hotel" && details.hotel_data.cancelation_policy ? (
					<div className="bg-overveiw rounded p-2">
						<h5 className="font-weight-bold m-0 px-1 h5 pt-3 text-dark text-danger text-uppercase my-2">
							{marketPlace.messages.cancellationPolicy}
						</h5>

						<p className="m-0 px-1">
							{details.hotel_data.cancelation_policy !== ""}
						</p>
					</div>
				) : null} */}
			</section>
		</>
	);
}
