import walletImg from "assets/images/wallet/walletSuccessfully.png";
import React from "react";
import { useHistory } from "react-router-dom";
import Locale from "translations";

export default function SuccessfullyPayment() {
	const history = useHistory();
	const { commons } = Locale;

	return (
		<div className="container">
			<div
				className="recharge-img my-2 py-5 mt-5 text-center"
				style={{ minHeight: "85vh" }}
			>
				<div className="w-100 py-4 pb-5" style={{ background: "#fff" }}>
					<img src={walletImg} className="mt-5" alt="Wallet" />
					<h6 className="mt-2 modal-head">
						{commons.paymentSuccessfullyReceived}
					</h6>

					<div className="col-md-6 m-auto">
						<button
							onClick={() => history.push("/inventory")}
							className="btn bg-nxt w-100 mt-3"
						>
							{commons.GoToInventory}
							
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
