import { useSBSState } from "context/global";
import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { Collapse } from "reactstrap";
import Locale from "translations";

function OfferBar({ setAdsList, adsList }) {
	const { marketPlace } = Locale;
	const { locale } = useSBSState();
	
	const isMobile=window.innerWidth<321?true:false;
	const mobileDivWidth=isMobile?105:25;
	const disabledButtonCarousel=isMobile?1:4;
	const [isopen, setisopen] = useState(false);
	const URI = process.env.REACT_APP_FILEUPLOAD_URL;

	const [currentIndex, setCurrentIndex] = useState(0);
	const [length, setLength] = useState(adsList.length);

	// Set the length to match current children from props
	useEffect(() => {
		setLength(adsList.length);
	}, [adsList]);

	const next = () => {
		if ((isMobile&&currentIndex < length-1)|| currentIndex < length/3 ) {
			setCurrentIndex((prevState) => prevState + 1);
		}
	};

	const prev = () => {
		if (currentIndex > 0) {
			setCurrentIndex((prevState) => prevState - 1);
		}
	};
	const showHide = (index) => {
		return true;
	};

	useEffect(() => {
		// setTimeout(() => {
		// 	document.getElementById("bar").scrollIntoView(false);
		// }, 100);
	}, [isopen]);

	useEffect(() => {
		setTimeout(() => {
			setisopen(true);
		}, 5000);
	}, []);

	console.log("length1888",currentIndex);
	return (
		<>
			{adsList && adsList.length > 0 ? (
				<div className="offerlayout" id="bar2">
					{isopen ? (
						<button
							onClick={() => {
								setisopen(!isopen);
							}}
							className="btn-close"
						>
							<i class="fas fa-times"></i>
						</button>
					) : (
						<button
							onClick={() => {
								setisopen(!isopen);
							}}
							className="btn-toggle "
						>
							{" "}
					<span className='elementToFadeInAndOut'>		{marketPlace.specialOffers}</span>
						</button>
					)}
					<Collapse isOpen={isopen}>
						<div className="container pb-3 pt-2 ">
							<div className="ads-head">
								<h4 className="ads-type">
								
										{marketPlace.specialRatesDestinations
										}
								</h4>
								
							</div>

							<div>
								{/******slider */}
								<button
									className="left-slider-arrow"
									onClick={locale === "ar" ? next : prev}
									disabled={locale === "ar"? Math.ceil(length/disabledButtonCarousel)>currentIndex: currentIndex===0}

								>
									<i className={`fas fa-chevron-left`}></i>
								</button>
								<div className="slider-row-wrapper">
									<div
										className="slider-row"
										style={{
											transform: `translateX(${locale === "ar" ? "+" : "-"}${
												currentIndex * mobileDivWidth
											}%)`,
										}}
										//*25
									>
										{adsList && adsList?.length > 0
											? adsList.map((item, index) => (
													<div
														className={`card-item ${
															showHide(index) ? "" : "d-none"
														}`}
														style={{ width: "300px" }}
													>
														<img
															className="img-card"
															src={`${URI}/fetch/${item.image}`}
															alt="hotel"
														/>
														<div className="card-info">
															{item.type == "destinations" ? (
																<h1 className="name">
																	{item?.destination_name}
																</h1>
															) : (
																<h1 className="name">{item?.hotel_name}</h1>
															)}
															<span>{item?.destination}</span>
															{item?.type != "destinations" ? (
																<p
																	className={
																		locale == "ar" ? "date_ar" : "date"
																	}
																>
																	<span>{marketPlace.From} </span>{" "}
																	<span>{item?.date_from}</span>
																	<span>{marketPlace.To} </span>
																	<span>{item?.date_to}</span>
																</p>
															) : null}
															<h3>
																<span>{marketPlace.PricesStartFrom} </span>
																<span className="font-weight-bold">
																	{item?.price_start_from} SAR/Night
																</span>
															</h3>
															<button className="btn-card">
																<a href={item?.link} target="_blank">
																	{/* 	{adsList[0]?.type == "destinations"
																		? marketPlace.ShowDestinations
																		: marketPlace.ShowHotels} */}
																	{marketPlace.ShowDetails}
																</a>
															</button>
														</div>
													</div>
											  ))
											: null}
									</div>
								</div>

								{/*****slider */}
								<button
									className="right-slider-arrow"
									onClick={locale === "ar" ? prev : next}
									disabled={locale === "en"? Math.ceil(length/disabledButtonCarousel)>currentIndex: currentIndex===0}

								>
									<i class="fas fa-chevron-right"></i>
								</button>
							</div>
						</div>
					</Collapse>
				</div>
			) : null}
		</>
	);
}

export default OfferBar;
