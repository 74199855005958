import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useRef, useState } from "react";
export default function DetailsTab() {
	const [passportPhoto, setPassportPhoto] = useState("");
	const inputFileRef = useRef(null);
	const onFilechange = (e) => {
		setPassportPhoto(window.URL.createObjectURL(e.target.files[0]));
	};
	return (
		<div className="container-custom">
			<div className="row align-items-center w-100 no-gutter m-0  ">
				<div className="col-md-2">
					<TextField
						type="text"
						label={"passport No. "}
						placeholder={"Type passport No."}
					//	value={filters.search}
					// onChange={(e) =>
					// 	setFilters({ ...filters, search: e.target.value })
					// }
					/>
				</div>

				<div className="col-md-2">
					<TextField
						type="text"
						label={"National ID. "}
						placeholder={"Type National ID."}
					//	value={filters.search}
					// onChange={(e) =>
					// 	setFilters({ ...filters, search: e.target.value })
					// }
					/>
				</div>
			</div>
			<div>
				<h3 className="mx-2">English Name</h3>
				<div className="row align-items-center w-100 no-gutter m-0  ">
					<div className="col-md-2">
						<TextField
							type="text"
							label={"First NAME "}
							placeholder={"Type First Name"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>
					<div className="col-md-2">
						<TextField
							type="text"
							label={"Father name "}
							placeholder={"Type Father name"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>

					<div className="col-md-2">
						<TextField
							type="text"
							label={"Grandfather name "}
							placeholder={"Type Grandfather name"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>

					<div className="col-md-2">
						<TextField
							type="text"
							label={"Last Name "}
							placeholder={"Type Last Name"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>
				</div>
			</div>

			<div>
				<h3 className="mx-2"> Traveler Details</h3>
				<div className="row align-items-center w-100 no-gutter m-0  ">
					<div className="col-md-2">
						<SelectField
							//hasLabel={false}
							label={"Nationality "}
							placeholder={"Select Nationality"}
						// options={status}
						// value={filters.source.name}
						// onChange={(e) =>
						// 	setFilters({
						// 		...filters,
						// 		source: { id: e.value, name: e.label },
						// 	})
						// }
						/>
					</div>
					<div className="col-md-2">
						<SelectField
							//hasLabel={false}
							label={"Gender  "}
							placeholder={"Select Gender"}
						// options={status}
						// value={filters.source.name}
						// onChange={(e) =>
						// 	setFilters({
						// 		...filters,
						// 		source: { id: e.value, name: e.label },
						// 	})
						// }
						/>
					</div>

					<div className="col-md-2">
						<DatePickerField
							label={"Date of birth "}
							placeholder={"DD/MM/YYYY"}

						//date={filters.date}
						//value={filters.date}
						// onChangeDate={(date) =>
						// 	setFilters({
						// 		...filters,
						// 		date: date,
						// 	})
						// }
						/>
					</div>

					<div className="col-md-2">
						<DatePickerField
							label={"issued date"}
							placeholder={"DD/MM/YYYY"}
						//date={filters.date}
						//value={filters.date}
						// onChangeDate={(date) =>
						// 	setFilters({
						// 		...filters,
						// 		date: date,
						// 	})
						// }
						/>
					</div>
					<div className="col-md-2">
						<DatePickerField
							label={"Expiry date"}
							placeholder={"DD/MM/YYYY"}
						//date={filters.date}
						//value={filters.date}
						// onChangeDate={(date) =>
						// 	setFilters({
						// 		...filters,
						// 		date: date,
						// 	})
						// }
						/>
					</div>
				</div>

				<div className="row align-items-center w-100 no-gutter m-0  ">
					<div className="col-md-2">
						<TextField
							type="text"
							label={"issued Place"}
							placeholder={" Type issued Place"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>
					<div className="col-md-2">
						<TextField
							type="text"
							label={"Job title "}
							placeholder={"Type"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>

					<div className="col-md-2">
						<TextField
							type="text"
							label={"address "}
							placeholder={"Type Address"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>

					<div className="col-md-2">
						<SelectField
							//hasLabel={false}
							label={"العلاقة بالمحرم  "}
							placeholder={"Select"}
						// options={status}
						// value={filters.source.name}
						// onChange={(e) =>
						// 	setFilters({
						// 		...filters,
						// 		source: { id: e.value, name: e.label },
						// 	})
						// }
						/>
					</div>
					<div className="col-md-2">
						<TextField
							type="text"
							label={"اسم المحرم "}
							placeholder={"Type"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>
				</div>
			</div>
			<div>
				<h3 className="mx-2">Arabic Name</h3>
				<div className="row align-items-center w-100 no-gutter m-0  ">
					<div className="col-md-2">
						<TextField
							type="text"
							label={"First NAME "}
							placeholder={"Type First Name"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>
					<div className="col-md-2">
						<TextField
							type="text"
							label={"Father name "}
							placeholder={"Type Father name"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>

					<div className="col-md-2">
						<TextField
							type="text"
							label={"Grandfather name "}
							placeholder={"Type Grandfather name"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>

					<div className="col-md-2">
						<TextField
							type="text"
							label={"Last Name "}
							placeholder={"Type Last Name"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>
				</div>
			</div>

			<div>
				<h3 className="mx-2">Visa Details</h3>
				<div className="row align-items-center w-100 no-gutter m-0  ">
					<div className="col-md-2">
						<TextField
							type="text"
							label={"Visa No. "}
							placeholder={"Type Visa No."}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
						/>
					</div>
					<div className="col-md-2">
						<SelectField
							//hasLabel={false}
							label={"Visa type  "}
							placeholder={"Select Visa type"}
						// options={status}
						// value={filters.source.name}
						// onChange={(e) =>
						// 	setFilters({
						// 		...filters,
						// 		source: { id: e.value, name: e.label },
						// 	})
						// }
						/>
					</div>

					<div className="col-md-2">
						<SelectField
							//hasLabel={false}
							label={"Consular"}
							placeholder={"Select"}
						// options={status}
						// value={filters.source.name}
						// onChange={(e) =>
						// 	setFilters({
						// 		...filters,
						// 		source: { id: e.value, name: e.label },
						// 	})
						// }
						/>
					</div>
				</div>
			</div>

			<div>
				<h3 className="mx-2">Passport Photo</h3>

				<div className="upload-photo">
					<div className="row align-items-center w-100 no-gutter m-0  ">
						<div className="col-md-2">
							<div className="traveler-row align-items-center">
								<label htmlFor="passportUpload" className="passport-upload">
									<div className="icon m-auto">
										{passportPhoto ? (
											<img
												src={passportPhoto}
												className="image-uploader__img w-100 rounded-0"
												alt="logo placeholder"
												accept="image/*"
											/>
										) : (
											<i className="fa fa-plus d-block"></i>
										)}
									</div>
								</label>
								<div className="traveler-col">
									<input
										type="file"
										id="passportUpload"
										className="d-none"
										//ref={inputFileRef}
										onChange={onFilechange}
									/>
								</div>
							</div>
						</div>
						<div className="col-md-2">
							<button
								className="btn btn-primary mx-2 w-100"
								//onClick={upload}
								id="cancel-photo"
							>
								Upload
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
