import Pagination from "components/Pagination";
import { useMarketplaceDispatch } from 'context/marketplace';
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import { groundServicesRequestsList } from "services/groundService";
import Locale from "translations";
import Filter from "./Filter";


export default function GroundServices() {
	const { inventory, onlineVisa, payment, marketPlace } = Locale;
	let history = useHistory();
	const dispatch = useMarketplaceDispatch();
	const [groundServiceList, setGroundServiceList] = useState([]);
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		search: "",
		destination_id: "",
		request_date: "",
		status: "",
		page: 1,
	});
	const [searchStatus, setSearchStatus] = useState("");
	const [total, setTotal] = useState([]);

	useEffect(() => {
		async function fetchRequests() {
			const res = await groundServicesRequestsList({
				...filter,
				destination_id: filter?.destination_id.id,
				request_date: filter.request_date
					? moment(filter.request_date).format("YYYY-MM-DD")
					: "",
				status: filter?.status?.title,
			});
			setGroundServiceList(res?.data?.data);
			setMeta(res?.data?.meta);
			setTotal(res?.data?.counters);
		}
		fetchRequests();
		// reset 
		dispatch({
			type: "groundServicePassengers",
			payload: [],
		});
		dispatch({
			type: "groundServiceSearch",
			payload: {},
		});
	}, [searchStatus, filter.page]);

	const search = () => {
		setSearchStatus(!searchStatus);
	};
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};

	return (
		<div className="bg-white visa-requests">
			<div className="d-flex justify-content-between p-3 header-visa inventory-header-totals">
				<h3>{inventory.messages.GroundServices}</h3>
				<div className=" d-flex justify-content-between totals_container">
					<span className="btn btn-yellow text-light mx-2">
						{inventory.messages.all} ({meta?.total})
					</span>
					<span className="btn btn-light border mx-2">
						{payment.messages.Pending}
						({total?.filter(approved => approved?.status == "pending")[0]?.total || 0})
					</span>
					<span className="btn btn-light border mx-2">
						{payment.messages.Approved}
						({total?.filter(approved => approved?.status == "approved")[0]?.total || 0})
					</span>
					<span className="btn btn-light border mx-2">
						{payment.messages.Expired}
						({total?.filter(expired => expired?.status == "expired")[0]?.total || 0})
					</span>
				</div>
			</div>
			<Filter filter={filter} setFilter={setFilter} search={search} />
			<div className='table-container'>
			<Table striped className="table-update p-2 bg-white ">
				<thead>
					<tr>
						<th>ID</th>
						<th>Main Request ID</th>
						<th>{inventory.messages.destination}</th>
						<th>
							<i class="fas fa-sort"></i>
							{inventory.messages.pax}
						</th>
						<th>{inventory.messages.departure}</th>
						<th>{onlineVisa.Return}</th>
						<th>{inventory.messages.requestDate}</th>
						<th>{marketPlace.messages.requestExpireDate}</th>
						<th>{inventory.messages.status}</th>

						<th>{inventory.messages.actions}</th>
					</tr>
				</thead>
				<tbody>
					{groundServiceList?.length > 0 ? (
						groundServiceList.map(item => {
							return (
								<tr>
									<td>{item?.id}</td>
									<td>{item?.parent_id || "-"}</td>
									<td>{item?.destination_name}</td>
									<td>{item?.pax}</td>
									<td>{item?.departure_date ? moment(item?.departure_date).format("YYYY-MM-DD") : "-"}</td>
									<td>{item?.return_date ? moment(item?.return_date).format("YYYY-MM-DD") : "-"}</td>
									<td>{moment(item?.request_date).format("YYYY-MM-DD")}</td>
									<td>{moment(item?.request_date).add((item?.expiration_days ? item?.expiration_days : 0), 'days').format("YYYY-MM-DD")}</td>
									<td>
										<span className={`font-weight-normal text-capitalize badge badge-${item?.status == "waiting" ? "waiting" :
											item?.status === "incomplete" || item?.status === "expired" ? "danger" :
												item?.status === 'pending' ? 'pending' : 'issued'
											}`}
										>
											{item?.status === 'waiting' ? inventory.messages.waitingApproval : item?.status}
										</span>
									</td>
									<td>
										<span className="text-yellow">
											{item?.status == "pending" ?
												<Link className='text-yellow' to={`/inventory/ground-services/edit/${item.id}`}>
													<i className="far fa-edit pointer"></i> Edit
												</Link>
												:
												<Link className='text-yellow' to={`/inventory/ground-services/view/${item.id}`}>
													<i className="fas fa-eye "></i> View
												</Link>
											}
										</span>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan="7">
								<div className="product-build__product-no-data fullHeight">
									<i className="icx icx-eye-slash" aria-hidden="true"></i>
									<h4>{"No Result"}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			</div>
			{groundServiceList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</div>
	);
}
