import newWallat from "assets/images/newWallat.svg";
import noHotel from "assets/images/placeHoleder.png";
import { useSBSState } from "context/global";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Locale from "translations";

function ListItem({ item, searchBy, Night }) {
	const { marketPlace } = Locale;
	const imageRef = useRef(null);
	const { token_data } = useSBSState();

	const search = window.location.search;
	const name = new URLSearchParams(search);
	const [params, setParams] = useState({});

	useEffect(() => {
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}
		setParams(x);
	}, []);
	return (
		<div>
			<div className="hotel-detail-section">
				<div className="img-box">
					<img
						src={item.image ? `${item.image}` : noHotel}
						ref={imageRef}
						onError={(e) => {
							imageRef.current.src = noHotel;
						}}
						alt="MarketImg"
					/>
				</div>

				<div className="hotel-detail-item">
					<div className="hotel-header px-1">
						<h6 className="hotel-titleHotel">
							{searchBy === "hotel" ? item.name : item.name}
						</h6>

						<>
							<p className="hotel-title2">
								<span>
									{item.address !== "" ? item.address + " |" : item.address}
								</span>
							</p>
							<div className="hotel-star-rate m-0">
								{[...Array(+item.rating).keys()].map((i) => (
									<i key={i} className="fa fa-star" aria-hidden="true"></i>
								))}
							</div>
							<div>
								<p className="titleHotelDescription">
									{item.description
										? item.description.length > 200
											? item.description.slice(0, 200) + "..."
											: item.description
										: ""}
								</p>
							</div>
						</>
					</div>
				</div>
			</div>
			<div className="new-price-number">
				{item.cachedHotels.map((res) => {
					return (
						<>
							<Link
								onClick={() =>
									localStorage.setItem(
										"selectedHotelSearch",
										JSON.stringify({
											...item,
											search_token_selected: res.search_token,
										})
									)
								}
								to={{
									pathname: params.hasOwnProperty("token")
										? `/market-details/${res.search_token}?token=${params.token}`
										: `/market-details/${res.search_token}`,
									state: {
										code: res.hotel_code,
									},
								}}
							>
								<div
									className="total-price-box py-2"
									style={{ borderBottom: "1px solid #cecece" }}
								>
									{token_data && token_data.discount != null ? (
										<>
											<div className="d-flex justify-content-between">
												<p
													className="d-flex align-items-center px-4 "
													style={{ color: "red" }}
												>
													<span className="font-weight-bold">
														{token_data.discount}
													</span>{" "}
													%{" OFF"}
												</p>

												<div className="d-flex pr-3">
													<img
														src={newWallat}
														className="px-2"
														alt=""
														srcset=""
													/>
													<p
														className="price-number price-text"
														style={{
															fontWeight: "700",
															lineHeight: ".9",
															fontSize: "17px",
															marginBottom: "5px",
															marginTop: "5px",
														}}
													>
														<p className="old-price">
															{" "}
															{(
																(res.total_price /
																	Math.ceil(100 - token_data.discount)) *
																	token_data.discount +
																res.total_price
															).toFixed(2)}{" "}
															{res.currency}
														</p>
														{res.total_price.toFixed(2)}{" "}
														<span className="text-currency pr-1">
															{res.currency}
														</span>
														<span className="text-Nights">
															/ {marketPlace.Nights} {Night}{" "}
														</span>
													</p>
												</div>
											</div>
										</>
									) : (
										<>
											<div className="d-flex justify-content-end">
												{/* <p> best price</p> */}
												<img
													src={newWallat}
													className="px-2"
													alt=""
													srcset=""
												/>
												<div style={{ width: "220px" }}>
													<h6 className="new-color text-left pr-4">
														{marketPlace.messages.startFrom}
													</h6>

													<p
														className="price-number price-text pr-4"
														style={{
															fontWeight: "700",
															lineHeight: ".9",
															fontSize: "18px",
															marginBottom: "5px",
														}}
													>
														{res.total_price}{" "}
														<span className="text-currency pr-1">
															{res.currency}
														</span>
														<span className="text-Nights">
															/ {marketPlace.Nights} {Night}{" "}
														</span>
													</p>
												</div>
											</div>
										</>
									)}
								</div>
							</Link>
						</>
					);
				})}
			</div>
		</div>
	);
}

export default ListItem;
