import iconNotification from "assets/images/customHeader/wallet.svg";
import walletgray from "assets/images/walletgray.svg";
import { useSBSState } from "context/global";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { payFees } from "services/marketplace";
import { getListWallets } from "services/wallet";
import Locale from "translations";
export default function PayModal({
	modalPay,
	togglePayModal,
	title,
	setCancel,
	cancel,

	togglePromocodeModal,
	productItem,
}) {
	const { inventory } = Locale;
	const { safaBalance, myBalance } = useSBSState();
	const [listWallets, setListWallets] = useState([]);

	useEffect(() => {
		const fetchWallets = async () => {
			const response = await getListWallets({
				currency: productItem.currency,
			});
			setListWallets(response.data.data);
		};
		fetchWallets();
	}, []);
	const [radio, setRadio] = useState(null);

	const fetchData = async (brn, type) => {
		const res = await payFees(brn, { payment_method: type });
		if (res.status == 200 || res.status == 201) {
			store.addNotification({
				title: "info!",
				message: "res?.data?.message",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});

			togglePayModal(null);
			window.location.reload();
		}
	};
	const roomType = (status) => {
		switch (status) {
			case 1:
				return "Single";
			case 2:
				return "Dauble";
			case 3:
				return "triple";
			case 4:
				return "quadruple";
			default:
				return "Rooms";
		}
	};
	let currencyFormat = Intl.NumberFormat("en-US");

	return (
		<>
			<Modal size="lg" isOpen={modalPay} toggle={togglePayModal}>
				<ModalHeader className="align-items-center" toggle={togglePayModal}>
					{/* {title == "deposit" ? "Pay Deposit" : "Pay Full Amount"} */}
					{productItem.payment_type == "full payment"
						? `${inventory.messages.PayFullAmount}`
						: `${inventory.messages.ChoosePaymentMethod}`}
					{/* {productItem?.brn} */}
				</ModalHeader>
				<ModalBody className="payModal">
					<div className="container ">
						<div className="payModal">
							<div className="container ">
								<div className="check-box-content mx-4">
									<div className="radios">
										{listWallets && listWallets?.length > 1 ? (
											<>
												<label
													htmlFor="payRadios1"
													className={`box-item ${radio == "debit" ? "checked-active" : ""
														}`}
												>
													<img
														width="100px"
														height="47px"
														className="m-auto"
														src={
															radio == "debit" ? iconNotification : walletgray
														}
														alt=""
														srcset=""
													/>
													<input
														//onClick={freeCancelationClicked}
														className="form-check-input"
														type="radio"
														name="payRadios"
														id="payRadios1"
														value="debit"
														onChange={(e) => {
															setRadio(e.target.value);
														}}
													/>
													<label className="text-center" htmlFor="payRadios1">
													{inventory.messages.PayFromMyWallet}
													</label>
													<p className="text-center">
														{/* {listWallets[0]?.amount} {""} {listWallets[0]?.currency} */}
														{listWallets[0]?.amount !== "" ? (
															<>
																{listWallets[0]?.amount} {""}{" "}
																{listWallets[0]?.currency}
															</>
														) : (
															<p className="text-hidden ">
																{inventory.messages.PayFromMyWallet}{" "}
															</p>
														)}
													</p>
												</label>

												<label
													htmlFor="payRadios2"
													className={`box-item ${radio == "credit" ? "checked-active" : ""
														}`}
												>
													<img
														width="100px"
														height="47px"
														className="m-auto "
														src={
															radio == "credit" ? iconNotification : walletgray
														}
														alt=""
														srcset=""
													/>
													<input
														//onClick={cancelationConditionClicked}
														className="form-check-input"
														type="radio"
														name="payRadios"
														id="payRadios2"
														value="credit"
														onChange={(e) => {
															setRadio(e.target.value);
														}}
													/>
													<label className="text-center " htmlFor="payRadios2">
													
														{inventory.messages.PayFromSafaWallet}
													</label>
													{listWallets?.length > 0 ? (
														listWallets && listWallets?.length == 1 ? (
															listWallets.map((res) => {
																<p className="text-center">
																	{res?.amount} {""} {res?.currency}
																</p>;
															})
														) : (
															<p className="text-center">
																{listWallets[1]?.amount} {""}{" "}
																{listWallets[1]?.currency}
															</p>
														)
													) : (
														<p className="text-hidden ">{inventory.messages.PayFromMyWallet} </p>
													)}
												</label>
											</>
										) : (
											""
										)}

										{listWallets && listWallets?.length == 1 ? (
											<>
												<label
													htmlFor="payRadios1"
													className={`box-item ${radio == "debit" ? "checked-active" : ""
														}`}
												>
													<img
														width="100px"
														height="47px"
														className="m-auto"
														src={
															radio == "debit" ? iconNotification : walletgray
														}
														alt=""
														srcset=""
													/>

													<input
														//onClick={freeCancelationClicked}
														className="form-check-input"
														type="radio"
														name="payRadios"
														id="payRadios1"
														value="debit"
														onChange={(e) => {
															setRadio(e.target.value);
														}}
													/>
													<label className="text-center " htmlFor="payRadios1">
													{inventory.messages.PayFromMyWallet}
													</label>
													{listWallets && listWallets?.length == 1 ? (
														listWallets.map((res) => {
															return (
																<p className="text-center">
																	{res?.amount} {""} {res?.currency}
																</p>
															);
														})
													) : (
														<p className="text-hidden">{inventory.messages.PayFromMyWallet} </p>
													)}
													{/**null */}
												</label>
											</>
										) : (
											""
										)}

										{/* <label
											className={`box-item ${
												radio == "online" ? "checked-active" : ""
											}`}
										<label
											className={`box-item ${radio == "online" ? "checked-active" : ""
												}`}
											htmlFor="onlinepayRadios3"
										>
											<img
												width="100px"
												height="47px"
												className="m-auto"
												src={visa}
												alt=""
												srcset=""
											/>
											<input
												className="form-check-input"
												type="radio"
												name="payRadios"
												id="onlinepayRadios3"
												value="online"
												onChange={(e) => {
													setRadio(e.target.value);
												}}
											/>
											<label className="text-center" htmlFor="onlinepayRadios2">
											{inventory.messages.PayFromCreditCard}{" "}
											</label>
											<p className="text-center">
												<p className="text-hidden">{inventory.messages.PayFromMyWallet} </p>
											</p>
										</label> */}
									</div>
								</div>
								<div className="wallet  price-commission my-2">
									{radio == "online" ? (
										<div className="bg-gray  mx-4 my-1 px-2 py-1 price-commission-text">
											<p>
												<i class="fas fa-info mx-1"></i> {inventory.messages.AllPaymentsMadeByCredit}{" "}
												<small>( {inventory.messages.BankFees})</small>{" "}
											</p>
										</div>
									) : null}
									<div className="d-flex justify-content-center my-2">
										<img
											className="mt-2"
											src={iconNotification}
											alt=""
											width={35}
											height={35}
										/>
										<div className="mx-3">
											<p className="price-total ">
												{radio == "online" ? (
													<>
														
														{inventory.messages.TotalCostWithBankFree}
														<br />
														<span>
															<bold> {productItem.currency} </bold>{" "}
															{currencyFormat.format(
																(
																	Number(
																		productItem.total_price.replace(
																			/[^0-9.-]+/g,
																			""
																		)
																	) +
																	Number(
																		productItem.total_price.replace(
																			/[^0-9.-]+/g,
																			""
																		)
																	) *
																	0.025
																).toFixed(2)
															)}
														</span>
													</>
												) : (
													<>
													
														{inventory.messages.TotalCost}
														<br />
														<span>
															{productItem.total_price}{" "}
															<bold>{productItem.currency}</bold>{" "}
														</span>
													</>
												)}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className=" border-0 px-4 "
						onClick={() => {
							togglePayModal();
							setCancel(!cancel);
						}}
						color="danger"
					>
						{inventory.messages.cancel}
					</Button>
					{title == "PayDeposit" ? (
						<Button
							disabled={radio == null}
							className="px-5 w-30 "
							color="primary"
							onClick={() => {
								fetchData(productItem?.brn, radio);
							}}
						>
							{inventory.messages.pay}
						</Button>
					) : (
						<Button
							disabled={radio == null}
							className="px-5 w-30 "
							color="primary"
							onClick={() => {
								//togglePayModal(null);
								//togglePromocodeModal("Voucher Code");
								fetchData(productItem?.brn, radio);
							}}
						>
							{inventory.messages.pay}
						</Button>
					)}
				</ModalFooter>
			</Modal>
		</>
	);
}
