import { useSBSDispatch, useSBSState } from "context/global";
import { useState } from "react";
import { TabPane } from "reactstrap";
import { fetchCitiesSearch } from "services/marketplace";
import Locale from "translations";
import SuccessModal from "../../../HotelResponse/Summary/successModal";
import Groups from "./Groups";
import Individual from "./Individual";
import openBook from "assets/images/opened-book.svg";
import { useSetpsList } from 'layouts/useSetpsList';


function HotelSearch({
	hotel,
	setHotel,
	allCurrenciesData,
	allCountries,
	mealPlanLookups,
	roomTypesLookups,
	roomViewsLookups,
}) {
	const { locale } = useSBSState();
	const dispatch = useSBSDispatch();
	const { marketPlace, guide } = Locale;
	const [activeTab, setActiveTab] = useState("1");
	const [modalsuccess, setModalsuccess] = useState(false);
	const togglesuccessModal = () => setModalsuccess(!modalsuccess);
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const CurrenciesData = allCurrenciesData;
	const [msgResponse, setMsgResponse] = useState(null);
	const { menuSteps, hotelsTabs, vacationsIndividualSteps, vacationsGroupsSteps } = useSetpsList();
	// AutoCompleteField
	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch("is_vacation", inputValue);

			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name + " - " + item.country.name[locale],
					value: item.id,
					label: item.name + " - " + item.country.name[locale],
				};
			});
			setListAuto(result);
		}
	};

	return (
		<div className="">
			<button className='btn market-search-guide-btn' onClick={() => {
				dispatch({
					type: "guide",
					payload: {
						initialStep: 0,
						enabled: true,
						steps: activeTab === "1" ? [...menuSteps, ...hotelsTabs,...vacationsIndividualSteps] : [...vacationsGroupsSteps]
					}
				})
			}}>
				<i class="fas fa-question-circle"></i>
				<span>{guide.pageGuide}</span>
			</button>
			<TabPane tabId="1">
				<div className="vacation-content sub-tabs-vacation col-md-10 HotelSearch m-auto ">
					<div className="d-flex sub-tabs-content" >
						<div
							id='hotel-vacations-individual'
							className={`${activeTab === "1" ? "active" : ""} TAPactive`}
							onClick={() => toggle("1")}
						>
							{marketPlace.INDIVIDUAL}
						</div>
						<div
							id='hotel-vacations-groups'
							className={`${activeTab === "2" ? "active" : ""} TAPactive`}
							onClick={() => toggle("2")}
						>
							{marketPlace.groups}
						</div>
					</div>
				</div>
				{activeTab == 1 ? (
					<Individual
						hotel={hotel}
						setHotel={setHotel}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						allCountries={allCountries}
						CurrenciesData={CurrenciesData}
					/>
				) : (
					<Groups
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						allCountries={allCountries}
						CurrenciesData={CurrenciesData}
						modalsuccess={modalsuccess}
						setModalsuccess={setModalsuccess}
						mealPlanLookups={mealPlanLookups}
						roomTypesLookups={roomTypesLookups}
						roomViewsLookups={roomViewsLookups}
						msgResponse={msgResponse}
						setMsgResponse={setMsgResponse}
					/>
				)}
			</TabPane>

			<SuccessModal
				modalsuccess={modalsuccess}
				togglesuccessModal={togglesuccessModal}
				msg={msgResponse}
			/>
		</div>
	);
}

export default HotelSearch;
