import myWalletImg from "assets/images/dashboard/3.svg";
import { useSBSState } from "context/global";
import { useMarketplaceState } from "context/marketplace";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Locale from "translations";
import VisaSearch from "../VisaSearch";
import CustomModal from "./Modal";

export default function ListHeader() {
	const { marketPlace, onlineVisa, inventory } = Locale;
	const [openModal, setOpenModal] = useState(false);
	const { onlineVisaSearch } = useMarketplaceState();
	const { locale } = useSBSState();

	const toggleModel = () => {
		setOpenModal(!openModal);
	};
	const { status, id } = useParams();
	return (
		<>
			<div className="rounded row m-0  justify-content-between bg-white border p-0">
				<div className="rounded m-0 row col-10  justify-content-between align-items-center  p-0">
					<div className="p-5 bg-gray text-center">
						<p className="m-0 font-weight-bold">VISA</p>
						<p className="m-0 font-weight-bold"> DETAILS</p>
					</div>

					<div className="row col-5">
						<div className="col-5  txt-blue">
							<p className="m-0 ">ID</p>
							<p className="m-0 ">{onlineVisa.Destination}</p>
							<p className="m-0 ">{onlineVisa.VisaType}</p>
							<p className="m-0 ">{onlineVisa.TransportationType}</p>
							<p className="m-0 ">{onlineVisa.VisaCount}</p>
							<p className="m-0 ">{onlineVisa.Nationality}</p>
							{/* <p className="m-0 ">{onlineVisa.Embassy}</p> */}
						</div>

						<div className="col-7">
							<p className="m-0">{id}</p>
							<p className="m-0">{onlineVisaSearch?.destination}</p>
							<p className="m-0">
								{onlineVisaSearch?.visaType?.name ? onlineVisaSearch?.visaType?.name : "-"}
							</p>
							<p className="m-0 text-capitalize">
								{onlineVisaSearch?.transportation_type?.label ? onlineVisaSearch?.transportation_type?.label : "-"}
							</p>
							<p className="m-0">{onlineVisaSearch?.pax}</p>
							<p className="m-0">{onlineVisaSearch?.residence?.label}</p>
							{/* <p className="m-0">{onlineVisaSearch?.embassy?.label}</p> */}
						</div>
					</div>

					<div className="row col-5 ">
						<div className="col-5  txt-blue font-weight-bold">
							<p className="m-0 ">{onlineVisa.Departure}</p>
							<p className="m-0 ">{onlineVisa.Return}</p>
							<p className="m-0 ">{onlineVisa.RequestDate}</p>
							<p className="m-0 ">{marketPlace.messages.requestExpireDate}</p>
							<p className="m-0 ">{onlineVisa.VisaPriceOne}</p>
							<p className="m-0 ">{onlineVisa.Status}</p>
						</div>
						<div className="col-7  ">
							<p className="m-0">
								{onlineVisaSearch?.departure_date ? moment(onlineVisaSearch?.departure_date).format("DD/MM/YYYY") : "-"}
							</p>
							<p className="m-0">
								{onlineVisaSearch?.return_date ? moment(onlineVisaSearch?.return_date).format("DD/MM/YYYY") : "-"}
							</p>

							<p className="m-0 ">
								{moment(onlineVisaSearch?.requestDate).format("DD/MM/YYYY")}
							</p>
							<p className="m-0 text-danger">
								{moment(onlineVisaSearch?.expirytDate).format("DD/MM/YYYY")}
							</p>
							<p className="m-0">
								{onlineVisaSearch?.transportation_type?.price}{" "}
								{onlineVisaSearch?.transportation_type?.currency?.toLocaleUpperCase()}
							</p>
							<p className={`m-0 font-weight-normal text-capitalize text-${onlineVisaSearch?.status == "waiting" ? "waiting" :
								onlineVisaSearch?.status === "incomplete" || onlineVisaSearch?.status === "expired" ? "danger" :
									onlineVisaSearch?.status === 'pending' ? 'pending' : 'issued'
								}`}
							>
								{onlineVisaSearch.status ? onlineVisaSearch?.status == "waiting" ? inventory.messages.waitingVisa : onlineVisaSearch?.status : "-"}
							</p>
						</div>
					</div>
				</div>

				<div className="d-flex justify-content-between align-items-end col-2 p-0 flex-column">
					{/* edit pax removed because the pay done */}

					<div className="d-flex  bg-gray px-4 text-center rounded justify-content-center h-100 align-items-center">
						<img src={myWalletImg} alt="" width="28px" className="online-vise-img" />
						<div className="mx-3">
							<p className="m-0 txt-blue">{onlineVisa.TotalPrice}</p>
							<p className="m-0 text-success font-weight-bold">
								{onlineVisaSearch?.transportation_type?.currency?.toLocaleUpperCase()}{" "}
								{onlineVisaSearch?.transportation_type?.price * +onlineVisaSearch?.pax || ""}{" "}
							</p>
						</div>
					</div>
				</div>
			</div>

			<CustomModal
				modalIsOpen={openModal}
				header={onlineVisa.EditPax}
				toggle={toggleModel}
				size={"md"}
				centered={true}
			>
				<VisaSearch isMarketSearch={false} toggleModel={toggleModel} />
			</CustomModal>
		</>
	);
}
