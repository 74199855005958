import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { paymentActionDone } from "services/productbuilder";
import Locale from "translations";

export default function PaymentAction(props) {
	const search = props.location.search;
	const name = new URLSearchParams(search);
	const [params, setParams] = useState({});
	const history = useHistory();
	const { payment } = Locale;

	useEffect(() => {
		let x = {};
		name.forEach(function (value, key) {
			x = { ...x, [key]: value };
		});
		setParams(x);
		pay(x);
	}, []);

	const pay = async (data) => {
		const response = await paymentActionDone(data);
		if (response.status == 200 || response.status == "paid") {
			store.addNotification({
				title: "info!",
				message: response.message
					? response.message
					: payment.messages.paymentSuccessful,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					if (response.pay_for == "wallet_recharge") {
						history.push("/wallet");
					} else {
						history.push("/successfullyPayment");
					}
				},
			});
		} else {
			if (response.data.pay_for == "umrah_holidays_reservation") {
				history.push(
					`/market-details/${response.data.data.payload.hotel_availability_cache_id}`
				);
			} else {
				history.push("/inventory");
			}
		}
	};
	return <div>Loading...</div>;
}
