import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Locale from "translations";

export const MemberInfo = ({ member }) => {
	const { visa } = Locale;
	const { locale } = useSBSState();
	const history = useHistory();
	const status = [
		{ id: 0, name: "status 1" },
		{ id: 1, name: "status 2" },
		{ id: 2, name: "status 3" },
	];
	const gender = (status) => {
		switch (status) {
			case 1:
				return "Male";
			case 2:
				return "Female";
			default:
				return "Male";
		}
	};
	return (
		<div className="member-info ">
			<div className=" d-flex align-items-start justify-content-between">
				<div className=" w-100">
					<div className="box1">
						<div className="row align-items-center w-100 no-gutter pt-3 m-0 ">
							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.NATIONALID}
									//	placeholder={visa.NATIONALIDPlaceholder}
									value={member?.IdNumber}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<p>{visa.Gender}</p>
								<SelectField
									name="contract"
									placeholder={"Select Gender"}
									options={status}
									value={gender(member?.Gender)}
									disabled={true}
								/>
							</div>

							<div className="col-md-3">
								<p>{visa.title}</p>
								<SelectField
									name="group-status"
									placeholder={"Select Title"}
									options={status}
									value={
										locale === "ar"
											? member?.Title?.TTL_NAME_AR
											: member?.Title?.TTL_NAME_LA
									}
									disabled={true}
								/>
							</div>
							<div className="col-md-3"></div>
						</div>
					</div>

					<div className="box2 bg-gray p-1">
						<h3 className="p-1 font-dark-blue"> {visa.ArabicName}</h3>
						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-3 ">
								<TextField
									hasLabel={true}
									type="text"
									//label={visa.firstName}
									placeholder={visa.firstNamePlaceholder}
									value={member?.AFirstName}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									//label={visa.fatherName}
									placeholder={visa.fatherNamePlaceholder}
									value={member?.AFatherName}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									//label={visa.grandFatherName}
									placeholder={visa.grandFatherNamePlaceholder}
									value={member?.AGrandName}
									disabled={true}
								/>
							</div>

							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									//label={visa.lastName}
									placeholder={visa.lastNamePlaceholder}
									value={member?.ALastName}
									disabled={true}
								/>
							</div>
							<div className="col-md-3"></div>
						</div>
						<h3 className="p-1 font-dark-blue"> English Name</h3>
						<div className="row align-items-center w-100 no-gutter m-0  ">
							<div className="col-md-3 ">
								<TextField
									hasLabel={true}
									type="text"
									//label={visa.firstName}
									placeholder={visa.firstNamePlaceholder}
									value={member?.FirstName}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									//label={visa.fatherName}
									placeholder={visa.fatherNamePlaceholder}
									value={member?.FatherName}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									//label={visa.grandFatherName}
									placeholder={visa.grandFatherNamePlaceholder}
									value={member?.GrandName}
									disabled={true}
								/>
							</div>

							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									//label={visa.lastName}
									placeholder={visa.lastNamePlaceholder}
									value={member?.LastName}
									disabled={true}
								/>
							</div>
							<div className="col-md-3"></div>
						</div>
					</div>

					{/* <h3 className="my-2 dark-blue-color"> {visa.MotamerDetails}</h3> */}
					<div className="bg-white my-2 p-2 ">
						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-3">
								<SelectField
									name="contract"
									label={visa.passportType}
									placeholder={visa.passportTypePlaceholder}
									options={status}
									value={member?.PPNo}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<SelectField
									name="contract"
									label={visa.maritalStatus}
									placeholder={visa.maritalStatusPlaceholder}
									options={status}
									value={member?.MaritalStatus[locale]}
									disabled={true}
								/>
							</div>

							<div className="col-md-3">
								<SelectField
									name="group-status"
									label={visa.educationLevel}
									placeholder={visa.educationLevelPlaceholder}
									options={status}
									value={member?.Education[locale]}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<SelectField
									name="contract"
									label={visa.country}
									placeholder={visa.countryPlaceholder}
									options={status}
									value={member?.country_name?.name[locale]}
									disabled={true}
								/>
							</div>
						</div>
						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-3">
								<SelectField
									name="contract"
									label={visa.nationality}
									placeholder={visa.nationalityPlaceholder}
									options={status}
									value={member?.nationality_name?.name[locale]}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<SelectField
									name="contract"
									label={visa.birthCountry}
									placeholder={visa.birthCountryPlaceholder}
									options={status}
									value={member?.birth_country_name?.name[locale]}
									disabled={true}
								/>
							</div>

							<div className="col-md-3">
								<SelectField
									name="group-status"
									label={visa.issueCountry}
									placeholder={visa.issueCountryPlaceholder}
									options={status}
									value={member?.issue_country_name?.name[locale]}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.issuePlace}
									placeholder={visa.issuePlacePlaceholder}
									value={member?.PPIssueCity}
									disabled={true}
								/>
							</div>
						</div>

						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.birthPlace}
									placeholder={visa.birthPlacePLaceholder}
									value={member?.birth_country_name?.name[locale]}
									disabled={true}
								/>
							</div>
							<div className="col-md-3 date-picker">
								<p>{visa.Birthday}</p>
								<DatePickerField
									// label={visa.Birthday}
									placeholder={"DD/MM/YYYY"}
									date={moment(member?.BirthDate)}
									value={moment(member?.BirthDate)}
									disabled={true}
								/>
							</div>

							<div className="col-md-3 date-picker">
								<p>{visa.issueDate}</p>
								<DatePickerField
									//label={visa.issueDate}
									placeholder={"DD/MM/YYYY"}
									date={moment(member?.PPIssueDate)}
									value={moment(member?.PPIssueDate)}
									disabled={true}
								/>
							</div>
							<div className="col-md-3 date-picker">
								<p>{visa.expiryDate}</p>
								<DatePickerField
									//label={visa.expiryDate}
									placeholder={"DD/MM/YYYY"}
									date={moment(member?.PPExpDate)}
									value={moment(member?.PPExpDate)}
									disabled={true}
								/>
							</div>
						</div>

						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-6">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.JOB}
									placeholder={visa.jobPlaceholdre}
									value={member?.Job}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.city}
									placeholder={visa.cityPlaceholder}
									value={member?.CAddaCity}
									disabled={true}
								/>
							</div>

							<div className="col-md-3">
								<SelectField
									name="group-status"
									label={visa.roomType}
									//placeholder={visa.roomTypePlaceholder}
									options={status}
									value={member?.roomTypeID}
									disabled={true}
								/>
							</div>
						</div>

						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-6">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.ADDRESS}
									//placeholder={visa.ADDRESSPlaceholder}
									value={member?.CAddaCity}
									disabled={true}
								/>
							</div>
							<div className="col-md-3">
								<SelectField
									name="group-status"
									label={visa.MAHRAM}
									placeholder={visa.MAHRAMPlaceholder}
									options={status}
									value={member?.mehrem_name ? member?.mehrem_name : "-"}
									disabled={true}
								/>
							</div>

							<div className="col-md-3">
								<SelectField
									name="group-status"
									label={visa.RELATION}
									placeholder={visa.relationPlaceholder}
									options={status}
									value={
										locale == "en"
											? member?.RelationWithSponser[locale]
											: member?.RelationWithSponser[locale]
									}
									disabled={true}
								/>
							</div>
						</div>

						<div className="row align-items-center w-100 no-gutter m-0 bg-gray round mrz ">
							<div className="mrz-label col-md-2 bg-grey py-2 text-light text-center">
								<p>MRZ</p>
							</div>
							<div className="col-md-10 py-2 text-16">
								<p>{member?.mrz}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <button
				className="btn btn-secondary  px-5 py-2 mt-2 "
				onClick={() => history.push("/visa")}
			>
				Back
			</button> */}
		</div>
	);
};
