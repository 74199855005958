import { useSBSState } from "context/global";
import { ProductsBuilderProvider } from "context/productsBuilder";
import DashboardLayout from "layouts/DashboardLayout";
import { Redirect, Route } from "react-router-dom";

// React Component
export default function ProductsBuilderRoute({
	component: Component,
	...props
}) {
	const { isAuth } = useSBSState();

	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<ProductsBuilderProvider>
						<DashboardLayout>
							<Component {...matchProps} />
						</DashboardLayout>
					</ProductsBuilderProvider>
				)}
			/>
		);
	} else {
		return <Redirect to="/" />;
	}
}
