import { useSBSDispatch, useSBSState } from "context/global";
import ShowForPermission from 'helpers/showForPermission';
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import { fetchImages } from "services/auth";
import { fetchUserProfile } from "services/profile";
import Locale from "translations";
import sitting from "../assets/images/setting.png";

export default function UserSettings(props) {
	const { messages, backOffice } = Locale;
	const { isAuth, companyName } = useSBSState();
	const dispatch = useSBSDispatch();
	const history = useHistory();
	const [avatar, setAvatar] = useState("");
	const [userData, setUserData] = useState();

	useEffect(() => {
		if (isAuth) {
			async function fetchData() {
				const user = await fetchUserProfile();
				setUserData(user)
				if (user.avatar) {
					const fetchImage = await fetchImages(user.avatar);
					if (fetchImage.status === 200) {
						setAvatar(fetchImage);
					}
				}
			}
			fetchData();
		}
	}, [isAuth]);

	return (
		<UncontrolledDropdown className="setting-dropdown">
			<DropdownToggle className="btn-light p-0">
				{avatar ? (
					<img
						className="img-lang px-2"
						src={avatar ? avatar : sitting}
						width="70"
						alt="sitting"
					/>
				) : (
					<div className="letter-logo">{userData?.full_name?.[0] || companyName[0]}</div>
				)}
			</DropdownToggle>

			<DropdownMenu right>
				<Link to="/user-profile">
					<DropdownItem>{messages.userProfile}</DropdownItem>
				</Link>
				<ShowForPermission permission={["edit-company-settings"]}>

					<Link to="/company-profile">
						<DropdownItem>{messages.companyProfile}</DropdownItem>
					</Link>
				</ShowForPermission>
				<Link to="/change-password">
					<DropdownItem>{backOffice.changePassword}</DropdownItem>
				</Link>
				<DropdownItem
					onClick={() => {
						dispatch({ type: "logout" });
						history.push("/auth/login");
					}}
				>
					<span>{messages.logout}</span>
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
