import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import Pagination from "components/Pagination";
import { useMarketplaceDispatch } from 'context/marketplace';
import moment from 'moment';
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useLocation } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { cancelTransporterBooking } from "services/marketplace";
import { transportationsRequestsList } from "services/transportation";
import Locale from "translations";
import Filter from "./Filter";

export default function TransportationRequests({ props }) {
  const { inventory, productsBuilder, payment, productElements, onlineVisa, marketPlace } = Locale;
  const [ProductList, setProductList] = useState([]);
  const location = useLocation();
  const [meta, setMeta] = useState(null);
  const [cancellationReason, setCancellationReason] = useState(null);
  const [brn, setBrn] = useState(null);
  const [modal, setModal] = useState(false);
  const [total, setTotal] = useState([]);
  const toggleModal = () => setModal(!modal);
  const dispatch = useMarketplaceDispatch();

  const [filter, setFilter] = useState({
    search: "",
    destination_id: "",
    cycle_type_id: "",
    created_at: "",
    status: "",
    page: 1,
  });
  const [searchStatus, setSearchStatus] = useState("");
  const search = () => {
    setSearchStatus(!searchStatus);
  };

  const goTo = (page) => {
    setFilter({ ...filter, page: page });
  };

  const cancelBooking = async () => {
    const data = {
      brn,
      cancellationReason,
    };
    const res = await cancelTransporterBooking(data);
    if (res.status === 200) {
      toggleModal();
      store.addNotification({
        title: "Info!",
        message: "Booking Canceled successfully!",
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };
  useEffect(() => {
    async function fetchRequests() {
      const res = await transportationsRequestsList({
        ...filter,
        destination_id: filter?.destination_id?.value,
        created_at: filter.created_at
          ? moment(filter.created_at).format("YYYY-MM-DD")
          : "",
        cycle_type_id: filter?.cycle_type_id?.value,
        status: filter?.status?.title,
      });
      setProductList(res.data?.data);
      setMeta(res?.data?.meta);
      setTotal(res?.data?.counters);
    }
    fetchRequests();
    // reset 
    dispatch({
      type: "transportationPassengersRemove",
      payload: [],
    });
    dispatch({
      type: "transportationSearch",
      payload: {},
    });
  }, [searchStatus, filter.page]);

  const AllProducts = ProductList?.map((productItem) => {
    return (
      <tr key={productItem.id}>
        <td>{productItem.id}</td>
        <td className="set-w-11">{productItem.parent_id}</td>
        <td className="">{productItem.destination}</td>
        <td>{productItem.cycle_type}</td>
        <td>{productItem.vehicle_type}</td>
        <td>{productItem.seats_count}</td>
        <td>{productItem.vehicle_model}</td>
        <td>{productItem.vehicle_count}</td>
        <td>{productItem.departure_date ? productItem.departure_date : "-"}</td>
        <td>{productItem.return_date ? productItem.return_date : "-"}</td>
        <td>{productItem.request_date ? productItem.request_date : "-"} </td>
        <td>{productItem.expiration_date ? productItem.expiration_date : "-"} </td>
        <td>
          <span className={`font-weight-normal text-capitalize badge badge-${productItem?.status == "waiting" ? "waiting" :
            productItem?.status === "incomplete" || productItem?.status === "expired" ? "danger" :
              productItem?.status === 'pending' ? 'pending' : 'issued'
            }`}
          >
            {productItem?.status === 'waiting' ? inventory.messages.waitingApproval : productItem?.status}
          </span>
        </td>
        <td>
          {productItem?.status == "pending" ?
            <Link className='text-yellow' to={`/inventory/transportations/edit/${productItem.id}`}>
              <i className="far fa-edit pointer"></i> {inventory.messages.edit}
            </Link>
            :
            <Link className='text-yellow' to={`/inventory/transportations/view/${productItem.id}`}>
              <i className="fas fa-eye "></i> {inventory.messages.view}
            </Link>
          }
        </td>
      </tr>
    );
  });

  return (
    <div className="bg-white visa-requests">
      <div className="product-header-list p-4 inventory-header-totals">
        <h3 className="font-weight-bold dark-blue-color">
          {marketPlace.messages.transportationRequests}
        </h3>
        <div className=" d-flex justify-content-between totals_container">
          <span className="btn btn-yellow text-light mx-2">
            {inventory.messages.all} ({meta?.total})
          </span>
          <span className="btn btn-light border mx-2">
            {payment.messages.Pending}
            ({total?.filter(approved => approved?.status == "pending")[0]?.total || 0})
          </span>
          <span className="btn btn-light border mx-2">
            {payment.messages.Approved}
            ({total?.filter(approved => approved?.status == "approved")[0]?.total || 0})
          </span>
          <span className="btn btn-light border mx-2">
            {payment.messages.Expired}
            ({total?.filter(expired => expired?.status == "expired")[0]?.total || 0})
          </span>
        </div>
      </div>
      <Filter filter={filter} setFilter={setFilter} search={search} />
     <div className='table-container'>
      <Table striped className="table-update p-2 bg-white  table table-striped" style={{ verticalAlign: 'center' }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Main Request ID</th>
            <th>{inventory.messages.destination}</th>
            <th>{productElements.cycleType}</th>
            <th>{productElements.vehicleType}</th>
            <th>{productElements.seats}</th>
            <th>{productElements.model}</th>
            <th>{productElements.vehiclesCount}</th>
            <th>{inventory.messages.departure}</th>
            <th>{onlineVisa.Return}</th>
            <th>{inventory.messages.requestDate}</th>
            <th>{marketPlace.messages.requestExpireDate}</th>
            <th>{inventory.messages.status}</th>
            <th>{inventory.messages.actions}</th>
          </tr>
        </thead>
        <tbody>
          {ProductList?.length > 0 ? AllProducts :
            <tr>
              <td colSpan="11">
                <div className="product-build__product-no-data">
                  <i className="fas fa-info-circle fa-lg"></i>{" "}
                  <h4>{productsBuilder.noResult}</h4>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </Table>
      </div>
      {ProductList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}

      <Modal size="lg" isOpen={modal} toggle={toggleModal}>
        <ModalHeader className="align-items-center" toggle={toggleModal}>
          {inventory.messages.cancelReservation}
        </ModalHeader>
        <ModalBody className="mx-3">
          <div className="row">
            <div className="col-md-12">
              <TextAreaField
                label={inventory.messages.cancellationReason}
                placeholder={inventory.messages.cancellationReason}
                value={cancellationReason}
                onChange={(e) => setCancellationReason(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="bg-gray-150 text-dark border-0 px-4"
            onClick={toggleModal}
          >
            {productsBuilder.cancel}
          </Button>
          <Button
            // disabled={["account", "otp"].some((el) => el === null || el === "")}
            className="px-5 w-30"
            color="danger"
            onClick={() => cancelBooking()}
          >
            {inventory.messages.cancelReservation}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
}
