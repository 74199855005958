import TextField from "components/Form/TextField/TextField";
import React, { useState, useEffect } from "react";
import Locale from "../../../translations";

export default function ApplicationOwner() {
	return (
		<div className="container-custom">
			<h3
				className="py-2 mx-2 mt-4 font-weight-bold"
				style={{ color: "#324356" }}
			>
				Application Owner
			</h3>
			<div className="row align-items-center w-100 no-gutter m-0  ">
				<div className="col-md-4">
					<TextField
						type="text"
						label={"FUllName"}
						placeholder={"FUllName"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
					/>
				</div>

				<div className="col-md-4">
					<TextField
						type="text"
						label={"ID NO"}
						placeholder={"ID NO"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
					/>
				</div>

				<div className="col-md-4">
					<TextField
						type="text"
						label={"Phone"}
						placeholder={"Phone"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
					/>
				</div>
			</div>
			<div className="row align-items-center w-100 no-gutter m-0  ">
				<div className="col-md-4">
					<TextField
						type="text"
						label={"Email Address"}
						placeholder={"Email Address"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
					/>
				</div>

				<div className="col-md-4">
					<TextField
						type="text"
						label={"Mobile"}
						placeholder={"Mobile"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
					/>
				</div>

				<div className="col-md-4">
					<TextField
						type="text"
						label={"Mobile2"}
						placeholder={"Mobile2"}
						//	value={filters.search}
						// onChange={(e) =>
						// 	setFilters({ ...filters, search: e.target.value })
						// }
					/>
				</div>
			</div>
		</div>
	);
}
