import visaImg from "assets/images/visaImg.svg";
import PasswordField from "components/Form/PasswordField/PasswordField";
import TextField from "components/Form/TextField/TextField";
import { useSBSDispatch, useSBSState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getToken, viewUser } from "services/visa";
import Locale from "translations";
import SafaProfile from "./SafaProfile";

export default function ViewSafa(props) {
	const { login, commons } = Locale;
	const tokenSafaVisa = localStorage.getItem("VisaToken");
	const dispatch = useSBSDispatch();

	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { locale } = useSBSState();
	const [reload, setReload] = useState(false);
	const [errors, setErrors] = useState({});
	let location = useHistory();
	const [Status, setStatus] = useState();
	const [loginData, setLoginData] = useState({
		safa_key: "",
		email: "",
		password: "",
	});
	const [show, setshow] = useState({
		safa_key: "",
		email: "",
	});

	useEffect(() => {
		const fetchUser = async () => {
			const res = await viewUser();
			setStatus(res.status);
			setshow({ ...res.data.data });
		};
		fetchUser();
	}, [tokenSafaVisa]);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "safa_key",
					value: loginData.safa_key,
				},
				{
					required: true,
				}
			),
			...validate(
				{
					name: "email",
					value: loginData.email,
				},
				{
					required: true,
					email: true,
				}
			),
			...validate(
				{
					name: "password",
					value: loginData.password,
				},
				{ required: true }
			),
		});
	};

	// Login User
	const loginUser = async () => {
		checkFormErrors();
		let requestBody = {
			safa_key: loginData.safa_key,
			email: loginData.email,
			pwd: loginData.password,
		};
		if (isFormValid(errors)) {
			const response = await getToken(requestBody);
			if (response?.status === 200) {
				localStorage.setItem("VisaToken", response?.data?.data?.token);
				setReload(!reload);
			} else {
				dispatch({
					type: "setError",
					payload: true,
					message: {
						title: null,
						body: response?.data?.message,
					},
				});
			}
		}
		// setSpin(false);
	};

	return (
		<>
			<div className=" ">
				{Status == 203 ? (
					<div className="py-5 d-flex align-items-center container   ">
						<div className="d-flex container  justify-content-center align-items-center ">
							{/* Left Banner */}
							<figure className="col-4 pt-3 m-0 d-flex justify-content-center px-5">
								<img src={visaImg} alt="" className="img-fluid" />
							</figure>

							{/* Login Form */}
							<div className="col-5 d-flex  flex-column  justify-content-between">
								<div className="w-100 ">
									<h1 className="text-title font-weight-bold ">
										Link Your Safe Visa Account
									</h1>
									<p className="mb-4">
										{" "}
										By linking your Safa visa account, you’ll manage groups and
										contracts
									</p>
									<div>
										<TextField
											// type="text"
											label={login.safa_key}
											placeholder={login.safaPlaceholder}
											name="safa_key"
											value={loginData.safa_key}
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
													}),
												});
												setLoginData({
													...loginData,
													safa_key: e.target.value,
												});
											}}
											color={errors?.safa_key?.required ? "danger" : ""}
											errors={errors?.safa_key}
										/>
									</div>
									<div>
										<TextField
											placeholder={login.emailPlaceholder}
											label={login.emailAddress}
											value={loginData.email}
											name="email"
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
														email: true,
													}),
												});
												setLoginData({
													...loginData,
													email: e.target.value,
												});
											}}
											color={
												errors?.email?.required || errors?.email?.email
													? "danger"
													: ""
											}
											errors={errors?.email}
										/>
									</div>

									<div>
										<PasswordField
											placeholder={login.passwordPlaceholder}
											label={login.password}
											name="password"
											value={loginData.password}
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(
														{ name: "password", value: e.target.value },
														{
															required: true,
														}
													),
												});
												setLoginData({
													...loginData,
													password: e.target.value,
												});
											}}
											// min={8}
											color={errors?.password?.required ? "danger" : "success"}
											errors={errors?.password}
										/>
									</div>

									<button
										className="btn bg-nxt w-100 mt-3"
										onClick={loginUser}
										//disabled={spin}
									>
										{/* {spin ? <Spinner color="light" size="sm" /> : null} */}
										{"  "} {login.loginTitle}
									</button>
								</div>
								<div></div>
							</div>
						</div>
					</div>
				) : (
					<SafaProfile />
				)}
			</div>
		</>
	);
}
