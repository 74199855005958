import { useEffect, useState } from "react";
import { fetchImages } from "services/auth";
import { fetchCompanyProfile } from "services/profile";
import Locale from "translations";
import imgIcon from "../../assets/images/customRegister/image-regular.svg";

import buildingIcon from "../../assets/images/customCompanyProfile/building.svg";
import homeIcon from "../../assets/images/customCompanyProfile/home.svg";
import materialIcon from "../../assets/images/customCompanyProfile/material-email.svg";
import metroIcon from "../../assets/images/customCompanyProfile/metro-earth.svg";
import phoneIcon from "../../assets/images/customCompanyProfile/phone.svg";

export default function ViewCompany() {
	const { companyDetails, productsBuilder, commons } = Locale;

	const [companyProfileState, setCompanyProfileState] = useState({
		name: "",
		address: "",
		businessAddress: "",
		country: 0,
		city: 0,
		tempCity: 0,
		phone: "",
		secondPhone: "",
		avatar: "",
		description: "",
		countryPhoneCode: null,
		companyLogo: null,
		filePreview: null,
		countryCode: null,
	});

	useEffect(() => {
		async function fetchData() {
			const company = await fetchCompanyProfile();
			setCompanyProfileState({
				...companyProfileState,
				name: company.name,
				businessAddress: company.business_email,
				phone: company.phone?.phone,
				address: company.address,
				secondPhone: company.extra_data?.phone
					? company.extra_data?.phone.phone
					: "",
				description: company.extra_data?.description,
				avatar: company.logo,
				country: {
					value: company.country?.id,
					label: company.country?.name,
				},
				countryPhoneCode: company.phone?.phone_code,
				countryCode: company.country?.code,
				city: { value: company.city?.id, label: company.city?.name },
			});
		}
		fetchData();
	}, []);

	useEffect(() => {
		async function fetchData() {
			if (companyProfileState.avatar) {
				const fetchImage = await fetchImages(companyProfileState.avatar);
				if (fetchImage) {
					updateLogo(fetchImage);
					setCompanyProfileState({
						...companyProfileState,
						filePreview: fetchImage,
					});
				}
			}
		}
		fetchData();
	}, [companyProfileState.avatar]);

	const updateLogo = (file) => {
		setCompanyProfileState({ ...companyProfileState, companyLogo: file });
	};

	return (
		<>
			<div className="bg-white   rounded p-0">
				<div className="d-flex justify-content-between py-2 px-4"></div>
				<div className="row m-0 p-5 align-items-center justify-content-end">
					<div className="col-3 d-flex justify-content-end px-5 ">
						<div className="image-uploader__outline position-relative bg-white img-center ">
							<div className="img-holder ">
								<img
									src={
										companyProfileState.filePreview
											? companyProfileState.filePreview
											: imgIcon
									}
									className="img-fluid"
									accept="image/*"
								/>
							</div>
						</div>
					</div>
					<div className="col-8">
						<div className="d-flex">
							<img src={buildingIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font ">{companyDetails.companyName}</p>
								<p className="m-0 font-weight-bold font-s20">
									{companyProfileState.name}
								</p>
							</div>
						</div>

						<div className="d-flex mt-3 ">
							<img src={materialIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font">{companyDetails.businesmail}</p>
								<p className="m-0 font-weight-bold font-s20">
									{companyProfileState.businessAddress}
								</p>
							</div>
						</div>

						<div className="d-flex mt-3">
							<img src={homeIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font">{companyDetails.address}</p>
								<p className="m-0 font-weight-bold font-s20">
									{companyProfileState.address}
								</p>
							</div>
						</div>

						<div className="d-flex mt-3 justify-content-between w-100">
							<div className="d-flex">
								<img src={metroIcon} alt="" />
								<div className="mx-3">
									<p className="m-0 sans-font">{companyDetails.country}</p>
									<p className="m-0 font-weight-bold font-s20">
										{companyProfileState.country?.label}
									</p>
								</div>
							</div>

							<div className="d-flex w-50">
								<img src={metroIcon} alt="" />
								<div className="mx-3">
									<p className="m-0 sans-font">{companyDetails.city}</p>
									<p className="m-0 font-weight-bold font-s20">
										{companyProfileState.city?.label}
									</p>
								</div>
							</div>
						</div>

						<div className="d-flex mt-3  justify-content-between w-100">
							<div className="d-flex">
								<img src={phoneIcon} alt="" />
								<div className="mx-3">
									<p className="m-0 sans-font">{` ${companyDetails.companyPhoneNumber} 1`}</p>
									<p className="m-0 font-weight-bold font-s20">
										<span className={Locale._language === "ar" && "code-num"}>
											{companyProfileState.countryPhoneCode}
										</span>
										<span>{companyProfileState.phone}</span>
									</p>
								</div>
							</div>
							{console.log(companyProfileState)}
							<div className="d-flex w-50">
								<img src={phoneIcon} alt="" />
								<div className="mx-3">
									<p className="m-0 sans-font">{` ${companyDetails.companyPhoneNumber} 2`}</p>
									<p className="m-0 font-weight-bold font-s20">
										{companyProfileState.secondPhone
											? companyProfileState.secondPhone
											: productsBuilder.noResult}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
