import { useSBSState } from "context/global";
import TSupport from "../assets/images/icons/TechnicalSupport.png";
import TSupportAR from "../assets/images/icons/TechnicalSupportAR.png";
import smallSupport from "../assets/images/icons/smallSupport.svg";

export default function TechnicalSupport() {
	const { locale } = useSBSState();

	return (
		<>
			<div className="TechnicalSupport-width border pointer">
				<a
					target="_blank"
					href="https://help2.safavisa.com/phplive.php?d=0&onpage=livechatimagelink&title=Live+Chat+Direct+Link&token=784185540a01721cd2d0da182376fcea"
				>
					<img src={locale === "en" ? TSupport : TSupportAR} alt=""></img>
					<img className='small-support d-none' src={smallSupport} alt=""></img>
				</a>
			</div>
		</>
	);
}
