import Ham from "assets/images/dashboard/ham.svg";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import SideNav from "components/SideNav";
import UserSettings from "components/UserSettings";
import { useSBSDispatch, useSBSState } from "context/global";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getPermissions } from "services/auth";
import Translation from "translations";
import WalletHeader from "./walletHeader";
/* import safaLogoMinimal from "assets/images/dashboard/safaLogoSmall.svg";
 */import newlogo from "assets/images/newlogo.png";
import { NotifactionHeader } from './notifactionHeader';

export default function Header() {
	const { dashboard } = Translation;
	const search = window.location.search;
	const name = new URLSearchParams(search);
	const history = useHistory();
	const dispatch = useSBSDispatch();
	const [show, setShow] = useState(false);
	const { pathname } = useLocation();
	const [discount, setdiscount] = useState(localStorage.getItem("discount"));

	const showToggle = () => {
		setShow(!show);
	};

	const showToggleOpen = () => {
		setShow(true);
	};

	const { isAuth, token_data, token, permissions,role } = useSBSState();
	const OPEND_SIDE_NAV_WIDTH = "250px";
	const CLOSED_SIDE_NAV_WIDTH = "70px";
	const showNav = {
		position: "sticky",
		width: "100%",
		top: "0",
	};
	const hamBtnStyle = {
		margin: `0 ${show ? OPEND_SIDE_NAV_WIDTH : CLOSED_SIDE_NAV_WIDTH}`,
		position: "absolute",
		border: 0,
		backgroundColor: "transparent",
		transition: ".7s",
	};

	let xx;
	useEffect(() => {
		async function fetchPermissions() {
			const res = await getPermissions();
			dispatch({ type: "getPermissions", payload: res?.data?.data });
		}
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}

		if (x.hasOwnProperty("token") && token != null) {
			fetchPermissions();
		} else if (!x.hasOwnProperty("token")) {
			fetchPermissions();
		}

		xx = x;
	}, []);

	const walletPermission = permissions?.includes(
		...["show-credit-wallet", "manage-wallets"]
	) || role === "super-admin";

	return (
		<>
			<div
				className="navbar bg-white shadow mb-5"
				style={show ? showNav : null}
			>
				<div className="d-flex position-absolute">
					<button
						type="button"
						className="side-nav-button"
						style={hamBtnStyle}
						onClick={showToggle}
					>
						{!show ? (
							<img src={Ham} />
						) : (
							<i
								class="fa fa-times"
								aria-hidden="true"
								style={{ color: "#BA5F20", fontSize: "18px" }}
							></i>
						)}
					</button>

					<span className={`navbar-brand-icon`}>
						<img
							className='logo-header'
							/* src={safaLogoMinimal} */ 
							src={newlogo}
							alt="Logo"
						/>
					</span>
				</div>

				<div className="container">
					{/* <Link
						className="navbar-brand"
						onClick={() => {
							show && setShow(false);
							history.push("/");
						}}
					// to="/"
					>
						<img className="img-fluid" src={SafaLogo} alt="Logo" width="70%" />
					</Link> */}

					<div>
						{/* {pathname !== "/auth/register" && isAuth ? (
							<div className="nav-item main-menu d-flex align-items-center">
								<div
									className="nav-link py-1 px-3 bg-nxt rounded-bottom d-flex custom-main pointer "
									// onMouseEnter={showToggleOpen}
									onClick={showToggle}
								>
									{!show ? (
										<img className="menu-img" src={MainMenu} alt="main menu" />
									) : (
										<i
											className={`fas fa-times pointer  mt-1 `}
											aria-hidden="true"
											onClick={() => setShow(!show)}
										></i>
									)}
									<span className="text-headline text-center w-100 ">
										{!show
											? dashboard.messages.mainMenu
											: dashboard.messages.closemainMenu}
									</span>
								</div>
							</div>
						) : null} */}
					</div>

					<div className="dropdowns d-flex align-items-center">
						<LanguageSwitcher className="bg-white" />
						{!search.includes("token") ? (
							<>
								{walletPermission && <WalletHeader />} <NotifactionHeader />{" "}
							</>
						) : token_data && token_data.discount != null ? (
							<p style={{ color: "#D92626" }} className="mx-2">
								<strong style={{ fontWeight: "700" }}>
									{token_data.discount}%
								</strong>{" "}
								OFF
							</p>
						) : (
							""
						)}

						{isAuth ? <UserSettings /> : null}
					</div>
				</div>
			</div>
			<SideNav show={show} setShow={setShow} />
			{/* <Dashboard show={show} setShow={setShow} /> */}
		</>
	);
}
