import visa from "assets/images/visa.svg";
import walletgray from "assets/images/walletgray.svg";

import iconNotification from "assets/images/customHeader/wallet.svg";
import { useMarketplaceState } from "context/marketplace";
import { useEffect, useState } from "react";
import { getListWallets } from "services/wallet";
import Locale from "translations";

export default function Payment({ payTransportation }) {
	const [radio, setRadio] = useState(null);
	const { marketPlace, onlineVisa, inventory } = Locale;
	const [listWallets, setListWallets] = useState([]);
	const { transportationSearch } = useMarketplaceState();

	useEffect(() => {
		const fetchWallets = async () => {
			const response = await getListWallets({
				currency: transportationSearch?.residence?.currency_code || transportationSearch?.residence?.currency,
			});
			setListWallets(response.data.data);
		};
		fetchWallets();
	}, []);

	return (
		<>
			{/* {transportationPassengers.length == transportationSearch?.pax ? ( */}
			<div>
				<div className="payModal">
					<div className="container ">
						<div className="check-box-content mx-4">
							<div className="radios">
								{listWallets && listWallets?.length > 1 ? (
									<>
										<label
											htmlFor="payRadios1"
											className={`box-item ${radio == "debit" ? "checked-active" : ""
												}`}
										>
											<img
												width="100px"
												height="47px"
												className="m-auto"
												src={radio == "debit" ? iconNotification : walletgray}
												alt=""
												srcset=""
											/>
											<input
												//onClick={freeCancelationClicked}
												className="form-check-input"
												type="radio"
												name="payRadios"
												id="payRadios1"
												value="debit"
												onChange={(e) => {
													setRadio(e.target.value);
												}}
											/>
											<label className="text-center" htmlFor="payRadios1">
												Pay From My Wallet
											</label>
											<p className="text-center">
												{/* {listWallets[0]?.amount} {""} {listWallets[0]?.currency} */}
												{listWallets[0]?.amount !== "" ? (
													<>
														{listWallets[0]?.amount} {""}{" "}
														{listWallets[0]?.currency}
													</>
												) : (
													<p className="text-hidden ">Pay From My Wallet </p>
												)}
											</p>
										</label>
										{/* safa wallet  */}
										{/* <label
												htmlFor="payRadios2"
												className={`box-item ${radio == "credit" ? "checked-active" : ""
													}`}
											>
												<img
													width="100px"
													height="47px"
													className="m-auto "
													src={
														radio == "credit" ? iconNotification : walletgray
													}
													alt=""
													srcset=""
												/>
												<input
													//onClick={cancelationConditionClicked}
													className="form-check-input"
													type="radio"
													name="payRadios"
													id="payRadios2"
													value="credit"
													onChange={(e) => {
														setRadio(e.target.value);
													}}
												/>
												<label className="text-center " htmlFor="payRadios2">
													Pay From Safa Wallet{" "}
												</label>
												{listWallets?.length > 0 ? (
													listWallets && listWallets?.length == 1 ? (
														listWallets.map((res) => {
															<p className="text-center">
																{res?.amount} {""} {res?.currency}
															</p>;
														})
													) : (
														<p className="text-center">
															{listWallets[1]?.amount} {""}{" "}
															{listWallets[1]?.currency}
														</p>
													)
												) : (
													<p className="text-hidden ">Pay From My Wallet </p>
												)}
											</label> */}
									</>
								) : (
									""
								)}

								{listWallets && listWallets?.length == 1 ? (
									<>
										<label
											htmlFor="payRadios1"
											className={`box-item ${radio == "debit" ? "checked-active" : ""
												}`}
										>
											<img
												width="100px"
												height="47px"
												className="m-auto"
												src={radio == "debit" ? iconNotification : walletgray}
												alt=""
												srcset=""
											/>

											<input
												//onClick={freeCancelationClicked}
												className="form-check-input"
												type="radio"
												name="payRadios"
												id="payRadios1"
												value="debit"
												onChange={(e) => {
													setRadio(e.target.value);
												}}
											/>
											<label className="text-center " htmlFor="payRadios1">
												Pay From My Wallet
											</label>
											{listWallets && listWallets?.length == 1 ? (
												listWallets.map((res) => {
													return (
														<p className="text-center">
															{onlineVisa.Balance}{":"} {res?.amount} {""} {res?.currency}
														</p>
													);
												})
											) : (
												<p className="text-hidden">Pay From My Wallet </p>
											)}
											{/**null */}
										</label>
									</>
								) : (
									""
								)}

								<label
									className={`box-item ${radio == "online" ? "checked-active" : ""
										}`}
									htmlFor="onlinepayRadios3"
								>
									<img
										width="100px"
										height="47px"
										className="m-auto"
										src={visa}
										alt=""
										srcset=""
									/>
									<input
										className="form-check-input"
										type="radio"
										name="payRadios"
										id="onlinepayRadios3"
										value="online"
										onChange={(e) => {
											setRadio(e.target.value);
										}}
									/>
									<label className="text-center" htmlFor="onlinepayRadios2">
										Pay From Credit Card{" "}
									</label>
									<p className="text-center">
										<p className="text-hidden">Pay From My Wallet </p>
									</p>
									{/* <p className="text-hidden">Pay From My Wallet </p> */}
								</label>
							</div>
						</div>
						<div className="wallet  price-commission my-2">
							{radio == "online" ? (
								<div className="bg-gray  mx-4 my-1 px-2 py-1 price-commission-text">
									<p>
										<i class="fas fa-info mx-1"></i> All Payments Made By
										Credit Card Will Be Subject To A 2.5% Charge{" "}
										<small>( Bank Fees)</small>{" "}
									</p>
								</div>
							) : null}
							<div className="d-flex justify-content-center my-2">
								<img
									className="mt-2"
									src={iconNotification}
									alt=""
									width={35}
									height={35}
								/>
								<div className="mx-3">
									<p className="price-total ">
										{radio == "online" ? (
											<>
												Total Cost With Bank Free
												<br />
												<span>
													<bold>
														{" "}{transportationSearch?.residence?.currency?.toLocaleUpperCase()}{" "}
													</bold>{" "}
													{(
														transportationSearch?.residence?.price * +transportationSearch?.vehicles_count + transportationSearch?.residence?.price * +transportationSearch?.vehicles_count * 0.025
													).toFixed(2)}{" "}
												</span>
											</>
										) : (
											<>
												Total Cost
												<br />
												<span>
													<bold>
														{transportationSearch?.re?.currency}
													</bold>{" "}
													{transportationSearch?.residence?.price * +transportationSearch?.vehicles_count}{" "}
													{transportationSearch?.residence?.currency?.toLocaleUpperCase()}{" "}
												</span>
											</>
										)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<button
					className="bg-nxt btn w-100 my-3"
					onClick={() => {
						payTransportation(radio);
					}}
				>
					{"Pay"}
				</button>
			</div>
			{/* ) : (
				<div className="d-flex flex-column justify-content-center align-items-center  ">
					<div>
						<img src={visaToken} alt="" />
					</div>
					<p className="m-0 my-3">Added passanger is more or less than pax</p>
				</div>
			)} */}
		</>
	);
}
