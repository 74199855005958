import visaoff from "assets/images/2Online.svg";
import flightsIcon from "assets/images/flights.svg";
import groundService from "assets/images/groundService.svg";
import groundServiceAcive from "assets/images/groundServiceAcive.svg";
import HotelsIcon from "assets/images/new-hotels-colored.svg";
import HotelGray from "assets/images/new-hotels.svg";
import transportationColored from "assets/images/new-transportation-colored.svg";
import car from "assets/images/new-transportation.svg";
import visa from "assets/images/new-visa-colored.svg";
import classnames from "classnames";
import { useSBSDispatch, useSBSState } from "context/global";
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import ShowForPermission from "helpers/showForPermission";
import useFetchCountries from "hooks/useFetchCountries";
import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import { fetchAllCurrencies, fetchLookups } from "services/lookups";
import { fetchCitiesSearch, getAdsList } from "services/marketplace";
import Locale from "translations";
import GroundService from "./GroundService";
import Hotels from "./Hotels";
import OnlineVisa from "./OnlineVisa";
import OfferBar from "./specialOfferBar";
import Transportation from "./Transportation";


function SearchContainer() {
	const { marketPlace, inventory } = Locale;
	const { permissions, role } = useSBSState();
	const dispatch = useSBSDispatch();
	const hotals =
		permissions?.includes("hotel-umrah") ||
		permissions?.includes("hotel-vacation") ||
		role == "super-admin";
	const visas = permissions?.includes("request-visa") || role == "super-admin";
	const transportations =
		permissions?.includes("request-transportations") || role == "super-admin";
	const groundWork =
		permissions?.includes("request-ground-work") || role == "super-admin";


	const scrollToContent = () => {
		let tabContent = document.getElementById("tabContentRef")
		tabContent.scrollIntoView({block:"start",behavior:"smooth"})
	}

	const [activeTab, setActiveTab] = useState(0);
	const [allCountries] = useFetchCountries();
	const [allCitiesVacation, setAllCitiesVacation] = useState();
	const [allCurrenciesData, setAllCurrenciesData] = useState();
	const [allLookups, setAllLookups] = useState([]);
	useEffect(() => {
		async function fetchCitiesVacation() {
			const countries = await fetchCitiesSearch("is_vacation");
			const Currencies = await fetchAllCurrencies();
			let x = Currencies?.map((res) => {
				return { name: res.currency_code, id: res.currency_code };
			});
			setAllCurrenciesData(x);
			setAllCitiesVacation(countries);
		}
		fetchCitiesVacation();
	}, []);
	useEffect(() => {
		async function fetchData() {
			const response = await fetchLookups();
			setAllLookups(response?.room_types);
		}
		fetchData();
	}, []);

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	useEffect(() => {
		if ((permissions && permissions.length > 0) || role) {
			setActiveTab(
				hotals
					? "1"
					: visas
					? "2"
					: transportations
					? "3"
					: groundWork
					? "4"
					: null
			);
		}
	}, [permissions, role]);



	const [adsList, setAdsList] = useState([]);

	// useEffect(() => {
	// 	async function fetchAdsList() {
	// 		const res = await getAdsList();
	// 		setAdsList(res?.data);
	// 	}
	// 	fetchAdsList();
	// }, []);

	return (
		<>
			<div className="market-search-conatainer k-market-search-container container pt-3">
				<div className="main-tabs1 ">
					<div className='market-search-tabs-container'>
					<Nav tabs className="align-items-center main-nav1">
						{/**request-hotels */}
						<ShowForPermission permission={["hotel-vacation", "hotel-umrah"]}>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === "1" })}
									onClick={() => {
										scrollToContent()
										toggle("1");
									}}
								>
									{/* <i class="fas fa-kaaba fa-lg mx-1"></i> */}
									<img
										src={activeTab === "1" ? HotelsIcon : HotelGray}
										alt=""
										srcset=""
										width="50px"
									/>
									<p>{marketPlace.messages.hotels}</p>
									<i class="fas fa-caret-right"></i>
								</NavLink>
							</NavItem>
						</ShowForPermission>
						{/* <ShowForPermission permission={["request-hotels"]}>
              <NavItem>
                <NavLink
                  style={{ marginTop: "10px " }}
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <i class="fas fa-umbrella-beach fa-lg mx-1"></i>
                  <img
                    style={{ margin: "auto" }}
                    src={activeTab === "2" ? VACATIONSActive : VACATIONS}
                    alt=""
                    srcset=""
                  />

                  <p>{marketPlace.messages.HOTELS}</p>
                  <i class="fas fa-caret-right"></i>
                </NavLink>
              </NavItem>
            </ShowForPermission> */}
						{/* <ShowForPermission permission={["request-visa"]}>
							<NavItem>
								<NavLink
									style={{ marginTop: "10px" }}
									className={classnames({ active: activeTab === "2" })}
									onClick={() => {
										scrollToContent()
										toggle("2");
									}}
									
								>
									<img
										style={{ margin: "auto" }}
										src={activeTab === "2" ? visa : visaoff}
										alt=""
										srcset=""
									/>

									<p>{marketPlace.messages.ONLINEVISA}</p>
									<i class="fas fa-caret-right"></i>
								</NavLink>
							</NavItem>
						</ShowForPermission> */}

						{/* <ShowForPermission permission={["request-transportations"]}>
							<NavItem>
								<NavLink
									style={{ marginTop: "10px" }}
									className={classnames({ active: activeTab === "3" })}
									onClick={() => {
										scrollToContent()
										toggle("3");
									}}
									
								>
									<img
										style={{ margin: "auto", width: "50px" }}
										src={activeTab === "3" ? transportationColored : car}
										alt=""
										srcset=""
									/>

									<p>{marketPlace.messages.transportation}</p>
									<i class="fas fa-caret-right"></i>
								</NavLink>
							</NavItem>
						</ShowForPermission> */}

						{/* <ShowForPermission permission={["request-ground-work"]}>
							<NavItem>
								<NavLink
									style={{ marginTop: "10px", fontSize: "15px" }}
									className={classnames({ active: activeTab === "4" })}
									onClick={() => {
										scrollToContent()
										toggle("4");
									}}
									
								>
									<img
										style={{ margin: "auto", width: "45px" }}
										src={activeTab === "4" ? groundServiceAcive : groundService}
										alt=""
										srcset=""
									/>

									<p>{marketPlace.messages.groundService}</p>
									<i class="fas fa-caret-right"></i>
								</NavLink>
							</NavItem>
						</ShowForPermission> */}

						{/* <NavItem>
							<NavLink
								style={{ marginTop: "10px", fontSize: "15px" }}
								className={`${classnames({ active: activeTab === "5" })}`}
								onClick={() => {
									scrollToContent()
									toggle("5");
								}}
								disabled
							>
								<img
									style={{ margin: "auto" }}
									src={activeTab === "5" ? flightsIcon : flightsIcon}
									alt=""
									srcset=""
								/>
								<span className="soon">{inventory.messages.soon}</span>

								<p>{marketPlace.messages.flights}</p>
								<i class="fas fa-caret-right"></i>
							</NavLink>
						</NavItem> */}
					</Nav>
					</div>
					<TabContent activeTab={activeTab} className="w-100" id="tabContentRef" >
						<div className="main-nav-contant ">
							{activeTab == "1" ? (
								<Hotels
									allCountries={allCountries}
									allCitiesVacation={allCitiesVacation}
									allCurrenciesData={allCurrenciesData}
									allLookups={allLookups}
								/>
							) : activeTab == "2" ? (
								<OnlineVisa allCountries={allCountries} />
							) : activeTab == "3" ? (
								<Transportation />
							) : activeTab == "4" ? (
								<GroundService />
							) : activeTab == "5" ? (
								"flights"
							) : null}
						</div>
					</TabContent>
				</div>
			</div>
			{/* <OfferBar adsList={adsList} setAdsList={setAdsList} /> */}
		</>
	);
}

export default SearchContainer;
