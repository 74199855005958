import iconNotification from "assets/images/customHeader/wallet.svg";
import visa from "assets/images/visa.svg";
import successImg from "assets/images/wallet/walletSuccessfully.png";
import walletgray from "assets/images/walletgray.svg";
import { useSBSDispatch, useSBSState } from "context/global";
import { useMarketplaceState } from "context/marketplace";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { bookHotel } from "services/marketplace";
import Locale from "translations";

export default function PayModal({
	modalPay,
	togglePayModal,
	title,
	productItem,
	resAvailability,
	otp,
	account,
	listWallets,
}) {
	// const { price } = useMarketplaceState();

	const { inventory } = Locale;
	const { safaBalance, myBalance } = useSBSState();
	const { selected } = useMarketplaceState();
	const [Hotel, setHotel] = useState(selected);
	const { locale } = useSBSState();
	const HotelData = JSON.parse(localStorage.getItem("HotelData"));

	useEffect(() => {
		if (!selected) {
			setHotel(JSON.parse(localStorage.getItem("selected")));
			// setFilter(JSON.parse(localStorage.getItem("filters")));
		}
	}, [setHotel]);

	const dispatchWallet = useSBSDispatch();

	const [radio, setRadio] = useState(null);

	const history = useHistory();

	const fetchData = async () => {
		const roomData = {
			special_request: "",
			hotel_availability_cache_id: selected.checkId,

			provider_id: HotelData.gds,
			// dummy room_guests

			payment_info: {
				payment_method: radio,
				account_number: account,
				otp: otp,
				pay_now: radio == "later" ? 0 : 1,
			},
			lang: locale,
		};
		const res = await bookHotel(roomData);

		if (res?.status == 200 || res?.status == 201) {
			store.addNotification({
				title: "info!",
				message: "Booking successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push("/inventory");
				},
			});
			window.dataLayer.push({ event: "buyNowtest" });

			togglePayModal(null);
		}

	};
	const roomType = (status) => {
		switch (status) {
			case 1:
				return "Single";
			case 2:
				return "Dauble";
			case 3:
				return "triple";
			case 4:
				return "quadruple";
			default:
				return "Rooms";
		}
	};

	return (
		<>
			<Modal
				size="lg"
				className="price-model"
				isOpen={modalPay}
				toggle={togglePayModal}
			>
				<ModalHeader className="align-items-center" toggle={togglePayModal}>
					{/* {title == "deposit" ? "Pay Deposit" : "Pay Full Amount"} */}
					<p> {inventory.messages.ChoosePaymentMethod}</p>
				</ModalHeader>
				<ModalBody className="payModal">
					<div className="container ">
						{/* <div className="wallet d-flex justify-content-center ">
							{listWallets && listWallets?.length > 0
								? listWallets.map((res) => {
										return (
											<div className="wallet-box w-100 text-center mr-3">
												<p>My Wallet</p>
												<p className="font-weight-bold">
											
												</p>
											</div>
										);
								  })
								: ""}
						</div> */}
						{/* <div className="wallet d-flex  justify-content-center ">
							<div className=" bg-transparent mt-3 mb-2 w-100 text-center mr-3">
								<p>Price</p>
								<p className="font-weight-bold text-success">
									{radio == "online" ? (
										<span>
											{" "}
											{(
												resAvailability?.total_price +
												resAvailability?.total_price * 0.025
											).toFixed(2)}{" "}
											{resAvailability?.currency}{" "}
										</span>
									) : (
										<>
											{resAvailability?.total_price} {resAvailability?.currency}
										</>
									)}
								</p>
							</div>
						</div> */}

						<div className="check-box-content mx-4">
							<div className="radios">
								{resAvailability?.is_auto_reservation ? (
									<label
										className={`box-item ${radio == "later" ? "checked-active" : ""
											}`}
										htmlFor="onlinepayRadios343"
									>
										<img
											width="58px"
											className=" mtFIX-2"
											src={successImg}
											alt=""
											srcset=""
										/>
										<input
											className="form-check-input"
											type="radio"
											name="payRadios"
											id="onlinepayRadios343"
											value="later"
											onChange={(e) => {
												setRadio(e.target.value);
											}}
										/>

										<label className="text-center" htmlFor="onlinepayRadios343">
											{inventory.messages.ConfirmNowPayLater}
										</label>
										<p className="text-center">
											<p className="text-hidden">{inventory.messages.PayFromMyWallet} </p>
										</p>
									</label>
								) : (
									""
								)}

								{listWallets && listWallets?.length > 1 ? (
									<>
										<label
											htmlFor="payRadios1"
											className={`box-item ${radio == "debit" ? "checked-active" : ""
												}`}
										>
											<img
												width="100px"
												height="47px"
												className="m-auto"
												src={radio == "debit" ? iconNotification : walletgray}
												alt=""
												srcset=""
											/>
											<input
												//onClick={freeCancelationClicked}
												className="form-check-input"
												type="radio"
												name="payRadios"
												id="payRadios1"
												value="debit"
												onChange={(e) => {
													setRadio(e.target.value);
												}}
											/>
											<label className="text-center" htmlFor="payRadios1">
												{inventory.messages.PayFromMyWallet}
											</label>
											<p className="text-center">
												{/* {listWallets[0]?.amount} {""} {listWallets[0]?.currency} */}
												{listWallets[0]?.amount !== "" ? (
													<>
														{listWallets[0]?.amount} {""}{" "}
														{listWallets[0]?.currency}
													</>
												) : (
													<p className="text-hidden ">{inventory.messages.PayFromMyWallet} </p>
												)}
											</p>
										</label>

										<label
											htmlFor="payRadios2"
											className={`box-item ${radio == "credit" ? "checked-active" : ""
												}`}
										>
											<img
												width="100px"
												height="47px"
												className="m-auto "
												src={radio == "credit" ? iconNotification : walletgray}
												alt=""
												srcset=""
											/>
											<input
												//onClick={cancelationConditionClicked}
												className="form-check-input"
												type="radio"
												name="payRadios"
												id="payRadios2"
												value="credit"
												onChange={(e) => {
													setRadio(e.target.value);
												}}
											/>
											<label className="text-center " htmlFor="payRadios2">
											{inventory.messages.PayFromSafaWallet}
											</label>
											{listWallets?.length > 0 ? (
												listWallets && listWallets?.length == 1 ? (
													listWallets.map((res) => {
														<p className="text-center">
															{res?.amount} {""} {res?.currency}
														</p>;
													})
												) : (
													<p className="text-center">
														{listWallets[1]?.amount} {""}{" "}
														{listWallets[1]?.currency}
													</p>
												)
											) : (
												<p className="text-hidden ">{inventory.messages.PayFromMyWallet} </p>
											)}
										</label>
									</>
								) : (
									""
								)}

								{listWallets && listWallets?.length == 1 ? (
									<>
										<label
											htmlFor="payRadios1"
											className={`box-item ${radio == "debit" ? "checked-active" : ""
												}`}
										>
											<img
												width="100px"
												height="47px"
												className="m-auto"
												src={radio == "debit" ? iconNotification : walletgray}
												alt=""
												srcset=""
											/>

											<input
												//onClick={freeCancelationClicked}
												className="form-check-input"
												type="radio"
												name="payRadios"
												id="payRadios1"
												value="debit"
												onChange={(e) => {
													setRadio(e.target.value);
												}}
											/>
											<label className="text-center " htmlFor="payRadios1">
											{inventory.messages.PayFromMyWallet}
											</label>
											{listWallets && listWallets?.length == 1 ? (
												listWallets.map((res) => {
													return (
														<p className="text-center">
															{res?.amount} {""} {res?.currency}
														</p>
													);
												})
											) : (
												<p className="text-hidden">{inventory.messages.PayFromMyWallet} </p>
											)}
											{/**null */}
										</label>
									</>
								) : (
									""
								)}
								{/* 
								<label
									className={`box-item ${radio == "online" ? "checked-active" : ""
										}`}
									htmlFor="onlinepayRadios3"
								>
									<img
										width="100px"
										height="47px"
										className="m-auto"
										src={visa}
										alt=""
										srcset=""
									/>
									<input
										className="form-check-input"
										type="radio"
										name="payRadios"
										id="onlinepayRadios3"
										value="online"
										onChange={(e) => {
											setRadio(e.target.value);
										}}
									/>
									<label className="text-center" htmlFor="onlinepayRadios2">
									{inventory.messages.PayFromCreditCard}
									</label>
									<p className="text-center">
										<p className="text-hidden">{inventory.messages.PayFromMyWallet} </p>
									</p>
								</label> */}
							</div>
						</div>
						<div className="wallet  price-commission my-2">
							{radio == "online" ? (
								<div className="bg-gray  mx-4 my-1 px-2 py-1 price-commission-text">
									<p>
										<i class="fas fa-info mx-1"></i> {inventory.messages.AllPaymentsMadeByCredit}
										<small>({inventory.messages.BankFees})</small>{" "}
									</p>
								</div>
							) : null}
							<div className="d-flex justify-content-center my-2">
								<img
									className="mt-2"
									src={iconNotification}
									alt=""
									width={35}
									height={35}
								/>
								<div className="mx-3">
									<p className="price-total">
										{radio == "online" ? (
											<>
												{inventory.messages.ReservationTotalCostWithBankFree}
												<br />
												<span>
													<bold> {resAvailability?.currency} </bold>{" "}
													{(
														resAvailability?.total_price +
														resAvailability?.total_price * 0.025
													).toFixed(2)}{" "}
												</span>
											</>
										) : (
											<>{inventory.messages.ReservationTotalCost}
												
												<br />
												<span>
													<bold>{resAvailability?.currency}</bold>{" "}
													{resAvailability?.total_price}{" "}
												</span>
											</>
										)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className=" border-0 px-4 "
						onClick={() => {
							togglePayModal();
						}}
						color="danger"
					>
						{inventory.messages.cancel}
					</Button>

					<Button
						className="px-5 w-30 btn-pay-prim-disabled"
						disabled={
							!(
								radio == "online" ||
								radio == "debit" ||
								radio == "credit" ||
								radio == "later"
							)
						}
						color="primary"
						onClick={() => {
							fetchData();
						}}
					>
						{inventory.messages.pay}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
