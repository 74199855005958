import clearIcon from "assets/images/wallet/clearIcon.svg";
import correctIcon from "assets/images/wallet/correctIcon.svg";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import Locale from "translations";

export default function Filter({ filter, setFilter, search, activeTab }) {
	const { payment, inventory } = Locale;
	const status = [
		{ id: 1, name: "NEW REQUEST" },
		{ id: 2, name: "PENDING" },
		{ id: 3, name: "TENTATIVE" },
		{ id: 4, name: "DEFINITE" },
		{ id: 5, name: "CANCELED" },
	];
	return (
		<>
			<div className="filter-update bg-filter-gray">
				<div className="product-buttons col-md-11">
					<div className="main-filter-payment d-flex justify-contain-between">
						<div className="main-label controls-field col-md-3 col-6">
							<TextField
								label={payment.messages.search}
								type="text"
								placeholder={payment.messages.search}
								onChange={(e) => setFilter({ ...filter, name: e.target.value })}
								value={filter.name}
							/>
						</div>
						{activeTab != "1" ? (
							<div className="main-label date-filter col-md-3 col-6 ">
								{" "}
								<DatePickerField
									label={inventory.messages.BookingDate}
									placeholder="DD/MM/YYY"
									date={filter.book_date}
									onChangeDate={(e) => {
										setFilter({ ...filter, book_date: e });
									}}
									isOutsideRange={(day) => {
										return false;
									}}
								/>
							</div>
						) : null}
						<div className="main-label date-filter col-md-3 col-6 ">
							{" "}
							<DatePickerField
								label={inventory.messages.checkIn}
								placeholder="DD/MM/YYY"
								date={filter.checkin}
								onChangeDate={(e) => {
									setFilter({ ...filter, checkin: e });
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>
						{activeTab != "1" ? (
							<div className="main-label  date-filter col-md-2 col-6">
								<SelectField
									label={inventory.messages.status}
									placeholder={inventory.messages.status}
									options={status}
									value={filter?.status?.name}
									onChange={(e) =>
										setFilter({
											...filter,
											status: e,
										})
									}
								/>
							</div>
						) : null}

						<div className="filter-btn col-md-4 col-6 d-flex align-items-center justify-content-start">
							<div className="apply pointer">
								<button className="btn btn-light px-3" onClick={search}>
									<img src={correctIcon} alt=" " />
									{payment.messages.apply}
								</button>
							</div>
							<div
								className="clear text-secondary pointer"
								onClick={() => {
									setFilter({
										name: "",

										checkin: "",
										page: 1,
									});
									search();
								}}
							>
								<button className="btn btn-light px-3 mx-2">
									<img src={clearIcon} alt=" " />
									{payment.messages.clear}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
