import ImagePlaceholder from "assets/images/image_placeholder.png";
import { useVisaState } from "context/visa";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { viewMember } from "services/visa";
import Locale from "translations";
import { MemberInfo } from "./member-info";

export default function ViewMember() {
	const { tokenSafaVisa } = useVisaState();
	const { onlineId, groupId } = useParams();
	const [member, setMember] = useState([]);
	const [counter, setCounter] = useState(1);
	const { visa } = Locale;
	let history = useHistory();


	useEffect(() => {
		async function fetchMember() {
			const res = await viewMember(onlineId, groupId, { token: tokenSafaVisa });
			setMember(res?.data);
		}
		fetchMember();
	}, []);
	const next = async () => {
		history.push(`/visa/view-member/${member?.next_online_id}/${groupId}`);
		const res = await viewMember(member?.next_online_id, groupId, {
			token: tokenSafaVisa,
		});
		setCounter(counter + 1);
		setMember(res.data);
	};
	const prev = async () => {
		history.push(`/visa/view-member/${member?.prev_online_id}/${groupId}`);

		const res = await viewMember(member?.prev_online_id, groupId, {
			token: tokenSafaVisa,
		});
		setCounter(counter - 1);
		setMember(res.data);
	};
	return (
		<>
			<div className=" bg-filter header-view ">
				<div className=" container-custom py-3  d-flex justify-content-between ">
					<p className="font-dark-blue">
						{visa.Group} : <span> {member?.group?.online_id}</span>{" "}
						<span> {member?.group?.name}</span>
					</p>
					<div>
						<button
							className="btn btn-danger  px-5"
							disabled={member?.prev_online_id == 0}
							onClick={prev}
						>
							{visa.previous}
						</button>
						<span className="btn btn-dark bg-dark-blue text-white mx-2 px-4 py-1">
							SEQ: {member?.arrange}
						</span>
						<button
							className="btn btn-success px-5"
							onClick={next}
							disabled={member?.next_online_id == 0}
						>
							{visa.next}
						</button>
					</div>
					<div>
						{/* history.goBack()
					/visa/view-group/45 */}
						<button
							className="btn btn-secondary px-5 "
							onClick={() => history.push(`/visa/view-group/${groupId}`)}
						>
							{visa.back}
						</button>
						<button className="btn btn-yellow  px-5 mx-2">{visa.Save}</button>
					</div>
				</div>
			</div>

			<div className="container-custom ">
				<div className="row  w-100 no-gutter m-0 ">
					<div className="col-md-2">
						<div className="photo-label bg-gray p-2 my-2 text-dark">
							{visa.NumberInGroups} : {member?.arrange}
						</div>
						<div className="member-photo m-0 w-100 ">
							<img
								className="img-fluid rounded w-100"
								src={
									member?.data?.photo ? member?.data?.photo : ImagePlaceholder
								}
								alt=""
								height="200"
							/>
							<button className="btn btn-link text-center w-100 change-photo-btn">
								<i class="fas fa-edit"></i>
								{visa.ChangePhoto}
							</button>
						</div>
					</div>

					<div className="col-md-10">
						<MemberInfo member={member?.data} />
					</div>
				</div>
			</div>
		</>
	);
}
