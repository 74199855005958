import successImg from "assets/images/wallet/walletSuccessfully.png";
import { useSBSState } from "context/global";
import { useMarketplaceState } from "context/marketplace";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function SuccessModal({
	modalsuccess = true,
	togglesuccessModal = true,
	msg,
}) {
	// const { price } = useMarketplaceState();

	const { inventory } = Locale;
	const { safaBalance, myBalance } = useSBSState();
	const { selected } = useMarketplaceState();
	const [Hotel, setHotel] = useState(selected);

	return (
		<>
			<Modal
				size="md"
				className="price-model"
				isOpen={modalsuccess}
				toggle={togglesuccessModal}
			>
				<ModalHeader className="align-items-center" toggle={togglesuccessModal}>
					<p> Group Request</p>
				</ModalHeader>
				<ModalBody className="payModal">
					<div className="container ">
						<div className="text-center my-3">
							<img className="mt-2" src={successImg} alt="" />
							<p>{msg}</p>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
