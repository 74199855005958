import React from "react";
import { Route, Redirect } from "react-router-dom";
import DashboardLayout from "layouts/DashboardLayout";
import { useSBSState } from "context/global";

// React Component
export default function PrivateRoute({ component: Component, ...props }) {
	const { isAuth } = useSBSState();

	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<DashboardLayout>
						<Component {...matchProps} />
					</DashboardLayout>
				)}
			/>
		);
	} else {
		return <Redirect to="/auth/login" />;
	}
}
