import dateExpireImage from "assets/images/datePicker.svg";
import walletImage from "assets/images/newWallat.svg";
import NumberField from "components/Form/NumberField/NumberField";
import SelectField from "components/Form/SelectField/SelectField";
import { useSBSState } from "context/global";
import { useMarketplaceDispatch, useMarketplaceState } from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import moment from 'moment';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TabPane } from "reactstrap";
import { createOnlineVisaRequest, listResidence, onlineVisaLookups } from "services/VisaOnline";
import Locale from "translations";
import CustomModal from "./components/Modal";
import Payment from "./components/Payment";
import PaymentSucess from './components/PaymentSucess';

export default function VisaSearch({ isMarketSearch }) {

	const { marketPlace, onlineVisa, inventory } = Locale;
	const [errors, setErrors] = useState({});
	const [destinationLookups, setDestinationLookups] = useState([]);
	const [residenceLookups, setResidenceLookups] = useState([]);
	const [embassiesLookups, setEmbassiesLookups] = useState([]);
	const [visaTypesLookups, setvisaTypesLookups] = useState([]);
	const [nationalitiesLookups, setNationalitiesLookups] = useState([]);
	const [transportationTypeLookups, setTransportationTypeLookups] = useState([]);
	const [isSearchDone, setIsSearchDone] = useState(false);
	const [ModalPayment, setModalPayment] = useState(false);
	const [ModalPaymentSucess, setModalPaymentSucess] = useState(false);

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [selectedResidenceIndex, setSelectedResidenceIndex] = useState(0);
	const tokenSafaVisa = localStorage.getItem("VisaToken");
	const dispatch = useMarketplaceDispatch();
	const { locale } = useSBSState();
	const history = useHistory();
	const { onlineVisaSearch } = useMarketplaceState();

	const [visaData, setVisaData] = useState({
		destination: null,
		VisaType: null,
		pax: "",
		transportation_type: null,
		nationality: null,
		embassy: null,
		expiration_days: null,
		price_per_one: 0,
	});

	const toggleModelPayment = () => {
		setModalPayment(!ModalPayment);
	};

	const toggleModelPaymentSucess = () => {
		setModalPaymentSucess(!ModalPaymentSucess);
	};

	useEffect(() => {
		const fetchDestinations = async () => {
			const response = await onlineVisaLookups({ token: tokenSafaVisa });
			if (response.status === 200) {
				const formatDestination = response?.data?.data.map((item) => ({
					id: item.country_code,
					name: item.country,
					visaTypes: item.visa_types.map((visaType) => {
						return {
							id: visaType.id,
							name: visaType.name,
							...visaType,
							residences: visaType.residences.map((residence) => {
								return {
									...residence,
									id: residence.id,
									name: residence.transportation_type,
								};
							}),
						};
					}),
				}));
				setDestinationLookups(formatDestination);
			}
		};
		fetchDestinations();
	}, []);

	useEffect(() => {
		const fetchResidence = async () => {
			const response = await listResidence();
			if (response.status === 200) {
				const formatResidence = response?.data?.data.map((item) => ({
					id: item.id,
					name: item.name,
					embassies: item.embassies,
				}));

				setResidenceLookups(formatResidence);
			}
		};
		fetchResidence();
	}, []);

	const checkFormErrors = () => {
		let SubmitError = {};
		Object.keys(visaData).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: visaData[key], },
					{ required: true }
				),
			};
		});
		setErrors(() => SubmitError);
	};

	const sumbit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	async function payOnlineVisa(paymentType) {
		const data = {
			destination_id: visaData?.destination?.id,
			residence_id: visaData?.nationality?.id,
			nationality_id: visaData?.nationality?.id,
			embassy_id: visaData?.embassy?.id,
			transportation_type: visaData?.transportation_type?.label,
			visa_type_id: visaData?.VisaType?.id,
			pax: visaData?.pax,
			currency: visaData?.VisaType?.residences[0]?.currency,
			price_per_one: visaData?.VisaType?.residences[0]?.price,
			expiration_days: visaData?.VisaType?.residences[0]?.expiration_days,
			payment_method: paymentType
		}
		const response = await createOnlineVisaRequest(data);
		if (response.status === 200) {
			history.push({ pathname: "/inventory/visa-requests" });
			dispatch({
				type: "onlineVisaSearch",
				payload: [],
			});
			toggleModelPayment();
		}
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			// send request to api to get available visa
			const handleSearch = async () => {
				dispatch({ type: "onlineVisaSearch", payload: visaData })
				if (!isSearchDone) {
					setVisaData({
						...visaData,
						expireDate: moment(new Date()).add(visaData?.expiration_days, "d"),
						totalPrice: (visaData?.pax * visaData?.price_per_one),
						currency: visaData?.VisaType?.residences[0]?.currency
					})
					setIsSearchDone(true);
				} else {
					setModalPayment(true);
				}
			}
			handleSearch();
		}
	}, [isErrorLoaded]);

	return (
		<>
			<TabPane tabId="1" className="w-100">
				<div className="col-md-10 HotelSearch m-auto pb-3">
					<div className={`row px-2 ${isMarketSearch ? " justify-content-start" : " justify-content-center"}`}>
						{/* destination */}
						<div className="col-md-10">
							<SelectField
								hasLabel={true}
								label={marketPlace.messages.destination}
								errors={errors?.destination}
								color={errors?.destination?.required ? "danger" : ""}
								options={destinationLookups}
								value={visaData?.destination?.label}
								onChange={(e) => {
									setVisaData({
										...visaData,
										destination: e,
										VisaType: null,
										transportation_type: null,
										nationality: null,
										embassy: null,
									});
									setvisaTypesLookups(e.visaTypes);
									setEmbassiesLookups([]);
									setNationalitiesLookups([]);
									setIsSearchDone(false);
									setErrors({
										...errors,
										...validate(
											{ name: "destination", value: e.id },
											{ required: true, }
										),
									});
								}}
							/>
						</div>
						{/* VisaType */}
						<div className="col-md-5 my-1">
							<div className="mybooking-input-wrapper marketplace-date">
								<SelectField
									hasLabel={true}
									label={onlineVisa.VisaType}
									errors={errors?.VisaType}
									multi={false}
									color={errors?.VisaType?.required ? "danger" : ""}
									options={visaTypesLookups}
									value={visaData?.VisaType?.label}
									onChange={(e) => {
										setVisaData({
											...visaData,
											VisaType: e,
											transportation_type: null,
											nationality: null,
											embassy: null,
										});
										setTransportationTypeLookups(e.residences);
										setIsSearchDone(false);
										setErrors({
											...errors,
											...validate(
												{ name: "VisaType", value: e.id },
												{ required: true, }
											),
										});
									}}
								/>
							</div>
						</div>

						{/* TransportationType */}
						<div className="col-md-5 my-1">
							<div className="mybooking-input-wrapper marketplace-date">
								<SelectField
									hasLabel={true}
									label={onlineVisa.TransportationType}
									errors={errors?.transportation_type}
									multi={false}
									color={errors?.transportation_type?.required ? "danger" : ""}
									value={visaData?.transportation_type?.label}
									options={transportationTypeLookups}
									onChange={(e) => {
										setVisaData({
											...visaData,
											transportation_type: e,
											nationality: null,
											embassy: null,
											expiration_days: e?.expiration_days,
											price_per_one: e?.price
										});
										setNationalitiesLookups(e?.nationalities?.map(nationality => {
											return { ...nationality, id: nationality?.country_id, name: nationality?.name }
										}))
										setIsSearchDone(false);
										setErrors({
											...errors,
											...validate(
												{ name: "transportation_type", value: e.id },
												{ required: true, }
											),
										});
									}}
								/>
							</div>
						</div>

						{/* nationality */}
						<div className="col-md-5 my-1">
							<div className="mybooking-input-wrapper marketplace-date">
								<SelectField
									label={marketPlace.messages.nationality}
									value={visaData?.nationality?.label}
									placeholder={`${marketPlace.select} ${marketPlace.messages.nationality} `}
									name="nationality"
									options={nationalitiesLookups}
									onChange={(e) => {
										setVisaData({
											...visaData,
											nationality: e,
											embassy: null,
										});
										setEmbassiesLookups(residenceLookups?.filter(residence => residence.id === e.id)[0]?.embassies);
										setErrors({
											...errors,
											...validate(
												{ name: "nationality", value: e.id },
												{ required: true }
											),
										})
									}}
									color={errors?.nationality?.required ? "danger" : ""}
									errors={errors?.nationality}
								/>
							</div>
						</div>
						{/* embassy */}
						<div className="col-md-5 my-1">
							<div className="mybooking-input-wrapper marketplace-date">
								<SelectField
									hasLabel={true}
									label={onlineVisa.Embassy}
									errors={errors?.embassy}
									multi={false}
									options={embassiesLookups}
									color={errors?.embassy?.required ? "danger" : ""}
									value={visaData?.embassy?.label}
									onChange={(e) => {
										setVisaData({ ...visaData, embassy: e });
										setErrors({
											...errors,
											...validate(
												{ name: "embassy", value: e.id },
												{ required: true, }
											),
										});
									}}
								/>
							</div>
						</div>
						{/* pax */}
						<div className="col-md-10">
							<div className="mybooking-input-wrapper marketplace-date">
								<NumberField
									hasLabel={true}
									removeArrow={true}
									placeholder={onlineVisa.paxPlaceholder}
									label={onlineVisa.Pax}
									name="pax"
									min={"1"}
									errors={errors?.pax}
									color={errors?.pax?.required ? "danger" : ""}
									value={visaData.pax}
									type={"number"}
									onChange={(e) => {
										setVisaData({
											...visaData,
											pax: e.target.value > 0 ? e.target.value : "",
											totalPrice: e.target.value * visaData.price_per_one
										});
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
											}),
										});
									}}
								/>
							</div>
						</div>
						{/* search result */}
						{isSearchDone ?
							<div className="col-md-10 d-flex justify-content-between align-items-center mt-3 mb-2 visa_search_result">
								<div className="d-flex align-items-center">
									<img src={dateExpireImage} alt="" width="28px" className="mr-3" />
									<p className="d-flex flex-column font-weight-normal txt-blue">
										{marketPlace.messages.requestExpireDate}
										<span>{moment(visaData?.expireDate).format("DD-MM-YYYY")}</span>

									</p>
								</div>
								<div className="d-flex align-items-center">
									<img src={walletImage} alt="" width="28px" className="mx-3" />
									<p className="d-flex flex-column font-weight-normal txt-blue">
										{marketPlace.messages.totalPrice}
										<span>
											{visaData?.currency} {" "}
											{visaData?.totalPrice.toFixed(2)}
										</span>
									</p>
								</div>
							</div>
							:
							null
						}
						<div className="col-md-10">
							<button className="btn w-100 bg-nxt mb-3 mt-3 px-0" type="button" onClick={sumbit}>
								<p className="d-flex align-items-center justify-content-center">
									{isSearchDone ? inventory.messages.pay : onlineVisa.search}
								</p>
							</button>
						</div>
					</div>
				</div>
			</TabPane>
			<CustomModal
				modalIsOpen={ModalPayment}
				header={onlineVisa.ChoosePayment}
				toggle={toggleModelPayment}
				size={"lg"}
				centered={true}
			>
				<Payment payOnlineVisa={payOnlineVisa} isMarketSearch={isMarketSearch} />
			</CustomModal>

			<CustomModal
				modalIsOpen={ModalPaymentSucess}
				header={onlineVisa.ChoosePayment}
				toggle={toggleModelPaymentSucess}
				size={"lg"}
				centered={true}
			>
				<PaymentSucess />
			</CustomModal>
		</>
	);
}
