import axios from "axios";
import TextField from "components/Form/TextField/TextField";
import {
	useReservationDispatch,
	useReservationState,
} from "context/reservation";
import validate from "helpers/validate";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import Accounting from "./Accounting";
import ReservationHeader from "./ReservationHeader";
import ReservationTable from "./reservationTable";

export default function Reservation(props) {
	const { reservation } = Locale;

	const state = useReservationState();
	const dispatch = useReservationDispatch();

	const history = useHistory();

	//Store
	const reservationApp = useSelector((state) => state.reservation_reducer);

	const [Travelers, setTravelers] = useState([]);

	const fetchTravelars = async () => {
		const res = await axios.get("../travelers.json");
		setTravelers(res.data.Travelers);
	};

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrLoaded] = useState(false);

	const [applicationOwner, setApplicationOwner] = useState({
		name: reservationApp.name ?? "",
		idNo: reservationApp.idNo ?? "",
		phone: reservationApp.phone ?? "",
		mobile1: reservationApp.mobile1 ?? "",
		mobile2: reservationApp.mobile2 ?? "",
		businessAddress: reservationApp.businessAddress ?? "",
	});

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: applicationOwner.name },
				{ required: true }
			),
			...validate(
				{ name: "idNo", value: applicationOwner.idNo },
				{ required: true }
			),
			...validate(
				{ name: "phone", value: applicationOwner.phone },
				{ required: true }
			),
			...validate(
				{ name: "businessAddress", value: applicationOwner.businessAddress },
				{ required: true, email: true }
			),
			...validate(
				{ name: "mobile1", value: applicationOwner.mobile1 },
				{ required: true }
			),
		});
	};

	const handleCreate = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrLoaded(true);
		} else {
			setIsErrLoaded(false);
		}
	};

	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (Travelers.length === 0) {
			fetchTravelars();
		}
	}, []);

	// Check All
	const selectItems = document.getElementsByClassName("select-item");
	function changeListner($event) {
		const isSelectorChecked = $event.target.checked;
		if (isSelectorChecked) {
			for (let item of selectItems) {
				item.checked = true;
			}
		} else {
			for (let item of selectItems) {
				item.checked = false;
			}
		}
	}

	const reservationRow = Travelers.map((traveler, i) => {
		return <ReservationTable key={i} travelers={traveler} />;
	});

	return (
		<>
			{/* <DashboardLayout /> */}
			<div className="reservation">
				{/* Header */}
				<ReservationHeader />

				<div className="reservation__inputs">
					<h1 className="head">{reservation.messages.applicationOwner}</h1>
					<div className="reservation__inputs">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group full-input">
									<TextField
										type="text"
										label={reservation.messages.fullName}
										placeholder={reservation.messages.fullNamePlaceholder}
										value={applicationOwner.name}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											setApplicationOwner({
												...applicationOwner,
												name: e.target.value,
											});
										}}
										errors={errors.name}
										color={errors?.name?.required ? "danger" : ""}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group full-input">
									<TextField
										label={reservation.messages.idNo}
										placeholder={reservation.messages.idNoPlaceholder}
										value={applicationOwner.idNo}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											setApplicationOwner({
												...applicationOwner,
												idNo: e.target.value,
											});
										}}
										errors={errors.idNo}
										color={errors?.idNo?.required ? "danger" : ""}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group full-input">
									<TextField
										label={reservation.messages.phone}
										placeholder={reservation.messages.phonePlaceholder}
										value={applicationOwner.phone}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											setApplicationOwner({
												...applicationOwner,
												phone: e.target.value
													.replace(/[^\d]/g, "")
													.replace(/[^\w\s]/gi, "")
													.replace(/^0/, ""),
											});
										}}
										errors={errors.phone}
										color={errors?.phone?.required ? "danger" : ""}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group full-input">
									<TextField
										type="email"
										label={reservation.messages.emailAddress}
										placeholder={reservation.messages.emailAddressPlaceholder}
										value={applicationOwner.businessAddress}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
											setApplicationOwner({
												...applicationOwner,
												businessAddress: e.target.value,
											});
										}}
										errors={errors.businessAddress}
										color={errors?.businessAddress?.required ? "danger" : ""}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group full-input">
									<TextField
										label={reservation.messages.mobileOne}
										placeholder={reservation.messages.mobileOnePlaceholder}
										onChange={(e) => {
											setApplicationOwner({
												...applicationOwner,
												mobile1: e.target.value,
											});
										}}
										value={applicationOwner.mobile1}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group full-input">
									<TextField
										label={reservation.messages.mobileTwo}
										placeholder={reservation.messages.mobileTwoPlaceholder}
										onChange={(e) => {
											setApplicationOwner({
												...applicationOwner,
												mobile2: e.target.value,
											});
										}}
										value={applicationOwner.mobile2}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12">
					<h2 className="head font-weight-bold px-1">
						{reservation.messages.travelers}
						<span className="text-primary">
							({reservation.messages.pax}: 30)
						</span>
					</h2>
					<div className="d-flex justify-content-between align-items-center">
						<div className="col-md-3">
							<div className="main-input-search mt-1 mb-0">
								<input
									className="form-control"
									type="text"
									placeholder={reservation.messages.searchPlaceholder}
									onChange={(e) => setSearchValue(e.target.value)}
									value={searchValue}
								/>
								<i className="fas fa-search fa-fw"></i>
							</div>
						</div>
						<button
							onClick={() => history.push("/reservation-traveler")}
							className="btn btn-success px-4 py-2"
						>
							{reservation.messages.addNew}
						</button>
					</div>
					<table striped className="custom-table w-100">
						<thead>
							<tr>
								<th>
									<div className="form-check">
										<input
											type="checkbox"
											name="traveller"
											className="form-check-input my-1 select-all"
											id="exampleCheck1"
											onChange={changeListner}
										/>
										<label
											className="form-check-label pointer"
											htmlFor="exampleCheck1"
										>
											{reservation.messages.travelerName}
										</label>
									</div>
								</th>
								<th>{reservation.messages.type}</th>
								<th>{reservation.messages.gender}</th>
								<th>{reservation.messages.nationality}</th>
								<th>{reservation.messages.passportNumber}</th>
								<th>{reservation.messages.idNumber}</th>
								<th>{reservation.messages.price}</th>
								<th>{reservation.messages.extraItem}</th>
								<th>{reservation.messages.action}</th>
							</tr>
						</thead>
						<tbody>{reservationRow}</tbody>
					</table>
				</div>

				<Accounting />

				<div className="save-buttons d-flex flex-row-reverse py-4">
					<button className="btn btn-primary px-5 mx-1" onClick={handleCreate}>
						{reservation.messages.saveChange}
					</button>
					<button className="btn btn-default mx-1 border">
						{reservation.messages.cancle}
					</button>
				</div>
			</div>
		</>
	);
}
