import Loader from "components/Loader";
import Pagination from "components/Pagination";
import { useSBSDispatch, useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory } from "react-router-dom";
import { cleanEmpty } from "services/general";
import {
	fetchHotels,
	fetchHotelsOffers,
	fetchTransports,
	guestHotels,
	guestHotelsOffers,
	searchHotelFilters,
	searchOfferHotelFilters,
} from "services/marketplace";
import Locale from "translations";
import HotelFilter from "./HotelFilter";
import ListItem from "./ListItem";
import SearchBar from "./SearchBar";

export default function List(props) {
	//Locale
	const search = props.location.search;
	const name = new URLSearchParams(search);
	const { marketPlace, messages } = Locale;
	const { searchResults, filters,  hotelFilters } =
		useMarketplaceState();
	const { locale, token_data } = useSBSState();
	const dispatchGlobal = useSBSDispatch();
	const [page, setpage] = useState(0);

	const dispatch = useMarketplaceDispatch();
	const [searchOrdering, setSearchOrdering] = useState({});

	const [allFilters, setAllFilters] = useState(false);
	const [priceRanges, setPriceRanges] = useState(null);

	// search filter when item change in the filter list get the data from the backend
	const searchFilters = async (filters, page = 0) => {
		let data = {
			search_ordering: searchOrdering,
			...filters,
			page: page,
			token: token_data?.token,
		};

		// let data = {
		// 	...filters,
		// 	page: page,
		// 	search_ordering: { ...searchOrdering, ...filters.search_ordering },
		// };
		data = cleanEmpty(data);

		const response =
			token_data && token_data?.token != null
				? await searchOfferHotelFilters(data)
				: await searchHotelFilters(data);

		dispatch({ type: "searchResults", payload: response.data });
	};
	// sort hotel list
	const sort = (by, value) => {
		const data = {
			search_ordering: {
				// ...searchOrdering,
				[by]: value,
			},
		};
		// setSearchOrdering({ ...setSearchOrdering, [by]: value });
		setAllFilters({ ...allFilters, ...data });
		searchFilters({ ...allFilters, ...data });
	};
	// get price range from search results context
	useEffect(() => {
		const localPriceRanges = searchResults?.filter_data;

		if (localPriceRanges) {
			setPriceRanges({
				min: localPriceRanges?.price?.min_price,
				max: localPriceRanges?.price?.max_price,
			});
		}
	}, [searchResults?.filter_data]);

	const history = useHistory();

	// get last result on page reload
	// TODO Refactor
	useEffect(() => {
		async function fetchData() {
			// debugger;
			const hotelData = {
				...hotelFilters,
				lang: locale,
			};


			if (searchResults === undefined) {
				if (filters === undefined ) {
					dispatch({
						type: "filter",
						payload: JSON.parse(localStorage.getItem("filters")),
					});
					
				}
				const offer = localStorage.getItem("hotelOffer");
				let response;

				let x = {};
				name.forEach(function (value, key) {
					x = { ...x, [key]: value };
				});

				if (x.hasOwnProperty("token")) {
					response = x.hasOwnProperty("offer")
						? await guestHotelsOffers(x)
						: await guestHotels(x);

					localStorage.setItem(
						"hotelFilters",
						JSON.stringify(response.data.token_data.search_payload)
					);
					
					localStorage.setItem("discount", response.data.token_data.discount);
					dispatchGlobal({
						type: "searchOffer",
						payload: response.data.token_data,
					});

					let search_payload = response.data.token_data.search_payload;

					setnight(
						moment(search_payload?.date_to).diff(
							moment(search_payload?.date_from),
							"days"
						)
					);
				} else {
					response = +offer
						? await fetchHotelsOffers(hotelData)
						: await fetchHotels(hotelData);
				}

				if (response.data.data.length > 0) {
					dispatch({ type: "searchResults", payload: response.data });
					setPriceRanges({
						min: response.data.filter_data.price.min_price,
						max: response.data.filter_data.price.max_price,
					});
				} else {
					store.addNotification({
						title: "Error!",
						message: messages.noResults + " !",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 4000,
							onScreen: true,
							pauseOnHover: true,
						},
					});

					setTimeout(() => {
						history.push("/");
					}, 4000);
				}
			} else if (searchResults.length === 0);
		}
		fetchData();
	}, [searchResults]);

	const hotelFiltersData = hotelFilters;

	const [Night, setnight] = useState(
		moment(hotelFiltersData?.date_to).diff(
			moment(hotelFiltersData?.date_from),
			"days"
		)
	);
	// go to function for pagination
	const goTo = async (e) => {
		let data = {
			search_ordering: searchOrdering,
			...allFilters,
			page: e,
			token: token_data?.token,
		};
		data = cleanEmpty(data);

		const response =
			token_data && token_data?.token != null
				? await searchOfferHotelFilters(data)
				: await searchHotelFilters(data);
		dispatch({ type: "searchResults", payload: response.data });
	};
	const [price, setPrice] = useState({
		min: priceRanges?.min,
		max: priceRanges?.max,
	});
	//
	useEffect(() => {
		if (allFilters) {
			setAllFilters({
				...allFilters,
				search_filters: {
					...allFilters?.search_filters,
					price: { min: price?.min, max: price.max },
				},
			});
			searchFilters({
				...allFilters,
				search_filters: {
					...allFilters?.search_filters,
					price: { min: price?.min, max: price.max },
				},
			});
		}
	}, [price]);

	return searchResults !== undefined ? (
		<>
			<section className="marketplace-details">
				{/* {params.includes("token") ? (
					""
				) : ( */}
				<SearchBar setnight={setnight} page={page} setpage={setpage} />
				{/* )} */}

				<div className="flight-tabs-section">
					<div className="container">
						<div className="tab-title py-3">
							<div className="row">
								<div className="col-md-3">
									<HotelFilter
										searchFilters={searchFilters}
										setAllFilters={setAllFilters}
										maxPrice={priceRanges?.max}
										minPrice={priceRanges?.min}
										setPrice={setPrice}
										price={price}
									/>
								</div>

								{/* <!--col-md-3--> */}
								<div className="col-md-9 mt-2">
									<div className="flight-invice-tab-wrraper">
										<div className="b2c-invoice-wrapper">
											<div className="container">
												<div className="select-flight">
													{/* <div className="info-box">
													<div className="d-flex align-items-center p-2">
														<i className="fas fa-info-circle fa-2x"></i>
														<h6 className="px-2">
															Hotels To Stay Are Unavailable For Your Dates On
															Our Site.
															<p className="font-weight-normal">
																If You're Flexible, Check Out Some Of These
																Alternative Dates;
															</p>
														</h6>
													</div>

													<div className="date-info">
														<div className="active">
															<h6>3 May - 10 May</h6>
															<p>From EGP 232SAR / Night</p>
														</div>
														<div>
															<h6>4 May - 11 May</h6>
															<p>From EGP 232SAR / Night</p>
														</div>
														<div>
															<h6>5 May - 12 May</h6>
															<p>From EGP 232SAR / Night</p>
														</div>
														<div>
															<h6>6 May - 13 May</h6>
															<p>From EGP 232SAR / Night</p>
														</div>
													</div>
												</div> */}
												</div>
												{/* <!--select-flight--> */}
												
												<div className="select-flight">
													<div className="sorting d-flex flex-column align-items-start m-0 ">
														<div className="d-flex justify-content-between w-100">
															<div className="select-flight-title hotel-found mb-1 make-text-hight">
																<h6 className="text-uppercase font-weight-bold  terms-color m-0 h4">
																	{marketPlace.messages.found}{" "}
																	{searchResults?.meta?.total}{" "}
																	{/* 	{searchBy === "hotel"
																	? marketPlace.messages.hotelFound
																	: marketPlace.messages.transporters} */}
																	{marketPlace.messages.hotelFound}
																</h6>
																<p className="text-uppercase">
																	{marketPlace.messages.mathingSearch}
																</p>
															</div>
															{searchResults?.data?.length > 0 &&
															token_data &&
															token_data?.token != null ? (
																<Link
																	to={`market-view-map?token=${token_data?.token}`}
																	className="show-map-btn"
																>
																	<span>{marketPlace.showOnMap}</span>
																</Link>
															) : (
																<Link
																	to="market-view-map"
																	className="show-map-btn"
																>
																	<span>{marketPlace.showOnMap}</span>
																</Link>
															)}
														</div>
														<div className="sort-filter mx-2 my-1">
															<span>{marketPlace.messages.sortBy} :</span>
															<button
																onClick={() => {
																	setSearchOrdering({
																		...searchOrdering,
																		price:
																			searchOrdering?.price === "asc"
																				? "desc"
																				: "asc",
																	});
																	sort(
																		"price",
																		searchOrdering?.price === "asc"
																			? "desc"
																			: "asc"
																	);
																}}
																className="toggle-sort"
															>
																<i
																	className={`fa fa-sort-amount-${
																		searchOrdering?.price === "asc"
																			? "down"
																			: "up"
																	} mx-1`}
																></i>
																{marketPlace.messages.price}
															</button>

															<button
																onClick={() => {
																	setSearchOrdering({
																		...searchOrdering,
																		rating:
																			searchOrdering?.rating === "asc"
																				? "desc"
																				: "asc",
																	});
																	sort(
																		"rating",
																		searchOrdering?.rating === "asc"
																			? "desc"
																			: "asc"
																	);
																}}
																className="toggle-sort"
															>
																<i
																	className={`fa fa-sort-amount-${
																		searchOrdering?.rating === "asc"
																			? "down"
																			: "up"
																	} mx-1`}
																></i>
																{marketPlace.messages.rating}
															</button>
															<button
																onClick={() => {
																	setSearchOrdering({
																		...searchOrdering,
																		name:
																			searchOrdering.name === "asc"
																				? "desc"
																				: "asc",
																	});
																	sort(
																		"name",
																		searchOrdering.name === "asc"
																			? "desc"
																			: "asc"
																	);
																}}
																className="toggle-sort"
															>
																<i
																	className={`fa fa-sort-amount-${
																		searchOrdering.name === "asc"
																			? "down"
																			: "up"
																	} mx-1`}
																></i>
																{marketPlace.messages.name}
															</button>
														</div>
													</div>
												</div>

												{searchResults.data.length === 0 ? (
													<p className="text-center mt-5">
														{marketPlace.noResults}
													</p>
												) : (
													searchResults.data.map((item, i) => {
														return (
															<ListItem
																key={i}
																item={item}
																searchBy="hotel"
																Night={Night}
															/>
														);
													})
												)}

												<Pagination
													info={searchResults.meta}
													goTo={goTo}
													items={10}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	) : (
		<Loader />
	);
}
