import TextField from "components/Form/TextField/TextField";
import { useMarketplaceState } from 'context/marketplace';
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import Locale from "translations";
import filterReset from "../../../../assets/images/filterReset.svg";

function HotelFilter({
	searchFilters,
	setAllFilters,
	maxPrice,
	minPrice,
	setPrice,
	price,
}) {
	const { filters} = useMarketplaceState();
	const { marketPlace, payment } = Locale;
	const [hotelFilters, setHotelFilters] = useState(null);
	const [rating, setRating] = useState([]);
	const [facilities, setFacilities] = useState([]);
	//TODO change when meals lookup ready from backend
	const [mealsLookup, setMealsLookup] = useState(["All Inclusive" ,"Half Board","Breakfast", "Room Only"]);

	// const [boarding, setBoarding] = useState([]);
	// const [distance, setDistance] = useState([]);
	const [mealsOptions, setMealsOptions] = useState([]);
	// const [price, setPrice] = useState({min:minPrice,max:maxPrice});
	const [isRefundable, setIsRefundable] = useState(false);
	const [isOpenstarRate, setIsOpenstarRate] = useState(true);
	const [isOpenPropretyType, setIsOpenPropretyType] = useState(true);
	const [isOpenPriceRange, setIsOpenPriceRange] = useState(true);
	const [isOpenHotelFacilities, setIsOpenHotelFacilities] = useState(true);
	const [isOpenMealOptions, setIsOpenMealOptions] = useState(true);
	const [isOpenSearch, setIsOpenSearch] = useState(true);
	const [isOpenCancellationPolicy, setIsOpenCancellationPolicy] = useState(true);
	const [isOpenPointsOfInterset, setIsOpenPointsOfInterset] = useState(true);

	// filter by stars / rating 
	const filterByRating = (e) => {
		const checked = e.target.checked;
		const value = +e.target.value;
		let data;

		if (checked) {
			setRating([...rating, value]);
			data = {
				rating: [...rating, value],
			};
		} else {
			setRating(rating.filter((item) => item !== value));
			data = {
				rating: rating.filter((item) => item !== value),
			};
		}

		const search_filters = { search_filters: { ...hotelFilters, ...data } };
		searchFilters(search_filters);
		setAllFilters(search_filters);
		setHotelFilters({ ...hotelFilters, rating: data.rating });
	};
	// const filterByPrice = (value) => {
	// 	if (value.min < 0) value.min = 0;
	// 	if (value.max > maxPrice) value.max = maxPrice;
	// 	const search_filters = {
	// 		search_filters: { ...hotelFilters, price: value },
	// 	};
	// 	searchFilters(search_filters);
	// 	setAllFilters(search_filters);
	// 	setHotelFilters({ ...hotelFilters, price: value });
	// };
		
		//setPrice({...price,[type]:+value})

	// filter by price range
	const filterByPrice = (type, value) => {
		if (price.min < 0) price.min = 0;
		if (price.max > maxPrice) price.max = +maxPrice;
		const search_filters = {
			search_filters: { ...hotelFilters, price },
		};
		searchFilters(search_filters);
		setAllFilters(search_filters);
		setHotelFilters({ ...hotelFilters, price });
	};
	// filter by hotel facilities
	
	const filterByFacilities = (e) => {
		const checked = e.target.checked;
		const value = e.target.value;
		let data;
		if (checked) {
			setFacilities([...facilities, value]);
			data = {
				facilities: [...facilities, value],
			};
		} else {
			setFacilities(facilities.filter((item) => item !== value));
			data = {
				facilities: facilities.filter((item) => item !== value),
			};
		}

		const search_filters = { search_filters: { ...hotelFilters, ...data } };
		searchFilters(search_filters);
		setAllFilters(search_filters);
		setHotelFilters({ ...hotelFilters, facilities: data.facilities });
	};
	// filter by meals change when api is ready
	const filterByMeals = (e) => {
		const checked = e.target.checked;
		const value = e.target.value;
		let data;
		if (checked) {
			setMealsOptions([...mealsOptions, value]);
			data = {
				roomMealTypes: [...mealsOptions, value],
			};
		} else {
			setMealsOptions(mealsOptions.filter((item) => item !== value));
			data = {
				roomMealTypes: mealsOptions.filter((item) => item !== value),
			};
		}

		const search_filters = { search_filters: { ...hotelFilters, ...data } };
		searchFilters(search_filters);
		setAllFilters(search_filters);
		setHotelFilters({ ...hotelFilters, roomMealTypes: data.roomMealTypes });
	};
	// filter by policy change when api is ready
	const filterByPolicy = (e) => {
		const checked = e.target.checked;
		// const value = e.target.value;
		let data;
		if (checked) {
			setIsRefundable(true);
			data = {
				isRefundable: true,
			};
		} else {
			setIsRefundable(false);
			data = {
				isRefundable: null,
			};
		}

		const search_filters = { search_filters: { ...hotelFilters, ...data } };
		searchFilters(search_filters);
		setAllFilters(search_filters);
		setHotelFilters({ ...hotelFilters, isRefundable: data.isRefundable });
	};
	// filter by point of interest
	const filterByPointOfInterset = (e) => {
		// const checked = e.target.checked;
		// const value = e.target.value;
		// if(checked) {
		// 	// add 
		// }
	}

	// const filterByBoarding = (e) => {
	// 	const checked = e.target.checked;
	// 	const value = e.target.value;
	// 	let data;
	// 	if (checked) {
	// 		setBoarding([...boarding, value]);
	// 		data = {
	// 			boarding: [...boarding, value],
	// 		};
	// 	} else {
	// 		setBoarding(boarding.filter((item) => item !== value));
	// 		data = {
	// 			boarding: boarding.filter((item) => item !== value),
	// 		};
	// 	}

	// 	const search_filters = { search_filters: { ...hotelFilters, ...data } };
	// 	searchFilters(search_filters);
	// 	setAllFilters(search_filters);
	// 	setHotelFilters({ ...hotelFilters, boarding: data.boarding });
	// };

	// const filterByPayment = (e) => {
	// 	const checked = e.target.checked;
	// 	const value = e.target.value;
	// 	let search_filters;
	// 	if (checked) {
	// 		setOptions([...options, value]);
	// 		search_filters = {
	// 			search_filters: { ...hotelFilters, [value]: checked },
	// 		};
	// 	} else {
	// 		setOptions(options.filter((item) => item !== value));
	// 		search_filters = {
	// 			search_filters: { ...hotelFilters, [value]: null },
	// 		};
	// 	}

	// 	searchFilters(search_filters);
	// 	setAllFilters(search_filters);
	// 	setHotelFilters({ ...hotelFilters, [value]: checked });
	// };

	// const filterByDistance = (e) => {
	// 	const checked = e.target.checked;
	// 	const value = +e.target.value;
	// 	let max_distance;
	// 	if (checked) {
	// 		setDistance([...distance, value]);
	// 		max_distance = [...distance, value];
	// 	} else {
	// 		setDistance(distance.filter((item) => item !== value));
	// 		max_distance = distance.filter((item) => item !== value);
	// 	}
	// 	const search_filters = {
	// 		search_filters: {
	// 			...hotelFilters,
	// 			max_distance: max_distance.length > 0 ? Math.max(...max_distance) : 0,
	// 		},
	// 	};
	// 	searchFilters(search_filters);
	// 	setAllFilters(search_filters);
	// 	setHotelFilters({
	// 		...hotelFilters,
	// 		max_distance: max_distance.length > 0 ? Math.max(...max_distance) : 0,
	// 	});
	// };

	const reset = () => {
		setHotelFilters(null);
		setRating([]);
		setPrice({ min: minPrice, max: maxPrice });
		setFacilities([]);
		setAllFilters({});
		// setDistance([]);
		// setOptions([]);
		searchFilters({});
	};
	// set price range when min or max price changes
	useEffect(() => {
		setPrice({ min: minPrice, max: maxPrice });
	}, [minPrice, maxPrice]);

	useEffect(() => {
		if (!price && minPrice && maxPrice) {
			setPrice({ min: minPrice, max: maxPrice });
		}
	}, [price, minPrice, maxPrice]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center mt-2">
				<div className="filter-head terms-color text-uppercase ">
					<h6 className="font-weight-bold h6 hotel-found">
						{marketPlace.messages.filterBy}:
					</h6>
				</div>
				<button
					className="btn bg-white clear-filter px-3 mx-2"
					onClick={() => reset()}
				>
					<img src={filterReset} alt="" className="mx-2" />
					{payment.messages.clear}
				</button>
			</div>
			<div className="">
				{/* <!--fliter-filter-title--> */}
				<div className="bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold  terms-color"
						onClick={() => setIsOpenSearch(!isOpenSearch)}
					>
						{marketPlace.messages.search}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenSearch}>
						<div className="col-md-12 mt-3">
							<TextField
								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
								value={hotelFilters?.name ? hotelFilters?.name : ""}
								onChange={(e) => {
									const search_filters = {
										search_filters: { ...hotelFilters, name: e.target.value },
									};
									searchFilters(search_filters);
									setHotelFilters(search_filters.search_filters);
									setAllFilters(search_filters);
								}}
							/>{" "}
						</div>
					</Collapse>
				</div>
				{/* price range */}
				<div className="bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold  terms-color"
						onClick={() => setIsOpenPriceRange(!isOpenPriceRange)}
					>
						{marketPlace.messages.priceRange}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenPriceRange}>
						{/* <div className=" mt-3 d-flex justify-content-between input-range-width ">
							<span className="bg-nxt p-1 rounded">
								{marketPlace.min}:{price?.min}
							</span>
							<span className=""> - </span>
							<span className="bg-nxt p-1 rounded">
								{marketPlace.max}:{price?.max}
							</span>
						</div> */}

						{/* <CustomInputRange
							max={maxPrice}
							min={minPrice}
							value={price}
							onChange={(value) => setPrice(value)}
							onChangeComplete={filterByPrice}
						/> */}
						<div className="d-flex  align-items-center mt-3 filter-price-range">
							<div>
								<TextField
									hasLabel={false}
									type="number"
									min={1}
									size="small"
									sx={{ width: "80px" }}
									value={
										price?.min?.toString()[0] === "0"
											? price?.min?.toString().slice(1)
											: price?.min
									}
									onChange={(e) => {
										setPrice({
											...price,
											min:
												e.target.value.charAt(0) === "0"
													? +e.target.value.slice(1)
													: +e.target.value,
										});
										filterByPrice(
											"min",
											e.target.value.charAt(0) === "0"
												? +e.target.value.slice(1)
												: +e.target.value
										);
									}}
									autoComplete="off"
									extraTextPosition={"append"}
									extraText={filters?.currency_code?.label}
								/>
							</div>
							<span className="mx-2 text-uppercase">{marketPlace.to}</span>
							<div>
								<TextField
									hasLabel={false}
									type="number"
									size="small"
									min={1}
									sx={{ width: "80px" }}
									value={price?.max?.toString()[0] === "0"
										? price?.max?.toString().slice(1)
										: price?.max}

									onChange={(e) => {
										setPrice({
											...price, max: e.target.value.charAt(0) === "0"
												? +e.target.value.slice(1)
												: +e.target.value,
										});
										filterByPrice("max", e.target.value.charAt(0) === "0"
											? +e.target.value.slice(1)
											: +e.target.value);
									}}
									autoComplete="off"
									extraTextPosition={"append"}
									extraText={filters?.currency_code?.label}
								/>
							</div>
						</div>
					</Collapse>
				</div>
				{/* proprety type */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color "
						onClick={() => setIsOpenPropretyType(!isOpenPropretyType)}
					>
						{marketPlace.propertyType}

						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenPropretyType}>
						<ul className="list-unstyled mt-3">
							{[ "Hotel", "Hostel", "Apartment" ].map((type) => {
								return (
									<li key={type}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={type}
													name="classification[]"
													type="checkbox"
													// value={type?.id}
													// onChange={filterByRating}
													// checked={rating.includes(type.id) ? true : false}
												/>
												<label
													className="custom-control-label"
													htmlFor={type}
												>
													{type}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</Collapse>
				</div>
				{/* star rate */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color "
						onClick={() => setIsOpenstarRate(!isOpenstarRate)}
					>
						{marketPlace.messages.starRate}

						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenstarRate}>
						<ul className="list-unstyled mt-3">
							{[5, 4, 3, 2, 1].map((el) => {
								return (
									<li key={el}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={`classification-0${el}`}
													name="classification[]"
													type="checkbox"
													value={el}
													onChange={filterByRating}
													checked={rating.includes(el) ? true : false}
												/>
												<label
													className="custom-control-label"
													htmlFor={`classification-0${el}`}
												>
													{[1, 2, 3, 4, 5].map((_, i) => {
														return el <= i ? (
															<i key={i} className="far fa-star"></i>
														) : (
															<i key={i} className="fas fa-star"></i>
														);
													})}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</Collapse>
				</div>

				{/* meals options */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color "
						onClick={() => setIsOpenMealOptions(!isOpenMealOptions)}
					>
						{marketPlace.mealOptions}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenMealOptions}>
						<ul className="list-unstyled mt-3">
							{mealsLookup.map((meal) => {
								return (
									<li key={meal}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={meal}
													name="classification[]"
													type="checkbox"
													// value={meal?.id}
													// onChange={filterByRating}
													// checked={rating.includes(meal.id) ? true : false}
												/>
												<label
													className="custom-control-label"
													htmlFor={meal}
												>
													{meal}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</Collapse>
				</div>

				{/* Cancellation Policy */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color "
						onClick={() => setIsOpenCancellationPolicy(!isOpenCancellationPolicy)}
					>
						{marketPlace.messages.cancellationPolicy}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenCancellationPolicy}>
						<ul className="list-unstyled mt-3">
							<li>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox ">
										<input
											className="custom-control-input permChecks"
											id={`classification-0100000`}
											name="classification[]"
											type="checkbox"
											// value={isRefundable}
										// onChange={filterByRating}
										// checked={rating.includes(meal.id) ? true : false}
										/>
										<label
											className="custom-control-label"
											htmlFor={`classification-0100000`}
										>
											{marketPlace.messages.isRefundable}
										</label>
									</div>
								</div>
							</li>

						</ul>
					</Collapse>
				</div>

				{/* Hotel Facilities  */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color"
						onClick={() => setIsOpenHotelFacilities(!isOpenHotelFacilities)}
					>
						{marketPlace.messages.hotelFacilities}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenHotelFacilities}>
						<div className='mt-3'>
							<TextField
								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
							// value={""}
							// onChange={(e) => {}}
							/>
						</div>
						<ul className="list-unstyled my-3">
							{["internet", "restaurant", "gym", "spa"].map((facility) => {
								return (
									<li key={facility}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={facility}
													name="classification[]"
													type="checkbox"
													value={facility}
													onChange={filterByFacilities}
													checked={facilities.includes(facility) ? true : false}
												/>
												<label
													className="custom-control-label"
													htmlFor={facility}
												>
													{marketPlace.messages[facility]}
												</label>
											</div>
											{/* <!--custom-checkbox--> */}
										</div>
									</li>
								);
							})}
						</ul>
						<span className='filter-showall'>{marketPlace.showAll}</span>
					</Collapse>
				</div>

				{/* point of interset  */}
				<div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color"
						onClick={() => setIsOpenPointsOfInterset(!isOpenPointsOfInterset)}
					>
						{marketPlace.pointsOfInterest}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenPointsOfInterset}>
						<div className='mt-3'>
							<TextField
								type="text"
								hasLabel={false}
								placeholder={marketPlace.messages.search}
							// value={""}
							// onChange={(e) => {}}
							/>
						</div>
						<ul className="list-unstyled my-3">
							{["Dubai Dolphinarium", "Al Fahidi", "Dubai Mall", "Dubai Marina"].map((pointsOfInterest) => {
								return (
									<li key={pointsOfInterest}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={pointsOfInterest}
													name="classification[]"
													type="checkbox"
													value={pointsOfInterest}
												// onChange={filterByFacilities}
												// checked={facilities.includes(pointsOfInterest) ? true : false}
												/>
												<label
													className="custom-control-label"
													htmlFor={pointsOfInterest}
												>
													{pointsOfInterest}
												</label>
											</div>
											{/* <!--custom-checkbox--> */}
										</div>
									</li>
								);
							})}
						</ul>
						<span className='filter-showall'>{marketPlace.showAll}</span>
					</Collapse>
				</div>

				{/* <div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color"
						onClick={() => setIsOpenMealOptions(!isOpenMealOptions)}
					>
						{marketPlace.messages.mealOptions}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenMealOptions}>
						<ul className="list-unstyled mt-3">
							{[
								{ id: "3", name: "All Inclusive" },
								{ id: "2", name: "Half board" },
								{ id: "1", name: "Breakfast" },
								{ id: "0", name: "Room Only" },
							].map((option) => {
								return (
									<li key={option.name}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={option.id}
													type="checkbox"
													value={option.id}
													onChange={filterByMeals}
													checked={
														mealsOptions.includes(option.id) ? true : false
													}
												/>
												<label
													className="custom-control-label"
													htmlFor={option.id}
												>
													{marketPlace.messages[option.name]}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</Collapse>
				</div> */}

				{/* <div className="airlines bg-white p-3 rounded mt-2">
					<h6
						className="font-weight-bold terms-color"
						onClick={() =>
							setIsOpenCancellationPolicy(!isOpenCancellationPolicy)
						}
					>
						{marketPlace.messages.cancellationPolicy}
						<span className="float-right">
							<i className="fa fa-angle-down"></i>
						</span>
					</h6>
					<Collapse isOpen={isOpenCancellationPolicy}>
						<ul className="list-unstyled mt-3">
							{["isRefundable"].map((option) => {
								return (
									<li key={option}>
										<div className="airlines-checkbox">
											<div className="custom-control custom-checkbox ">
												<input
													className="custom-control-input permChecks"
													id={option}
													type="checkbox"
													value={option}
													onChange={filterByPolicy}
													checked={isRefundable}
												/>
												<label
													className="custom-control-label"
													htmlFor={option}
												>
													{marketPlace.messages[option]}
												</label>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</Collapse>
				</div> */}

				{/* <div className="airlines">
				<h6 className="font-weight-bold">
					{marketPlace.messages.distanceFromHaram}
				</h6>
				<ul className="list-unstyled">
					{[1000, 2000, 3000].map((dist) => {
						return (
							<li key={`d-${dist}`}>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox ">
										<input
											className="custom-control-input permChecks"
											id={dist}
											name="classification[]"
											type="checkbox"
											value={dist}
											onChange={filterByDistance}
											checked={distance.includes(dist) ? true : false}
										/>
										<label className="custom-control-label" htmlFor={dist}>
											{marketPlace.messages.lessThan} {dist.toString()[0]}
											{marketPlace.messages.km}
										</label>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div> */}

				{/* <div className="airlines">
				<h6 className="font-weight-bold">{marketPlace.messages.roomOffers}</h6>
				<ul className="list-unstyled">
					{["full board", "half board", "room only"].map((meal) => {
						return (
							<li key={meal.replace(" ", "-")}>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox ">
										<input
											className="custom-control-input permChecks"
											id={meal.replace(" ", "-")}
											name="classification[]"
											type="checkbox"
											value={meal}
											onChange={filterByBoarding}
											checked={boarding.includes(meal) ? true : false}
										/>
										<label
											className="custom-control-label"
											htmlFor={meal.replace(" ", "-")}
										>
											{
												marketPlace.messages[
													meal.split(" ")[0] +
														meal.split(" ")[1].charAt(0).toUpperCase() +
														meal.split(" ")[1].slice(1)
												]
											}
										</label>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div> */}

				{/* <div className="airlines">
				<h6 className="font-weight-bold">
					{marketPlace.messages.cancellationPolicy}
				</h6>
				<ul className="list-unstyled">
					{["has_free_cancellation"].map((option, i) => {
						return (
							<li key={option}>
								<div className="airlines-checkbox">
									<div className="custom-control custom-checkbox ">
										<input
											className="custom-control-input permChecks"
											id={option}
											name="classification[]"
											type="checkbox"
											value={option}
											onChange={filterByPayment}
											checked={options.includes(option) ? true : false}
										/>
										<label className="custom-control-label" htmlFor={option}>
											{marketPlace.messages.freeCancellation}
										</label>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div> */}
			</div>
		</>
	);
}

export default HotelFilter;
