import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
import safaLogoMinimal from "assets/images/dashboard/safaLogoSmall.svg";
  import newlogo from "assets/images/newlogo2.png";

import { useSBSState } from 'context/global';
import ShowForPermission from "helpers/showForPermission";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import Locale from "translations";
import "../../scss/modules/Dashboard/_sidenav.scss";
import {
	ActivityLogIcon,
	Arrow,
	HotelFIightsIcon,
	MyBookingIcon,
	MyTeamIcon,
	SafaOfflineIcon,
	StatisticsIcon,
	TripsManagementIcon,
	WalletIcon,
} from "./icons";

export default function SideNav({ show, setShow }) {
	const { marketPlace,dashboard } = Locale;
	const history = useHistory();
	const { pathname } = useLocation();
  const { locale } = useSBSState();

	let dashboardRoutes = [
		{
			id: 2,
			route: "/market-search",
			icon: <HotelFIightsIcon />,
			text: `${dashboard.messages.hotelsFlights}`,
			ring: false,
			disabled:false
		},
		{
			id: 4,
			route: "/inventory",
			icon: <MyBookingIcon />,
			text: `${dashboard.messages.myBookings}`,
			ring: false,
			disabled:false
		},
		{
			id: 1,
			route: "/products-builder/products",
			icon: <TripsManagementIcon />,
			text: `${dashboard.messages.tripsManagement}`,
			ring: false,
			disabled:true
		},
		{
			id: 6,
			route: "/visa",
			icon: <SafaOfflineIcon />,
			text: `${dashboard.messages.SafaOffline}`,
			ring: false,
			disabled:false
		},
		{
			id: 3,
			route: "/team-managementlist",
			icon: <MyTeamIcon />,
			text: `${dashboard.messages.MyTeam}`,
			ring: false,
			disabled:false
		},
		{
			id: 10,
			route: "/wallet",
			icon: <WalletIcon />,
			text: `${dashboard.messages.myWallet}`,
			ring: true,
			disabled:false
		},
		{
			id: 11,
			route: "/activity-logs",
			icon: <ActivityLogIcon />,
			text: `${dashboard.messages.activityLogs}`,
			ring: true,
			disabled:false
		},
		{
			id: 11,
			route: "/statistics",
			icon: <StatisticsIcon />,
			text: `${dashboard.messages.statistics}`,
			ring: true,
			disabled:false
		},
	];
	const dashboardList = dashboardRoutes.map((dashboard) => {
		if (dashboard.route == "/wallet") {
			return (
				<ShowForPermission
					permission={["show-credit-wallet", "manage-wallets"]}
				>
					<li className={`sidenav_item  ${dashboard.disabled ? "disabled-li":""} `} key={dashboard.id}>
						<NavLink
							id="sidenav-wallet"
							onClick={() =>
								pathname !== "/" || show ? setShow(false) : false
							}
							to={dashboard?.route}
							value={dashboard?.id}
							className="sidenav_link"
							activeClassName="active"
						>
							<span className="sidenav_icon">{dashboard.icon}</span>
							<div className="sidenav_dropdown">
								<p className="dash-text">{dashboard?.text}</p>
							</div>
							<span className="sidenav_arrow">
								<Arrow />
							</span>
						</NavLink>
					</li>
				</ShowForPermission>
			);
		} else if (dashboard.route == "/market-search") {
			return (
				<ShowForPermission
					permission={[
						"hotel-vacation",
						"hotel-umrah",
						"request-transportations",
						"request-ground-work",
						"request-visa",
					]}
				>
					<li className={`sidenav_item  ${dashboard.disabled ? "disabled-li":""} `} key={dashboard.id}>
						<NavLink
							id='sidenav-market-search'
							key={dashboard.id}
							onClick={() =>
								pathname !== "/" || show ? setShow(false) : false
							}
							to={dashboard?.route}
							value={dashboard?.id}
							className="sidenav_link"
							activeClassName="active"
						>
							<span className="sidenav_icon">{dashboard.icon}</span>
							<div className="sidenav_dropdown">
								<p className="dash-text">{dashboard?.text}</p>
							</div>
							<span className="sidenav_arrow">
								<Arrow />
							</span>
						</NavLink>
					</li>
				</ShowForPermission>
			);
		} else if (dashboard.route == "/inventory") {
			return (
				<ShowForPermission
					permission={[
						"manage-hotels",
						"manage-transportations",
						"manage-ground-work",
						"manage-visa-requests",
					]}
				>
					<li className="sidenav_item" key={dashboard.id}>
						<NavLink
							id='sidenav-mybooking'
							key={dashboard.id}
							onClick={() =>
								pathname !== "/" || show ? setShow(false) : false
							}
							to={dashboard?.route}
							value={dashboard?.id}
							className="sidenav_link"
							activeClassName="active"
						>
							<span className="sidenav_icon">{dashboard.icon}</span>
							<div className="sidenav_dropdown">
								<p className="dash-text">{dashboard?.text}</p>
							</div>
							<span className="sidenav_arrow">
								<Arrow />
							</span>
						</NavLink>
					</li>
				</ShowForPermission>
			);
		} else if (dashboard.route == "/team-managementlist") {
			return (
				<ShowForPermission permission={["manage-team"]}>
					<li className="sidenav_item" key={dashboard.id}>
						<NavLink
							id='sidenav-myteam'
							onClick={() =>
								pathname !== "/" || show ? setShow(false) : false
							}
							to={dashboard?.route}
							value={dashboard?.id}
							className="sidenav_link"
							activeClassName="active"
						>
							<span className="sidenav_icon">{dashboard.icon}</span>
							<div className="sidenav_dropdown">
								<p className="dash-text">{dashboard?.text}</p>
							</div>
							<span className="sidenav_arrow">
								<Arrow />
							</span>
						</NavLink>
					</li>
				</ShowForPermission>
			);
		} else if (dashboard.route == "/visa") {
			return (
				<ShowForPermission permission={["manage-safa-offline"]}>
					<li className="sidenav_item" key={dashboard.id}>
						<NavLink
							id='sidenav-safaOffline'
							onClick={() =>
								pathname !== "/" || show ? setShow(false) : false
							}
							to={dashboard?.route}
							value={dashboard?.id}
							className="sidenav_link"
							activeClassName="active"
						>
							<span className="sidenav_icon">{dashboard.icon}</span>
							<div className="sidenav_dropdown">
								<p className="dash-text">{dashboard?.text}</p>
							</div>
							<span className="sidenav_arrow">
								<Arrow />
							</span>
						</NavLink>
					</li>
				</ShowForPermission>
			);
		} else if (dashboard.route == "/products-builder/products") {
			return (
				<ShowForPermission permission={["manage-trips"]}>
					<li className={`sidenav_item  ${dashboard.disabled ? "disabled-li":""} `} key={dashboard.id}>
						<a
							onClick={() =>
								pathname !== "/" || show ? setShow(false) : false
							}
							// to={dashboard?.route}
							value={dashboard?.id}
							className="sidenav_link"
							activeClassName="active"
							disabled
							href={()=>false}
						>
														{dashboard.disabled ?	<span className="Soon-span">{marketPlace.Soon}!</span>:null}

							<span className="sidenav_icon">{dashboard.icon}</span>
							<div className="sidenav_dropdown">
								<p className="dash-text">{dashboard?.text}</p>
							</div>
							<span className="sidenav_arrow">
								<Arrow />
							</span>
						</a>
					</li>
				</ShowForPermission>
			);
		} else if (dashboard.route == "/activity-logs") {
			return (
				<ShowForPermission permission={["manage-trips"]}>
					<li className={`sidenav_item  ${dashboard.disabled ? "disabled-li":""} `} key={dashboard.id}>
					<NavLink
							id='sidenav-activity-log'
							onClick={() =>
								pathname !== "/" || show ? setShow(false) : false
							}
							to={dashboard?.route}
							value={dashboard?.id}
							className="sidenav_link"
							activeClassName="active"
						>
						{dashboard.disabled ?	<span className="Soon-span">{marketPlace.Soon}!</span>:null}

							<span className="sidenav_icon">{dashboard?.icon}</span>
							<div className="sidenav_dropdown">
								<p className="dash-text">{dashboard?.text}</p>
							</div>
							<span className="sidenav_arrow">
								<Arrow />
							</span>
						</NavLink>
					</li>
				</ShowForPermission>
			);
		} else if (dashboard.route == "/statistics") {
			return (
				<ShowForPermission permission={["manage-trips"]}>
					<li className="sidenav_item" key={dashboard.id}>
						<NavLink
							id='sidenav-statistics'
							onClick={() =>
								pathname !== "/" || show ? setShow(false) : false
							}
							to={dashboard?.route}
							value={dashboard?.id}
							className="sidenav_link"
							activeClassName="active"
						>
							<span className="sidenav_icon">{dashboard?.icon}</span>
							<div className="sidenav_dropdown">
								<p className="dash-text">{dashboard?.text}</p>
							</div>
							<span className="sidenav_arrow">
								<Arrow />
							</span>
						</NavLink>
					</li>
				</ShowForPermission>
			);
		} else {
			return (
				<li className="sidenav_item" key={dashboard.id}>
					<Link
						onClick={() => (pathname !== "/" || show ? setShow(false) : false)}
						to={dashboard?.route}
						value={dashboard?.id}
						className="sidenav_link"
						activeClassName="active"
					>
						<span className="sidenav_icon">
							<ActivityLogIcon />
						</span>
						<div className="sidenav_dropdown">
							<p className="dash-text">{dashboard?.text}</p>
						</div>
						<span className="sidenav_arrow">
							<Arrow />
						</span>
					</Link>
				</li>
			);
		}
	});

	return (
		<nav id="sideNav" className={show ? "opend" : "closed"}>
			<Link
				className={`navbar-brand ${show ? "w-100 m-0" : "m-0"} mt-3`}
				onClick={() => {
					show && setShow(false);
					history.push("/market-search");
				}}
			>
				<span className={`navbar-brand-icon`}>
					<img
						className={show ? "img-fluid-logo" : "open-logo w-100"}
						/* src={show ? SafaLogo : safaLogoMinimal} */ src={newlogo}
						alt="Logo"
					/>
				</span>
			</Link>
			{/* nav list */}
			<ul className="sidenav_list">
				<h1 className={`sidenav_header ${locale === "en" ? "" : "mr-7"}`}>
					{dashboard.messages.menu}
				</h1>
				{dashboardList}
			</ul>
		</nav>
	);
}
