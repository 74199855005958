import clearIcon from "assets/images/wallet/clearIcon.svg";
import correctIcon from "assets/images/wallet/correctIcon.svg";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSDispatch } from "context/global";
import { useVisaDispatch } from "context/visa";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { cleanEmpty } from "services/general";
import {
	ActiveMofa,
	FetchMofaNumber,
	FetchTableMofa,
	FetchWayMofa,
	getVisaNumber,
	printVisaNumber,
	searchMember,
	SendEup,
	SendHayaa,
	viewGroup,
} from "services/visa";
import Locale from "translations";
import { GroupCard } from "./group-card";
import TableViewGroupContent from "./tableViewGroupContent";

export default function ViewGroup() {
	const dispatch = useVisaDispatch();
	const dispatchSBS = useSBSDispatch();
	//const { loginData } = useVisaState();
	const loginData = JSON.parse(localStorage.getItem("loginData"));
	const { visa } = Locale;
	const [modalMofa, setModalMofa] = useState(false);
	const toggleModalMofa = () => setModalMofa(!modalMofa);

	const [modalActiveMofa, setModalActiveMofa] = useState(false);
	const toggleModalActiveMofa = () => setModalActiveMofa(!modalActiveMofa);

	const customStyles = {
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: "white",
				fontSize: "16px",
			};
		},
		option: (base, state) => ({
			...base,
			color: "#0C3B5C",
			fontSize: "15px",
			fontWeight: "600",
			//backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
			padding: ".5rem 3rem .5rem .5rem",
			cursor: "pointer",
			borderBottom: "1px solid #ddd",
		}),
	};

	const [statusState, setStatusState] = useState({ value: "", label: "" });
	let history = useHistory();
	const { id } = useParams();
	const [data, setData] = useState({});
	const [viewSelect, setViewSelect] = useState({
		selectItem: [],
		dataRow: [],
		printData: [],
		viewMehram: [],
		mofa: [],
		all: [],
		sudan: [],
	});
	const status = [
		{ id: 3, name: "Get MOFA" },
		{ id: 4, name: "Active MOFA" },
		{ id: 1, name: "Get Visa Numbers" },
		{ id: 2, name: "Print Visa" },
		data.group?.country_id == 20 ? { id: 5, name: "Send To EUP" } : null,
		data.group?.country_id == 249 ? { id: 6, name: "Send To Hayaa" } : null,
	];

	const [selectItems, setSelectItems] = useState([]);
	const [loader, setLoader] = useState(false);
	const [loaderMofa, setLoaderMofa] = useState(false);
	const [loaderActiveMofa, setLoaderActiveMofa] = useState(false);
	const [wayMofaOption, setWayMofaOption] = useState([
		{
			mofa_way_group_id: 282099,
			mofa_way_group_name: "ملحق الرحاب1-282099",
		},
		{
			mofa_way_group_id: 282098,
			mofa_way_group_name: "ملحق الرحاب2-282098",
		},
		{
			mofa_way_group_id: 282097,
			mofa_way_group_name: "ملحق الرحاب3-282097",
		},
	]);
	const [arrivalDestinationOption, setArrivalDestinationOption] = useState([
		{
			id: 1,
			name: "ryad",
		},
		{
			id: 2,
			name: "jaddah",
		},
		{
			id: 3,
			name: "zahran",
		},
		{
			id: 4,
			name: "madinah",
		},
		{
			id: 5,
			name: "damam",
		},
	]);
	const [transporterIdOption, setTransporterIdOption] = useState([
		{
			id: 1,
			name: "land",
		},
		{
			id: 2,
			name: "air",
		},
		{
			id: 3,
			name: "sea",
		},
		{
			id: 4,
			name: "open",
		},
	]);
	const [wayMofa, setWayMofa] = useState({});

	const [activeMofaState, setActiveMofaState] = useState({
		ArrivalDestination: {
			id: 2,
			name: "jaddah",
		},
		ArrivalDate: data?.group?.travel_date,
		TransporterId: {
			...transporterIdOption.filter(
				(item) => item.id == data?.group?.TransporterId
			),
		},

		DurationOfStay: data?.group?.DayCount,
		safa_user: data?.group?.email,
	});
	useEffect(() => {
		setActiveMofaState({
			//data?.group?.travel_date
			ArrivalDestination: {
				id: 2,
				name: "jaddah",
			},
			ArrivalDate: data?.group?.travel_date,
			TransporterId: {
				...transporterIdOption.filter(
					(item) => item.id == data?.group?.TransporterId
				),
			},
			DurationOfStay: data?.group?.DayCount,
			safa_user: data?.group?.email,
		});
	}, [data]);
	const [errors, setErrors] = useState({});
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "mofaWayGroup",
					value: wayMofa,
				},
				{
					required: true,
				}
			),
		});
	};

	const checkFormErrorsActiveMofa = () => {
		let SubmitError = {};
		Object.keys(activeMofaState).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{
						name: key,
						value: activeMofaState[key],
					},
					{ required: true, email: key == "safa_user" ? true : false }
				),
			};
		});

		setErrors(() => SubmitError);
		return isFormValid(SubmitError);
	};

	const groupRaw =
		data?.data?.length > 0 ? (
			data?.data?.map((member, index) => (
				<TableViewGroupContent
					member={member}
					index={index}
					viewSelect={viewSelect}
					setViewSelect={setViewSelect}
					groupId={data?.group?.id}
					loader={loader}
					loaderMofa={loaderMofa}
					loaderActiveMofa={loaderActiveMofa}
					setSelectItems={setSelectItems}
					selectItems={selectItems}
					UASP={data?.group?.UASP}
				/>
			))
		) : (
			<tr>
				<td colSpan="15">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result"}</h4>
					</div>
				</td>
			</tr>
		);
	const arr = [];
	const arrData = [];
	const mohrem = [];

	const fillArray = () => {
		for (var item of data?.data) {
			arr.push({ visa: item.visa, enum: item.e_number });
			arrData.push(
				[item.CNationality, item.PPNo, item.e_number, item.mofa].toString()
			);
			mohrem.push(item.mehrem_name);
		}
		return arr;
	};

	//Select All Item

	const selectAllItems = document.getElementsByClassName("select-all");

	const selectItem = document.getElementsByClassName("select-item");
	function selectAll(event) {
		const name = event.target.name;
		const isSelectorChecked = event.target.checked;
		if (isSelectorChecked) {
			for (var item of selectItem) {
				item.checked = true;
			}
			fillArray();
			setViewSelect({
				printData: [...arr],
				[name]: [...arr],
				dataRow: arrData,
				viewMehram: [...mohrem],
				mofa: [
					...data?.data.map((item, index) => {
						return { PPNo: item.PPNo, CNationality: item.CNationality };
					}),
				],
				all: [
					...data?.data.map((item, index) => {
						return {
							PPNo: item.PPNo,
							CNationality: item.CNationality,
							mofa: item.mofa,
							online_id: item.online_id,
						};
					}),
				],
				sudan: [...data?.data],
			});
			setSelectItems([
				...data.data.map((item) => {
					return item.id;
				}),
			]);
		} else {
			for (let item of selectItem) {
				item.checked = false;
				setViewSelect({
					[name]: [],
					printData: [],
					dataRow: [],
					viewMehram: [],
					mofa: [],
					all: [],
					sudan: [],
				});
				setSelectItems([]);
			}
		}
	}
	// view Group API
	//viewGroup
	const [filter, setFilter] = useState({
		search: undefined,
		group_id: id,
		clear: true,
	});
	const tokenSafaVisa = localStorage.getItem("VisaToken");

	const fetchMemberInGroup = async () => {
		const res = await viewGroup(id, { token: tokenSafaVisa }, filter);
		setData(res?.data);
	};
	useEffect(() => {
		fetchMemberInGroup();
	}, [filter.clear]);

	const searchMem = async () => {
		if (filter.search != undefined) {
			const res = await searchMember({ token: tokenSafaVisa }, filter);
			setData(res.data);
		}
	};
	const optionAction = (status) => {
		switch (status) {
			case 1:
				return fetchVisaNumber();
			case 2:
				return fetchPrintVisaNumber(status);
			case 3:
				return getMofa();
			case 4:
				return activeMofaPop();
			case 5:
				return sendPassportEup();
			case 6:
				return sendPassportHayaa();
			default:
				return "";
		}
	};
	const [togglePrint, setTogglePrint] = useState(false);

	function fetchPrintVisaNumber(status) {
		if (viewSelect.selectItem?.length > 0) {
			setTogglePrint(!togglePrint);
			setTimeout(() => {
				history.push("/visa/print-visa");
			}, 800);
		} else {
			dispatchSBS({
				type: "setError",
				payload: true,
				message: {
					title: "Visa Number",
					body: "Select Element",
				},
			});
		}
	}
	async function getMofa() {
		if (data.group?.UASP != 2) {
			setLoaderMofa(true);
			let dataSend = {
				token: tokenSafaVisa,
				group_id: data.group.id,
				contract_id: data.group.contract_id,
				embassy: data.group.embassy,
				status: data.group.status,
				status_id: data.group.status_id,
				passengers: viewSelect.mofa,
				UASP: data.group?.UASP,
				embassy_id: data.group?.embassy_id,
				Code: data.group?.code,
			};
			if (viewSelect.selectItem?.length > 0) {
				const res = await FetchMofaNumber(dataSend);
				if (res.status == 200 || res.status == 201) {
					setLoaderMofa(false);
					setData(res?.data);
					store.addNotification({
						title: "Get Mofa",
						message: res?.data?.message
							? res?.data?.message
							: "Get Mofa Successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 1500,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							//fetchMemberInGroup();
							setSelectItems([]);
							selectAllItems[0].checked = false;
							setViewSelect({ ...viewSelect, mofa: [] });
						},
					});
				} else {
					dispatchSBS({
						type: "setError",
						payload: true,
						message: {
							title: "Get Mofa",
							body: "Error",
						},
					});
				}
			} else {
				dispatchSBS({
					type: "setError",
					payload: true,
					message: {
						title: "Get Mofa ",
						body: "Select Element",
					},
				});
			}
		} else {
			toggleModalMofa();
		}
	}
	function activeMofaPop() {
		let mofaData = viewSelect.dataRow.map((item, index) => +item.slice(-8));

		if (
			viewSelect.selectItem?.length > 0 &&
			!mofaData.includes("-") &&
			!mofaData.includes(NaN)
		) {
			toggleModalActiveMofa();
		} else {
			dispatchSBS({
				type: "setError",
				payload: true,
				message: {
					title: "Active Mofa ",
					body: "You Should Passanger Has Mofa Number First",
				},
			});
		}
	}
	async function activeMofa() {
		setLoaderActiveMofa(true);
		let dataSend = {
			safa_key: loginData?.safa_key,
			//loginData?.email
			safa_user: activeMofaState?.safa_user,
			safa_pwd: loginData?.password,
			token: tokenSafaVisa,
			group_id: data.group.id,
			contract_id: data.group.contract_id,
			embassy: data.group.embassy,
			status: data.group.status,
			status_id: data.group.status_id,
			UASP: data.group.UASP,
			passengers: viewSelect.all,
			//data.group.travel_date
			ArrivalDate: moment(activeMofaState?.ArrivalDate).format("YYYY-MM-DD"),
			//data.group.TransporterId
			TransporterId: activeMofaState?.TransporterId?.id
				? activeMofaState?.TransporterId?.id
				: activeMofaState?.TransporterId[0]?.id,
			//data.group.DayCount
			DurationOfStay: activeMofaState?.DurationOfStay,
			ArrivalDestination: activeMofaState.ArrivalDestination?.id,
			//activate_id: data.group.activate_id,
		};
		let mofaData = viewSelect.dataRow.map((item, index) => +item.slice(-8));
		if (checkFormErrorsActiveMofa()) {
			if (
				viewSelect.selectItem?.length > 0 &&
				!mofaData.includes("-") &&
				!mofaData.includes(NaN)
			) {
				const res = await ActiveMofa(dataSend);
				if (res?.status == 200 || res?.status == 201) {
					setLoaderActiveMofa(false);
					fetchMemberInGroup();
					store.addNotification({
						title: "Active Mofa",
						message: "Active Mofa Successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 1500,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							//fetchMemberInGroup();
							setSelectItems([]);
							selectAllItems[0].checked = false;
							setViewSelect({ ...viewSelect, all: [] });
						},
					});
				} else {
					toggleModalActiveMofa();
					dispatchSBS({
						type: "setError",
						payload: true,
						message: {
							title: "Active Mofa",
							body:
								res?.data?.message?.length > 0
									? res?.data?.message?.map((item, index) => {
											return (
												<div>
													{item.PPNo} has {item.msg}
													<br />
												</div>
											);
									  })
									: res?.data?.message
									? res?.data?.message
									: "error",
						},
					});
				}
			} else {
				dispatchSBS({
					type: "setError",
					payload: true,
					message: {
						title: "Active Mofa ",
						body: "You Should Passanger Has Mofa Number First",
					},
				});
			}
		}
	}

	async function sendPassportEup() {
		if (data.group?.country_id == 20) {
			let dataSend = {
				// token: tokenSafaVisa,
				// safa_key: +loginData?.safa_key,
				// safa_user: loginData?.email,
				// safa_pwd: +loginData?.password,
				passengers: viewSelect.mofa,
				embassy: data.group?.embassy_id,
				visa_type: 10,
			};
			if (viewSelect.selectItem?.length > 0) {
				const res = await SendEup(dataSend);
				if (res.status == 200 || res.status == 201) {
					fetchMemberInGroup();
					store.addNotification({
						title: "Send To EUP",
						message: res?.data?.message
							? res?.data?.message
							: "Send To EUP Successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 1500,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							//fetchMemberInGroup();
							setSelectItems([]);
							selectAllItems[0].checked = false;
							setViewSelect({ ...viewSelect, mofa: [], selectItem: [] });
						},
					});
				} else {
					dispatchSBS({
						type: "setError",
						payload: true,
						message: {
							title: "Send To EUP",
							body: res?.data?.message ?? "Error",
						},
					});
				}
			} else {
				dispatchSBS({
					type: "setError",
					payload: true,
					message: {
						title: "Send To EUP ",
						body: "Select Element",
					},
				});
			}
		}
	}
	async function sendPassportHayaa() {
		if (data.group.country_id == 249) {
			let dataSend = {
				//token: tokenSafaVisa,
				//safa_key: loginData?.safa_key,
				contract_id: data.group.contract_id,
				group_id: data.group.id,
				TransporterId: data.group.TransporterId,
				GroupId: data.group.id,
				GroupName: data.group.name,
				Group_Name: data.group.name,
				Group_Code: data.group?.Code,
				group_travel_date: data.group.travel_date,
				return_date: data.group.return_date,

				passengers: [
					...viewSelect.sudan.map((item, index) => {
						return {
							id: item.id,
							online_id: item.online_id,
							CNationality: item.CNationality,
							PPNo: item.PPNo,
							mofa: item.mofa,
							border: item.border,
							e_number: item.e_number,
							VisaNumber: 6079547951,
							VisaDate: "2022/04/18",
							VisaValidity: "2022/05/03",
							VisaDuration: "30+Days",
							VisaIssue: "Saudi+mission+in+Khartoum",
							EA: "وكالة+الطور+للسفر+والسياحة",
							UO: "شركة+وصل+الخير+المحدودة",
							_Seq: item.arrange,
							TypeOfVisa: "Umrah",
						};
					}),
				],
			};
			if (viewSelect.selectItem?.length > 0) {
				const res = await SendHayaa(dataSend);
				if (res.status == 200 || res.status == 201) {
					fetchMemberInGroup();
					store.addNotification({
						title: "Send To Hayaa",
						message: res?.data?.message
							? res?.data?.message
							: "Send To Hayaa Successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 1500,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							//fetchMemberInGroup();
							setSelectItems([]);
							selectAllItems[0].checked = false;
							setViewSelect({ ...viewSelect, mofa: [], selectItem: [] });
						},
					});
				} else {
					dispatchSBS({
						type: "setError",
						payload: true,
						message: {
							title: "Send To Hayaa",
							body: res?.data?.message ?? "Error",
						},
					});
				}
			} else {
				dispatchSBS({
					type: "setError",
					payload: true,
					message: {
						title: "Send To EUP ",
						body: "Select Element",
					},
				});
			}
		}
	}
	async function fetchVisaNumber() {
		setLoader(true);
		if (viewSelect.selectItem?.length > 0) {
			const res = await getVisaNumber({
				token: tokenSafaVisa,
				data: viewSelect.dataRow,
			});
			if (!res?.data?.error) {
				setLoader(false);
				store.addNotification({
					title: "Visa Number",
					message: res?.data?.msg,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			} else {
				setLoader(false);

				dispatchSBS({
					type: "setError",
					payload: true,
					message: {
						title: "Visa Number",
						body: res?.data?.msg ? res?.data?.msg : "Error",
					},
				});
			}
		} else {
			dispatchSBS({
				type: "setError",
				payload: true,
				message: {
					title: "Visa Number",
					body: "Select Element",
				},
			});
		}
	}
	/// print visa

	useEffect(() => {
		if (togglePrint) {
			async function fetchPrintVisaData() {
				const res = await printVisaNumber({
					token: tokenSafaVisa,
					data: viewSelect?.printData,
				});
				dispatch({ type: "getPrintData", payload: res?.data?.data });
				dispatch({ type: "getMehremName", payload: viewSelect.viewMehram });
			}
			fetchPrintVisaData();
		}
	}, [togglePrint]);

	useEffect(() => {
		if (data.group?.UASP == 2) {
			async function getWayMofaData() {
				const res = await FetchWayMofa({
					token: tokenSafaVisa,
					group_id: data.group.id,
					contract_id: data.group.contract_id,
				});
				let formated = wayMofaOption.map((item, index) => ({
					id: item.mofa_way_group_id,
					name: item.mofa_way_group_name,
				}));
				setWayMofaOption(formated);
				//setWayMofaOption(res?.data?.data);
			}
			getWayMofaData();
		}
	}, [data.group?.UASP]);

	const sendGroupToMofa = async () => {
		checkFormErrors();
		if (isFormValid(errors)) {
			let dataSend = {
				token: tokenSafaVisa,
				group_id: data.group.id,
				contract_id: data.group.contract_id,
				embassy: data.group.embassy,
				status: data.group.status,
				status_id: data.group.status_id,
				UASP: data.group?.UASP,
				embassy_id: data.group?.embassy_id,
				Code: data.group?.Code,
			};
			async function getTableMofaData() {
				const res = await FetchTableMofa(wayMofa?.id, dataSend);
				if (res.status == 200 || res.status == 201) {
					store.addNotification({
						title: "Get Mofa",
						message: "Get Mofa Successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 1500,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							setData(res?.data);
							toggleModalMofa();
							setSelectItems([]);
							selectAllItems[0].checked = false;
							setViewSelect({ ...viewSelect, mofa: [] });
						},
					});
				}
			}
			getTableMofaData();
		}
	};
	return (
		<>
			<div className="container view-group  ">
				<GroupCard groupInfo={data.group} />
				<div className="header d-flex justify-content-between mt-2">
					<h3 className="table-title mt-2 ">
						{visa.Motamers + "(" + data?.data?.length + ")"}
					</h3>
					<button className="btn bg-nxt" onClick={() => history.push("/visa")}>
						{visa.BackGroup}
					</button>
				</div>
				<div className="table-layout">
					<div className="table-header d-flex align-items-center">
						<div className="row d-flex justify-content-between align-items-center w-100 no-gutter m-0 view-group-input">
							<div className="col-md-3 view-group-select">
								<SelectField
									styles={customStyles}
									placeholder={visa.MofaOperations}
									options={cleanEmpty(status)}
									//value={statusState?.status?.name}
									onChange={(e) => {
										setStatusState({
											...statusState,
											status: e,
										});
										optionAction(e.value);
									}}
								/>
							</div>
							<div className="col-md-4">
								<div className="d-flex justify-content-between align-items-center ">
									<div className="w-50">
										<TextField
											type="text"
											// label={visa.search}
											placeholder={visa.search}
											value={filter.search}
											onChange={(e) =>
												setFilter({ ...filter, search: e.target.value })
											}
										/>{" "}
									</div>

									<div className="clear text-secondary pointer border">
										<button
											className="btn brn-blue-dark text-white"
											onClick={searchMem}
										>
											<img src={correctIcon} alt=" " />
											{visa.apply}
										</button>
									</div>
									<div className="clear text-secondary pointer border">
										<button
											className="btn btn-white"
											onClick={() =>
												setFilter({
													...filter,
													search: "",
													clear: !filter.clear,
												})
											}
										>
											<img src={clearIcon} alt=" " />
											{visa.clear}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<Table striped className="table-update border bg-white ">
						<thead>
							<tr>
								<th>
									<input
										type="checkbox"
										name="selectItem"
										className="select-all mx-1"
										onChange={selectAll}
									/>
								</th>

								<th>{visa.No}</th>
								<th>{visa.s}</th>
								<th>{visa.PassportNumber}</th>
								<th>{visa.Name}</th>
								<th>{visa.Gender}</th>
								<th>{visa.Birthday}</th>
								<th>{visa.Maharam}</th>
								<th>{visa.Relationship}</th>
								<th>{visa.MOFA}</th>
								<th>{visa.BorderNo}</th>
								<th>{visa.ENumber}</th>
								<th>{visa.Visa}</th>
								<th width="1%"></th>
							</tr>
						</thead>

						<tbody>{groupRaw}</tbody>
					</Table>
				</div>
			</div>

			{/*****Mofa Modal */}
			<div>
				<Modal
					size="lg"
					isOpen={modalMofa}
					toggle={toggleModalMofa}
					className="recharge-Modal"
				>
					<ModalHeader
						className="align-items-center py-1  border-none"
						toggle={toggleModalMofa}
					>
						{visa.getMofa}
					</ModalHeader>
					<ModalBody>
						<div className="container-custom-lg-modal container mofa-modal px-5">
							<div className="row ">
								<div className="col-md-7 ">
									<SelectField
										styles={customStyles}
										label={visa.GroupName}
										name={"mofaWayGroup"}
										placeholder={visa.MofaOperations}
										options={wayMofaOption}
										value={wayMofa?.name}
										onChange={(e) => {
											setErrors({
												...errors,
												...validate(e, {
													required: true,
												}),
											});
											setWayMofa(e);
										}}
										color={errors?.mofaWayGroup?.required ? "danger" : ""}
										//errors={errors?.mofaWayGroup}
									/>
								</div>
								<div className="col-md-5 d-flex align-items-center mt-4">
									<button
										className="btn brn-blue-dark text-white px-4 py-2"
										onClick={sendGroupToMofa}
									>
										{visa.getMofaNumber}
									</button>
								</div>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>

			{/*****Active Mofa Modal */}
			<div>
				<Modal
					size="lg"
					isOpen={modalActiveMofa}
					toggle={toggleModalActiveMofa}
					className="recharge-Modal"
				>
					<ModalHeader
						className="align-items-center py-1  border-none"
						toggle={toggleModalActiveMofa}
					>
						{visa.activeMofa}
					</ModalHeader>
					<ModalBody>
						<div className="container-custom-lg-modal container mofa-modal px-5">
							<div className="row ">
								<div className="col-md-6 ">
									<SelectField
										styles={customStyles}
										label={visa.arrivalCity}
										placeholder={visa.select}
										options={arrivalDestinationOption}
										value={activeMofaState?.ArrivalDestination.name}
										onChange={(e) => {
											setActiveMofaState({
												...activeMofaState,
												ArrivalDestination: e,
											});
											setErrors({
												...errors,
												...validate(
													{ name: "ArrivalDestination", value: e },
													{
														required: true,
													}
												),
											});
										}}
										errors={errors?.ArrivalDestination}
										color={errors?.ArrivalDestination?.required ? "danger" : ""}
									/>
								</div>
								<div className="col-md-6">
									<DatePickerField
										label={visa.ArrivalDate}
										date={moment(activeMofaState.ArrivalDate)}
										value={moment(activeMofaState.ArrivalDate)}
										onChangeDate={(date) =>
											setActiveMofaState({
												...activeMofaState,
												ArrivalDate: date,
											})
										}
										isOutsideRange={(day) => {
											return false;
										}}
									/>
								</div>
								<div className="col-md-6 ">
									<SelectField
										styles={customStyles}
										label={visa.Transporter}
										placeholder={visa.select}
										options={transporterIdOption}
										value={
											activeMofaState?.TransporterId[0]?.id
												? activeMofaState?.TransporterId[0]?.id
												: activeMofaState?.TransporterId?.id
										}
										onChange={(e) => {
											setActiveMofaState({
												...activeMofaState,
												TransporterId: e,
											});
											setErrors({
												...errors,
												...validate(
													{ name: "TransporterId", value: e },
													{
														required: true,
													}
												),
											});
										}}
										errors={errors?.TransporterId}
										color={errors?.TransporterId?.required ? "danger" : ""}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										hasLabel={true}
										type="text"
										label={visa.durationOfStay}
										placeholder={visa.durationOfStay}
										value={activeMofaState?.DurationOfStay}
										onChange={(e) => {
											setActiveMofaState({
												...activeMofaState,
												DurationOfStay: e.target.value,
											});
											setErrors({
												...errors,
												...validate(
													{ name: "DurationOfStay", value: e.target.value },
													{
														required: true,
													}
												),
											});
										}}
										errors={errors?.DurationOfStay}
										color={errors?.DurationOfStay?.required ? "danger" : ""}
									/>
								</div>
								<div className="col-md-12">
									<TextField
										hasLabel={true}
										type="text"
										label={visa.emailAddress}
										placeholder={visa.emailAddress}
										value={activeMofaState?.safa_user}
										onChange={(e) => {
											setActiveMofaState({
												...activeMofaState,
												safa_user: e.target.value,
											});
											setErrors({
												...errors,
												...validate(
													{ name: "safa_user", value: e.target.value },
													{
														required: true,
														email: true,
													}
												),
											});
										}}
										errors={errors?.safa_user}
										color={errors?.safa_user?.required ? "danger" : ""}
									/>
								</div>
								<div className="col-md-12  m-2 ">
									<button
										className="btn btn-yellow  text-white w-100 font-16  "
										onClick={activeMofa}
									>
										{visa.activeMofa}
									</button>
								</div>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		</>
	);
}
