import classnames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
// import InventoryPolicies from "./InventoryPolicies";
// import InventoryPromotion from "./InventoryPromotion";
import HotelGray from "assets/images/inventory/HotelGray.svg";
import HotelColored from "assets/images/inventory/HotelsColored.svg";
import remarksGray from "assets/images/inventory/remarksGray.svg";

import {
	fetchHotelBrn,
	fetchTransportaionsBrn,
	viewGroupINventory,
} from "services/marketplace";
import Locale from "translations";
import BookingDetails from "./BookingDetails";
import BookingRemarks from "./bookingRemarks";

export default function InventoryUmrahHolidays(props) {
	const { inventory } = Locale;
	const [activeTab, setActiveTab] = useState("2");
	const [details, setDetails] = useState(null);
	const { pathname } = useLocation();
	const [_, number] = pathname.split("/inventory/");
	const { brn } = useParams();

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const [state, setstate] = useState({ tab: "hotel" });
	const [GroupInventory, setGroupInventory] = useState();
	let location = useLocation();
	useEffect(() => {
		async function fetchData() {
			if (!location.pathname.includes("/inventory-group")) {
				setGroupInventory(false);
				const { data } =
					state.tab === "hotel"
						? await fetchHotelBrn(number)
						: await fetchTransportaionsBrn(number);

				setDetails(data.data);
			} else {
				setGroupInventory(true);

				const { data } = await viewGroupINventory(brn);
				setDetails(data.data);
			}
		}
		fetchData();
	}, []);
	console.log("GroupInventory", GroupInventory);
	return (
		<>
			<div className=" container  interest border p-0 inventory-booking-details">
				<div className="main-nav-sbs">
					{/* <div>
						<button className="btn btn-danger mx-1 px-5 py-2">
							Cancle Reservation
						</button>
						<button className="btn btn-primary mx-1 px-5 py-2">Edit</button>
					</div> */}

					<Nav tabs>
						{/* <NavItem>
							<NavLink
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggle("1");
								}}
							>
								{state.tab === "hotel" ? (
									<i className="fas fa-hotel fa-fw mx-1"></i>
								) : (
									<i className="icx icx-transport fa-fw mx-1"></i>
								)}

								{state.tab === "hotel"
									? `${inventory.messages.hotelDetails}`
									: `${inventory.messages.transport}`}
							</NavLink>
						</NavItem> */}
						<NavItem>
							<NavLink
								className={`border-0  ${classnames({
									active: activeTab === "2",
								})}`}
								onClick={() => {
									toggle("2");
								}}
							>
								<div className="product-build-list-item">
									<img
										src={activeTab === "2" ? HotelColored : HotelGray}
										alt=""
									/>

									<span className="text mx-1">
										{" "}
										{inventory.messages.bookingDetails}
									</span>
								</div>
							</NavLink>
						</NavItem>
						{GroupInventory ? (
							<NavItem>
								<NavLink
									className={`border-0  ${classnames({
										active: activeTab === "3",
									})}`}
									onClick={() => {
										toggle("3");
									}}
								>
									<div className="product-build-list-item">
										<img src={remarksGray} alt="" />

										<span className="text mx-1">
											{inventory.messages.BookingRemarks}
										</span>
									</div>
								</NavLink>
							</NavItem>
						) : null}
						{/* <NavItem>
							<NavLink
								className={classnames({ active: activeTab === "4" })}
								onClick={() => {
									toggle("4");
								}}
							>
								<i className="fas fa-briefcase fa-fw mx-1"></i>
								{"Edit Request"}
							</NavLink>
						</NavItem> */}
					</Nav>
				</div>
				{details !== null && (
					<TabContent activeTab={activeTab} style={{ minHeight: "100%" }}>
						{/* {activeTab === "1" ? (
								<TabPane tabId="1">
									<HotelDetails details={details} />
								</TabPane>
							) : null} */}
						{activeTab === "2" ? (
							<TabPane tabId="2">
								<BookingDetails
									details={details}
									setDetails={setDetails}
									GroupInventory={GroupInventory}
								/>
							</TabPane>
						) : null}
						{activeTab === "3" ? (
							<TabPane tabId="3">
								<BookingRemarks details={details} setDetails={setDetails} />
							</TabPane>
						) : null}
						{/* {activeTab === "3" ? (
							<TabPane tabId="3">
								<InventoryTab details={details} setDetails={setDetails} />
							</TabPane>
						) : null} */}
						{/* {activeTab === "4" ? (
								<TabPane tabId="4">
									<EditRequest details={details} />
								</TabPane>
							) : null} */}
					</TabContent>
				)}
			</div>
		</>
	);
}
