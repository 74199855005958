import { useSBSState } from "context/global";
import React from "react";
import Locale from "translations";

export default function WalletBoxs(props) {
	const { payment, dashboard } = Locale;
	const { safaBalance, myBalance } = useSBSState();
	return (
		<>
			{/*** Start Two Box Wallet ***/}
			<div className="col-md-8 WalletBoxs">
				<div className="row d-flex justify-content-between ">
					{/* <div className=" col-md-12  ">
						<div className="d-flex justify-content-between"> */}
					<div className="col-md-5 ">
						<h2 className="walletBalanceHeader BalanceHeader my-2">
							{dashboard.messages.safaWallet}
						</h2>
						<div className="walletBalanceContent BalanceContent text-center">
							<div className="walletBalanceAmountContent AmountContent">
								<h2 className="walletBalanceAmountTitle AmountTitle">
									{dashboard?.messages.current_balance}
								</h2>
								<p className="walletBalanceAmountNumber text-success AmountNumber">
									{safaBalance?.amount}
									<span className="walletBalanceAmountPound AmountPound mx-2 text-dark">
										{dashboard?.messages.egyptian_pound}
									</span>
								</p>
								{/* <div className="walletBalanceAmountNumber AmountNumber ">
											<span className="text-dark">
												{dashboard?.messages.creditor} :
											</span>
											<span className="text-success AmountNumber"> 8500</span>
											&nbsp;
											<span className="text-dark">
												{dashboard?.messages.debtor} :{" "}
											</span>
											<span className="text-success AmountNumber ">8740</span>
										</div> */}
							</div>
						</div>
					</div>

					<div className="col-md-5 ">
						<h2 className="walletBalanceHeader BalanceHeader my-2">
							{dashboard.messages.myWallet}
						</h2>
						<div className="walletBalanceContent BalanceContent ">
							<div className="walletBalanceAmountContent AmountContent text-center">
								<h2 className="walletBalanceAmountTitle AmountTitle">
									{dashboard?.messages.current_balance}
								</h2>
								<p className="walletBalanceAmountNumber text-success AmountNumber">
									{myBalance?.amount}
									<span className="walletBalanceAmountPound AmountPound mx-2 text-dark">
										{dashboard?.messages.egyptian_pound}
									</span>
								</p>
								{/* <div className="walletBalanceAmountNumber AmountNumber ">
											<span className="text-dark">
												{" "}
												{dashboard?.messages.creditor} :
											</span>
											<span className="text-success AmountNumber"> 8500</span>
											&nbsp;
											<span className="text-dark">
												{dashboard?.messages.debtor} :{" "}
											</span>
											<span className="text-success AmountNumber ">8740</span>
										</div> */}
							</div>
						</div>
					</div>
					{/* </div>
					</div> */}
				</div>
			</div>
			{/*** End Two Box Wallet ***/}
		</>
	);
}
