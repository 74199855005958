import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchImages } from "services/auth";
import { getInvoice } from "services/marketplace";
import Locale from "translations";
import SafaLogo from "../../../../assets/images/MarketInvoice/SafaLogo.png";
import "./invoice.scss";

export default function MarketInvoice() {
	const { inventory } = Locale;
	const [invoice, setInvoice] = useState();
	const { brn } = useParams();
	const [avatar, setAvatar] = useState();
	useEffect(() => {
		const fetchInvoice = async () => {
			const response = await getInvoice(brn);
			if (response) {
				setInvoice(response);
			}
		};
		fetchInvoice();
	}, []);

	useEffect(() => {
		const fetchCompanyImage = async () => {
			const res = await fetchImages(invoice?.company?.avatar);
			if (res) {
				setAvatar(res);
			}
		};

		if (invoice?.company?.avatar) {
			fetchCompanyImage();
		}
	}, [invoice?.company?.avatar]);

	const allRooms =
		invoice?.rooms?.length > 0
			? invoice?.rooms.map((room, index) => {
				return (
					<tr>
						<td
							className={`${index + 1 === +invoice.rooms.length
								? "last-td-border"
								: "remove-last-td-border"
								}`}
						>
							{index === 0 ? invoice?.hotel_name : ""}
						</td>
						<td>{room.name}</td>
						<td>
							{" "}
							{room?.passengers?.length > 0
								? room?.passengers.map((passenger) => {
									return (
										<p className="m-0">
											{passenger?.first_name} {passenger?.last_name}
										</p>
									);
								})
								: "-"}
						</td>
						<td>{room.quantity}</td>
						<td>{room.adults}</td>
						<td>{room.children}</td>
						<td>{room.nights}</td>
						<td>{room.from_date}</td>
						<td>{room.to_date}</td>
						<td>
							{room.price} {invoice?.currency}
						</td>
					</tr>
				);
			})
			: [];

	useEffect(() => {
		setTimeout(() => {
			window.print();
		}, 2000);
	}, []);

	return (
		<div className="invoice container py-4 bg-white  ">
			<div className="container-wrapper mt-2 mx-3 ">
				<div className="bg-gray p-4 border-header">
					<div className="row justify-content-between align-items-center ">
						<div className="col-3">
							<img src={SafaLogo} alt="hotal-logo" className="img-fluid " />
						</div>
						<div className="col-4">
							<h4 className="txt-blue">SAFA SOFT</h4>
							<h4 className="txt-gray">9 Othman Towers</h4>
							<h4 className="txt-gray">
								Kornish El-Nile, Maadi, Cairo - Egypt
							</h4>
							<h4 className="txt-gray">Email: info@safasoft.com</h4>
						</div>
					</div>
				</div>

				<div className="border-section">
					<div className="row justify-content-around align-items-center  p-4 ">
						<div className="col-2">
							<h2 className="invoice-word ">INVOICE</h2>
						</div>

						<div className="row col-8">
							<div className="col-2"></div>
							<div className="col-4">
								<h4 className="txt-gray mb-1">Invoice No.</h4>
								<h4 className="txt-gray mb-1">Invoice Date</h4>
								<h4 className="txt-gray mb-1">Confirmation No.</h4>
							</div>

							<div className="col-6">
								<h4 className="font-weight-bold mb-1">
									{invoice?.invoiceNumber}
								</h4>
								<h4 className="font-weight-bold mb-1">
									{invoice?.invoiceDate}
								</h4>
								<h4 className="font-weight-bold mb-1">
									{invoice?.confirmationNumber}
								</h4>
							</div>
						</div>
					</div>
				</div>

				<div className="">
					<div className="row justify-content-around align-items-center  p-4 ">
						<div className="col-3 border  p-2 d-flex  justify-content-center ">
							{avatar ? (
								<img src={avatar} alt="hotal-logo" className="img-fluid w-50" />
							) : (
								<div className="company-logo-font letter-logo p-5 ">
									{invoice?.company?.name[0]}
								</div>
							)}
						</div>

						<div className="row col-8">
							<div className="col-1 mx-3"></div>

							<div className="col-4 px-2">
								<h4 className="txt-gray mb-1">Agency Name</h4>
								<h4 className="txt-gray mb-1">Address</h4>
								<h4 className="txt-gray mb-1">Phone</h4>
							</div>

							<div className="col-6">
								<h4 className="font-weight-bold mb-1">
									{invoice?.company?.name}
								</h4>

								<h4 className="font-weight-bold mb-1">
									{invoice?.company?.address ? invoice?.company?.address : "-"}
								</h4>

								<h4 className="font-weight-bold mb-1">
									{invoice?.company?.phone}
								</h4>
							</div>
						</div>
					</div>
				</div>

				<div className="">
					<table class=" marketInvoice-table">
						<thead>
							<tr>
								<th scope="col  " className="font-weight-bold width-10">
									Hotel Name
								</th>
								<th scope="col  " className="font-weight-bold width-10">
									Room Name
								</th>
								<th scope="col  " className="font-weight-bold width-10">
									Guest Name
								</th>
								<th scope="col  " className="font-weight-bold">
									Rooms
								</th>
								<th scope="col  " className="font-weight-bold">
									Adult
								</th>
								<th scope="col  " className="font-weight-bold">
									Child
								</th>
								<th scope="col  " className="font-weight-bold">
									Nights
								</th>
								<th scope="col  " className="font-weight-bold">
									Check-in
								</th>
								<th scope="col  " className="font-weight-bold">
									Check-out
								</th>
								<th scope="col  " className="font-weight-bold">
									Price
								</th>
							</tr>
						</thead>
						<tbody>{allRooms}</tbody>
					</table>
				</div>

				{/* 		<div className="border-section">
					<div className="row justify-content-start align-items-center  p-4 ">
						<div className=" col-3 d-flex flex-column">
							<p className="m-0">Check-in</p>
							<p className="m-0 txt-blue">{invoice?.checkin}</p>
						</div>

						<div className=" col-3 d-flex flex-column">
							<p className="m-0">Check-out</p>
							<p className="m-0 txt-blue">{invoice?.checkout}</p>
						</div>
					</div>
				</div>
 */}
				{invoice?.remark ? (
					<div className="border-section">
						<div className="row justify-content-start align-items-center  p-4 ">
							<div className=" col-12 d-flex flex-column">
								<p className="font-weight-bold mb-1">Agent Remarks</p>
								<p className="m-0">{invoice?.remark}</p>
							</div>
						</div>
					</div>
				) : null}

				<div className="">
					<div className="row justify-content-start align-items-center  p-4 ">
						<div className=" col-3 d-flex flex-column">
							<p className="mb-1">Billed by</p>
							<p className="mb-1">Issued By</p>
							<p className="mb-1">Invoice Status</p>
							<p className="mb-1">Amount</p>
						</div>

						<div className=" col-6 d-flex flex-column">
							<p className="font-weight-bold mb-1"> {invoice?.company?.name}</p>
							<p className="font-weight-bold mb-1">Safa Soft</p>
							<p className="font-weight-bold mb-1">Confirmed</p>
							<p className="font-weight-bold mb-1">
								{invoice?.total_price} {invoice?.currency}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
