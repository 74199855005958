import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function PromocodeModal({
	modalPromocode,
	togglePromocodeModal,
	productItem,
	promoOrVoucherState,
	promoState,
}) {
	const { inventory } = Locale;
	const copyCodeToClipboard = () => {
		const text = document.getElementById("text").innerText;
		var textField = document.createElement("textarea");
		textField.innerText = text;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
	};

	return (
		<>
			<Modal
				size="md"
				isOpen={modalPromocode.toggle}
				toggle={() => togglePromocodeModal()}
			>
				<ModalHeader
					className="align-items-center"
					toggle={() => togglePromocodeModal()}
				>
					{productItem?.payment_type == "full payment"
						? `${inventory.messages.voucher}`
						: `${inventory.messages.promocode}`}
				</ModalHeader>
				<ModalBody className="payModal">
					<div className="container ">
						<div className="wallet d-flex justify-content-center ">
							<div className="wallet-box  text-center px-5 py-3">
								<p className="font-dark-gray font-size-18">
									{modalPromocode.title}{" "}
								</p>
								{productItem?.payment_type == "full payment" ||
								productItem?.payment_type == null ? (
									productItem?.voucher_code?.files.map((file, i) => {
										return (
											<a
												href={file}
												download
												target="_blank"
												className="font-weight-bolder text-underline d-block"
												rel="noreferrer"
											>
												{inventory.messages.download}
												{i + 1}
												<i class="fas fa-arrow-circle-down text-primary mx-1"></i>
											</a>
										);
									})
								) : (
									<p className="font-weight-bolder">
										<span id="text"> {productItem?.voucher_code?.code}</span>

										<i
											class="far fa-clone text-primary mx-1"
											onClick={() => copyCodeToClipboard()}
											style={{ cursor: "pointer" }}
										></i>
									</p>
								)}
							</div>
						</div>

						<div className=" my-3">
							<div className=" d-flex align-items-center">
								<div>
									<h6>{inventory.messages.notes}</h6>
									<p className="font-size-14 text-gray">
										{productItem?.voucher_code?.notes != null &&
										productItem?.voucher_code?.notes != "null" ? (
											productItem?.voucher_code?.notes
										) : (
											<p className="text-center">
												{" "}
												<i class="fas fa-info-circle"></i>{" "}
												{inventory.messages.noNote}{" "}
											</p>
										)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
