import axios from "axios";
import { useEffect, useState } from "react";

export default function useFetchCountries() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [countries, setCountries] = useState([]);
	useEffect(() => {
		async function get() {
			await axios
				.get(`${process.env.REACT_APP_LOOKUPS_ENDPOINT}/geolocation/countries`)
				.then((res) => {
					setCountries(res.data.data);
					setIsLoaded(true);
				});
		}
		get();
	}, []);

	return [countries, isLoaded];
}
