import Header from "components/Header/Header";
import { useSBSDispatch, useSBSState } from "context/global";
import { Steps } from 'intro.js-react';
import { useEffect } from "react";
import { getCredit, getDebit } from "services/wallet";
import 'intro.js/introjs.css';
import { useSetpsList } from 'layouts/useSetpsList';


export default function DashboardLayout(props) {
	const dispatch = useSBSDispatch();
	const search = window.location.search;
	const name = new URLSearchParams(search);
	const { guide, userInfo } = useSBSState();
	const { menuSteps, hotelsTabs, vacationsIndividualSteps } = useSetpsList();

	useEffect(() => {
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}
		debugger
		if (x.hasOwnProperty("token")) {
		} else {
			async function fetchSafaWallet() {
				const res = await getCredit();
				dispatch({ type: "getSafaBalance", payload: res?.data?.data });
			}

			async function fetchMyWallet() {
				const res = await getDebit();
				dispatch({ type: "getMyBalance", payload: res?.data?.data[0] });
			}

			fetchSafaWallet();
			fetchMyWallet();
		}
	}, []);

	useEffect(()=> {
		if (userInfo?.is_first_login && document.getElementById('sidenav-market-search')) {
			dispatch({ 
				type: "guide", 
				payload: { 
					enabled: true, 
					initialStep: 0,
					steps: [...menuSteps, ...hotelsTabs, ...vacationsIndividualSteps]
				} 
		})
		}
	}, [document.getElementById('sidenav-market-search')])

	const path = window.location.href;

	return (
		<div className={`bg-gray-100  ${!path.includes("hyperpay") ? "vh-100" : ""}`}>
			{/* guids */}
			<Steps
				enabled={guide.enabled}
				steps={guide.steps}
				initialStep={guide.initialStep}
				onExit={() => {
					dispatch({ type: "guide", payload: { enabled: false, initialStep: 0, steps:[] } })
					if (userInfo?.is_first_login && document.getElementById('sidenav-market-search')) {
						dispatch({
							type: "removeFirstLogin",
							payload: {
								...userInfo,
								is_first_login: false,
							}
						});
					}
				}}
			/>


			<Header />

			<div className="bg-gray-100 container_layout"  {...props}>
				{props.children}
			</div>
		</div>
	);
}
