import folder from "assets/images/MarketInvoice/Folder.png";
import { useMarketplaceState } from "context/marketplace";
import { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { useHistory } from "react-router-dom";
import { getPassengerMrz } from "services/VisaOnline";
import Locale from "translations";

export default function MRZ({ checkedIds }) {
	const { marketPlace, onlineVisa, inventory } = Locale;
	const [files, setFiles] = useState({});
	const [PassportPhoto, setPassportPhoto] = useState({});
	const tokenSafaVisa = localStorage.getItem("VisaToken");
	const { onlineVisaSearch, onlineVisaPassenger } = useMarketplaceState();
	const imgRef = useRef(null);

	const [crop, setCrop] = useState({
		unit: "%", // Can be 'px' or '%'
		x: 5,
		y: 73,
		width: 90,
		height: 25,
	});

	let history = useHistory();

	const sendPassport = async () => {
		let form = new FormData();
		form.append("passport_image", files.passport_image);
		form.append("mrz_image", files.croppedImage);
		form.append("token", tokenSafaVisa);
		const response = await getPassengerMrz(form);
		if (response?.status === 200) {
			history.push({
				pathname: "/Online-Visa/Add/Passenger",
				state: response.data.data,
			});
		}
	};

	const onCropComplete = (crop) => {
		if (PassportPhoto && crop.width && crop.height) {
			getCroppedImg(crop);
		}
	};
	const TO_RADIANS = Math.PI / 180;

	const getCroppedImg = (crop) => {
		let image = imgRef.current;
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");

		if (!ctx) {
			throw new Error("No 2d context");
		}

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		// devicePixelRatio slightly increases sharpness on retina devices
		// at the expense of slightly slower render times and needing to
		// size the image back down if you want to download/upload and be
		// true to the images natural size.
		const pixelRatio = window.devicePixelRatio;
		// const pixelRatio = 1

		canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
		canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

		ctx.scale(pixelRatio, pixelRatio);
		ctx.imageSmoothingQuality = "high";

		const cropX = crop.x * scaleX;
		const cropY = crop.y * scaleY;

		const rotateRads = 0 * TO_RADIANS;

		const centerX = image.naturalWidth / 2;
		const centerY = image.naturalHeight / 2;

		ctx.save();

		// 5) Move the crop origin to the canvas origin (0,0)
		ctx.translate(-cropX, -cropY);
		// 4) Move the origin to the center of the original position
		ctx.translate(centerX, centerY);
		// 3) Rotate around the origin
		ctx.rotate(rotateRads);
		// 2) Scale the image
		ctx.scale(1, 1);
		// 1) Move the center of the image to the origin (0,0)
		ctx.translate(-centerX, -centerY);
		ctx.drawImage(
			image,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight
		);

		ctx.restore();

		const reader = new FileReader();
		let previewUrl;
		canvas.toBlob((blob) => {
			reader.readAsDataURL(blob);
			previewUrl = URL.createObjectURL(blob);

			reader.onloadend = () => {
				dataURLtoFile(reader.result, "cropped.jpg", previewUrl);
			};
		});
	};
	const dataURLtoFile = (dataurl, filename, previewUrl) => {
		let arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let croppedImage = new File([u8arr], filename, { type: mime });
		setFiles({
			...files,
			croppedImage: croppedImage,
			croppedImageUrl: dataurl,
			previewUrl: previewUrl,
		});
		// setFiles({ croppedImageUrl });
	};

	return (
		<>
			{onlineVisaSearch?.pax != checkedIds.length.toString() ? (
				<div className="">
					<div className="  d-flex flex-column align-items-center  px-4  justify-content-center  text-gray w-100 py-4 scan-mrz-onlineVisa">
						<span>
							<img className="mx-2" src={folder} alt="" />{" "}
							{onlineVisa.PlzScanFromMRZ} !
						</span>{" "}
						<div className="mt-4 text-center mb-2">
							<button className="btn bg-nxt  file-btn-mrz mx-2 mb-2">
								BROWES PASSPORT
								<input
									type="file"
									className="custom-file-mrz"
									onChange={(e) => {
										setPassportPhoto(
											window.URL.createObjectURL(e.target.files[0])
										);

										setFiles({
											...files,
											passport_image: e.target.files[0],
										});
									}}
									multiple
								/>
							</button>

							{files.passport_image ? (
								<>
									{!files?.croppedImage ? (
										<p>{onlineVisa.pleaseSelectMRZ}</p>
									) : (
										""
									)}
									<ReactCrop
										crop={crop}
										onChange={(c) => setCrop(c)}
										onComplete={onCropComplete}
									>
										<img ref={imgRef} width="100%" src={PassportPhoto} />
									</ReactCrop>
								</>
							) : null}

							{/* <button className="btn bg-nxt  file-btn-mrz  mx-2">
								BROWES MRZ
								<input
									type="file"
									className="custom-file-mrz"
									onChange={(e) => {
										setFiles({
											...files,
											mrz_image: e.target.files[0],
										});
									}}
									multiple
								/>
							</button> */}
							{/* {files.mrz_image ? (
								<i class="fas fa-check-circle txt-gold-onlinevisa"></i>
							) : null} */}
							{/* \croppedImageUrl show */}
							{/* <img
								width={"100%"}
								src={files?.croppedImageUrl}
								alt=""
								srcset=""
							/> */}
						</div>
					</div>

					<div className="d-flex justify-content-end">
						<button
							className="btn bg-nxt  file-btn-mrz my-2 "
							disabled={!files?.croppedImage}
							onClick={sendPassport}
						>
							Insert
						</button>
					</div>
				</div>
			) : (
				<p className="text-center p-5 text-gray h4">
					You have completed your pax
				</p>
			)}{" "}
		</>
	);
}
