import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";
export default function Accounting(props) {
	//tabel Date
	const data = [
		{
			ref: "Taha",
			app_no: "12",
			app_owner: "taha",
			pax: "20",
			booking_date: "12/5/2010",
			source: "Source Name",
		},
	];
	return (
		<>
			<h3
				className="container-custom py-2 font-weight-bold"
				style={{ color: "#324356" }}
			>
				Accounting
			</h3>
			<div div className="container-custom" style={{ background: "#fff" }}>
				<div>
					<div className="row align-items-center w-100 no-gutter m-0  ">
						<div className="col-md-6">
							<SelectField
								//hasLabel={false}
								label={"Discount "}
								placeholder={"SourSelect discount namece"}
								// options={status}
								// value={filters.source.name}
								// onChange={(e) =>
								// 	setFilters({
								// 		...filters,
								// 		source: { id: e.value, name: e.label },
								// 	})
								//}
							/>
						</div>

						<div className="col-md-6">
							<div className="main-label">
								<SelectField
									//hasLabel={false}
									label={"additional Discount"}
									placeholder={"additional Discount"}
									// options={status}
									// value={filters.source.name}
									// onChange={(e) =>
									// 	setFilters({
									// 		...filters,
									// 		source: { id: e.value, name: e.label },
									// 	})
									//}
								/>
							</div>
						</div>
					</div>
					<div className="row align-items-center w-100 no-gutter m-0  ">
						<div className="col-md-6">
							<div className="price-block d-flex justify-content-between bg-gray border-radius-sm  py-3 px-2">
								<p className="font-weight-bold">Total Application Price</p>
								<p className="font-weight-bolder">120,000 EGP</p>
							</div>
						</div>

						<div className="col-md-6">
							<div className="price-block d-flex justify-content-between bg-gray border-radius-sm  py-3 px-2">
								<p className="font-weight-bold">Total Price After Discount</p>
								<p className="font-weight-bolder">120,000 EGP</p>
							</div>
						</div>
					</div>

					<div className="row align-items-center w-100 no-gutter mx-0 my-2   ">
						<div className="col-md-6">
							<div className="price-block d-flex justify-content-between bg-gray border-radius-sm  py-3 px-2 text-success">
								<p className="font-weight-bold">Paid</p>
								<p className="font-weight-bolder">120,000 EGP</p>
							</div>
						</div>

						<div className="col-md-6">
							<div className="price-block d-flex justify-content-between bg-gray border-radius-sm  py-3 px-2 text-danger">
								<p className="font-weight-bold">Un Paid </p>
								<p className="font-weight-bolder">120,000 EGP</p>
							</div>
						</div>
					</div>
				</div>

				<div>
					<Table striped className="custom-table p-2">
						<thead>
							<tr>
								<th>{"Amount"}</th>
								<th>{"Date"}</th>
								<th width="50%">{"Comment"}</th>
							</tr>
						</thead>

						<tbody>
							{data?.length > 0 ? (
								data?.map((member, index) => (
									<>
										<tr>
											<td>{"10,000 EGP"}</td>
											<td>{"21/09/2020"}</td>
											<td>
												Lorem Ipsum is simply dummy text of the printing and
												typesetting industry.
											</td>
										</tr>
										<tr>
											<td>{"10,000 EGP"}</td>
											<td>{"21/09/2020"}</td>
											<td>
												Lorem Ipsum is simply dummy text of the printing and
												typesetting industry.
											</td>
										</tr>
									</>
								))
							) : (
								<tr>
									<td colSpan="9">
										<div className="product-build__product-no-data fullHeight">
											<i className="icx icx-eye-slash" aria-hidden="true"></i>
											<h4>{"No Result"}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>

				<div>
					<div className="main-filter d-flex">
						<div className="row align-items-center w-100 no-gutter m-0  ">
							<div className="col-md-3">
								<div className="main-label">
									<TextField
										type="text"
										label={"Amount "}
										placeholder={"Type Amount"}
										// value={filters.search}
										// onChange={(e) =>
										// 	setFilters({ ...filters, search: e.target.value })
										// }
									/>
								</div>
							</div>

							<div className="col-md-3">
								<DatePickerField
									label={"Date "}
									placeholder={"DD/MM/YYYY"}

									//date={filters.date}
									//value={filters.date}
									// onChangeDate={(date) =>
									// 	setFilters({
									// 		...filters,
									// 		date: date,
									// 	})
									// }
								/>
							</div>

							<div className="col-md-3">
								<div className="main-label">
									<TextField
										hasLabel={false}
										type="text"
										label={"Comment "}
										placeholder={"Type Comment"}
										// value={filters.search}
										// onChange={(e) =>
										// 	setFilters({ ...filters, search: e.target.value })
										// }
									/>
								</div>
							</div>

							<div className="col-md-3">
								<button className="btn btn-primary w-100 py-3">Add Pay</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
