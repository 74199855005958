import TextField from "components/Form/TextField/TextField";
import React from "react";
import Locale from "translations";

export const Agents = () => {
	const { visa } = Locale;
	return (
		<div className="agents ">
			<div className="contract-name ">
				<div className="row align-items-center w-100 no-gutter m-0 ">
					<div className="col-md-6 contract-name-containt ">
						<TextField
							hasLabel={true}
							type="text"
							label={visa.Contract}
							placeholder={visa.Contract}
						/>
					</div>
				</div>
			</div>

			{/* Saudi lab */}
			<div className="agent-item saudi mt-2">
				<div className="agent-container  w-100 ">
					<div className="agent-name ">
						<h3 className="dark-blue-color">{visa.SaudiAgent}</h3>
					</div>

					<div className="agent-content bg-white">
						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={"UASP"}
									placeholder={"UASP"}
								/>
							</div>

							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.UserName}
									placeholder={visa.UserName}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.Password}
									placeholder={visa.Password}
								/>
							</div>
						</div>

						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.UmrahCompantName}
									placeholder={visa.UmrahCompantName}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.UmrahCompantCode}
									placeholder={visa.UmrahCompantCode}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="agent-item saudi mt-2">
				<div className="agent-container  w-100 ">
					<div className="agent-name ">
						<h3 className="dark-blue-color">{visa.SaudiAgent}</h3>
					</div>

					<div className="agent-content bg-white">
						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.CompantName}
									placeholder={visa.CompantName}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={"EA Code"}
									placeholder={"EA Code"}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.Address}
									placeholder={visa.Address}
								/>
							</div>
						</div>

						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.CompanyOwner}
									placeholder={visa.CompanyOwner}
								/>
							</div>

							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.Agent}
									placeholder={visa.Agent}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.Phone}
									placeholder={visa.Phone}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="agent-item egypt-gate mt-2">
				<div className="agent-container  w-100 ">
					<div className="agent-name ">
						<h3 className="dark-blue-color">{visa.EgyptGate}</h3>
					</div>

					<div className="agent-content bg-white">
						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.UserName}
									placeholder={visa.UserName}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.Password}
									placeholder={visa.Password}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="agent-item hayya mt-2">
				<div className="agent-container  w-100 ">
					<div className="agent-name ">
						<h3 className="dark-blue-color">{visa.Hayya}</h3>
					</div>

					<div className="agent-content bg-white">
						<div className="row align-items-center w-100 no-gutter m-0 ">
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.AGENCYCODE}
									placeholder={visa.AgenyCodePlaceholder}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.VERIFICATIONCODE}
									placeholder={visa.VERIFICATIONCODEPlaceholder}
								/>
							</div>

							<div className="col-md-4">
								<TextField
									hasLabel={true}
									type="text"
									label={visa.AGENCYNAME}
									placeholder={visa.AGENCYNAMEPlaceholder}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
