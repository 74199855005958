import visaImg from "assets/images/1Online.svg";
import transportationImg from "assets/images/inventory/carColored.svg";
import HotelImg from "assets/images/inventory/HotelsColored.svg";
import one from "assets/images/LandPage/1.png";
import two from "assets/images/LandPage/2.png";
import three from "assets/images/LandPage/3.png";
import lineAr from "assets/images/LandPage/lineArabic.png";
import line from "assets/images/LandPage/sec2Line.png";
import SelectField from "components/Form/SelectField/SelectField";
import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import TextField from "components/Form/TextField/TextField";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useSBSState } from "context/global";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchCountries } from "services/lookups";
import Locale from "translations";
import airIcon from "../../assets/images/LandPage/airIcon.svg";
import dashboardIcon from "../../assets/images/LandPage/dashboardIcon.png";
import hotalServices from "../../assets/images/LandPage/hotalServices.svg";
import lineAR from "../../assets/images/LandPage/lineAR.png";
import lineEN from "../../assets/images/LandPage/lineEN.png";
import pointerIcon from "../../assets/images/LandPage/pointer.png";
import photoSEC3 from "../../assets/images/LandPage/sec3.png";
import card from "../../assets/images/LandPage/service/card.png";
import doc from "../../assets/images/LandPage/service/doc.png";
import doller from "../../assets/images/LandPage/service/doller.png";
import dollerHand from "../../assets/images/LandPage/service/dollerHand.png";
import hotalIcon from "../../assets/images/LandPage/service/hotalIcon.png";
import money from "../../assets/images/LandPage/service/money.png";
import offer from "../../assets/images/LandPage/service/offer.png";
import offer1 from "../../assets/images/LandPage/service/offer1.png";
import setting from "../../assets/images/LandPage/service/setting.png";
import walletService from "../../assets/images/LandPage/service/walletIcon.png";
import soonEN from "../../assets/images/LandPage/soonEN1.png";
import supportIcon from "../../assets/images/LandPage/support.png";
import transportainService from "../../assets/images/LandPage/transportainService.svg";
import userIcon from "../../assets/images/LandPage/userIcon.png";
import visaIcon from "../../assets/images/LandPage/visaIcon.png";
import walletIcon from "../../assets/images/LandPage/walletIcon.svg";
import SafaLogo from "../../assets/images/MarketInvoice/SafaLogo.png";

export default function LandPage() {
	const { landing } = Locale;
	const { locale } = useSBSState();

	const history = useHistory();
	const [countries, setCountries] = useState([]);
	const [isHovering, setIsHovering] = useState({
		flag: false,
		servesName: "",
	});
	const [formState, setFormState] = useState({
		country: null,
	});
	useEffect(() => {
		async function fetchData() {
			const res = await fetchCountries();

			if (res.status == 200 || res.length > 0) {
				setCountries(res);
			}
		}
		fetchData();
	}, []);

	const handleMouseOver = (e) => {
		//setIsHovering(true);
		setIsHovering({
			flag: true,
			servesName: e.target?.attributes?.name?.value,
		});
	};
	return (
		<>
			<div className="landPage">
				<div className="container-custom-land">
					<header className="d-flex  justify-content-between py-3">
						<figure className="safa-logo">
							<img src={SafaLogo} alt="" className="img-fluid" />
						</figure>
						<div className="d-flex   align-items-center ">
							<div className="">
								<button
									className="btn-landPage"
									onClick={() => history.push("/auth/login")}
								>
									{landing.login}
								</button>
							</div>
							<div>
								<LanguageSwitcher />
							</div>
						</div>
					</header>
					<dev className="sec1">
						<div className="w-100">
							<h2>{landing.safaBusinessSolutions}</h2>
							<h3>{landing.portalToBook}</h3>
						</div>
						<div className="d-flex home-service">
							<div className="box-item-home">
								<img
									src={HotelImg}
									alt=""
									width={35}
									height={35}
									className="mx-2"
								/>
								{landing.hotels}
							</div>
							<div className="box-item-home">
								{" "}
								<img
									src={visaImg}
									alt=""
									width={35}
									height={35}
									className="mx-2"
								/>
								{landing.visas}{" "}
							</div>
							<div className="box-item-home">
								{" "}
								<img
									src={transportationImg}
									alt=""
									width={35}
									height={35}
									className="mx-2"
								/>
								{landing.transportation}
							</div>
						</div>
						<div className="w-100">
							<div className="home-input-containt">
								<input
									type="text"
									placeholder={landing.registerPlaceholder}
									className="home-input w-100"
								/>
								<button className="btn btn-yellow">
									{landing.freeRegistration}
								</button>
							</div>
						</div>
						<div className="w-100">
							<div className="d-flex home-list">
								<div className="list-item-home d-flex">
									<div className="list-item-home-icon">
										<i class="fas fa-check"></i>
									</div>
									<span>{landing.easyToUse}</span>
								</div>
								<div className="list-item-home d-flex">
									<div className="list-item-home-icon">
										<i class="fas fa-check"></i>
									</div>
									<span>{landing.technicalSupport}</span>
								</div>
								<div className="list-item-home d-flex">
									<div className="list-item-home-icon">
										<i class="fas fa-check"></i>
									</div>
									<span>{landing.multiplePaymentMethods}</span>
								</div>
							</div>
						</div>
					</dev>
				</div>
			</div>
			{locale == "en" ? (
				<div className="sec2 container-custom-land" style={{ height: "800px" }}>
					<div className="header-sec2 ">
						<p>MANAGE YOUR PROFIT WITH ONE STOP SOLUTION</p>
						<h2>B2B Solution For Travel Agencies</h2>
						<div>
							<span>Agents And Partners</span>
							<p className="w-25">
								More than 2,600 travel agencies in 13 countries around the world
								trust Safa’s services, start now your free trial, and enjoy many
								products and services at the lowest rate.
							</p>
							<button className="btn btn-yellow">GET STARTED</button>
						</div>
					</div>

					<div className="containt-sec2">
						<img src={line} alt="" className="img-fluide" />
						<div className="sec2-box d-flex">
							<div className="sec2-box-item">
								<div className="dot"></div>
								<h3>Payment Options</h3>
								<p>
									Payment options: fast and secure payment experience, Safa soft
									provides a variety of financial transaction options including
									payment by Credit Card, Debit Card, and Safa wallet.
								</p>
								<div className="bg-box">
									<img src={one} alt="" />
								</div>
							</div>
							<div className="sec2-box-item">
								<div className="dot"></div>
								<h3>Technical Support</h3>
								<p>
									Safa Soft provides general support for every process that
									takes place on the software, whether technical support or
									operations management, 24/7.
								</p>
								<div className="bg-box">
									<img src={two} alt="" />
								</div>
							</div>
							<div className="sec2-box-item">
								<div className="dot"></div>
								<h3>Vouchers And Invoices</h3>
								<p>
									Save your time, money, and headache. Having all of your
									reservation details in one place, helps you track open
									reservations and print the financial information from
									finalized bookings.
								</p>
								<div className="bg-box">
									<img src={three} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="sec2 container-custom-land" style={{ height: "800px" }}>
					<div className="header-sec2 ">
						<p>إدارة كل الخدمات السياحية من مكان واحد</p>
						<h2>طـور أعمالـك لزيــادة أرباحــك ومتـابعــة التقارير اللحظية</h2>
						<div>
							<span>العملاء و الشركاء</span>
							<p className="w-25">
								أكثر من 4600 شركة سياحة في 13 دولة تستخدم منصة صفا لخدمات
								الاعمال.
							</p>
							<button className="btn btn-yellow">إبدأ الآن</button>
						</div>
					</div>

					<div className="containt-sec2">
						<img src={lineAr} alt="" className="img-fluide" />
						<div className="sec2-box d-flex">
							<div className="sec2-box-item">
								<div className="dot"></div>
								<h3>خيارات للدفع</h3>
								<p>
									خيارات متعددة للمعاملات المالية إما محفظة صفا الأئتمانية أو
									الدفع آونلاين (فيزا – ماستر كارد)
								</p>
								<div className="bg-box">
									<img src={one} alt="" />
								</div>
							</div>
							<div className="sec2-box-item">
								<div className="dot"></div>
								<h3>دعم فنى 24/7</h3>
								<p>
									دعم عام لكل عملية تتم على النظام سواء دعم تقني أو إدارة
									العمليات على مدار اليوم طوال أيام الأسبوع.
								</p>
								<div className="bg-box">
									<img src={two} alt="" />
								</div>
							</div>
							<div className="sec2-box-item">
								<div className="dot"></div>
								<h3>فواتير مفصلة</h3>
								<p>
									إدارة الحجوزات تمكنك من متابعة كافة الحجوزات مع إمكانية طباعة
									الفواتير – فاوتشر الحجوزات.
								</p>
								<div className="bg-box">
									<img src={three} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="sec3 justify-content-center position-relative">
				<img src={photoSEC3} className="w-100" alt="" srcset="" />
				<div className="w-100 d-flex position-top-sec3">
					<span className="services-span  ">{landing.services}</span>
				</div>
				<div className="ItemS-line position-relative">
					<img
						className="center-block "
						src={locale == "en" ? lineEN : lineAR}
						alt=""
						srcset=""
					/>
					<div className="container-BoxLand">
						<div
							className={`item-BoxLand  ${
								isHovering.flag && isHovering.servesName == "visa"
									? "activeLand"
									: ""
							}`}
							name="visa"
							onMouseOver={(e) => handleMouseOver(e)}
							onClick={(e) => handleMouseOver(e)}
						>
							{isHovering.servesName == "visa" && (
								<span className="item-BoxLand-name">{landing.Visas}</span>
							)}
							<img src={visaImg} alt="" srcset="" />
						</div>
						<div
							className={`item-BoxLand ${
								isHovering.flag && isHovering.servesName == "hotel"
									? "activeLand"
									: ""
							}`}
							name="hotel"
							onMouseOver={(e) => handleMouseOver(e)}
							onClick={(e) => handleMouseOver(e)}
						>
							{isHovering.servesName == "hotel" && (
								<span className="item-BoxLand-name">{landing.Hotels}</span>
							)}
							<img src={hotalServices} alt="" srcset="" />
						</div>
						<div
							className={`item-BoxLand  ${
								isHovering.flag && isHovering.servesName == "transportation"
									? "activeLand"
									: ""
							}`}
							name="transportation"
							onMouseOver={handleMouseOver}
							onClick={(e) => handleMouseOver(e)}
						>
							{isHovering.servesName == "transportation" && (
								<span className="item-BoxLand-name">
									{landing.Transportation}
								</span>
							)}
							<img src={transportainService} alt="" srcset="" />
						</div>
						<div
							className={`item-BoxLand  ${
								isHovering.flag && isHovering.servesName == "flight"
									? "activeLand"
									: ""
							}`}
							name="flight"
							onMouseOver={handleMouseOver}
							onClick={handleMouseOver}
						>
							{isHovering.servesName == "flight" && (
								<span className="item-BoxLand-name">{landing.Flight}</span>
							)}
							<img src={airIcon} alt="" srcset="" />
						</div>
						<div
							className={`item-BoxLand  ${
								isHovering.flag && isHovering.servesName == "wallet"
									? "activeLand"
									: ""
							}`}
							name="wallet"
							onMouseOver={handleMouseOver}
							onClick={(e) => handleMouseOver(e)}
						>
							{isHovering.servesName == "wallet" && (
								<span className="item-BoxLand-name">{landing.Wallet}</span>
							)}
							<img src={walletIcon} alt="" srcset="" />
						</div>
					</div>
				</div>
				{isHovering.flag && isHovering.servesName == "visa" && (
					<div className="ItemS-line pt-280 bg-white">
						<div className="row m-0">
							<div className="col-md-4">
								<div className="box-cradLand">
									<p>
										<i class="fas fa-sync"></i>
									</p>
									<p>{landing.visaService.sec1}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									{/* <p>
									<i class="fas fa-sync"></i>
								</p> */}
									<img src={pointerIcon} alt="" height={35} width={50} />
									<p>{landing.visaService.sec2}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={visaIcon} height={35} width={50} alt="" />
									<p>{landing.visaService.sec3}</p>
								</div>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={dashboardIcon} height={35} width={50} alt="" />
									<p>{landing.visaService.sec4}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={userIcon} height={35} width={50} alt="" />

									<p>{landing.visaService.sec5}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={supportIcon} height={35} width={50} alt="" />

									<p>{landing.visaService.sec6} </p>
								</div>
							</div>
						</div>
					</div>
				)}
				{isHovering.flag && isHovering.servesName == "hotel" && (
					<div className="ItemS-line pt-280 bg-white">
						<div className="row m-0">
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={pointerIcon} alt="" height={35} width={50} />

									<p>{landing.hotelService.sec1}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={hotalIcon} alt="" height={35} width={50} />
									<p>{landing.hotelService.sec2}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={offer} height={35} width={50} alt="" />
									<p>{landing.hotelService.sec3}</p>
								</div>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={supportIcon} height={35} width={50} alt="" />
									<p>{landing.hotelService.sec4}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={offer1} height={35} width={50} alt="" />

									<p>{landing.hotelService.sec5}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={doc} height={35} width={50} alt="" />

									<p>{landing.hotelService.sec6} </p>
								</div>
							</div>
						</div>
					</div>
				)}

				{isHovering.flag && isHovering.servesName == "wallet" && (
					<div className="ItemS-line pt-280 bg-white">
						<div className="row m-0">
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={card} alt="" height={35} width={50} />

									<p>{landing.walletService.sec1}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={walletService} alt="" height={35} width={50} />
									<p>{landing.walletService.sec2}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={dollerHand} height={35} width={50} alt="" />
									<p>{landing.walletService.sec3}</p>
								</div>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={money} height={35} width={50} alt="" />
									<p>{landing.walletService.sec4}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={setting} height={35} width={50} alt="" />

									<p>{landing.walletService.sec5}</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="box-cradLand">
									<img src={doller} height={35} width={50} alt="" />

									<p>{landing.walletService.sec6}</p>
								</div>
							</div>
						</div>
					</div>
				)}
				{isHovering.flag && isHovering.servesName == "transportation" && (
					<div className="ItemS-line pt-280 bg-white">
						<img src={soonEN} alt="" />
					</div>
				)}
				{isHovering.flag && isHovering.servesName == "flight" && (
					<div className="ItemS-line pt-280 bg-white">
						<img src={soonEN} alt="" />
					</div>
				)}
				<div
					className={`${
						isHovering.servesName == ""
							? "ItemS-line ItemS-line-containt pt-280 bg-white"
							: ""
					}`}
				></div>
			</div>
			<div className="form-landing " style={{ height: "800px" }}>
				<div className="bg-img py-5">
					<div className="container-form">
						<h2>{landing.someQuestions} </h2>
						<p>{landing.fillForm}</p>
						<div className="form-controls">
							<div className="row">
								<div className="col-6">
									<TextField
										placeholder={landing.Name}
										label={landing.namePlaceholder}
										//value={}
										name="name"
										onChange={(e) => {}}
									/>
								</div>
								<div className="col-6">
									<TextField
										placeholder={landing.email}
										label={landing.emailPlaceholder}
										//value={}
										name="email"
										onChange={(e) => {}}
									/>
								</div>
								<div className="col-6">
									<SelectField
										label={landing.country}
										placeholder={landing.select}
										options={countries}
										value={formState.country?.label}
										name="country"
										onChange={(e) => {
											setFormState({
												...formState,
												country: e,
											});
										}}
									/>
								</div>
								<div className="col-6">
									<TextField
										extraText={formState.country?.phone_code ?? "+20"}
										extraTextPosition="prepend"
										placeholder={landing.Phone}
										label={landing.mobileNo}
										//value={}
										name="phone"
									/>
								</div>
								<div className="col-6">
									<TextField
										label={landing.companyName}
										placeholder={landing.companyPlaceholder}
										//value={}
										name="company_name"
									/>
								</div>
								<div className="col-6">
									<TextField
										label={landing.subject}
										placeholder={landing.subjectPlaceholder}
										//value={}
										name="subject"
									/>
								</div>
								<div className="col-12">
									<TextAreaField
										label={landing.Message}
										placeholder={landing.messagePlaceholder}
										rows={3}
									/>
								</div>

								<div className="col-12">
									<button className="btn btn-yellow w-100 py-2">
										{landing.send}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer" style={{ height: "50px" }}>
				<p>
					Copyright <span>&copy;</span> <bold>Safa Soft</bold> 2022. All Rights
					Reserved.
				</p>
			</div>
		</>
	);
}
