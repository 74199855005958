import datePickerIcon from "assets/images/datePicker.svg";
import { useSBSState } from "context/global";
import moment from 'moment';
import { useState } from "react";
import { isInclusivelyAfterDay, isInclusivelyBeforeDay, SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Locale from "translations";

export default function DatePickerField(props) {
	const [focused, setFocused] = useState(false);
	const { locale } = useSBSState();
	const { commons } = Locale;

	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(` should be at least ${props.min} chars`);
				} else if (k === "email" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "phone" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "number" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "date" && v) {
					message.push(commons.shouldBeValid);
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	return (
		<>
			{/* Label */}
			{props.hasLabel ? (
				<label
					className={`control-field__label ${props.color ? " control-field__label--" + props.color : ""
						}`}
				>
					{props.label}
				</label>
			) : null}
			<div
				className={`my-1 control-field + ${props.color ? " control-field--" + props.color : ""
					} ${props.disabled ? "isDisabled" : ""
					}`}
			>
				{/* Date Range Body */}
				<div
					className={`control-field__body newFixPadding position-relative ${props.disabled && props.readOnly ? "isDisabled" : ""
						}`}
				>
					{/* Date Inputs */}
					<div className="no-gutters">
						<SingleDatePicker
							numberOfMonths={1}
							onOutsideClick={true}
							date={props.date}
							noBorder={true}
							disabled={props.disabled}
							focused={focused}
							displayFormat={() => "DD/MM/YYYY"}
							onFocusChange={({ focused }) => setFocused(focused)}
							onDateChange={props.onChangeDate}
							isOutsideRange={(day) =>
								isInclusivelyBeforeDay(day, props.minDate === false ? null : props.minDate ? props.minDate : moment(new Date()).subtract(1, "days")) ||
								isInclusivelyAfterDay(day, props.maxDate ? props.maxDate : null)
							}
							placeholder={props.placeholder}
							hideKeyboardShortcutsPanel={true}
							customArrowIcon={true}
							isRTL={locale === "en" ? false : true}
							anchorDirection={locale === "en" ? "left" : "right"}
							{...props}
						/>
						{/* <i className="fas fa-calendar-alt date-icon p-2"></i> */}
						<img className="date-icon p-2" src={datePickerIcon} alt="" />
					</div>
				</div>
			</div>

			{/* Feedback */}
			{/* <small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small> */}
			{/* Error Message */}
			<small
				className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</>
	);
}

DatePickerField.defaultProps = {
	hasLabel: true,
};
