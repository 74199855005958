import background from "assets/images/1visaback.jpeg";
import visa from "assets/images/new-visa-colored.svg";
import classnames from "classnames";
import { useSBSState } from "context/global";
import { useRef, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import Locale from "translations";
import VisaSearch from "./VisaSearch";

function OnlineVisa({ allCountries }) {
	const { onlineVisa, marketPlace } = Locale;
	const { locale } = useSBSState();
	const divHigth = useRef({});
	let clientHeight = divHigth.current.clientHeight;

	const [activeTab, setActiveTab] = useState("1");

	const toggle = (tab) => {
		setActiveTab(tab);
	};

	return (
		<>
			<div className="market-search mb-4">
				<div className="main-tabs w-100 p-0" ref={divHigth}>
					<Nav tabs className="align-items-center main-nav">
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggle("1");
								}}
							>
								<img
									className="px-2"
									src={visa}
									alt=""
									srcSet=""
									width="50px"
								/>
								{onlineVisa.onlineVisa}
							</NavLink>
						</NavItem>
					</Nav>
						<div className="heading col-md-10 py-3 m-auto">
							<h5 className="mt-3 txt-blue">
								{onlineVisa.ISSUINGVISASREQUEST}
							</h5>
							<h3 className="text-gray ">{onlineVisa.CompleteVisa}</h3>
						</div>

						<TabContent activeTab={activeTab}>
							<VisaSearch allCountries={allCountries} />
						</TabContent>
					</div>
				<div
					className="search-banner"
					style={{
						backgroundImage: `url(${background})`,
						height: `${clientHeight}px`,
					}}
				></div>
			</div>
		</>
	);
}

export default OnlineVisa;
