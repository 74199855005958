import React, { useState } from "react";
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Row,
	Col,
} from "reactstrap";
import classnames from "classnames";
import SelectField from "components/Form/SelectField/SelectField";
import useFetchCountries from "hooks/useFetchCountries";
import Locale from "translations";
import AdditionalServiceAccommodation from "./AdditionalServiceAccommodation";
import AccountingAccommodation from "./AccountingAccommodation";
import HotelMakkahAccommodation from "./HotelMakkahAccommodation";
import HotelMadinahAccommodation from "./HotelMadinahAccommodation";

export default function AccommodationTab(props) {
	const [countries] = useFetchCountries();
	const { reservation } = Locale;

	const [activeTabHotel, setActiveTabHotel] = useState("1");
	const toggleHotel = (tab) => {
		if (activeTabHotel !== tab) setActiveTabHotel(tab);
	};

	//ForExample To Searchable SelectField
	const countriesList = countries.map((country, index) => (
		<option key={`country-${country.id}${index}`} value={country.id}>
			{country.name}
		</option>
	));

	return (
		<>
			<Nav tabs className="hotel-tab">
				<NavItem>
					<NavLink
						className={classnames({ active: activeTabHotel === "1" })}
						onClick={() => {
							toggleHotel("1");
						}}
					>
						{reservation.messages.hotelMakkah}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTabHotel === "2" })}
						onClick={() => {
							toggleHotel("2");
						}}
					>
						{reservation.messages.hotelMadinah}
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTabHotel} className="makkah-content">
				<TabPane tabId="1">
					<HotelMakkahAccommodation />
				</TabPane>
				<TabPane tabId="2">
					<HotelMadinahAccommodation />
				</TabPane>
			</TabContent>

			{/* Additional Service */}
			<AdditionalServiceAccommodation />

			{/* Accounting */}
			<AccountingAccommodation />

			<div className="save-buttons d-flex flex-row-reverse py-4">
				<button className="btn btn-primary px-5 mx-1">Save Changes</button>
				<button className="btn btn-default mx-1 border">Cancle</button>
			</div>
		</>
	);
}
