import React, { useState } from "react";
import SelectField from "components/Form/SelectField/SelectField";
import { useHistory } from "react-router-dom";
import useFetchCountries from "hooks/useFetchCountries";
import Locale from "translations";

import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from "classnames";
import TextField from "components/Form/TextField/TextField";

export default function AddNewBank(props) {
	const [activeTab, setActiveTab] = useState("1");
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const history = useHistory();
	const { paymentAdmin } = Locale;

	const [currency, setCurrency] = useState("");
	const [countries] = useFetchCountries();
	const [country, setCountry] = useState([]);
	const [specifiedCompany, setSpecifiedCompany] = useState([]);

	const [formValidation, setFormValidation] = useState({
		currency: true,
		country: true,
		specifiedCompany: true,
	});

	// Validate CurrencyName Field
	const validateCurrencyField = () => {
		setFormValidation({
			...formValidation,
			currency: currency.length > 2 ? true : false,
		});
	};

	// Validate Country
	const validateCountry = () => {
		setFormValidation({
			...formValidation,
			country: country !== 0 ? true : false,
		});
	};

	//Submit Form
	const submit = () => {
		if (Object.values(formValidation).every((item) => item === true)) {
			alert(`
			Currency Name => : ${currency}
			Countries => : ${JSON.stringify(country)}
			SPECIFIED Companies => : ${JSON.stringify(specifiedCompany)}
		`);
		}
	};

	return (
		<>
			<div className="payment-admin">
				<div className="text-center py-4 text-title ">
					<h2 className="text-decor text-dark">{paymentAdmin.messages.menu}</h2>
				</div>

				<div className="bg-white py-3 px-2">
					<h2 className="font-weight-bold">
						{paymentAdmin.messages.addNewBank}
					</h2>
				</div>

				<div className="my-2">
					<div className="row m-0">
						<div className="col-md-6">
							<div className="form-group full-input">
								<TextField
									label={paymentAdmin.messages.bankName}
									placeholder={paymentAdmin.messages.bankNamePlaceholder}
									selectPosition="append"
									feedbackMessage={
										paymentAdmin.validation.currencyNameValidationMessage
									}
									value={currency}
									onChange={(e) => setCurrency(e.target.value)}
									onBlur={validateCurrencyField}
									color={formValidation.currency ? "" : "danger"}
									isInvalid={!formValidation.currency}
								/>
							</div>
						</div>

						<div className="main-tabs col-md-12 bg-transparent">
							<h6 className="font-weight-bold">Active Currencies</h6>
							<Nav tabs className="align-items-center main-nav">
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === "1" })}
										onClick={() => {
											toggle("1");
										}}
									>
										EGP
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === "2" })}
										onClick={() => {
											toggle("2");
										}}
									>
										USD
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === "3" })}
										onClick={() => {
											toggle("3");
										}}
									>
										EUR
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									<div className="col-md-12">
										<div className="form-group full-input">
											<SelectField
												multi
												label={paymentAdmin.messages.selectSpecifiedCompanies}
												options={countries}
												defaultValue={
													paymentAdmin.messages
														.selectSpecifiedCompaniesPlaceholder
												}
												feedbackMessage={
													paymentAdmin.currencySpecifiedValidationMessage
												}
												onChange={(values) => setCountry([...country, values])}
												onBlur={validateCountry}
												color={formValidation.country ? "" : "danger"}
												isInvalid={!formValidation.country}
												value={specifiedCompany.label}
											/>
										</div>
									</div>
								</TabPane>
								<TabPane tabId="2">
									<div className="col-md-12">
										<div className="form-group full-input">
											<SelectField
												multi
												label={paymentAdmin.messages.selectCountries}
												options={countries}
												defaultValue={
													paymentAdmin.messages.selectCountriesPlaceholder
												}
												feedbackMessage={
													paymentAdmin.currencyCountryValidationMessage
												}
												onChange={(values) =>
													setSpecifiedCompany([...specifiedCompany, values])
												}
												onBlur={validateCountry}
												color={formValidation.country ? "" : "danger"}
												isInvalid={!formValidation.country}
												value={country.label}
											/>
										</div>
									</div>
								</TabPane>
								<TabPane tabId="3">
									<div className="col-md-12">
										<div className="form-group full-input">
											<SelectField
												multi
												label={paymentAdmin.messages.selectSpecifiedCompanies}
												options={countries}
												defaultValue={
													paymentAdmin.messages
														.selectSpecifiedCompaniesPlaceholder
												}
												feedbackMessage={
													paymentAdmin.currencySpecifiedValidationMessage
												}
												onChange={(values) => setCountry([...country, values])}
												onBlur={validateCountry}
												color={formValidation.country ? "" : "danger"}
												isInvalid={!formValidation.country}
												value={specifiedCompany.label}
											/>
										</div>
									</div>
								</TabPane>
							</TabContent>
						</div>

						<div className="save-buttons d-flex py-4">
							<button
								className="btn btn-default mx-1 border"
								onClick={() => history.push("/online-banking")}
							>
								{paymentAdmin.messages.back}
							</button>
							<button
								className="btn btn-primary px-5 mx-1"
								onClick={submit}
								disabled={
									!Object.values(formValidation).every(
										(item) => item === true
									) || [currency, country, specifiedCompany].includes("")
								}
							>
								{paymentAdmin.messages.saveChange}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
