import walletImg from "assets/images/inventory/walletSm.svg";
import { useState } from "react";
import { Table } from "reactstrap";
import Locale from "translations";

const PaymentDetails = ({
	togglePaymentDetailsModal,
	details,
	setIdGroupPaymentRow,
}) => {
	const { inventory } = Locale;

	const [PaymentList, setPaymentList] = useState(details?.payments);

	const AllPayments =
		PaymentList?.length > 0 ? (
			PaymentList.map((item, index) => (
				<tr className={`${item.status == "Pending" ? "light-danger" : ""}`}>
					<td>{item.option_date}</td>
					<td>
						{item.amount} {details?.currency}
					</td>
					{/* <td>{item.type}</td> */}
					<td>{item.payment_method}</td>
					<td>{item.payment_date}</td>
					<td
						className={`font-weight-bolder ${
							item.status == "confirmed" ? "text-success" : "text-warning"
						}`}
					>
						{item.status}
					</td>
					<td>
						{item.status == "confirmed" ? (
							<i className="fa fa-eye mx-3"></i>
						) : details.status != "canceled" ? (
							<button
								disabled={!item?.is_first_payment}
								className={`btn btn-link text-danger font-weight-bolder text-underline ${
									!item?.is_first_payment ? "opacity-5" : ""
								}`}
								onClick={() => {
									togglePaymentDetailsModal();
									setIdGroupPaymentRow({ id: item?.id, amount: item?.amount });
								}}
							>
								<img src={walletImg} alt="" width={20} height={20} />
								<span className="mt-3">Pay Now</span>
							</button>
						) : null}
					</td>
				</tr>
			))
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-build__product-no-data fullHeight">
						<i className="icx icx-eye-slash" aria-hidden="true"></i>
						<h4>{"No Result"}</h4>
					</div>
				</td>
			</tr>
		);
	return (
		<div className="px-4 my-4 room-details payment-details">
			<div className="passenger ">
				<h3 className="header-hotel-info">{inventory.messages.Payments}</h3>
				<div className="mt-3 invetory-table ">
					<Table striped className="table-update p-2 bg-white  m-0">
						<thead>
							<tr>
								<th>{inventory.messages.OptionDate}</th>
								<th>{inventory.messages.Amount}</th>
								{/* <th>{inventory.messages.paymentType}</th> */}

								<th>{inventory.messages.PaymentMethod} </th>
								<th>{inventory.messages.PaymentDate}</th>
								<th>{inventory.messages.Status}</th>
								<th>{inventory.messages.Actions}</th>
							</tr>
						</thead>
						<tbody>{AllPayments}</tbody>
					</Table>
				</div>
			</div>
		</div>
	);
};
export default PaymentDetails;
