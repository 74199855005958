import axios from "axios";
import { useSBSDispatch, useSBSState } from "context/global";
import Locale from "translations";

let axiosInterceptor = null;
let axiosRequestInterceptor = null;
const AxiosConfiguration = () => {
	let currentLocale = localStorage.getItem("currentLocale") ?? "en";
	let agent_token = localStorage.getItem("agent_token") ?? generator();
	// let IPv4 = localStorage.getItem("IPv4") ?? "";

	let { token } = useSBSState();
	const dispatch = useSBSDispatch();
	const { commons } = Locale;
	function uuidv4() {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
		);
	}

	function generator() {
		let x = uuidv4();
		localStorage.setItem("agent_token", x);
		return x;
	}

	axios.defaults.headers.common["X-Locale"] = currentLocale;
	// axios.defaults.headers.common["X-Client-ID"] = IPv4;
	axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";


	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	axios.defaults.headers.common["X-Agent-Token"] = `${agent_token}`;
	let counter = 0;
	if (!!axiosInterceptor || axiosInterceptor === 0) {
		axios.interceptors.response.eject(axiosInterceptor);
	}

	if (!!axiosRequestInterceptor || axiosRequestInterceptor === 0) {
		axios.interceptors.request.eject(axiosRequestInterceptor);
	}
	axiosRequestInterceptor = axios.interceptors.request.use(
		async (request) => {

			// if (IPv4=="") {
			// 	const response = await fetch('https://ipapi.co/json/')
			// 	const data = await response.json();
			// 	request.headers.common["X-Client-ID"] = data.ip;
			// 	localStorage.setItem("IPv4",data.ip);
			// }
			counter++;
			if (request.data && request.data.hasOwnProperty("search")) {
				return request;
			} else {
				dispatch({ type: "setLoading", payload: true });
				return request;
			}
		},
		(error) => {
			counter--;
		}
	);

	axiosInterceptor = axios.interceptors.response.use(
		(response) => {
			counter--;
			// if (!response.config.url.includes("view_user_profile")) {
			if (counter === 0) {



				dispatch({ type: "setLoading", payload: false });

				if (response.status == 202) {
					// window.location.href = response.data.data.url;

					localStorage.setItem("checkoutId", response.data.data.sessionId);
					window.location.href = "/hyperpay";
				}
				return response;
			} else {
				return response;
			}
		},

		(error) => {
			counter--;
			// Show Alert FOr Error
			if (counter === 0) {
				dispatch({ type: "setLoading", payload: false });
			}
			if (
				error.response &&
				error.response.status === 400 &&
				!window.location.href.includes("wallet") &&
				!error.response.config.url.includes("files/fetch")
			) {
				dispatch({
					type: "setError",
					payload: true,
					message: {
						title: commons.somethingWentWrong,
						body: Array.isArray(error?.response?.data?.message)
							? error?.response?.data?.message.msg
							: error?.response?.data?.message,
					},
				});
			} else if (error.response && error.response.status === 401) {
				dispatch({
					type: "setError",
					payload: true,
					message: { title: "Unauthenticated!", body: "Go Back to Login" },
				});

				localStorage.removeItem("isAuth");
				localStorage.removeItem("token");
				localStorage.removeItem("currentLocale");
				window.location.href = "/auth/login";
			} else if (error.response && error.response.status === 422) {
				let errorsMsgs = ``;
				
				if (!error.config.url.includes("marketplace/hotels/search")) {
				Object.values(error.response.data.errors).map(
					(msg) => (errorsMsgs += msg + "\n")
				);
				}else{
					Object.values(error.response.data.errors).map(
						(msg) => (errorsMsgs = msg)
					);
				}

				dispatch({
					type: "setError",
					payload: true,
					message: { title: error?.response?.data?.message, body: errorsMsgs },
				});
			} else if (error.response && error.response.status === 500) {
				dispatch({
					type: "setError",
					payload: true,
					message: {
						title: error?.response?.data?.message,
						body: "Internal Server Error, Please try again later",
					},
				});
			} else if (error.response && error.response.status === 404) {
				dispatch({
					type: "setError",
					payload: true,
					message: {
						title: error?.response?.data?.message,
						body: "Page not found 404",
					},
				});
			}

			return Promise.reject(error);
		}
	);
};

export default AxiosConfiguration;
