import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { readNotification } from "services/notifaction";

export default function NotificationContent({
	notifyData,
	index,
	notifactionData,
}) {
	const [data, setData] = useState();
	const id = notifyData.id;
	const history = useHistory();

	const readNotify = async (id1) => {
		const res = await readNotification(id1);
		setData(res);
	};

	return (
		<div className=" w-75 mx-auto  mt-0 noty ">
			<div
				className={`d-flex align-items-start  px-3 py-2 read-noty ${
					notifyData?.read_at != null ? "read" : "not-read"
				}`}
			>
				<div className="notify-content w-100 py-2">
					<h3 className="notify-header  text-primary">{notifyData?.title}</h3>
					<div className="notify-body d-flex justify-content-between">
						<p>{notifyData?.body}</p>
						<Link
							// to={notifyData?.url}
							className="btn-link text-center text-grey"
							onClick={() => {
								readNotify(id);
								notifactionData();
								// setTimeout(() => {
								// 	window.location.reload();
								// }, 550);
								if (notifyData.item_type=="groupReservation") {
									history.push(`/Hotels/inventory-group/${notifyData.item_id}`);
								}
								if (notifyData.item_type=="payment_card") {
									history.push(`/wallet`);
								
								}
								
							}}
						>
							<i
								className={`dot-read ${
									notifyData?.read_at != null
										? "fa fa-eye"
										: "  fa fa-eye-slash"
								} `}
							></i>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
