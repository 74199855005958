import visaImg from "assets/images/visaImg.svg";
import { useSBSDispatch } from "context/global";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { logOutVisa, viewUser } from "services/visa";

import Locale from "translations";

export default function SafaProfile() {
	const { login, messages } = Locale;
	const tokenSafaVisa = localStorage.getItem("VisaToken");
	let history = useHistory();
	const dispatch = useSBSDispatch();

	const [loginData, setLoginData] = useState({
		safa_key: "",
		email: "",
	});

	useEffect(() => {
		const fetchUser = async () => {
			const res = await viewUser({ token: tokenSafaVisa });
			setLoginData({ ...res.data.data });
		};

		fetchUser();
	}, []);

	const logOutUser = async () => {
		let requestBody = {
			token: localStorage.getItem("VisaToken"),
		};
		const response = await logOutVisa(requestBody);
		if (response?.status === 200) {
			localStorage.removeItem("VisaToken");
			history.push("/");
		} else {
			dispatch({
				type: "setError",
				payload: true,
				message: {
					title: null,
					body: response?.data?.message,
				},
			});
		}
		// setSpin(false);
	};

	return (
		<>
			<div className="container ">
				<div className=" d-flex align-items-center   log-first  ">
					<div className="d-flex container  justify-content-start align-items-center ">
						{/* Left Banner */}
						<figure className="col-5 pt-3 m-0 d-flex  justify-content-end px-5">
							<img src={visaImg} alt="" className="img-fluid" />
						</figure>

						{/* Login Form */}
						<div className="col-5 d-flex  flex-column  justify-content-between">
							<div className="w-100 ">
								<h1 className="text-title font-weight-bold ">
									Your Safe Visa Account Is Linked
								</h1>
								<p className="mb-4"> you can manage groups and contracts</p>
								<div className="col-8">
									<div className="d-flex">
										<p className="m-0 ">Safa Key : </p>
										<p className="m-0 font-weight-bold">{loginData.safa_key}</p>
									</div>
									<div className="d-flex my-2">
										<p className="m-0 ">Email : </p>
										<p className="m-0 font-weight-bold">{loginData.email}</p>
									</div>
								</div>
								<button
									className="btn bg-nxt w-75 mt-4"
									onClick={logOutUser}
									//disabled={spin}
								>
									{/* {spin ? <Spinner color="light" size="sm" /> : null} */}
									{"  "} {messages.logout}
								</button>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
