import { useSBSState } from "context/global";
import { WalletProvider } from "context/wallet";
import DashboardLayout from "layouts/DashboardLayout";
import React from "react";
import { Redirect, Route } from "react-router-dom";

// React Component
export default function WalletRoute({ component: Component, ...props }) {
	const { isAuth } = useSBSState();

	if (isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<WalletProvider>
						<DashboardLayout>
							<Component {...matchProps} />
						</DashboardLayout>
					</WalletProvider>
				)}
			/>
		);
	} else {
		return <Redirect to="/" />;
	}
}
